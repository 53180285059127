import { useParams } from 'react-router-dom'
import { DetailsBlock, SettingsCard } from '.'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useUpdateJobPosition } from 'src/hooks/mutations/use-update-job-position'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import { Form, Input, RadioGroup } from 'src/components/forms'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Button } from 'src/components/primitives/button'
import { useListJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import { pluralize } from 'src/libs/pluralize'
import { useBatchAutoApproveJobSearchRefinements } from 'src/hooks/mutations/use-batch-auto-approve-job-search-refinements'
import { AutoExportCandidateType, JobSearchRefinementType } from 'src/libs/api/backend/jobs'
import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { Toggle } from 'src/components/primitives/toggle'
import { Caption } from 'src/components/primitives/typography'
import { isNil } from 'lodash'
import { useSession } from 'src/hooks/use-session'
import { Select } from 'src/components/forms/select'
import type { SelectItem } from 'src/components/forms/select'
import { useGetMergeJobs } from 'src/hooks/queries/use-get-merge-jobs'
import { Flex } from 'src/components/primitives'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useAtomValue, useSetAtom } from 'jotai'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { notifyAtom } from 'src/stores/notifications'
import { When } from '../when'
import { Banner } from '../banner'
import { Spinner } from 'src/components/primitives/spinner'
import { useAtsIntegrationStatus } from 'src/hooks/queries/use-ats-integration-status'
import { atsChannelAtom } from 'src/stores/websocket-channels'
import { useChannel } from 'ably/react'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'

export const MAX_ALLOWED_CANDIDATES = 100

const autoApproveCandidatesForm = z.object({
  maxCandidates: z.number().max(MAX_ALLOWED_CANDIDATES, 'Maximum number of candidates is 100'),
  jobSearchRefinementIds: z.string()
})

type AutoApproveCandidatesForm = z.infer<typeof autoApproveCandidatesForm>

export const AutoApproveCandidates = (): JSX.Element => {
  const { jobId } = useParams()
  const { data: jobData } = useJobQuery()
  const { updateJobPosition } = useUpdateJobPosition()
  const { batchAutoApproveRefinements } = useBatchAutoApproveJobSearchRefinements()

  const updatedJobData = useMemo(
    () => ({
      ...jobData,
      id: jobId ?? '',
      title: jobData?.title ?? '',
      salaryMin: jobData?.salaryMin ?? undefined,
      salaryMax: jobData?.salaryMax ?? undefined,
      workspace: jobData?.workspace ?? undefined
    }),
    [jobData, jobId]
  )

  const { register, formData, setValue, submit } = useForm<AutoApproveCandidatesForm>({
    schema: autoApproveCandidatesForm
  })

  const update = useCallback((): void => {
    if (formData?.maxCandidates !== jobData?.automateAutoApproveCandidatesDaily) {
      if (
        typeof formData?.maxCandidates === 'number' &&
        formData?.maxCandidates <= MAX_ALLOWED_CANDIDATES
      ) {
        updateJobPosition({
          updatedJob: {
            ...updatedJobData,
            automateAutoApproveCandidates: !jobData?.automateAutoApproveCandidates,
            automateAutoApproveCandidatesDaily: (formData?.maxCandidates as unknown as number) ?? 0,
            autoExportCandidate: AutoExportCandidateType.DISABLED
          },
          toastMessage: 'Updated daily auto-approval limit'
        })
      }
    }
  }, [formData?.maxCandidates, jobData?.automateAutoApproveCandidatesDaily])

  const handleSubmit = async (): Promise<void> => {
    update()
  }

  useEffect(() => {
    if (jobData) {
      setValue('maxCandidates', jobData?.automateAutoApproveCandidatesDaily ?? 0)
    }
  }, [jobData])

  const { data: jobSearchRefinements } = useListJobSearchRefinementsQuery(jobId, { type: JobSearchRefinementType.SEARCH_REFINEMENT })

  const [selected, setSelected] = useState<Array<JobSearchRefinement['id']>>([])
  const [items, setItems] = useState<MenuItemProps[]>([])

  const handleSelectSearchRefinement = (jobSearchRefinementId: string): void => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(jobSearchRefinementId)) {
        const updated = prevSelected.filter(item => item !== jobSearchRefinementId)
        batchAutoApproveRefinements({ enabled: true, jobSearchRefinementIds: updated })
        return updated
      } else {
        const updated = [...prevSelected, jobSearchRefinementId]
        batchAutoApproveRefinements({ enabled: true, jobSearchRefinementIds: updated })
        return updated
      }
    })
  }

  useEffect(() => {
    if (jobSearchRefinements) {
      const prepared = jobSearchRefinements?.map(refinement => ({
        title: refinement.title,
        value: refinement.id,
        onSelect: () => { handleSelectSearchRefinement(refinement.id) }
      }))
      const activeRefinements = jobSearchRefinements?.filter(refinement => refinement.automateAutoApproveCandidates)?.map(item => item.id)
      setItems(prepared)
      setSelected(activeRefinements)
    }
  }, [jobSearchRefinements])

  const getSelectedSearchesDropdownText = (): string => {
    if (!selected?.length) {
      return 'No search selected'
    }
    if (selected?.length === jobSearchRefinements?.length) {
      return 'All searches for this position'
    } else {
      return `${pluralize(selected?.length, 'search', { plural: 'searches' })} selected`
    }
  }

  const handleUpdateAllSearchRefinements = (jobSearchRefinementIds: string[]): void => {
    batchAutoApproveRefinements({ enabled: true, jobSearchRefinementIds })
    setSelected(jobSearchRefinementIds)
  }

  return (
    <SettingsCard
      heading="Auto-approve sourced candidates"
      description="Add new sourced candidates to your outreach sequence daily."
      isActive={jobData?.automateAutoApproveCandidates ?? false}
      onToggle={() => {
        const newState = !jobData?.automateAutoApproveCandidates
        updateJobPosition({
          updatedJob: {
            ...updatedJobData,
            automateAutoApproveCandidates: newState,
            automateAutoApproveCandidatesDaily: (formData?.maxCandidates as unknown as number) ?? 0,
            autoExportCandidate: AutoExportCandidateType.DISABLED
          },
          toastMessage: `${newState ? 'Enabled' : 'Paused'} auto-approval of sourced candidates`
        })
      }}
      details={
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <DetailsBlock
            label="From which sourcing searches"
            isActive={jobData?.automateAutoApproveCandidates ?? false}
          >
            <Dropdown
              trigger={
                <Button
                  nested
                  $variant="outline"
                  $colorTheme="normal"
                  $height={40}
                  $width="full"
                  trailingIcon="chevron-down"
                  $align="space-between"
                >
                  {getSelectedSearchesDropdownText()}
                </Button>
              }
              triggerFullWidth
              selectedValue={selected}
              selectType="multi"
              $menuWidth="full"
              allowTopLevelSelect
              topLevelSelectText="All searches for this position"
              onTopLevelSelect={(values) => {
                handleUpdateAllSearchRefinements(values)
              }}
              items={items}
            />
          </DetailsBlock>
          <DetailsBlock
            label="How many candidates per day maximum"
            isActive={jobData?.automateAutoApproveCandidates ?? false}
          >
            <Form onSubmit={submit(handleSubmit)}>
              <Input
                label="How many candidates per day maximum"
                hiddenLabel
                type="number"
                name="maxCandidates"
                placeholder="0"
                register={register}
                max={MAX_ALLOWED_CANDIDATES}
                onBlurEvent={() => {
                  update()
                }}
                $marginBottom={0}
              />
            </Form>
          </DetailsBlock>
        </div>
      }
    />
  )
}

export const MAX_ALLOWED_EMAILS = 1000

const sendOutreachEmailsForm = z.object({
  dailyEmailLimit: z.number().max(MAX_ALLOWED_EMAILS, 'Maximum is 1000')
})

type SendOutreachEmailsForm = z.infer<typeof sendOutreachEmailsForm>

export const SendOutreachEmails = (): JSX.Element => {
  const { data: emailSequence } = useJobSequenceQuery()
  const { upsertJobSequence } = useUpsertJobSequence()
  const { jobId } = useParams()

  const { register, setValue, formData, submit } = useForm<SendOutreachEmailsForm>({
    schema: sendOutreachEmailsForm
  })

  const update = useCallback((): void => {
    if (formData?.dailyEmailLimit !== emailSequence?.dailyEmailLimit) {
      if (
        typeof formData?.dailyEmailLimit === 'number' &&
        formData?.dailyEmailLimit <= MAX_ALLOWED_EMAILS
      ) {
        upsertJobSequence({
          jobId: jobId ?? '',
          dailyEmailLimit:
            (formData?.dailyEmailLimit as unknown as number) ??
            emailSequence?.dailyEmailLimit ??
            100,
          toastMessage: 'Updated daily outreach email limit'
        })
      }
    }
  }, [formData?.dailyEmailLimit, emailSequence?.dailyEmailLimit])

  const handleSubmit = async (): Promise<void> => {
    update()
  }

  useEffect(() => {
    setValue('dailyEmailLimit', emailSequence?.dailyEmailLimit ?? 100)
  }, [emailSequence])

  return (
    <SettingsCard
      heading="Send outreach emails"
      description="Schedule and send emails from the outreach sequence automatically."
      isActive={emailSequence?.active ?? false}
      onToggle={() => {
        upsertJobSequence({
          jobId: jobId ?? '',
          active: !emailSequence?.active,
          toastMessage: !emailSequence?.active
            ? 'Enabled sending outreach emails'
            : 'Paused sending outreach emails'
        })
      }}
      details={
        <DetailsBlock
          label="Max emails per day"
          isActive={emailSequence?.active ?? false}
        >
          <Form onSubmit={submit(handleSubmit)}>
            <div style={{ width: '50%' }}>
              <Input
                label="Max emails per day"
                hiddenLabel
                type="number"
                name="dailyEmailLimit"
                placeholder="100"
                max={MAX_ALLOWED_EMAILS}
                register={register}
                onBlurEvent={() => {
                  update()
                }}
                $marginBottom={0}
              />
            </div>
          </Form>
        </DetailsBlock>
      }
    />
  )
}

export const AutoArchiveCandidates = (): JSX.Element => {
  const { jobId } = useParams()
  const { data: jobData } = useJobQuery()

  const { updateJobPosition } = useUpdateJobPosition()

  return (
    <SettingsCard
      heading="Auto-archive non interested candidates"
      description="If candidate replies they're not interested Pin will archive them automatically."
      isActive={!!jobData?.automateAutoArchiveCandidates}
      details={
        <DetailsBlock
          label=""
          hiddenLabel
          isActive={!!jobData?.automateAutoArchiveCandidates}
        >
          <Flex $gap={16} $align="center" $justify="space-between">
            <Caption size="SM" $color="fgSecondary">Send an email back after archiving</Caption>
            <Toggle name="automateSendArchiveEmail" checked={!!jobData?.automateSendArchiveEmail} onChange={() => {
              updateJobPosition({
                updatedJob: {
                  id: jobId ?? '',
                  automateSendArchiveEmail: !jobData?.automateSendArchiveEmail
                }
              })
            }} />
          </Flex>
        </DetailsBlock>
      }
      onToggle={() => {
        const newState = !jobData?.automateAutoArchiveCandidates
        updateJobPosition({
          updatedJob: {
            id: jobId ?? '',
            automateAutoArchiveCandidates: newState
          },
          toastMessage: `${
            newState ? 'Enabled' : 'Paused'
          } auto-archiving non-interested candidates`
        })
      }}
    />
  )
}

export const NudgeUnresponsiveCandidates = (): JSX.Element => {
  const { jobId } = useParams()
  const { data: jobData } = useJobQuery()

  const { updateJobPosition } = useUpdateJobPosition()

  return (
    <SettingsCard
      heading="Nudge interested candidates if they become unresponsive"
      description="Pin sends a reminder email if an interested candidate stops responding."
      isActive={jobData?.automateNudgeCandidates ?? false}
      onToggle={() => {
        const newState = !jobData?.automateNudgeCandidates
        updateJobPosition({
          updatedJob: {
            id: jobId ?? '',
            automateNudgeCandidates: newState
          },
          toastMessage: `${newState ? 'Enabled' : 'Paused'} nudging unresponsive candidates`
        })
      }}
    />
  )
}

export const AutoScheduleMeetings = (): JSX.Element => {
  const { jobId } = useParams()
  const { data: jobData } = useJobQuery()

  const { updateJobPosition } = useUpdateJobPosition()

  return (
    <SettingsCard
      heading="Auto-schedule meetings"
      description="If candidate agrees to meet, Pin will find a time and schedule meetings automatically based on your availability and calendar preferences"
      isActive={jobData?.automateScheduling ?? false}
      onToggle={() => {
        const newState = !jobData?.automateScheduling
        updateJobPosition({
          updatedJob: {
            id: jobId ?? '',
            automateScheduling: newState
          },
          toastMessage: `${newState ? 'Enabled' : 'Paused'} auto-scheduling`
        })
      }}
    />
  )
}

const exportToATSSchema = z.object({
  exportWhen: z.nativeEnum(AutoExportCandidateType).nullish(),
  mergeAtsJobId: z.string().nullish()
})

type ExportToATSForm = z.infer<typeof exportToATSSchema>

export const ExportToATS = (): JSX.Element | null => {
  const { jobId } = useParams()
  const { data: jobData } = useJobQuery()
  const { org, featureFlags } = useSession()
  const { updateJobPosition } = useUpdateJobPosition()
  const openDialog = useSetAtom(openDialogAtom)
  const notify = useSetAtom(notifyAtom)
  const [importing, setImporting] = useState(false)
  const atsChannel = useAtomValue(atsChannelAtom)
  const { isImporting } = useAtsIntegrationStatus(org?.id)
  useEffect(() => {
    if (importing !== isImporting) {
      setImporting(isImporting)
    }
  }, [importing, isImporting])
  useChannel({ channelName: atsChannel, skip: !atsChannel }, () => {
    // Refetch the integration status when there a new message on the ats channel
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.atsIntegrationStatus, org?.id]
    })
  })

  const { data: mergeJobs = [] } = useGetMergeJobs()

  const mergeJobsMap = useMemo(() => {
    const mergeJobMap = new Map<string, SelectItem>()
    for (const mergeJob of mergeJobs) {
      mergeJobMap.set(mergeJob.id, {
        value: mergeJob.id,
        title: mergeJob.name
      })
    }
    return mergeJobMap
  }, [mergeJobs])

  const mergeJobsList = useMemo(() => {
    return [...mergeJobsMap.values()]
  }, [mergeJobsMap])

  const defaultSelectedJob = useMemo(() => {
    return !isNil(jobData) && !isNil(jobData?.mergeAtsJobId)
      ? mergeJobsMap.get(jobData.mergeAtsJobId)?.value
      : undefined
  }, [mergeJobsMap, jobData])

  const isDetailsActive = useMemo(() => {
    return !isNil(org?.mergeAtsIntegration) && (featureFlags?.includes(FeatureFlags.ATS_INTEGRATION) ?? false)
  }, [org, featureFlags])

  const isSettingActive = useMemo(() => {
    return jobData?.autoExportCandidate !== AutoExportCandidateType.DISABLED
  }, [jobData])

  const { register, formData, submit } = useForm<ExportToATSForm>({
    schema: exportToATSSchema
  })

  const handleSubmit = async (): Promise<void> => {}

  const isOrgConnectedToATS = useMemo(() => {
    return !isNil(org?.mergeAtsIntegration)
  }, [org?.mergeAtsIntegration])

  if (!featureFlags?.includes(FeatureFlags.ATS_INTEGRATION)) {
    return null
  }

  return (
    <SettingsCard
      heading="Export candidates to ATS"
      description={isOrgConnectedToATS ? 'Export candidates from Pin to your ATS automatically' : 'Connect Pin to your ATS to enable auto-exporting to ATS'}
      isActive={isSettingActive}
      disabled={importing}
      details={
          isOrgConnectedToATS
            ? (
                <>
                  <When condition={importing}>
                    <Banner $variant="ghost" $padding={{ top: 0, bottom: 0, left: 0, right: 0 }} $borderRadius={8}>
                      <Spinner size={20} />
                      <Caption size="SM" $color="fgSecondary" as="h6" $lineHeight={1}>We&rsquo;re still importing your ATS data… We&rsquo;ll email you when it&rsquo;s complete.</Caption>
                    </Banner>
                  </When>
                  <When condition={!importing}>
                    <Form onSubmit={submit(handleSubmit)}>
                      <Flex $direction='row' $align='center' $justify='space-between' $gap={16}>
                        <Select
                          disabled={!isDetailsActive}
                          label='Select job from ATS'
                          name="mergeAtsJobId"
                          placeholder="No job selected"
                          defaultValue={defaultSelectedJob}
                          items={mergeJobsList}
                          register={register}
                          $marginBottom={24}
                          emptyStateText={mergeJobs?.length === 0 ? 'No jobs found' : undefined}
                        />
                        <DetailsBlock
                          isActive={isDetailsActive}
                          label="Export candidates when…"
                        >
                          <RadioGroup
                            name="exportWhen"
                            label="Export when candidates"
                            hiddenLabel
                            variant="bullets"
                            defaultValue={jobData?.autoExportCandidate ?? AutoExportCandidateType.ON_MOVED_TO_OUTREACH}
                            register={register}
                            options={[
                              {
                                title: 'They’re added to an Outreach sequence',
                                value: AutoExportCandidateType.ON_MOVED_TO_OUTREACH
                              },
                              {
                                title: 'They reply to an email',
                                value: AutoExportCandidateType.ON_EMAIL_REPLY
                              }
                            ]}
                          />
                        </DetailsBlock>
                      </Flex>
                    </Form>
                  </When>
                </>
              )
            : undefined
      }
      toggleTextEnabled={isOrgConnectedToATS ? 'Pause' : 'Connect'}
      toggleTextDisabled={isOrgConnectedToATS ? 'Enable' : 'Connect'}
      onToggle={() => {
        if (isNil(jobId)) {
          return
        }

        const mergeAtsJobId = formData?.mergeAtsJobId ?? null
        const autoExportCandidate = formData?.exportWhen ?? AutoExportCandidateType.ON_EMAIL_REPLY

        if (isOrgConnectedToATS && isNil(mergeAtsJobId)) {
          notify({
            type: 'toast',
            variant: 'warning',
            position: 'bottom-right',
            icon: 'alert-triangle',
            autoClose: false,
            message: 'Please select a job from your ATS to enable auto-exporting to ATS'
          })
        }

        if (!isOrgConnectedToATS) {
          openDialog({ id: DialogId.CONNECT_ATS })
        } else if (isSettingActive) {
          updateJobPosition({
            updatedJob: {
              id: jobId,
              autoExportCandidate: AutoExportCandidateType.DISABLED
            },
            toastMessage: 'Paused auto-exporting to ATS'
          })
        } else if (!isSettingActive && !isNil(mergeAtsJobId) && !isNil(autoExportCandidate)) {
          updateJobPosition({
            updatedJob: {
              id: jobId,
              mergeAtsJobId: mergeAtsJobId as string | null,
              autoExportCandidate: autoExportCandidate as AutoExportCandidateType
            },
            toastMessage: 'Enabled auto-exporting to ATS'
          })
        }
      }}
    />
  )
}
