import { CREDIT_USAGE_TYPE_COST, CreditUsageType } from 'src/libs/api/backend/billing'
import { useCustomerSubscriptionsQuery } from './queries/use-customer-subscriptions'

export const useAvailableCredits = (): number => {
  const { data } = useCustomerSubscriptionsQuery()
  const { seatCredits = 100, creditsUsed = 0, purchasedCredits = 0, rolloverCredits = 0 } = data?.credit ?? {}
  return seatCredits + purchasedCredits + rolloverCredits - creditsUsed
}

export const useCanTriggerPhoneNumberLookup = (): boolean => {
  const availableCredits = useAvailableCredits()
  return availableCredits >= CREDIT_USAGE_TYPE_COST[CreditUsageType.PHONE_LOOKUP]
}

export const useCanTriggerEmailSequence = (): boolean => {
  const availableCredits = useAvailableCredits()
  return availableCredits >= CREDIT_USAGE_TYPE_COST[CreditUsageType.EMAIL_LOOKUP]
}
