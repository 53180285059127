import styled, { keyframes } from 'styled-components'

const fadeInScale = keyframes`
  from {
    opacity: 0;
    transform: scale(0.96);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

const bounceIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const starWobble = keyframes`
  0% { transform: scale(0.9); opacity: 0; }
  50% { transform: scale(0.92); opacity: 0.75; }
  100% { transform: scale(0.9); opacity: 0; }
`

export const SourcingSuccessWrapper = styled.svg`
  width: 160px;
  height: 127px;

  path, rect {
    transform-origin: center;
  }

  [data-name="Binoculars"] {
    animation: ${fadeInScale} 0.75s ease-out forwards;
  }

  [data-name="CheckmarkBg"],
  [data-name="CheckmarkBgBorder"],
  [data-name="Checkmark"] {
    transform-origin: 136px 103px; /* x: 114 + 22, y: 81 + 22 */
    animation: ${bounceIn} 0.6s ease-out forwards;
    animation-delay: 0.5s;
    opacity: 0;
  }

  [data-name="StarsLeft1"],
  [data-name="StarsLeft2"],
  [data-name="StarsLeft3"],
  [data-name="StarsRight1"],
  [data-name="StarsRight2"],
  [data-name="StarsRight3"] {
    animation: ${starWobble} 3s ease-in-out infinite;
    opacity: 0;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  [data-name="StarsRight1"],
  [data-name="StarsRight2"],
  [data-name="StarsRight3"] {
    animation-delay: 1.2s;
  }

  [data-name="StarsLeft2"] {
    animation-delay: 1.2s;
  }

  [data-name="StarsLeft3"] {
    animation-delay: 1.4s;
  }

  [data-name="StarsRight2"] {
    animation-delay: 1.3s;
  }

  [data-name="StarsRight3"] {
    animation-delay: 1.5s;
  }
`
