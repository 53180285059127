import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useLocation, useParams } from 'react-router-dom'
import { queryKeys } from 'src/libs/query-keys'
import { candidateJobStageDisplay, createCandidatesByLinkedInUrl } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import { useSetAtom } from 'jotai'
import { notifyAtom, notifyErrorAtom } from 'src/stores/notifications'
import { Icons } from 'src/components/primitives/icon'
import { pluralize } from 'src/libs/pluralize'

interface Args {
  linkedins: string[]
  stage: CandidateJobStage
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  createCandidatesByLinkedIn: ({ linkedins, stage, onSuccess, onError }: Args) => void
}

export const useCreateCandidateByLinkedIn = (): Res => {
  const queryClient = useQueryClient()
  const notifyError = useSetAtom(notifyErrorAtom)
  const notify = useSetAtom(notifyAtom)
  const { jobId } = useParams()
  const location = useLocation()

  const favorite = location.pathname.includes('/shortlisted')

  const mutation = useMutation({
    mutationFn: async ({ linkedins, stage }: Args) => await createCandidatesByLinkedInUrl(linkedins, stage, jobId, favorite),
    onError: (err, variables) => {
      console.error(err)
      notifyError({
        message: `An error occurred while creating your candidate: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async ({ duplicatedLinkedInUrls }, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, jobId]
      })

      if (duplicatedLinkedInUrls.length > 0) {
        notify({
          type: 'toast',
          variant: 'tint',
          position: 'bottom-right',
          icon: Icons.info,
          message: `${pluralize(duplicatedLinkedInUrls.length, 'candidate')} already exists in this stage`
        })
      }
      const newCandidateJobs = variables.linkedins.length - duplicatedLinkedInUrls.length
      if (newCandidateJobs > 0) {
        notify({
          type: 'toast',
          variant: 'positive',
          position: 'bottom-right',
          icon: Icons.checkCheck,
          message: `${pluralize(newCandidateJobs, 'candidate')} added to ${candidateJobStageDisplay[variables.stage]}`
        })
      }

      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const createCandidatesByLinkedIn = ({ linkedins, stage, onSuccess, onError }: Args): void => {
    mutation.mutate({ linkedins, stage, onSuccess, onError })
  }

  return { createCandidatesByLinkedIn }
}
