import { store } from 'src/stores'
import { lastViewedJobAtom } from 'src/stores/job'
import { isUuid } from '../uuid'
import { redirect } from 'react-router-dom'
import RouteBuilder from '../route-builder'

export const jobLoader = async ({ params: { jobId } }: { params: { jobId: string } }): Promise<Response | null> => {
  if (!isUuid(jobId)) {
    return redirect(RouteBuilder.build('ROOT', {}))
  }
  // Set localstorage
  store.set(lastViewedJobAtom, jobId)
  return null
}
