import styled from 'styled-components'

export const SuccessView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  flex: 1 1 auto;
`

export const SuccessIcon = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.positiveBg};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
`
