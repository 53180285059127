interface PluralizeOptions {
  plural?: string
  hideCount?: boolean
}

export const pluralize = (count: number, singular: string, options: PluralizeOptions = {}): string => {
  const defaultOptions = {
    plural: singular + 's',
    hideCount: false,
    ...options
  }
  return `${defaultOptions.hideCount ? '' : count + ' '}${count === 1 ? singular : defaultOptions.plural}`.trim()
}
