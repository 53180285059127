import { Flex } from 'src/components/primitives'
import * as S from './applicant-candidates-ats.styled'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { isNil } from 'lodash'

interface ApplicantsStatusProps {
  count: number
  label: string
  total?: number
  onClick?: () => void
  selected: boolean
}

export const ApplicantsStatus = ({
  count,
  label,
  total,
  onClick,
  selected
}: ApplicantsStatusProps): JSX.Element => {
  return (
    <S.CandidatesViewFilter $clickable={!!onClick} onClick={onClick} $selected={selected}>
      <Caption size="MD" $fontWeight={700}>{count}</Caption>
      <Flex $flex="1" $width="fit-content">
        <Caption size="XS">{label}</Caption>
      </Flex>
      {!isNil(total) && (
        <Paragraph size="XS">
          {total > 0 ? Math.round(count / total * 100) : 0}%
        </Paragraph>
      )}
    </S.CandidatesViewFilter>
  )
}
