import { CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'
import styled from 'styled-components'

export const Banner = styled.div`
  padding: 2.5rem;
  border-radius: 6px;
  background: ${({ theme }) => theme.backgrounds.aiGradientTranslucent10};
  max-width: ${CANDIDATES_PAGES_MAX_WIDTH};
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  svg {
    stroke: #1D37C8;
  }
  h3 {
    background: linear-gradient(267deg, #8B20C9 -0.11%, #1D37C8 99.81%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-weight: ${({ theme }) => theme.fontWeights['500']};
  }
`

export const UpgradeBenefits = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  opacity: 0.75;
  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`

export const UpgradeBenefitIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
`
