export type Spacing =
  | 0
  | 1
  | 2
  | 4
  | 6
  | 8
  | 10
  | 12
  | 14
  | 16
  | 18
  | 20
  | 22
  | 24
  | 26
  | 28
  | 30
  | 32
  | 36
  | 40
  | 48
  | 56
  | 64
  | 72
  | 80
  | 160
  | 9999 // for full border-radius
export type FontSize = 10 | 12 | 14 | 16 | 18 | 20 | 24 | 32
export type FontWeight = 400 | 500
export type LineHeight = 1 | 1.2 | 1.33 | 1.43 | 1.5
export type LetterSpacing = 0 | 0.12 | 0.2
export type Shadow = 'sm' | 'md' | 'lg' | 'focused' | 'light-active'

export const Colors = {
  bgPrimary: 'bgPrimary',
  bgSecondary: 'bgSecondary',
  bgTertiary: 'bgTertiary',
  bgTranslucent25: 'bgTranslucent25',
  bgTranslucent10: 'bgTranslucent10',
  fgPrimary: 'fgPrimary',
  fgSecondary: 'fgSecondary',
  fgTertiary: 'fgTertiary',
  fgFaded10: 'fgFaded10',
  fgTranslucent25: 'fgTranslucent25',
  fgTranslucent10: 'fgTranslucent10',
  fgTranslucent5: 'fgTranslucent5',
  borderOpaque: 'borderOpaque',
  borderTranslucent: 'borderTranslucent',
  tintBg: 'tintBg',
  tintFg: 'tintFg',
  tintFaded: 'tintFaded',
  tintTranslucent25: 'tintTranslucent25',
  tintTranslucent15: 'tintTranslucent15',
  tintTranslucent5: 'tintTranslucent5',
  tintContrast: 'tintContrast',
  negativeBg: 'negativeBg',
  negativeFg: 'negativeFg',
  negativeFaded: 'negativeFaded',
  negativeTranslucent25: 'negativeTranslucent25',
  negativeTranslucent10: 'negativeTranslucent10',
  negativeTranslucent5: 'negativeTranslucent5',
  negativeContrast: 'negativeContrast',
  positiveBg: 'positiveBg',
  positiveFg: 'positiveFg',
  positiveFaded: 'positiveFaded',
  positiveTranslucent25: 'positiveTranslucent25',
  positiveTranslucent10: 'positiveTranslucent10',
  positiveTranslucent5: 'positiveTranslucent5',
  positiveContrast: 'positiveContrast',
  warningBg: 'warningBg',
  warningFg: 'warningFg',
  warningFaded: 'warningFaded',
  warningTranslucent25: 'warningTranslucent25',
  warningTranslucent10: 'warningTranslucent10',
  warningTranslucent5: 'warningTranslucent5',
  charts1: 'charts1',
  charts2: 'charts2',
  charts3: 'charts3',
  charts4: 'charts4',
  charts5: 'charts5',
  charts6: 'charts6',
  charts7: 'charts7',
  staticTransparent: 'staticTransparent',
  aiTranslucent10: 'aiTranslucent10',
  aiSolidBg: 'aiSolidBg',
  aiSolidFg: 'aiSolidFg',
  aiSolidTranslucent50: 'aiSolidTranslucent50',
  aiSolidTranslucent25: 'aiSolidTranslucent25',
  aiSolidTranslucent10: 'aiSolidTranslucent10',
  aiSolidTranslucent5: 'aiSolidTranslucent5'
}

export type Color = (typeof Colors)[keyof typeof Colors]

// export type Color =
//   | 'bgPrimary'
//   | 'bgSecondary'
//   | 'bgTertiary'
//   | 'bgTranslucent25'
//   | 'bgTranslucent10'
//   | 'fgPrimary'
//   | 'fgSecondary'
//   | 'fgTertiary'
//   | 'fgFaded10'
//   | 'fgTranslucent25'
//   | 'fgTranslucent10'
//   | 'borderOpaque'
//   | 'borderTranslucent'
//   | 'tintBg'
//   | 'tintFg'
//   | 'tintFaded'
//   | 'tintTranslucent25'
//   | 'tintTranslucent15'
//   | 'tintTranslucent5'
//   | 'negativeBg'
//   | 'negativeFg'
//   | 'negativeFaded'
//   | 'negativeTranslucent25'
//   | 'negativeTranslucent5'
//   | 'positiveBg'
//   | 'positiveFg'
//   | 'positiveFaded'
//   | 'positiveTranslucent25'
//   | 'positiveTranslucent5'
//   | 'warningBg'
//   | 'warningFg'
//   | 'warningFaded'
//   | 'warningTranslucent25'
//   | 'warningTranslucent10'

export type ZIndex =
  | 'outerspace'
  | 'dropdown'
  | 'dialog'
  | 'toast'
  | 'alert'
  | 'navigation'
  | 'menu'

export type Background = 'aiGradientFaded' | 'aiGradientPastel' | 'aiGradientVivid' | 'aiGradientTranslucent10' | 'purpleGradientFadeOutToBottom'

export interface Theme {
  colors: Record<Color, string>
  spacing: Record<Spacing, string | number>
  fontSizes: Record<FontSize, string>
  fontWeights: Record<FontWeight, number>
  lineHeights: Record<LineHeight, number>
  letterSpacings: Record<LetterSpacing, string>
  zindeces: Record<ZIndex, string | number>
  boxShadows: Record<Shadow, string>
  backgrounds: Record<Background, string>
}
