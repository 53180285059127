import { Logo } from 'src/components/primitives/logo'
import * as S from './auth-layout-v3.styled'
import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Icon } from 'src/components/primitives/icon'

export const AuthLayoutV3 = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <S.AuthLayout>
      <S.AuthInner>
        <S.Spotlight>
          <S.SpotlightInner>
            <S.SpotlightMain>
              <Logo variant="dark" />
              <S.SpotlightContent>
                <Icon name="check-circle-2" size={40} color="positiveBg" />
                <Flex $direction="column" $gap={8}>
                  <Caption size="LG">Your meeting is scheduled!</Caption>
                  <Paragraph size="MD" $color="fgPrimary" $lineHeight={1.5}>
                    Looking forward to discussing how we can help you in your recruiting efforts. In the meantime feel free to get started&nbsp;for&nbsp;free!
                  </Paragraph>
                </Flex>
              </S.SpotlightContent>
            </S.SpotlightMain>
            <S.BackLink href="https://www.pin.com" target="_blank">
              <Icon name="chevron-left" size={12} color="fgSecondary" />
              <p>
                Back to <span>Pin.com</span>
              </p>
            </S.BackLink>
          </S.SpotlightInner>
        </S.Spotlight>
        <S.AuthForm>
          <S.AuthFormInner>{children}</S.AuthFormInner>
        </S.AuthForm>
      </S.AuthInner>
    </S.AuthLayout>
  )
}
