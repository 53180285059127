import { isNil } from 'lodash'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import { useCallback, useMemo, useRef, useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/'
}

const MAX_WIDTH = 1200

interface Size {
  width?: number
  height?: number
}

interface PDFProps {
  fileUrl: string
}

export const PDF = ({ fileUrl }: PDFProps): JSX.Element => {
  const [numPages, setNumPages] = useState<number>()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [{ width }, setContainerSize] = useState<Size>({
    width: undefined,
    height: undefined
  })

  const onResize = useDebounceCallback(setContainerSize, 200)

  useResizeObserver({
    ref: containerRef,
    onResize
  })

  const onDocumentLoadSuccess = useCallback(({ numPages: nextNumPages }: PDFDocumentProxy): void => {
    setNumPages(nextNumPages)
  }, [])

  const file = useMemo(() => ({ url: fileUrl }), [fileUrl])

  return (
    <div ref={containerRef}>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        {Array.from(new Array(numPages), (_el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={!isNil(width) ? Math.min(width, MAX_WIDTH) : MAX_WIDTH}
          />
        ))}
      </Document>
    </div>
  )
}
