import { useEffect, useState } from 'react'
import { Icon } from 'src/components/primitives/icon'
import type { FieldCommonProps } from '../common'
import { FormElement } from '../form-element'
import * as S from './checkbox.styled'
import { FieldError } from '../field-error'
import { Paragraph } from 'src/components/primitives/typography'

export interface CheckboxStyleProps {
  $variant?: 'plain' | 'outline'
}

interface CheckboxProps extends CheckboxStyleProps, FieldCommonProps {
  defaultChecked?: boolean
  description?: string
}

export const Checkbox = ({
  defaultChecked = false,
  label,
  name,
  description,
  register,
  $marginBottom,
  $variant = 'plain'
}: CheckboxProps): JSX.Element => {
  const [isDefaultChecked, setIsDefaultChecked] = useState(defaultChecked)
  const { value, onSelect, error } = register(name)
  useEffect(() => {
    setIsDefaultChecked(defaultChecked)
  }, [defaultChecked])

  return (
    <FormElement $marginBottom={$marginBottom}>
      <S.Checkbox $variant={$variant} $isChecked={Boolean(value)}>
        <S.CheckboxRoot
          id={name}
          defaultChecked={isDefaultChecked}
          checked={Boolean(value)}
          onCheckedChange={onSelect}
          $hasDescription={Boolean(description)}
        >
          <S.CheckboxIndicator>
            <Icon name="check" color="tintBg" size={12} />
          </S.CheckboxIndicator>
        </S.CheckboxRoot>
        <S.LabelWrapper>
          <S.Label htmlFor={name}>{label}</S.Label>
          {description && <Paragraph size="XS" $color="fgSecondary">{description}</Paragraph>}
        </S.LabelWrapper>
      </S.Checkbox>
      {error ? <FieldError id={`${name}-error`}>{error}</FieldError> : null}
    </FormElement>
  )
}
