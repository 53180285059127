import { useMutation } from '@tanstack/react-query'
import { copyCompanyPreferences as copyCompanyPreferencesHandler } from 'src/libs/api/backend/company_preferences'
import queryClient from '../query-client'
import { queryKeys } from 'src/libs/query-keys'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args {
  fromJobId: string
  toJobId: string
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  copyCompanyPreferences: (args: Args) => void
}

export const useCopyCompanyPreferences = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async (args: Args) => {
      await copyCompanyPreferencesHandler(args)
    },
    onError: (err, variables) => {
      notifyError({
        message: err.message
      })
      variables.onError?.()
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.companyPreferences, variables.toJobId]
      })
      variables.onSuccess?.()
    }
  })

  const copyCompanyPreferences = (args: Args): void => {
    mutation.mutate(args)
  }

  return { copyCompanyPreferences }
}
