import type { Color, FontSize, Spacing } from 'src/styles/theme/types'
import styled from 'styled-components'

export interface StylesProps {
  $width?: string
  $borderColor?: Color
  $border?: string
  $borderRadius?: Spacing
  $padding?: string
  $height?: Spacing
  $inputHeight?: Spacing
  $fontSize?: FontSize
}

export const SearchInputWrapper = styled.div<StylesProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ $padding }) => $padding ?? '0rem 1.125rem'};
  border: ${({ $border }) => $border ?? 'none'};
  border-color: ${({ theme, $borderColor }) => theme.colors[$borderColor ?? 'bgTertiary']};
  border-radius: ${({ theme, $borderRadius }) => theme.spacing[$borderRadius ?? 0]};
  height: ${({ theme, $height }) => theme.spacing[$height ?? 56]};
  width: ${({ $width }) => $width ?? 'auto'};
  input {
    height: ${({ theme, $inputHeight }) => theme.spacing[$inputHeight ?? 26]};
    width: 100%;
    font-size: ${({ theme, $fontSize }) => theme.fontSizes[$fontSize ?? 16]};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.fgPrimary};
    border: none;
    &:focus {
      outline: none;
    }
    &:placeholder {
      opacity: 0.5;
    }
  }
`

export const Icon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
