import { styled } from 'styled-components'

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`

export const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.625rem 1.5rem;
  border-radius: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgTertiary};
`

export const UserProfileButton = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing[4]};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.fgTranslucent10};
    }
  }
`

export const Body = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[64]};
  gap: 2.5rem;
  overflow-y: auto;
  scrollbar-width: thin;
`

export const TrialEndedNotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
`

export const TrialEndedBox = styled.div`
  display: flex;
  width: 640px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing[40]};
  border-radius: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`

export const TrialCloseAccountBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 640px;
`
