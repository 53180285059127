import styled from 'styled-components'

export const AuthLayout = styled.div`
  position: relative;
  width: calc(100vw - (1.5rem * 2));
  max-width: calc(100% - (1.5rem * 2));
  height: calc(100vh - (1.5rem * 2));
  top: 1.5rem;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    align-items: flex-start;
    width: calc(100vw - (0.5rem * 2));
    max-width: calc(100% - (0.5rem * 2));
    height: calc(100vh - (0.5rem * 2));
    top: 0.5rem;
  }
`

export const AuthInner = styled.div`
  width: 100%;
  aspect-ratio: 10 / 6.67;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05), 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 1.25rem;
  @media screen and (max-width: 1512px), screen and (max-height: 880px) {
    height: 100%;
    max-width: unset;
    aspect-ratio: unset;
    box-shadow: unset;
    overflow: unset;
    padding: 0;
  }
  @media screen and (max-width: 1024px) {
    aspect-ratio: unset;
    flex-direction: column;
    justify-content: flex-start;
    max-width: unset;
    height: unset;
  }
`

const Column = styled.div`
  flex: 0 0 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
    flex: unset;
    justify-content: flex-start;
    height: auto;
  }
`

export const Marketing = styled(Column)`
  position: relative;
  padding: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
`

export const Media = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 1rem;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
  @media screen and (max-width: 1024px) {
    aspect-ratio: 1 / 0.80;
  }
`

export const MarketingInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 2;
  @media screen and (max-width: 1280px) {
    padding: 4rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 1rem;
  }
  h1 {
    font-size: ${({ theme }) => theme.fontSizes[24]};
    font-weight: ${({ theme }) => theme.fontWeights[500]};
    color: ${({ theme }) => theme.colors.bgPrimary};
    span {
      font-size: ${({ theme }) => theme.fontSizes[24]};
      font-weight: ${({ theme }) => theme.fontWeights[400]};
      color: ${({ theme }) => theme.colors.bgPrimary};
    }
  }
`

export const Profiles = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.375rem;
`

export const ProfileSkill = styled.div<{ $fg: string, $bg: string }>`
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.375rem;
  padding: 4px 4px 4px 0.5rem;
  height: 28px;
  background-color: ${({ $bg }) => $bg};
  color: ${({ $fg }) => $fg};
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  box-shadow: 0px 73px 20px 0px rgba(0, 0, 0, 0), 0px 47px 19px 0px rgba(0, 0, 0, 0.01),
    0px 26px 16px 0px rgba(0, 0, 0, 0.05), 0px 12px 12px 0px rgba(0, 0, 0, 0.09),
    0px 3px 6px 0px rgba(0, 0, 0, 0.1);
  &:nth-child(1) {
    opacity: 0.65;
  }
  &:nth-child(2) {
    opacity: 0.75;
  }
`

export const ProfileSkillContext = styled.div<{ $bg: string }>`
  border-radius: 4px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  padding: 0 0.25rem;
  line-height: 1;
  background-color: ${({ $bg }) => $bg};
  color: rgba(255, 255, 255, 0.8);
  white-space: nowrap;
  opacity: 0.8;
`

export const AuthForm = styled(Column)`
  padding: 2.5rem;
  @media screen and (max-width: 1024px) {
    padding: 2rem 1.5rem 1.5rem 1.5rem;
  }
`

export const AuthFormInner = styled.div`
  max-width: 400px;
  width: 100%;
`
