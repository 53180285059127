import { useState } from 'react'
import { When } from '../when'
import type { EntryProps } from './timeline-entry'
import { Entry } from './timeline-entry'
import { Button } from 'src/components/primitives'
import * as S from './timeline.styled'
import type { Spacing } from 'src/styles/theme/types'

export interface TimelineProps extends Pick<EntryProps, 'avatarFallbackIcon' | '$padding'> {
  entries: EntryProps[]
  yearsToExpand?: number
  minInitialEntries?: number
  maxTotalEntries?: number
  isCollapsible?: boolean
  isPrintView?: boolean
}

export interface MoreButttonStyleProps {
  $padding?: {
    left: Spacing
    right: Spacing
  }
}

export const Timeline = ({
  isCollapsible = false,
  entries,
  yearsToExpand = 8,
  minInitialEntries = 4,
  maxTotalEntries,
  avatarFallbackIcon,
  $padding,
  isPrintView = false
}: TimelineProps): JSX.Element => {
  const currentDate = new Date()
  const cutoffDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - yearsToExpand))

  let cappedEntries = entries.filter(entry => {
    const startDate = entry.details?.[entry.details?.length - 1]?.entryStartDate
    const endDate = entry.details?.[0]?.entryEndDate
    if (!startDate) {
      return false
    }
    return new Date(startDate) >= cutoffDate || (endDate && new Date(endDate) >= cutoffDate)
  })

  if (cappedEntries.length < minInitialEntries) {
    cappedEntries = entries.slice(0, minInitialEntries)
  }

  if (maxTotalEntries && cappedEntries.length > maxTotalEntries) {
    cappedEntries = cappedEntries.slice(0, maxTotalEntries)
  }

  const remainingEntries = maxTotalEntries ? 0 : entries.length - cappedEntries.length

  const initialEntries = cappedEntries

  const [displayedItems, setDisplayedItems] = useState(initialEntries)
  const [showMore, setShowMore] = useState(remainingEntries > 1)

  return (
    <S.Timeline>
      {displayedItems?.map((entry, index) => (
        <Entry
          key={`${entry.heading.title}-${entry.heading.description}-${index}`}
          {...entry}
          avatarFallbackIcon={avatarFallbackIcon}
          $padding={$padding}
          isCollapsible={isCollapsible}
          isPrintView={isPrintView}
        />
      ))}
      <When condition={showMore && entries.length - cappedEntries.length > 0}>
        <S.MoreButton $padding={$padding}>
          <Button
            $variant="outline"
            $colorTheme="muted"
            $height={24}
            $width="auto"
            $fontSize={12}
            onClick={() => {
              setDisplayedItems(entries)
              setShowMore(false)
            }}
          >
            Show {entries.length - cappedEntries.length} older positions
          </Button>
        </S.MoreButton>
      </When>
    </S.Timeline>
  )
}
