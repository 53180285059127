import styled from 'styled-components'

export const SequenceStatusCell = styled.div`
  button {
    margin-top: 0.25rem;
    display: inline-flex;
    transform: translateX(-0.375rem);
  }
`

export const CandidateStatusCell = styled.div`
  button {
    margin-top: 0.25rem;
    display: inline-flex;
    transform: translateX(-0.375rem);
  }
`

export const FavoriteHeader = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const CandidateStageActionsCell = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.5rem;
  gap: ${({ theme }) => theme.spacing[12]};
`

export const CandidateCriteriaMatchDetailsCell = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-right: solid 1px;
  // No border left as it's handled by the control column
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2.5rem;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.bgTertiary};
    opacity: 0.5;
    z-index: -1;
  }
`

export const CandidateCriteriaMatchDetailsCellInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`
