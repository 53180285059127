import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { Button } from 'src/components/primitives/button'
import { Icon } from 'src/components/primitives/icon'
import { Spacer } from 'src/components/primitives/spacer'
import { closeDialogAtom, DialogId } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'

interface IntroProps {
  onContinue: () => void
}

export const Intro = ({ onContinue }: IntroProps): JSX.Element => {
  const closeDialog = useSetAtom(closeDialogAtom)

  return (
    <>
      <Icon name="mails" size={40} color="fgFaded10" />
      <Spacer $size={24} />
      <Caption size="LG">No outreach sequence</Caption>
      <Spacer $size={16} />
      <Paragraph size="SM">
        You don&rsquo;t have an outreach sequence for this job position yet, create one to start moving
        candidates to the outreach.
      </Paragraph>
      <Spacer $size={24} />
      <Flex $gap={16}>
        <Button
          $variant="fill"
          $colorTheme="tint"
          $width="full"
          $height={40}
          $align="center"
          onClick={onContinue}
        >
          Create outreach sequence
        </Button>
        <Button
          $variant="outline"
          $colorTheme="muted"
          $width="full"
          $height={40}
          $align="center"
          onClick={() => {
            closeDialog(DialogId.CREATE_SEQUENCE)
          }}
        >
          Cancel
        </Button>
      </Flex>
    </>
  )
}
