import { CONTENT_PADDING } from 'src/styles/constants'
import styled from 'styled-components'

export const RefinementActionsBar = styled.div<{ $maxWidth: string }>`
  width: 100%;
  max-width: ${({ $maxWidth }) => `calc(${$maxWidth} + 1rem)`};
  padding: ${({ theme }) => `${theme.spacing[8]} 0`};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[8]};
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  transition: padding-top 0.1s;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    padding-top: ${({ theme }) => theme.spacing[8]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
    opacity: var(--border-opacity, 0);
    transition: opacity 0.1s;
  }
`

export const RefinementActionsBarHeader = styled.div<{ $maxWidth: string }>`
  max-width: ${({ $maxWidth }) => $maxWidth};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.spacing[40]};
  margin-left: ${CONTENT_PADDING};
`

export const RefinementActionsBarPrint = styled.div<{ $maxWidth: string }>`
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth};
  page-break-before: avoid;
  page-break-inside: avoid;
  page-break-after: avoid;
`

export const Sentinel = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  visibility: hidden;
`
