import { PDF } from 'src/components/primitives/pdf'
import * as S from './candidate-resume.styled'

interface CandidateResumeProps {
  resumeUrl: string
}

export const CandidateResume = ({ resumeUrl }: CandidateResumeProps): JSX.Element => {
  return (
    <S.CandidateResumeContainer>
      <S.ResumePreview>
        <PDF fileUrl={resumeUrl} />
      </S.ResumePreview>
    </S.CandidateResumeContainer>
  )
}
