export const waitForNetworkIdle = async (iframe: HTMLIFrameElement): Promise<void> => {
  const scrollToBottom = async (): Promise<void> => {
    if (iframe.contentDocument?.documentElement) {
      for (let i = 0; i < 3; i++) {
        iframe.contentDocument.documentElement.scrollTop = iframe.contentDocument.documentElement.scrollHeight
        await new Promise(resolve => setTimeout(resolve, 1000))
      }
      setTimeout(() => {
        if (iframe.contentDocument?.documentElement) {
          iframe.contentDocument.documentElement.scrollTop = 0
        }
      }, 2000)
    }
  }

  // First scroll to trigger lazy loading
  await scrollToBottom()

  // Then wait for network idle
  await new Promise<void>((resolve) => {
    let activeRequests = 0
    let timeoutId: number

    // Function to check if network is idle
    const checkNetworkIdle = (): void => {
      if (activeRequests === 0) {
        if (timeoutId) clearTimeout(timeoutId)
        // Wait an additional 500ms to ensure no new requests start
        timeoutId = window.setTimeout(() => {
          if (activeRequests === 0) {
            observer.disconnect()
            mutationObserver.disconnect()
            resolve()
          }
        }, 500)
      }
    }
    // Track all images loading
    const trackImage = (img: HTMLImageElement): void => {
      // Check if image is lazy loaded (has data-src or similar)
      const isLazyLoaded = img.dataset.src ?? img.getAttribute('loading') === 'lazy'
      if (!img.complete || isLazyLoaded) {
        activeRequests++

        // Create intersection observer for lazy loaded images
        if (isLazyLoaded) {
          const intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                intersectionObserver.disconnect()
                // Wait for actual load after intersection
                img.addEventListener('load', () => {
                  activeRequests--
                  checkNetworkIdle()
                }, { once: true })
              }
            })
          })
          intersectionObserver.observe(img)
        } else {
          // Normal image loading
          img.addEventListener('load', () => {
            activeRequests--
            checkNetworkIdle()
          }, { once: true })
        }

        // Error handling
        img.addEventListener('error', () => {
          activeRequests--
          checkNetworkIdle()
        }, { once: true })
      }
    }

    const images = iframe.contentDocument?.getElementsByTagName('img') ?? []
    Array.from(images).forEach(trackImage)

    // Watch for dynamically added images
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof HTMLImageElement) {
            trackImage(node)
          } else if (node instanceof Element) {
            const newImages = node.getElementsByTagName('img')
            Array.from(newImages).forEach(trackImage)
          }
        })
      })
    })

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const originalFetch = iframe.contentWindow?.fetch
    if (originalFetch && iframe.contentWindow?.fetch) {
      iframe.contentWindow.fetch = async (...args) => {
        activeRequests++
        return await originalFetch.apply(this, args)
          .finally(() => {
            activeRequests--
            checkNetworkIdle()
          })
      }
    }

    // Track all other network requests (images, scripts, etc)
    const observer = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        if (entry.entryType === 'resource') {
          activeRequests--
          checkNetworkIdle()
        }
      })
    })

    if (iframe.contentDocument) {
      mutationObserver.observe(iframe.contentDocument, {
        subtree: true,
        childList: true
      })
    }
    observer.observe({ entryTypes: ['resource'] })

    // Start checking
    checkNetworkIdle()
  })
}

export const waitForNetworkIdleWithTimeout = async (iframe: HTMLIFrameElement, timeout = 60000): Promise<void> => {
  await Promise.race([
    waitForNetworkIdle(iframe),
    new Promise<void>((_resolve, reject) =>
      setTimeout(() => {
        reject(new Error('Timeout waiting for network idle'))
      }, timeout)
    )
  ])
}
