// import { useCompanyLogoQuery } from 'src/hooks/queries/use-company-logo'
import * as S from './company-logo.styled'
import { useAvatarQuery } from 'src/hooks/queries/use-avatar'
import { Icon, Icons } from '../icon'
import type { IconName } from '../icon'
import { isNil } from 'lodash'
import { useCompanyLogoQuery } from 'src/hooks/queries/use-company-logo'
import { useMemo, useState } from 'react'
import type { Spacing } from 'src/styles/theme/types'

export interface CompanyLogoProps extends S.CompanyLogoStyleProps {
  name?: string
  url?: string | null
  logoUrl?: string | null
  externalLogoUrl?: string | null
  fallbackIcon?: IconName
  $size?: Spacing
  loading?: 'eager' | 'lazy'
}

export const CompanyLogo = ({
  name,
  url,
  logoUrl,
  externalLogoUrl,
  fallbackIcon = Icons.building2,
  $shape,
  $size,
  loading = 'lazy'
}: CompanyLogoProps): JSX.Element => {
  const [isClearbitLogoError, setIsClearbitLogoError] = useState(false)
  const {
    isLoading,
    data
  } = useAvatarQuery({ url: logoUrl, size: $size })
  const {
    // isPending: isCompanyLogoPending,
    data: companyLogoData
  } = useCompanyLogoQuery(!isLoading && isNil(data) ? { name, url } : {})

  const companyAvatar = useMemo(() => {
    if (!isNil(externalLogoUrl)) {
      return (
        <img
          src={externalLogoUrl}
          width={$size}
          height={$size}
          alt={name}
          loading={loading}
        />
      )
    }
    if (!isNil(data)) {
      return (
        <img
          src={data}
          width={$size}
          height={$size}
          alt={name}
          loading={loading}
        />
      )
    }
    if (!isClearbitLogoError && !isNil(companyLogoData)) {
      return (
        <img
          src={companyLogoData.logoUrl}
          width={$size}
          height={$size}
          alt={name}
          onError={() => { setIsClearbitLogoError(true) }}
        />
      )
    }
    return null
  }, [$size, companyLogoData, data, externalLogoUrl, isClearbitLogoError, loading, name])

  return (
    <S.CompanyLogo $shape={$shape}>
      {companyAvatar}
      {!companyAvatar && (
        <S.Icon>
          <Icon name={fallbackIcon} color="fgTertiary" />
        </S.Icon>
      )}
    </S.CompanyLogo>
  )
}
