import { sessionQuery } from 'src/hooks/queries/use-session'
import queryClient from 'src/hooks/query-client'
import type { DeferredData } from '@remix-run/router/dist/utils'
import { defer, redirect } from 'react-router-dom'
import { mergeIntegrationsQuery } from 'src/hooks/queries/use-merge-integrations-list'
import { AtsIntegrationType } from '../api/backend/merge'
import type { AtsIntegration, MergeAtsJob, MergeIntegration } from '../api/backend/merge'
import { getMergeJobs } from 'src/hooks/queries/use-get-merge-jobs'
import { getAtsIntegrationDetails } from 'src/hooks/queries/use-get-integration-details'
import { jobQuery } from 'src/hooks/queries/use-job'
import { JobSearchRefinementType } from '../api/backend/jobs'
import type { Job, JobSearchRefinement } from '../api/backend/jobs'
import RouteBuilder from '../route-builder'
import { applicantsOverviewStatusQuery } from 'src/hooks/queries/use-applicants-overview-status'
import type { ApplicantsOverviewStatus, MergeInterviewStage } from '../api/backend/applicants'
import { atsInterviewStagesQuery } from 'src/hooks/queries/use-ats-interview-stages'
import { listJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import { APPLICANT_STAGES } from '../api/backend/candidate_jobs'

export interface ApplicantsCandidatesLoaderData {
  job: Job
  mergeIntegrations: MergeIntegration[]
  mergeJobs: MergeAtsJob[]
  atsJobIntegrationDetails: AtsIntegration
  atsCandidateIntegrationDetails: AtsIntegration
  atsAttachmentIntegrationDetails: AtsIntegration
  applicantsOverviewStatus: ApplicantsOverviewStatus
  atsInterviewStages: MergeInterviewStage[]
  jobId: Job['id']
  applicantsRefinementId: JobSearchRefinement['id'] | undefined
  stage: APPLICANT_STAGES
}

interface ApplicantsCandidatesLoader {
  params: {
    jobId: string
  }
  request: Request
}

export const applicantsCandidatesLoader = async ({ params: { jobId }, request }: ApplicantsCandidatesLoader): Promise<DeferredData | Response> => {
  const url = new URL(request.url)
  const searchParamStage = url.searchParams.get('stage')
  const { org } = await queryClient.ensureQueryData(sessionQuery())
  const job = await queryClient.ensureQueryData(jobQuery(jobId))
  if (job.mergeAtsJobId && org?.mergeAtsIntegration && !searchParamStage) {
    // Currently on root path but job is already linked --> go to incoming applicants
    return redirect(RouteBuilder.build('JOBS_APPLICANTS', { jobId }, { stage: APPLICANT_STAGES.INCOMING }))
  }
  if ((!job.mergeAtsJobId || !org?.mergeAtsIntegration) && searchParamStage) {
    // Stage is provided but job is not linked --> go to applicants page
    return redirect(RouteBuilder.build('JOBS_APPLICANTS', { jobId }))
  }
  const applicantsRefinements = await queryClient.ensureQueryData(
    listJobSearchRefinementsQuery(jobId, { type: JobSearchRefinementType.APPLICANTS })
  )
  return defer({
    job,
    mergeIntegrations: queryClient.ensureQueryData(mergeIntegrationsQuery()),
    mergeJobs: queryClient.ensureQueryData(getMergeJobs()),
    atsJobIntegrationDetails: queryClient.ensureQueryData(getAtsIntegrationDetails(AtsIntegrationType.Job)),
    atsCandidateIntegrationDetails: queryClient.ensureQueryData(getAtsIntegrationDetails(AtsIntegrationType.Candidate)),
    atsAttachmentIntegrationDetails: queryClient.ensureQueryData(getAtsIntegrationDetails(AtsIntegrationType.Attachment)),
    applicantsOverviewStatus: queryClient.ensureQueryData(applicantsOverviewStatusQuery(jobId)),
    atsInterviewStages: queryClient.ensureQueryData(atsInterviewStagesQuery(jobId)),
    jobId,
    applicantsRefinementId: applicantsRefinements[0]?.id,
    stage: searchParamStage as APPLICANT_STAGES
  })
}
