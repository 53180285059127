import { useCallback } from 'react'
import { Button, Flex } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import { useToggleCandidateFavoriteStatus } from 'src/hooks/mutations/use-toggle-candidate-favorite-status'
import { ApplicantArchive, ApplicantMoveStage } from '../applicant-move-stage'

interface ApplicantsActionsProps {
  candidateJobId: string
  jobId: string
  favorite: boolean
}

export const ApplicantsActions = ({ candidateJobId, jobId, favorite }: ApplicantsActionsProps): JSX.Element => {
  const { toggleFavoriteStatus } = useToggleCandidateFavoriteStatus()

  const handleToggleFavoriteStatus = useCallback((newStatus: boolean): void => {
    toggleFavoriteStatus({
      candidateJobId,
      newFavoriteStatus: newStatus
    })
  }, [toggleFavoriteStatus, candidateJobId])

  return (
    <Flex $justify="flex-end" $gap={8}>
      <Button
        ariaLabel="Toggle favorite status"
        $variant="outline"
        $colorTheme={favorite ? 'warning' : 'muted'}
        $fontSize={12}
        $height={24}
        // $width={32}
        leadingIcon={favorite ? Icons.starFill : Icons.star}
        // TODO: Hook up this up to the actual current candidate status
        onClick={() => {
          handleToggleFavoriteStatus(!favorite)
        }}
      >
        Shortlist
      </Button>
      <ApplicantMoveStage jobId={jobId} candidateJobId={candidateJobId} />
      <ApplicantArchive candidateJobId={candidateJobId} />
    </Flex>
  )
}
