import { atom } from 'jotai'
import type { Atom } from 'jotai'
// Remove this import as we won't use it anymore
// import { useQueryParams } from 'src/hooks/use-query-params'
import type { Job } from 'src/libs/api/backend/jobs'

export enum DialogId {
  ADD_CANDIDATE = 'add-candidate',
  ALERT = 'alert',
  CANDIDATE_DETAILS = 'candidate-details',
  CREATE_DEPARTMENT = 'create-company',
  EDIT_DEPARTMENT = 'edit-department',
  CREATE_PROJECT = 'create-project',
  EDIT_PROJECT = 'edit-project',
  // Legacy, replace with CREATE_NEW_JOB
  CREATE_JOB_POSITION = 'create-position',
  CREATE_NEW_JOB = 'create-new-job',
  CREATE_SEQUENCE = 'create-sequence',
  DEFAULT_SETTINGS = 'default-settings',
  EDIT_CANDIDATE = 'edit-candidate',
  JOB_SETTINGS = 'job-settings',
  MOVE_TO_ANOTHER_JOB = 'move-to-another-job',
  SEARCH_CANDIDATES = 'search-candidates',
  WRITE_MESSAGE = 'write-message',
  NOTIFICATIONS = 'notifications',
  DISCONNECTED_EMAIL_ACCOUNT = 'disconnected-email-account',
  SEND_FEEDBACK = 'send-feedback',
  WRITE_CUSTOM_OUTREACH = 'write-custom-outreach',
  BILLING_SUBSCRIBE = 'billing-subscribe',
  CALENDAR = 'calendar',
  ARCHIVE_JOB = 'archive-job',
  REFINEMENT_FEEDBACK = 'refinement-feedback',
  EXPORT_CANDIDATES = 'export-candidates',
  EXPORT_CANDIDATES_TO_FILE_OPTIONS = 'export-candidates-to-file-options',
  ENABLE_AUTO_OUTREACH = 'enable-auto-outreach',
  UPGRADE_YOUR_PLAN_FROM_TRIAL = 'upgrade-your-plan-from-trial',
  UPGRADE_YOUR_PLAN_FROM_PROFESSIONAL = 'upgrade-your-plan-from-professional',
  CONNECT_ATS = 'connect-ats',
  INSUFFICIENT_CREDITS = 'insufficient-credits',
  VIEW_HOLIDAYS = 'view-holidays',
  MANAGE_SEQUENCE_PAUSE_DATES = 'manage-sequence-pause-dates',
  COPY_COMPANY_RATINGS = 'copy-company-ratings',
  DOWNLOAD_PDF = 'download-pdf',
  WELCOME_TO_PIN = 'welcome-to-pin',
  REQUEST_APPLICANTS_ACCESS = 'request-applicants-access'
}

export interface EditDepartmentPayload {
  departmentId: string
  name: string
  domain: string
}

export interface CreateDepartmentPayload {
  setDepartmentId: (id: string) => void
}

export interface ArchiveJobPayload {
  job: Job
}

export interface DialogArgs<T = unknown> {
  id: DialogId
  payload?: T
}

export interface AlertArgs {
  variant?: 'neutral' | 'tint' | 'negative'
  message: string
  description?: string
  confirmText?: string
  cancelText?: string
  isModal?: boolean
  onConfirm?: () => void
  onCancel?: () => void
}

export const dialogsAtom = atom<DialogArgs[]>([])
export const openDialogAtom = atom(null, (_, set, args: DialogArgs) => {
  set(dialogsAtom, (prev) => {
    return [...prev.filter((d) => d.id !== args.id), args]
  })
  switch (args.id) {
    case DialogId.CANDIDATE_DETAILS: {
      if (args.payload) {
        const candidateJobId = args.payload as string
        if (candidateJobId) {
          // Use the URL API to add the candidate param
          const url = new URL(window.location.href)
          url.searchParams.set('candidate', candidateJobId)
          window.history.pushState({}, '', url.toString())
        }
      }
      break
    }
    default: {
      break
    }
  }
})
export const openAlertAtom = atom(null, (_, set, args: AlertArgs) => {
  // Only keep the one alert
  set(dialogsAtom, (prev) => {
    if (prev.some((d) => d.id === DialogId.ALERT)) {
      return prev
    }
    return [...prev, { id: DialogId.ALERT, payload: args }]
  })
})
export const closeDialogAtom = atom(null, (_, set, id: DialogId) => {
  set(dialogsAtom, (prev) => prev.filter((d) => d.id !== id))
})
export const controlDialogAtom = atom(null, (_, set, args: { id: DialogId, newState: boolean }) => {
  if (args.newState) {
    set(openDialogAtom, args)
  } else {
    set(closeDialogAtom, args.id)
  }
})

// REMEMBER TO USE USEMEMO AROUND THESE ATOMS GENERATORS
export const getDialogPayloadAtom = <T>(id: DialogId): Atom<T> => atom(
  (get) => get(dialogsAtom).find((d) => d.id === id)?.payload as T
)
export const isDialogOpenAtom = (id: DialogId): Atom<boolean> => atom((get) => get(dialogsAtom).some((d) => d.id === id))
