import {
  Calendar,
  DateSegment,
  Dialog,
  Heading
} from 'react-aria-components'
import { Icon, Icons } from 'src/components/primitives/icon'
import * as S from './date-picker.styled'
import { parseDate } from '@internationalized/date'
import type { FieldCommonProps } from '../common'

export interface DatePickerStyleProps {
  $height?: number
}

interface DatePickerInputProps extends DatePickerStyleProps, FieldCommonProps {}

export const DatePickerInput = ({ $height = 40, name, register }: DatePickerInputProps): JSX.Element => {
  const { onSelect, value } = register(name)

  return (
    <S.DatePickerInput
      // defaultValue={parseDate(new Date().toISOString().slice(0, 10))}
      value={value instanceof Date ? parseDate(value.toISOString().slice(0, 10)) : undefined}
      onChange={(value) => {
        if (value) {
          onSelect(value.toDate('UTC'))
        }
      }}
    >
      <S.Trigger $height={$height}>
        <Icon name={Icons.calendar} size={16} color="fgSecondary" />
        <S.TriggerInner>
          <S.DateInputField>{(segment) => <DateSegment segment={segment} />}</S.DateInputField>
          <S.ToggleCalendarButton><Icon name={Icons.chevronsUpDownSmall} /></S.ToggleCalendarButton>
        </S.TriggerInner>
      </S.Trigger>
      <S.CalendarPopover data-name="calendar-popover" offset={16}>
        <Dialog>
          <Calendar>
            <S.CalendarHeader>
              <S.CalendarPrevNextButton slot="previous"><Icon name={Icons.chevronLeft} /></S.CalendarPrevNextButton>
              <Heading />
              <S.CalendarPrevNextButton slot="next"><Icon name={Icons.chevronRight} /></S.CalendarPrevNextButton>
            </S.CalendarHeader>
            <S.CalendarGrid>{(date) => <S.CalendarCell date={date} />}</S.CalendarGrid>
          </Calendar>
        </Dialog>
      </S.CalendarPopover>
    </S.DatePickerInput>
  )
}
