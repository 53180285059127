import CONFIG from 'src/config'
import { Button } from 'src/components/primitives/button'
import { Spacer } from 'src/components/primitives/spacer'
import { Flex } from 'src/components/primitives/flex'
import * as S from './login-box.styled'
import { Paragraph } from 'src/components/primitives/typography'

interface LoginBoxProps {
  redirectPath: string
  children: React.ReactNode
}

const LoginBox = ({ redirectPath, children }: LoginBoxProps): JSX.Element => {
  const redirectUrl = `${CONFIG.APP_DOMAIN}${redirectPath}`
  const errorRedirectPath = window.location.href
  const queryString = `?redirect=${redirectUrl}&error_redirect=${errorRedirectPath}`
  const googleOauthUrl = `${CONFIG.API_DOMAIN}/auth/google${queryString}`
  const microsoftOauthUrl = `${CONFIG.API_DOMAIN}/auth/microsoft${queryString}`

  return (
    <>
      {children}
      <Spacer $size={40} />
      <Flex $direction="column" $gap={16}>
        <Button
          href={googleOauthUrl}
          hrefTarget="_self"
          $variant="raised"
          $colorTheme="normal"
          $width="full"
          $gap={10}
          leadingIcon="google"
        >
          Continue with Google
        </Button>
        <Button
          href={microsoftOauthUrl}
          hrefTarget="_self"
          $variant="raised"
          $colorTheme="normal"
          $width="full"
          $gap={10}
          leadingIcon="microsoft"
        >
          Continue with Microsoft
        </Button>
      </Flex>
      <Spacer $size={36} />
      <S.Disclaimer>
        <Paragraph size="SM" $color="fgTertiary">
          By continuing you confirm that you accept Pin&rsquo;s <a href="https://www.pin.com/terms" target="_blank" rel='noopener noreferrer'>Terms of Service</a> and <a href="https://www.pin.com/privacy" target="_blank" rel='noopener noreferrer'>Privacy Policy</a>.
        </Paragraph>
      </S.Disclaimer>
    </>
  )
}

export default LoginBox
