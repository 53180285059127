import { z } from 'zod'
import Api from '..'
import type { Job } from './jobs'
import type { CandidateJob } from './candidate_jobs'

const applicantsOverviewStatusParser = z.object({
  candidatesCount: z.number(),
  applicantsCount: z.number(),
  approved: z.number(),
  archived: z.number(),
  pending: z.number(),
  shortlisted: z.number(),
  thisWeek: z.number()
})

export type ApplicantsOverviewStatus = z.infer<typeof applicantsOverviewStatusParser>

export async function getApplicantsOverviewStatus (jobId: Job['id']): Promise<ApplicantsOverviewStatus> {
  const { data } = await Api.get(`/jobs/${jobId}/applicants/overview`)
  return applicantsOverviewStatusParser.parse(data)
}

const mergeInterviewStageParser = z.object({
  id: z.string().uuid(),
  mergeName: z.string().nullish(),
  mergeStageNumber: z.number().nullish(),
  mergeId: z.string(),
  remoteId: z.string().nullish(),
  mergeCreatedAt: z.coerce.date(),
  mergeModifiedAt: z.coerce.date(),
  resumeReviewStage: z.boolean().nullish()
})

export type MergeInterviewStage = z.infer<typeof mergeInterviewStageParser>

export async function getInterviewStagesByJobId (jobId: Job['id']): Promise<MergeInterviewStage[]> {
  const { data } = await Api.get(`/jobs/${jobId}/applicants/interview-stages`)
  return z.array(mergeInterviewStageParser).parse(data)
}

const rejectionReasonsParser = z.array(z.object({
  id: z.string().uuid(),
  mergeId: z.string(),
  mergeCreatedAt: z.coerce.date(),
  mergeModifiedAt: z.coerce.date(),
  mergeName: z.string()
}))

export type RejectionReasons = z.infer<typeof rejectionReasonsParser>

export async function getRejectionReasonsByJobId (jobId: Job['id']): Promise<RejectionReasons> {
  const { data } = await Api.get(`/jobs/${jobId}/applicants/rejection-reasons`)
  return rejectionReasonsParser.parse(data)
}

export async function advanceJobInterviewStageApi (jobId: Job['id'], candidateJobIds: Array<CandidateJob['id']>, destinationJobInterviewStageId: MergeInterviewStage['id']): Promise<void> {
  await Api.put(`/jobs/${jobId}/applicants/advance_job_interview_stage`, {
    candidateJobIds,
    destinationJobInterviewStageId
  })
}
