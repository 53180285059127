import styled from 'styled-components'
import {
  Button as AriaButton,
  CalendarCell as AriaCalendarCell,
  CalendarGrid as AriaCalendarGrid,
  DateInput,
  DatePicker,
  Group,
  Popover
} from 'react-aria-components'
import type { DatePickerStyleProps } from './date-picker'

export const DatePickerInput = styled(DatePicker)`
  position: relative;
`

export const Trigger = styled.div<DatePickerStyleProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 0.625rem;
  height: ${({ $height }) => $height}px;
`

export const TriggerInner = styled(Group)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`

export const DateInputField = styled(DateInput)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.fgPrimary};
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: 1;
  text-transform: uppercase;
  div {
    &[data-placeholder="true"] {
      color: ${({ theme }) => theme.colors.fgSecondary};
    }
  }
`

export const ToggleCalendarButton = styled(AriaButton)`
  all: unset;
  width: 1rem;
  height: 1rem;
  flex: 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const CalendarPopover = styled(Popover)`
  width: 320px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  z-index: ${({ theme }) => theme.zindeces.dropdown};
  transform: translateX(-30px) translateY(0);
  transform-origin: top center;

  &[data-entering] {
    animation: slideIn 0.15s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-exiting] {
    animation: slideOut 0.15s cubic-bezier(0.16, 1, 0.3, 1);
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-30px) translateY(-4px) scale(0.98);
    }
    to {
      opacity: 1;
      transform: translateX(-30px) translateY(0) scale(1);
    }
  }

  @keyframes slideOut {
    from {
      opacity: 1;
      transform: translateX(-30px) translateY(0) scale(1);
    }
    to {
      opacity: 0;
      transform: translateX(-30px) translateY(-4px) scale(0.98);
    }
  }
`

export const CalendarHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
  h2 {
    font-size: ${({ theme }) => theme.fontSizes[14]};
    font-weight: ${({ theme }) => theme.fontWeights[500]};
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
`

export const CalendarPrevNextButton = styled(AriaButton)`
  all: unset;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const CalendarGrid = styled(AriaCalendarGrid)`
  width: 100%;
  th {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: ${({ theme }) => theme.fontWeights[500]};
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
  td[aria-disabled="true"] {
    opacity: 0.5;
    // pointer-events: none;
  }
`

export const CalendarCell = styled(AriaCalendarCell)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  border-radius: 4px;
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.tintTranslucent15};
      color: ${({ theme }) => theme.colors.tintFg};
      user-select: none;
      cursor: pointer;
    }
  }
`
