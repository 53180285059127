import { atom } from 'jotai'
import type { Setter } from 'jotai'
import { Icons } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { LocalStorageKey } from 'src/constants'

export type NotificationType = 'toast' | 'api-status-toast' | 'candidate-added' | 'candidate-rejected' | 'candidate-moved' | 'candidate-restored' | 'failed-adding-candidates' | 'candidate-favorited'

export const CUSTOM_NOTIFICATIONS: NotificationType[] = ['candidate-added', 'candidate-rejected', 'candidate-moved', 'candidate-restored', 'candidate-favorited']

export const CUSTOM_NOTIFICATION_MESSAGE: NotificationType[] = ['failed-adding-candidates']

export interface Notification<T = unknown> {
  id: string
  type: NotificationType
  position?: 'bottom-center' | 'bottom-right'
  variant: 'neutral' | 'warning' | 'positive' | 'negative' | 'tint'
  icon?: IconName
  iconNode?: React.ReactNode
  isLoading?: boolean
  message?: string | React.ReactNode
  delay?: number
  autoClose?: boolean // default true
  payload?: T
  action?: React.ReactNode
  customContent?: React.ReactNode
  closeDelay?: number
}

const defaultNotification: Partial<Notification> = {
  position: 'bottom-right',
  variant: 'neutral',
  type: 'toast',
  autoClose: true
}

export const notificationsAtom = atom<Notification[]>([])

export const closeNotificationAtom = atom(null, (get, set, id: string | null, message?: string) => {
  const notifications = get(notificationsAtom)

  if (message) {
    const notificationId = notifications.find(n => n.message?.toString().toLowerCase().includes(message.toLowerCase()))?.id
    set(notificationsAtom, (prev) => prev.filter((n) => n.id !== notificationId))
    return
  }

  const closingNotification = notifications.find(n => n.id === id)
  if (closingNotification?.type === 'api-status-toast') {
    localStorage.setItem(LocalStorageKey.API_STATUS_NOTICED, Date.now().toString())
  }

  set(notificationsAtom, (prev) => prev.filter((n) => n.id !== id))
})

export const clearNotificationsAtom = atom(null, (_, set) => {
  set(notificationsAtom, [])
})

const shouldClearBeforeAdding = (notifications: Notification[], currentType?: NotificationType): boolean => {
  if (currentType === 'toast') {
    return notifications.some((n) => n.type !== 'toast')
  } else if (currentType?.startsWith('candidate-')) {
    return notifications.some((n) => n.type === 'toast')
  } else if (currentType === 'api-status-toast') {
    return notifications.some((n) => n.type === 'api-status-toast')
  }
  return false
}

const setNotification = (set: Setter, notification: Omit<Notification, 'id'>): void => {
  set(notificationsAtom, (prev) => {
    const shouldClear = shouldClearBeforeAdding(prev, notification.type)
    const filteredNotifications = shouldClear
      ? prev.filter(n => n.type !== notification.type)
      : prev

    const newNotifications = [
      ...filteredNotifications,
      {
        ...notification,
        id: crypto.randomUUID()
      }
    ]

    if (newNotifications.length >= 4) {
      newNotifications.shift()
    }
    return newNotifications
  })
}

export const notifyAtom = atom(null, (_, set, notification: Omit<Notification, 'id'>) => {
  if (notification.delay) {
    setTimeout(() => {
      setNotification(set, { ...defaultNotification, ...notification })
    }, notification.delay)
  } else {
    setNotification(set, { ...defaultNotification, ...notification })
  }
})

export const notifySuccessAtom = atom(null, (_, set, notification: Partial<Omit<Notification, 'id'>>) => {
  set(notifyAtom, {
    type: 'toast',
    variant: 'positive',
    icon: Icons.checkCheck,
    position: 'bottom-right',
    ...notification
  })
})

export const notifyErrorAtom = atom(null, (_, set, notification: Partial<Omit<Notification, 'id'>>) => {
  set(notifyAtom, {
    type: 'toast',
    variant: 'negative',
    icon: Icons.xOctagon,
    position: 'bottom-right',
    ...notification
  })
})
