import styled from 'styled-components'

export const TabsGroup = styled.div`
  position: relative;
  gap: 0.25rem;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  padding-bottom: ${({ theme }) => theme.spacing[4]};
`

export const TabButton = styled.button<{ $isActive: boolean, $isDisabled: boolean }>`
  all: unset;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: ${({ theme }) => theme.spacing[20]};
  font-weight: ${({ theme }) => theme.fontWeights['500']};
  color: ${({ theme }) => theme.colors.fgPrimary};
  gap: ${({ theme }) => theme.spacing[2]};
  padding: ${({ theme }) => theme.spacing[6]};
  background: none;
  border: none;
  opacity: ${({ $isDisabled }) => $isDisabled ? 0.5 : 1};
`

export const TabIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing[2]};
`

export const TabIndicator = styled.div<{ $width: number, $left: number }>`
  position: absolute;
  bottom: 0px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.tintFg};
  transition: all 0.15s cubic-bezier(0.77,0,0.18,1);
  width: ${({ $width }) => `${$width}px`};
  left: ${({ $left }) => `${$left}px`};
`
