import { CandidatesPageHeader } from '../candidates-page-header'
import type { CandidatesPageHeaderProps } from '../candidates-page-header'
import { CONTENT_PADDING } from 'src/styles/constants'
import { useMemo } from 'react'
import type { ReactNode } from 'react'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Button } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import RouteBuilder from 'src/libs/route-builder'
import { useDeleteJobSearchRefinement } from 'src/hooks/mutations/use-delete-job-search-refinement'
import { cloneRefinementCriteriaAtom } from 'src/stores/job-refinement'
import { useSetAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { usePrintView } from 'src/hooks/use-print-view'
import { DialogId, openAlertAtom, openDialogAtom } from 'src/stores/dialogs'

interface SourcingPageHeaderProps extends Pick<CandidatesPageHeaderProps, 'isEditable' | 'onEdit' | 'headingLoading'> {
  title: string
  showActions?: boolean
  jobSearchRefinement?: JobSearchRefinement
  jobId?: string
  isSequenceEmpty: boolean
  customActions?: ReactNode[]
  printUrl?: string
  hasCandidateJobs?: boolean
  isRecommending?: boolean
  maxWidth: string
}

export const SourcingPageHeader = ({
  headingLoading,
  title,
  showActions = true,
  jobId,
  jobSearchRefinement,
  isRecommending,
  maxWidth,
  ...headerProps
}: SourcingPageHeaderProps): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)
  const openAlert = useSetAtom(openAlertAtom)
  const { deleteSearchRefinement } = useDeleteJobSearchRefinement()
  const setCloneRefinementCriteria = useSetAtom(cloneRefinementCriteriaAtom)
  const isPrintView = usePrintView()
  const navigate = useNavigate()

  const headerActions: CandidatesPageHeaderProps['actions'] = useMemo(() => ([
    {
      children: 'Add Candidate',
      icon: 'plus',
      // $variant: 'outline',
      $colorTheme: 'tint',
      onClick: () => {
        openDialog({ id: DialogId.ADD_CANDIDATE })
      }
    }
  ]), [openDialog])

  const contextItems = useMemo(() => {
    const items: MenuItemProps[] = []
    if (jobSearchRefinement) {
      items.push({
        id: 'clone-ai-sourced',
        title: 'Clone Search',
        icon: Icons.copy,
        isDisabled: isRecommending,
        onSelect: () => {
          setCloneRefinementCriteria(jobSearchRefinement.searchCriteria ?? undefined)
          navigate(RouteBuilder.build('JOBS_CANDIDATES_SOURCING_SEARCH', { jobId }))
        }
      }, {
        id: 'clone-ai-sourced-separator',
        title: 'Clone AI Sourced Separator',
        type: 'separator'
      })
    }
    items.push({
      id: 'position-details',
      title: 'Position Details',
      icon: Icons.settings2,
      href: RouteBuilder.build('SETTINGS_JOB_DETAILS', { jobId })
    })
    if (jobSearchRefinement) {
      items.push({
        id: 'delete-search',
        title: 'Delete Search',
        icon: Icons.trash,
        variant: 'negative',
        isDisabled: isRecommending,
        onSelect: () => {
          openAlert({
            message: 'Are you sure you want to delete this search?',
            description: 'All candidates within this search will be lost.',
            cancelText: 'Cancel',
            confirmText: 'Delete',
            onConfirm: () => {
              deleteSearchRefinement({ jobSearchRefinementId: jobSearchRefinement.id })
            }
          })
        }
      })
    }
    return items
  }, [
    deleteSearchRefinement,
    isRecommending,
    jobId,
    jobSearchRefinement,
    navigate,
    openAlert,
    setCloneRefinementCriteria
  ])

  return (
    <div
      style={{
        maxWidth,
        width: '100%',
        pageBreakBefore: 'avoid',
        pageBreakAfter: 'avoid',
        marginLeft: isPrintView ? 0 : CONTENT_PADDING
      }}
    >
      <CandidatesPageHeader
        heading={title}
        subHeading={jobSearchRefinement?.subtitle}
        headingLoading={headingLoading}
        actions={showActions ? headerActions : []}
        isPrintView={isPrintView}
        context={
          <Dropdown
            trigger={
              <Button
                nested
                ariaLabel="Options"
                $variant="ghost"
                $colorTheme="muted"
                $width={24}
                $height={24}
                $fontSize={12}
                leadingIcon="more-vertical"
              />
            }
            items={contextItems}
            menuPosition="end"
            size="small"
            $minWidth='150px'
            $menuWidth='auto'
          />
        }
        $padding={jobSearchRefinement ? { top: 18, bottom: 2 } : undefined}
        {...headerProps}
      />
    </div>
  )
}
