import styled, { css } from 'styled-components'
import {
  Combobox as AriaKitCombobox,
  ComboboxItem as AriaKitComboboxItem,
  ComboboxList as AriaKitComboboxList
} from '@ariakit/react'

export const sharedInputStyles = css`
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 0 0.625rem 0 0.35rem;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  color: ${({ theme }) => theme.colors.fgPrimary};
`

export const ComboboxWrapper = styled.div<{ $isLoading: boolean }>`
  ${sharedInputStyles}
  ${({ $isLoading }) => $isLoading && `
    pointer-events: none;
    * {
      pointer-events: none;
    }
  `}
`

export const Combobox = styled(AriaKitCombobox)<{ $isLoading: boolean }>`
  ${sharedInputStyles}
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  &:placeholder {
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
  ${({ $isLoading }) => $isLoading && `
    pointer-events: none;
    opacity: 0.8;
    * {
      pointer-events: none;
    }
  `}
`

export const Icon = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.2rem;
  margin-right: 0.1rem;
`

export const ComboboxList = styled(AriaKitComboboxList)`
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  z-index: ${({ theme }) => theme.zindeces.dropdown};
`

export const ComboboxListInner = styled.div<{ $height?: number }>`
  padding: 0.375rem;
  width: var(--radix-popover-trigger-width) !important;
  max-height: calc(7 * 40px + (2 * 0.375rem));
  height: ${({ $height }) => $height ? `${$height}px` : '100%'};
  overflow-y: auto;
`

export const ComboboxItemText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ComboboxItemTextContext = styled.span`
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  color: ${({ theme }) => theme.colors.fgSecondary};
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const TrailingAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0;
`

export const ComboboxItem = styled(AriaKitComboboxItem)<{ $itemHeight?: number }>`
  padding: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border-radius: 3px;
  min-height: 2.5rem;
  min-height: ${({ $itemHeight }) => $itemHeight ? 'unset' : `${$itemHeight}px`};
  height: ${({ $itemHeight }) => $itemHeight ? `${$itemHeight}px` : 'unset'};
  cursor: pointer;
  overflow: hidden;
  /* span { */
  /*   font-size: ${({ theme }) => theme.fontSizes[14]}; */
  /*   font-weight: ${({ theme }) => theme.fontWeights[500]}; */
  /*   color: ${({ theme }) => theme.colors.fgPrimary}; */
  /* } */
  &[data-active-item] {
    background-color: ${({ theme }) => theme.colors.bgTertiary};
    background-color: ${({ theme }) => theme.colors.tintTranslucent5};
    ${ComboboxItemText} {
      color: ${({ theme }) => theme.colors.tintBg};
    }
    ${ComboboxItemTextContext} {
      color: ${({ theme }) => theme.colors.tintBg};
      opacity: 0.75;
    }
    ${TrailingAction} {
      opacity: 1;
    }
  }
`

export const ComboboxItemLabel = styled.div`
  padding: 0.125rem 0.375rem 0 0.375rem;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  color: ${({ theme }) => theme.colors.fgSecondary};
  user-select: none;
`

export const SelectedValueButton = styled.button`
  height: 1.5rem;
  padding: 0.125rem 0.375rem;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  &:first-child {
    margin-left: 0.375rem;
  }
`
