import styled from 'styled-components'

export const PlanBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
`

export const PlanDescriptionBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[24]};
  padding: ${({ theme }) => theme.spacing[16]} ${({ theme }) => theme.spacing[24]};
  width: 100%;
`

export const CurrentTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  height: 32px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.fgTranslucent5};
  color: ${({ theme }) => theme.colors.fgSecondary};
  font-size: ${({ theme }) => theme.fontSizes[14]};
  font-weight: 500;
`

export const Link = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.fgSecondary};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: 400;
  line-height: ${({ theme }) => theme.lineHeights[1]};
  text-decoration-line: underline;
`

export const DiscountedTag = styled.div`
  display: flex;
  height: 16px;
  padding: 0px 4px;
  align-items: center;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.positiveFaded};
`

export const OriginalPrice = styled.div`
  text-decoration-line: line-through;
  color: ${({ theme }) => theme.colors.fgTertiary};
`

export const MembersBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spacing[24]};
  align-self: stretch;
  border-top: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgSecondary};
`

export const MembersBoxHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};
  width: 100%;
  padding: ${({ theme }) => theme.spacing[8]} 0;
`

export const OptionsList = styled.ul`
  list-style-type: none;
  border-radius: ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  margin-bottom: ${({ theme }) => theme.spacing[20]};
`
