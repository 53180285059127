import * as Dialog from 'src/components/primitives/dialog'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { Badge } from 'src/components/primitives/badge'
import { Button, Spacer } from 'src/components/primitives'
import * as S from './disconnected-email-account-dialog.styled'
import { z } from 'zod'
import { useForm } from 'src/hooks/use-form'
import { FormActionButtons } from 'src/components/blocks/form-action-buttons'
import { Form } from 'src/components/forms'
import { Select } from 'src/components/forms/select'
import { Icon } from 'src/components/primitives/icon'
import { useSession } from 'src/hooks/use-session'
import { pluralize } from 'src/libs/pluralize'
import { When } from 'src/components/blocks/when'
import { Signal } from 'src/components/primitives/signal'
import { getEmailAccountAuthUrl } from 'src/libs/auth-urls'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { isNil } from 'lodash'
import { useMemo } from 'react'
import { Avatar } from 'src/components/primitives/avatar'
import { useJobByDepartmentsQuery } from 'src/hooks/queries/use-jobs-by-department'
import type { JobAndDepartment } from 'src/libs/api/backend/jobs'
import { Dropdown } from 'src/components/primitives/dropdown'
import RouteBuilder from 'src/libs/route-builder'
import { useBulkReassignSequenceSender } from 'src/hooks/mutations/use-bulk-reassign-sequence-sender'
import type { EmailAccount as UserEmailAccount } from 'src/libs/api/backend/users'
import type { EmailAccount as SessionEmailAccount } from 'src/libs/api/backend/session'
import { invalidateEmailAccounts } from 'src/hooks/invalidate-email-accounts'
import { closeDialogAtom, controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'

const replaceAccountForm = z.object({
  updatedSenderAccountId: z.string().uuid().nullish()
})

type ReplaceAccountForm = z.infer<typeof replaceAccountForm>

interface DisconnectedEmailAccountDialogProps {
  sequenceSenderEmailAccount?: UserEmailAccount
  emailAccount?: SessionEmailAccount
}

export const DisconnectedEmailAccountDialog = ({
  sequenceSenderEmailAccount,
  emailAccount
}: DisconnectedEmailAccountDialogProps): JSX.Element => {
  const { emailAccountAccessTokens } = useSession()
  const currentAccount = emailAccountAccessTokens?.[0]

  const relevantAccount = useMemo((): SessionEmailAccount | null => {
    if (emailAccount) {
      return emailAccount
    } else if (sequenceSenderEmailAccount) {
      return {
        type: sequenceSenderEmailAccount.type,
        email: sequenceSenderEmailAccount.email,
        emailAliases: sequenceSenderEmailAccount.emailAliases,
        emailAccountId: sequenceSenderEmailAccount.id,
        hasAccessToken: sequenceSenderEmailAccount.hasAccessToken,
        teamSendAsPermission: sequenceSenderEmailAccount.currentUserHasSendAsPermission,
        isPrimary: sequenceSenderEmailAccount.isPrimary,
        usedInJobIds: emailAccountAccessTokens?.find(account => account.emailAccountId === sequenceSenderEmailAccount.id)?.usedInJobIds ?? []
      }
    } else if (currentAccount) {
      return currentAccount
    }
    return null
  }, [currentAccount, emailAccountAccessTokens, sequenceSenderEmailAccount, emailAccount])

  const { data: orgUsers, refetch: refetchOrgUsers } = useOrgUsersQuery()
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.DISCONNECTED_EMAIL_ACCOUNT), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const closeDialog = useSetAtom(closeDialogAtom)
  const { data: jobsByDepartment } = useJobByDepartmentsQuery()

  const { reassignSequenceSender } = useBulkReassignSequenceSender()

  const { submit, register } = useForm<ReplaceAccountForm>({
    schema: replaceAccountForm
  })

  const jobsUsedByEmailAccount = useMemo((): JobAndDepartment[] => {
    let allJobs: JobAndDepartment[] = []
    const jobIds = relevantAccount?.usedInJobIds ?? []
    const departments = jobsByDepartment ?? {}

    Object.values(departments).forEach((projectJobs) => {
      Object.values(projectJobs).forEach((jobs) => {
        allJobs = allJobs.concat(jobs)
      })
    })

    return allJobs.filter((job) => jobIds.includes(job.id))
  }, [jobsByDepartment, relevantAccount])

  const userEmailAccounts = useMemo(() => {
    return (
      orgUsers?.flatMap((user) => {
        return user.emailAccounts
          .filter(emailAccount => emailAccount.currentUserHasSendAsPermission)
          .map((emailAccount) => ({
            ...emailAccount,
            profilePhotoUrl: user.profilePhotoUrl
          }))
          // Filter out email accounts that are deactivated or do not have permission
          .filter(emailAccount => emailAccount.hasAccessToken && emailAccount.currentUserHasSendAsPermission)
      }) ?? []
    )
  }, [orgUsers])

  const handleSubmit = async (formData: ReplaceAccountForm): Promise<void> => {
    const originalAccountId = relevantAccount?.emailAccountId
    const updatedAccountId = formData?.updatedSenderAccountId
    const updatedEmailAddress = userEmailAccounts?.find(account => account.id === updatedAccountId)
    if (originalAccountId && updatedAccountId) {
      reassignSequenceSender({
        originalAccountId,
        updatedAccountId,
        updatedEmailAddress: updatedEmailAddress?.email ?? '',
        onSuccess: () => { closeDialog(DialogId.DISCONNECTED_EMAIL_ACCOUNT) }
      })
    }
  }

  const handleReconnect = (): void => {
    if (!isNil(relevantAccount)) {
      const redirectUrl = `${window.location.origin}/login/redirect/close`
      const authUrl = getEmailAccountAuthUrl(relevantAccount, redirectUrl)
      const loginWindow = window.open(authUrl, '_blank', 'popup=1,height=600,width=600')
      const timer = setInterval(() => {
        if (loginWindow?.closed) {
          void invalidateEmailAccounts()
          void refetchOrgUsers()
          clearInterval(timer)
        }
      }, 500)
    }
  }

  return (
    <Dialog.Root
      id={DialogId.DISCONNECTED_EMAIL_ACCOUNT}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.DISCONNECTED_EMAIL_ACCOUNT, newState: value })
      }}
      $width="640px"
      $maxWidth="640px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Disconnected Email Account"
          onClose={() => {
            controlDialog({ id: DialogId.DISCONNECTED_EMAIL_ACCOUNT, newState: false })
          }}
        />
        <Dialog.Content>
          <S.CurrentEmailCard>
            <S.EmailCardHeader>
              <Flex $gap={16} $width="auto">
                <BrandIcon name={relevantAccount?.type ?? 'google'} color="original" />
                <Caption size="SM">{relevantAccount?.email}</Caption>
              </Flex>
              <Flex $align="center" $justify="flex-end" $gap={12} $width="auto">
                <Badge $variant="ghost" $transform="none" $fontSize={12}>
                  <Signal $color="negativeBg" />
                  Disconnected
                </Badge>
                <Button
                  $height={24}
                  $variant="fill"
                  $colorTheme="tint"
                  leadingIcon="refresh-cw"
                  $fontSize={12}
                  ariaLabel="Reconnect email account"
                  onClick={handleReconnect}
                  disabled={relevantAccount?.emailAccountId !== currentAccount?.emailAccountId}
                >
                  Reconnect
                </Button>
              </Flex>
            </S.EmailCardHeader>
            <S.CardGroup>
              <Caption size="XS" $color="fgSecondary">
                Account connected by {relevantAccount?.email}
              </Caption>
            </S.CardGroup>
            <S.CardGroup>
              <Paragraph size="XS" $color="fgSecondary">
                This account is used in outreach sequences for{' '}
                <Dropdown
                  trigger={
                    <S.JobsListTrigger>
                      {pluralize(relevantAccount?.usedInJobIds?.length ?? 0, 'job position')}.
                    </S.JobsListTrigger>
                  }
                  items={jobsUsedByEmailAccount?.map((job) => ({
                    title: job.title,
                    href: RouteBuilder.build('SETTINGS_JOB_EMAIL_SEQUENCE', { jobId: job.id })
                  }))}
                />
              </Paragraph>
            </S.CardGroup>
          </S.CurrentEmailCard>
          <When condition={true}>
            <Spacer $size={24} />
            <S.ReplaceForm>
              <Caption size="SM" $color="fgSecondary">
                Replace account in outreach sequences
              </Caption>
              <Spacer $size={8} />
              <Form onSubmit={submit(handleSubmit)}>
                <Flex $align="center" $gap={12}>
                  <S.DummyInput value={relevantAccount?.email} disabled />
                  <S.ArrowIcon>
                    <Icon name="arrow-right" size={16} color="fgSecondary" />
                  </S.ArrowIcon>
                  <Select
                    name="updatedSenderAccountId"
                    label="Replace account in outreach sequences"
                    placeholder={relevantAccount?.email ?? ''}
                    hiddenLabel
                    defaultValue={relevantAccount?.email}
                    items={
                      userEmailAccounts?.map((account) => ({
                        value: account.id,
                        title: account.email,
                        image: (
                          <Avatar $size={16} $type="photo" photoUrl={account.profilePhotoUrl} />
                        ),
                        trailingIcon: <Signal $color={account?.hasAccessToken ? 'positiveBg' : 'negativeBg'} />,
                        disabled: !account?.hasAccessToken
                      })) ?? []
                    }
                    register={register}
                    $marginBottom={0}
                  />
                </Flex>
                <FormActionButtons
                  saveText="Save changes"
                  isSaving={false}
                  cancelText="Cancel"
                  onCancel={() => {
                    closeDialog(DialogId.DISCONNECTED_EMAIL_ACCOUNT)
                  }}
                />
              </Form>
            </S.ReplaceForm>
          </When>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
