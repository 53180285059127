import { Badge } from 'src/components/primitives/badge'
import * as S from './applicants-coming-soon.styled'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Button } from 'src/components/primitives/button'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'

interface PreviewCardProps {
  description: React.ReactNode
  imageSrc: string
}

const PreviewCard = ({ description, imageSrc }: PreviewCardProps): JSX.Element => {
  return (
    <S.PreviewCard>
      <S.PreviewImage>
        <img src={`/applicants-preview/${imageSrc}`} alt="Illustration visually demonstrating this feature" />
      </S.PreviewImage>
      <Paragraph size="XS" $color="fgSecondary" $lineHeight={1.33}>
        {description}
      </Paragraph>
    </S.PreviewCard>
  )
}

export const ApplicantsComingSoon = (): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)

  return (
    <S.Container>
      <S.Inner>
        <S.Header>
          <Badge $variant="tintLight" $transform="uppercase" $fontSize={10}>
            Coming soon
          </Badge>
          <Caption size="2XL" $color="fgPrimary">
            Applicants
          </Caption>
          <Paragraph size="SM" $color="fgSecondary" $lineHeight={1.43}>
            Uncover hidden talent, evaluating every resume with PhD-level precision to match skills,
            experience, and untapped potential.
          </Paragraph>
          <S.CtaWrapper>
            <Button
              $variant="fill"
              $colorTheme="tint"
              $height={40}
              onClick={() => { openDialog({ id: DialogId.REQUEST_APPLICANTS_ACCESS }) }}
            >
              Request Beta Access
            </Button>
          </S.CtaWrapper>
        </S.Header>
        <S.Preview>
          <PreviewCard
            description={<>Connect your ATS to import applicants&nbsp;automatically.</>}
            imageSrc="Feature_SM_ATS.svg"
          />
          <PreviewCard
            description={<>Pin enhances your data by pulling from multiple&nbsp;sources.</>}
            imageSrc="Feature_SM_Sources.svg"
          />
          <PreviewCard
            description={<>Set your criteria to analyze and find the best candidates&nbsp;instantly.</>}
            imageSrc="Feature_SM_Source.svg"
          />
          <PreviewCard
            description={<>Sync back to your ATS, or contact them with&nbsp;Pin.</>}
            imageSrc="Feature_SM_Channels.svg"
          />
        </S.Preview>
      </S.Inner>
    </S.Container>
  )
}
