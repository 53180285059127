import type { VirtualItem, Virtualizer } from '@tanstack/react-virtual'
import * as S from './table.styled'
import { COLUMN } from 'src/components/tables/candidate-table-cells'
import type { TableColumnDef } from 'src/components/tables/candidate-table-cells'
import { IndeterminateCheckbox } from './IndeterminateCheckbox'
import { useTheme } from 'styled-components'

interface TableBodyProps<T> {
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>
  items: VirtualItem[]
  tableRows: T[]
  onRowClick?: (row: T) => void
  selectedRows: Set<string>
  columns: Array<TableColumnDef<T>>
  onRowSelectionClicked: (event: React.MouseEvent<HTMLInputElement>, rowIndex: number) => void
}

export const TableBody = <T extends { id: string }>({
  rowVirtualizer,
  items,
  tableRows,
  onRowClick,
  selectedRows,
  columns,
  onRowSelectionClicked
}: TableBodyProps<T>): JSX.Element => {
  const theme = useTheme()
  return (
    <S.Body
      style={{
        height: rowVirtualizer.getTotalSize()
      }}
    >
      {items.map((virtualRow) => {
        const row = tableRows[virtualRow.index]
        return (
          <S.TableRow
            key={virtualRow.key}
            data-index={virtualRow.index}
            // ref={(node) => { rowVirtualizer.measureElement(node) }}
            style={{
              cursor: onRowClick ? 'pointer' : 'default',
              backgroundColor:
                virtualRow.index % 2 === 0
                  ? theme.colors.bgSecondary
                  : theme.colors.bgPrimary,
              position: 'absolute',
              top: 0,
              height: virtualRow.size,
              transform: `translateY(${virtualRow.start}px)`
            }}
            onClick={() => {
              if (onRowClick) {
                onRowClick(row)
              }
            }}
            data-selection={selectedRows.has(row.id) ? 'active' : 'inactive'}
          >
            {columns.map((column) => {
              let cell = column.collapsed ? column.collaspedCell?.(row, virtualRow.index) : column.cell(row, virtualRow.index)
              if (column.columnId === COLUMN.SELECTION_COLUMN_ID) {
                cell = (
                  <IndeterminateCheckbox
                    name={row.id}
                    checked={selectedRows.has(row.id)}
                    onChange={() => {
                      // DO NOTHING
                    }}
                    onClick={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      onRowSelectionClicked(event, virtualRow.index)
                    }}
                  />
                )
              }
              return (
                <S.TableCell
                  key={column.columnId}
                  $customizedCell={column.customizedCell}
                  $hasDependentColumn={!!column.dependentExpandableColumnId}
                  $collapsed={column.collapsed}
                  onClick={(e) => {
                    if (column.ignoredOnRowClick) {
                      e.stopPropagation()
                      e.preventDefault()
                    }
                  }}
                >
                  {cell}
                </S.TableCell>
              )
            })}
          </S.TableRow>
        )
      })}
    </S.Body>
  )
}
