import { z } from 'zod'
import Api, { ApiError } from '..'

export const departmentParser = z.object({
  id: z.string(),
  orgId: z.string(),
  name: z.string(),
  domain: z.string().nullable(),
  deleted: z.boolean().nullish(),
  deletedAt: z.coerce.date().nullish(),
  stealth: z.boolean().nullish()
})

export type Department = z.infer<typeof departmentParser>

export async function fetchDepartments (): Promise<Department[]> {
  const { data, status } = await Api.get('/departments')
  if (status !== 200) {
    throw new ApiError('Failed to get departments from org')
  }

  const orgsDepartments = z
    .array(departmentParser)
    .parse(data)

  return orgsDepartments
}

export async function createDepartment (
  name: string,
  domain?: string | null,
  stealth?: boolean
): Promise<Department> {
  const { data, status } = await Api.post('/departments', null, {
    name,
    domain,
    stealth
  })

  if (status !== 201) {
    throw new ApiError('Failed to create department')
  }

  const department = departmentParser.parse(data)
  return department
}

export async function updateDepartment (
  id: string,
  name?: string,
  domain?: string | null,
  stealth?: boolean
): Promise<Department> {
  const { data, status } = await Api.put(`/departments/${id}`, null, {
    name,
    domain,
    stealth
  })

  if (status !== 200) {
    throw new ApiError('Failed to update department')
  }

  const department = departmentParser.parse(data)
  return department
}

export async function deleteDepartment (id: string, deleted: boolean): Promise<Department> {
  const { status, data } = await Api.delete(`/departments/${id}`, { deleted })

  if (status !== 200) {
    throw new ApiError('Failed to delete department')
  }

  const department = departmentParser.parse(data)
  return department
}
