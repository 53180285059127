import type { CriteriaKey, JobTitleCriteria } from 'src/libs/api/backend/candidate_search'
import * as S from './index.styled'
import { useEffect, useRef, useState } from 'react'
import { CriteriaPill } from './criteria-pill'
import { RemoveCriteria } from './remove-criteria'
import { isDuplicatedCriteria } from 'src/utils/refinement-criteria'

interface JobRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: JobTitleCriteria[]
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: JobTitleCriteria[]) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey) => void
  disabled: boolean
}

export const JobRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  disabled
}: JobRowProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Enter' && inputValue && document.activeElement === inputRef.current) {
        const newJobTitle = {
          name: inputValue,
          optional: false,
          negative: false
        }
        const isJobDuplicated = isDuplicatedCriteria(newJobTitle, criteriaValue, 'name')
        if (!isJobDuplicated) {
          onCriteriaUpdate(criteriaKey, [...criteriaValue, newJobTitle])
        }
        setInputValue('')
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [criteriaKey, criteriaValue, inputValue, onCriteriaUpdate])

  return (
    <S.RowContainer>
      <S.CriteriaMultiValuesField $disabled={disabled}>
        {criteriaValue.map((jobTitle, index) => {
          return (
            <CriteriaPill
              key={index}
              value={{
                label: jobTitle.name,
                optional: false,
                negative: false
              }}
              onRemove={() => {
                const updatedCriteriaValue = criteriaValue.filter((_, i) => i !== index)
                onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
              }}
              useOptional={false}
            />
          )
        })}
        <S.SearchInput as='div'>
          <S.InputField
            ref={inputRef}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value)
            }}
            autoFocus={true}
            disabled={disabled}
          />
        </S.SearchInput>
      </S.CriteriaMultiValuesField>
      <RemoveCriteria
        onRemove={() => {
          onCriteriaRemove(criteriaKey)
        }}
        disabled={disabled}
      />
    </S.RowContainer>
  )
}
