import styled, { css } from 'styled-components'
import type { CardStyleProps } from './card'

export const Card = styled.div<CardStyleProps>`
  width: 100%;
  min-height: ${({ $minHeight }) => $minHeight ? `${$minHeight}px` : 'auto'};
  padding-top: ${({ $padding, theme }) => theme.spacing[$padding?.top ?? 16]};
  padding-right: ${({ $padding, theme }) => theme.spacing[$padding?.right ?? 16]};
  padding-bottom: ${({ $padding, theme }) => theme.spacing[$padding?.bottom ?? 16]};
  padding-left: ${({ $padding, theme }) => theme.spacing[$padding?.left ?? 16]};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  overflow: hidden;
  ${({ $variant }) => $variant === 'raised' && css`
    box-shadow: ${({ theme }) => theme.boxShadows.sm};
  `}
  ${({ $variant }) => $variant === 'outline' && css`
    border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  `}
`
