import { useNavigate } from 'react-router-dom'
import RouteBuilder from 'src/libs/route-builder'
import { OnboardingHeader } from 'src/components/blocks/onboarding-header'
import { CompanyProfileForm } from 'src/components/blocks/company-profile-form'
import type { CompanyProfileFormData } from 'src/components/blocks/company-profile-form'
import { useCreateOrg } from 'src/hooks/mutations/use-create-org'
import { useCallback, useState } from 'react'
import { MAX_PROFILE_FILE_SIZE, PROFILE_MAX_WIDTH, PNG_MIME_TYPE, compressAndGenerateUrl } from 'src/utils/compress-image'
import { useSession } from 'src/hooks/use-session'
import { useUpdateOrg } from 'src/hooks/mutations/use-update-org'
import { isNil } from 'lodash'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom } from 'src/stores/notifications'
import { useOnboardingSteps } from 'src/hooks/use-onboarding-steps'

const OnboardingCompanyProfile = (): JSX.Element => {
  const navigate = useNavigate()
  const notifyError = useSetAtom(notifyErrorAtom)
  const { createOrg } = useCreateOrg()
  const { updateOrg } = useUpdateOrg()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { nextStep } = useOnboardingSteps()

  const { org } = useSession()

  const handleSubmit = useCallback(async (data: CompanyProfileFormData): Promise<void> => {
    // This is an edge case, but it can happen that a user
    // clicks the back button during onboarding and navigates back
    // to /onboarding/company-profile. In this case we want to update
    // the just created org instead of creating a new one.
    const hasOrg = !isNil(org)

    try {
      setIsSubmitting(true)
      let newLogo // undefined means unchanged
      if (data.companyLogo instanceof File) {
        if (data.companyLogo.size > MAX_PROFILE_FILE_SIZE) {
          throw new Error('File is too large, please choose an image smaller than 1MB')
        }
        const uploadedTempProfilePhoto = await compressAndGenerateUrl({ file: data.companyLogo, fileType: PNG_MIME_TYPE, maxWidth: PROFILE_MAX_WIDTH })
        if (uploadedTempProfilePhoto?.key) {
          newLogo = {
            sourceKey: uploadedTempProfilePhoto.key
          }
        }
      }
      if (hasOrg) {
        updateOrg({
          orgUpdate: {
            name: data.companyName,
            domain: data.companyUrl,
            newLogo
          },
          onSuccess: () => {
            setIsSubmitting(false) // Not really needed as it should go to the next step but just to be safe
            navigate(RouteBuilder.build('ONBOARDING_JOB_POSITION'))
          }
        })
      } else {
        createOrg({
          org: {
            name: data.companyName,
            domain: data.companyUrl,
            mission: data.companyMission,
            country: data.companyLocation ?? '',
            city: data.companyLocation ?? '',
            industries: data.companyIndustries?.map((industry) => industry.title) ?? [],
            newLogo
          },
          onSuccess: () => {
            setIsSubmitting(false) // Not really needed as it should go to the next step but just to be safe
            const next = nextStep?.href
            if (next) {
              navigate(next)
            }
          }
        })
      }
    } catch (e) {
      console.error('[onboarding-company-profile] Error creating org', e)
      notifyError({
        message: (e as Error)?.message
      })
      setIsSubmitting(false)
    }
  }, [org, updateOrg, navigate, createOrg, nextStep?.href, notifyError])

  return (
    <>
      <OnboardingHeader
        heading="Tell Us About Your Company"
        subheading="Build your team on Pin."
      />
      <CompanyProfileForm
        onSubmit={(data) => { void handleSubmit(data) }}
        disableSubmit={isSubmitting}
      />
    </>
  )
}

export default OnboardingCompanyProfile
