import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Preview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  aspect-ratio: 1/0.56;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 8px;
  margin-bottom: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    vertical-align: bottom;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`
