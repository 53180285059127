import { isNil, sortBy } from 'lodash'
import { useMemo, useCallback, useState } from 'react'
import { Button } from 'src/components/primitives'
import { Avatar } from 'src/components/primitives/avatar'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { useSendableAddresses } from 'src/hooks/queries/use-sendable-addresses'
import { useSession } from 'src/hooks/use-session'
import { SequenceStepType } from 'src/libs/api/backend/sequences'
import { FeatureFlags } from 'src/libs/api/backend/session'
import type { EmailAccount, LinkedInAccount } from 'src/libs/api/backend/users'
import { Spinner } from '../../primitives/spinner'
import * as S from './sender-selection.styled'
import { Toggle } from 'src/components/primitives/toggle'
import { Caption } from 'src/components/primitives/typography'

export interface EmailAccountWithProfilePhoto extends EmailAccount {
  profilePhotoUrl?: string | null
}

export interface HandleSenderChangeArgs {
  emailAccount: EmailAccountWithProfilePhoto
  emailAlias: string | null
  linkedInAccount: LinkedInAccount | null
}

type SelectableAccounts = 'EMAIL' | 'LINKEDIN'
export interface SenderSelectionProps {
  sendersAreLoading?: boolean
  selectableAccounts?: Readonly<SelectableAccounts[]> | SelectableAccounts[]
  sendingMessageType?: SequenceStepType
  handleSenderChange: ({ emailAccount, emailAlias, linkedInAccount }: HandleSenderChangeArgs) => void
  userEmailAccounts: EmailAccountWithProfilePhoto[]
  linkedInAccounts: LinkedInAccount[]
  selectedEmailAccount?: EmailAccountWithProfilePhoto
  selectedEmailAlias: string | null
  selectedLinkedInAccountId: string | null
  disableEmailAccountSelection?: boolean
}

export const SenderSelection = ({
  sendersAreLoading = false,
  selectableAccounts = ['EMAIL', 'LINKEDIN'],
  sendingMessageType,
  handleSenderChange,
  userEmailAccounts,
  linkedInAccounts,
  selectedEmailAccount,
  selectedEmailAlias,
  selectedLinkedInAccountId,
  disableEmailAccountSelection = false
}: SenderSelectionProps): JSX.Element => {
  const sendableAddresses = useSendableAddresses()
  const { featureFlags } = useSession()
  const { data: orgUsers } = useOrgUsersQuery()
  const [showAllAccounts, setShowAllAccounts] = useState(false)

  const messageSenderDropdownItems = useMemo(() => {
    let items: MenuItemProps[] = []

    if (selectableAccounts.includes('EMAIL')) {
      items = userEmailAccounts
        .filter(emailAccount => {
          const isActiveUser = orgUsers?.some(user => user.id === emailAccount.userId && user.active)
          if (!showAllAccounts) {
            const sendable = sendableAddresses.find((sendableAddress) => sendableAddress?.id === emailAccount.id)
            return isActiveUser && sendable && emailAccount.hasAccessToken
          }
          return isActiveUser
        })
        .flatMap((emailAccount) => {
          const sendable = sendableAddresses.find((sendableAddress) => sendableAddress?.id === emailAccount.id)
          const hasAccessToken = emailAccount.hasAccessToken

          let trailingIcon
          let trailingTooltip
          if (!sendable) {
            trailingIcon = 'lock'
            trailingTooltip = {
              text: "The current user doesn't have permission to send emails as this account. Please request permission and try again."
            }
          } else if (!hasAccessToken) {
            trailingIcon = 'negativeBg'
            trailingTooltip = {
              text: 'Email account not connected.'
            }
          } else {
            trailingIcon = undefined
            trailingTooltip = undefined
          }

          const items = [
            {
              id: emailAccount.id,
              title: emailAccount.email,
              value: emailAccount.id,
              isDisabled: !sendable,
              icon: 'mail',
              trailingTooltip,
              trailingIcon,
              onSelect: () => {
                handleSenderChange({ emailAccount, emailAlias: null, linkedInAccount: null })
              }
            }
          ]
          if (!isNil(emailAccount.emailAliases) && emailAccount.emailAliases.length > 0) {
            emailAccount.emailAliases.forEach((emailAlias: { sendAsEmail: string }) => {
              items.push({
                id: `${emailAccount.id}_${emailAlias.sendAsEmail}`,
                title: emailAlias.sendAsEmail,
                value: emailAlias.sendAsEmail,
                isDisabled: !sendable,
                icon: 'mail',
                trailingTooltip,
                trailingIcon,
                onSelect: () => {
                  handleSenderChange({ emailAccount, emailAlias: emailAlias.sendAsEmail, linkedInAccount: null })
                }
              })
            })
          }
          return items
        })
    }

    items = sortBy(items, (item) => {
      if (item.isDisabled) {
        return 2
      }
      if (item.trailingIcon === 'negativeBg') {
        return 1
      }
      return 0
    })

    if (featureFlags?.includes(FeatureFlags.LINKEDIN_CONNECTION) && selectableAccounts.includes('LINKEDIN') && linkedInAccounts?.length > 0) {
      if (selectableAccounts.includes('EMAIL') && selectableAccounts.includes('LINKEDIN')) {
        items.push({
          id: 'linkedin-separator',
          title: 'LinkedIn',
          type: 'separator'
        },
        {
          id: 'linkedin-label',
          title: 'LinkedIn',
          type: 'sublabel'
        })
      }
      const linkedInItems = linkedInAccounts.map((linkedInAccount) => ({
        id: linkedInAccount.id,
        title: linkedInAccount.unipileUserName,
        value: linkedInAccount.id,
        icon: <BrandIcon name="linkedinOriginal" color="original" size={12} />,
        isDisabled: false,
        isSelectable: true,
        trailingTooltip: undefined,
        trailingIcon: undefined,
        onSelect: () => {
          const user = linkedInAccount.userId
          const emailAccount = userEmailAccounts.find((emailAccount) => emailAccount.userId === user)
          if (emailAccount) {
            handleSenderChange({ emailAccount, emailAlias: null, linkedInAccount })
          }
        }
      }))

      items = items.concat(linkedInItems)
    }

    items.unshift(
      {
        id: 'toggle-sendable-accounts',
        title: 'Show all accounts',
        type: 'node',
        content: (
          <S.ToggleAllAccountsBlock>
            <Caption size="XS" $color="fgSecondary">Show all accounts</Caption>
            <Toggle
              name="toggle-sendable-accounts"
              checked={showAllAccounts}
              onChange={() => { setShowAllAccounts(!showAllAccounts) }}
              $toggleSize={12}
            />
          </S.ToggleAllAccountsBlock>
        )
      },
      {
        id: 'separator',
        title: '',
        type: 'separator'
      }
    )

    return items
  }, [userEmailAccounts, sendableAddresses, handleSenderChange, linkedInAccounts, featureFlags, orgUsers, selectableAccounts, showAllAccounts])

  // const senderLabel = useMemo(() => {
  //   if (selectedEmailAlias) {
  //     return selectedEmailAlias
  //   }
  //   if (selectedLinkedInAccountId) {
  //     const unipileUserName = linkedInAccounts
  //       .find((linkedInAccount) => linkedInAccount.id === selectedLinkedInAccountId)
  //       ?.unipileUserName
  //     return !isNil(unipileUserName) ? unipileUserName : ''
  //   }
  //   return selectedEmailAccount?.email
  // }, [selectedEmailAlias, selectedEmailAccount?.email, selectedLinkedInAccountId, linkedInAccounts])

  const getLinkedInUsername = useCallback((accountId: string | null): string => {
    if (!accountId && linkedInAccounts.length > 0) {
      return linkedInAccounts[0].unipileUserName || ''
    }

    if (!accountId) return ''
    const unipileUserName = linkedInAccounts
      .find((linkedInAccount) => linkedInAccount.id === accountId)
      ?.unipileUserName
    return !isNil(unipileUserName) ? unipileUserName : ''
  }, [linkedInAccounts])

  const senderLabel = useMemo(() => {
    if (sendingMessageType) {
      const isLinkedIn = [
        SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE,
        SequenceStepType.MANUAL_LINKEDIN_MESSAGE
      ].includes(sendingMessageType)

      if (isLinkedIn) {
        return getLinkedInUsername(selectedLinkedInAccountId)
      }

      const isEmail = [
        SequenceStepType.AUTOMATED_EMAIL,
        SequenceStepType.MANUAL_EMAIL
      ].includes(sendingMessageType)

      if (isEmail) {
        return selectedEmailAlias ?? selectedEmailAccount?.email
      }
    }

    // Fallback logic for when no sendingMessageType is provided
    if (selectedEmailAlias) return selectedEmailAlias
    if (selectedLinkedInAccountId) return getLinkedInUsername(selectedLinkedInAccountId)
    return selectedEmailAccount?.email
  }, [
    sendingMessageType,
    selectedEmailAlias,
    selectedEmailAccount?.email,
    selectedLinkedInAccountId,
    linkedInAccounts
  ])

  const selectedValue = useMemo(() => {
    // If no selectedLinkedInAccountId was provided, find the LinkedIn account by the userId
    const getLinkedInAccountId = (): string => {
      if (!selectedLinkedInAccountId && selectedEmailAccount?.userId) {
        return linkedInAccounts.find(account => account.userId === selectedEmailAccount.userId)?.id ?? ''
      }
      return selectedLinkedInAccountId ?? ''
    }

    if (sendingMessageType) {
      const isLinkedInMessage = [SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE, SequenceStepType.MANUAL_LINKEDIN_MESSAGE].includes(sendingMessageType)
      const isEmailMessage = [SequenceStepType.AUTOMATED_EMAIL, SequenceStepType.MANUAL_EMAIL].includes(sendingMessageType)

      if (isLinkedInMessage) return getLinkedInAccountId()
      if (isEmailMessage) return selectedEmailAccount?.id ?? ''
    }

    return getLinkedInAccountId() ?? selectedEmailAccount?.id ?? null
  }, [sendingMessageType, selectedLinkedInAccountId, selectedEmailAccount, linkedInAccounts])

  const leadingIcon = useMemo(() => {
    if (sendingMessageType) {
      if (sendingMessageType === SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE ||
          sendingMessageType === SequenceStepType.MANUAL_LINKEDIN_MESSAGE) {
        return <BrandIcon name="linkedinOriginal" color="original" size={12} />
      }
      if (sendingMessageType === SequenceStepType.AUTOMATED_EMAIL ||
          sendingMessageType === SequenceStepType.MANUAL_EMAIL) {
        return (
          <Avatar
            $shape="circle"
            $border={false}
            $size={12}
            photoUrl={selectedEmailAccount?.profilePhotoUrl}
            initials={senderLabel?.[0]}
          />
        )
      }
    }

    return selectedLinkedInAccountId
      ? (
          <BrandIcon name="linkedinOriginal" color="original" size={12} />
        )
      : (
          <Avatar
            $shape="circle"
            $border={false}
            $size={12}
            photoUrl={selectedEmailAccount?.profilePhotoUrl}
            initials={senderLabel?.[0]}
          />
        )
  }, [
    sendingMessageType,
    selectedLinkedInAccountId,
    selectedEmailAccount?.profilePhotoUrl,
    senderLabel
  ])

  return (
    <Dropdown
      trigger={
        <Button
          disabled={disableEmailAccountSelection}
          nested
          $variant="outline"
          $height={24}
          $fontSize={12}
          leadingIcon={!sendersAreLoading ? leadingIcon : undefined}
          trailingIcon="chevrons-up-down-small"
        >
          {!sendersAreLoading ? senderLabel : <><Spinner /> Loading…</>}
        </Button>
      }
      selectedValue={selectedValue ?? undefined}
      disabled={disableEmailAccountSelection}
      items={messageSenderDropdownItems}
      size="small"
    />
  )
}
