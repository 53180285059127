import styled from 'styled-components'

export const Carousel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  height: 1.5rem;
  padding-right: 1rem;
`

export const AvatarWrapper = styled.div<{ $needsOutline: boolean }>`
  position: relative;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 1px);
    height: calc(100% - 2px);
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.borderTranslucent};
    border-radius: 4px;
    display: ${({ $needsOutline }) => $needsOutline ? 'block' : 'none'};
    z-index: 2;
  }
`

export const CarouselViewport = styled.div`
  width: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const CarouselItems = styled.div`
  width: auto;
  width: fit-content;
  display: flex;
  transition: all 0.4s cubic-bezier(0.65,0.05,0.36,1);
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  height: 100%;
  position: relative;
  padding-right: 1.5rem;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 2px);
    pointer-events: none;
  }
`

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  * {
    white-space: nowrap;
  }
`

export const ItemContentRow = styled.div`
  height: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
`
