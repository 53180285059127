import { isUuid } from './../../libs/uuid'
import { useQuery, queryOptions } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { listJobSearchRefinements } from 'src/libs/api/backend/jobs'
import type { JobSearchRefinement, ListJobSearchRefinementsParams } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'

export const listJobSearchRefinementsQuery = (jobId: string, params: ListJobSearchRefinementsParams = {}) => queryOptions({
  queryKey: [queryKeys.jobSearchRefinements, jobId, params],
  queryFn: async () => await listJobSearchRefinements(jobId, params),
  staleTime: 60 * 1000 * 15, // 15 minutes
  enabled: isUuid(jobId)
})

export const useListJobSearchRefinementsQuery = (jobId?: string, params: ListJobSearchRefinementsParams = {}): UseQueryResult<JobSearchRefinement[]> => {
  const { jobId: jobIdParam } = useParams()
  return useQuery(listJobSearchRefinementsQuery(jobId ?? jobIdParam ?? '', params))
}
