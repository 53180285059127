import styled from 'styled-components'

export const ScheduleDemo = styled.div`
  position: relative;
`

export const ScheduleDemoInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 778px;
  height: 100%;
  transform: translateX(-124px);
`
