import styled from 'styled-components'
import type { BoxStyleProps } from './box'

export const Box = styled.div<BoxStyleProps>`
  display: ${({ $display }) => $display};
  width: ${({ $width }) => $width};
  height: ${({ $height, theme }) =>
    typeof $height === 'string' ? $height : theme.spacing[$height ?? 0]};
  flex-grow: ${({ $flexGrow }) => $flexGrow};
  flex-shrink: ${({ $flexShrink }) => $flexShrink};
  flex-basis: ${({ $flexBasis }) => $flexBasis};
  flex: ${({ $flex }) => $flex};
  flex-direction: ${({ $flexDirection }) => $flexDirection};
  flex: ${({ $flex }) => $flex};
  align-items: ${({ $align }) => $align};
  justify-content: ${({ $justify }) => $justify};
  gap: ${({ $gap, theme }) => theme.spacing[$gap ?? 0]};
  grid-column: ${({ $gridColumn }) => $gridColumn};
  background-color: ${({ $background, theme }) =>
    $background === 'transparent' ? $background : theme.colors[$background ?? 'bgPrimary']};
  border: ${({ $border }) => ($border === 'none' ? 'none' : 'solid 1px')};
  border-color: ${({ theme, $border }) =>
    $border === 'none' ? 'none' : theme.colors[$border ?? 'bgPrimary']};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  padding-top: ${({ $padding, theme }) => theme.spacing[$padding?.top ?? 0]};
  padding-right: ${({ $padding, theme }) => theme.spacing[$padding?.right ?? 0]};
  padding-bottom: ${({ $padding, theme }) => theme.spacing[$padding?.bottom ?? 0]};
  padding-left: ${({ $padding, theme }) => theme.spacing[$padding?.left ?? 0]};
  margin-top: ${({ $margin, theme }) => theme.spacing[$margin?.top ?? 0]};
  margin-right: ${({ $margin, theme }) => theme.spacing[$margin?.right ?? 0]};
  margin-bottom: ${({ $margin, theme }) => theme.spacing[$margin?.bottom ?? 0]};
  margin-left: ${({ $margin, theme }) => theme.spacing[$margin?.left ?? 0]};
  box-shadow: ${({ $shadow, theme }) => $shadow ? theme.boxShadows[$shadow] : 'none'};
  transform: translate(${({ $offset }) => $offset?.x ?? 0}px, ${({ $offset }) => $offset?.y ?? 0}px);
`
