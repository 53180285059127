import { useEffect, useState } from 'react'
import { Icon } from 'src/components/primitives/icon'
import type { FieldCommonProps } from '../common'
import { FormElement } from '../form-element'
import * as S from './checkbox.styled'
import { Paragraph } from 'src/components/primitives/typography'

export interface CheckboxStyleProps {
  $variant?: 'plain' | 'outline'
}

interface CheckboxProps extends CheckboxStyleProps, Omit<FieldCommonProps, 'register'> {
  defaultChecked?: boolean
  onChange?: (checked: boolean) => void
  description?: string
}

export const StandaloneCheckbox = ({
  defaultChecked = false,
  label,
  name,
  description,
  $marginBottom,
  $variant = 'plain',
  onChange
}: CheckboxProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked)

  useEffect(() => {
    setIsChecked(defaultChecked)
  }, [defaultChecked])

  return (
    <FormElement $marginBottom={$marginBottom}>
      <S.LabelClickableArea htmlFor={name}>
        <S.Checkbox $variant={$variant} $isChecked={isChecked}>
          <S.CheckboxRoot
            id={name}
            checked={isChecked}
            onCheckedChange={(checked: boolean) => {
              setIsChecked(checked)
              onChange?.(checked)
            }}
            $hasDescription={Boolean(description)}
          >
            <S.CheckboxIndicator>
              <Icon name="check" color="tintBg" size={12} />
            </S.CheckboxIndicator>
          </S.CheckboxRoot>
          <S.LabelWrapper>
            <S.Label htmlFor={name}>{label}</S.Label>
            {description && <Paragraph size="XS" $color="fgSecondary">{description}</Paragraph>}
          </S.LabelWrapper>
        </S.Checkbox>
      </S.LabelClickableArea>
    </FormElement>
  )
}
