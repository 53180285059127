// import { CreateJobForm } from 'src/components/blocks/create-job-form'
import { CreateNewJobForm } from 'src/components/blocks/create-new-job-form'
import { OnboardingHeader } from 'src/components/blocks/onboarding-header'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'

const OnboardingJobPosition = (): JSX.Element => {
  return (
    <>
      <OnboardingHeader
        heading="Which job are you hiring for?"
        subheading="Tell us about who you’re looking to hire."
      />
      <CreateNewJobForm
        redirectType='onboarding'
        onCreateJob={() => {
          void queryClient.invalidateQueries({
            queryKey: [queryKeys.session]
          })
        }}
      />
    </>
  )
}

export default OnboardingJobPosition
