import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { fetchTimezonesAndHolidays } from 'src/libs/api/backend/configuration'
import type { TimezonesAndHolidays } from 'src/libs/api/backend/configuration'
import { queryKeys } from 'src/libs/query-keys'

export const useTimezonesAndHolidaysQuery = (): UseQueryResult<TimezonesAndHolidays> => {
  return useQuery({
    queryKey: [queryKeys.timezonesAndHolidays],
    queryFn: async () => await fetchTimezonesAndHolidays(),
    staleTime: 60 * 1000
  })
}
