import { useMutation, useQueryClient } from '@tanstack/react-query'
import { acknowledgeSequenceableErrors as acknowledgeSequenceableErrorsHandler } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { queryKeys } from 'src/libs/query-keys'
import { useParams } from 'react-router-dom'
import { notifyErrorAtom } from 'src/stores/notifications'
import { useSetAtom } from 'jotai'

interface Args {
  candidateJobs: CandidateJobExpanded[]
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  acknowledgeErrors: (args: Args) => void
}

export const useAcknowledgeSequenceableErrors = (): Res => {
  const queryClient = useQueryClient()
  const { jobId } = useParams()
  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ candidateJobs }: { candidateJobs: CandidateJobExpanded[] }) => {
      await acknowledgeSequenceableErrorsHandler(candidateJobs)
    },
    onError: (err) => {
      notifyError({
        message: `An error occurred when acknowledging sequenceable errors: ${err.message}`
      })
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobs, jobId, { errored: true }],
          exact: false
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobCounts, jobId]
        })
      ])
    }
  })

  const acknowledgeErrors = (args: Args): void => {
    console.log('acknowledgeErrors called with:', args)
    mutation.mutate(args)
  }

  return { acknowledgeErrors }
}
