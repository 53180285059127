import { PageHeader } from 'src/components/blocks/page-header'
import { Spacer } from 'src/components/primitives/spacer'
import { SEO } from '../../../components/primitives/seo'
import {
  AutoApproveCandidates,
  AutoArchiveCandidates,
  AutoScheduleMeetings,
  ExportToATS,
  NudgeUnresponsiveCandidates,
  SendOutreachEmails
} from 'src/components/blocks/automation-settings/settings'
import { Flex } from 'src/components/primitives'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'

const AutomationSettingsPage = (): JSX.Element => {
  const { featureFlags } = useSession()

  return (
    <>
      <PageHeader heading="Automation" />
      <SEO title="Automation" />
      <Flex $direction="column" $gap={12}>
        <AutoApproveCandidates />
        <SendOutreachEmails />
        {featureFlags?.includes(FeatureFlags.CALENDAR) && <AutoScheduleMeetings />}
        <AutoArchiveCandidates />
        {featureFlags?.includes(FeatureFlags.ATS_INTEGRATION) && <ExportToATS />}
        <NudgeUnresponsiveCandidates />
        <Spacer $size={36} />
      </Flex>
    </>
  )
}

export default AutomationSettingsPage
