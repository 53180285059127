import { useMemo } from 'react'
import { Button } from 'src/components/primitives'
import { ButtonGroup } from 'src/components/primitives/button-group'
import { LocalStorageKey, ViewMode } from 'src/constants'
import { useTheme } from 'styled-components'
import { useLocalStorage } from 'usehooks-ts'

interface ToggleCandidateViewProps {
  disabled?: boolean
  disabledTooltip?: string
  order?: ViewMode[]
  localStorageKey?: LocalStorageKey
}

export const ToggleCandidateView = ({
  disabled = false,
  disabledTooltip,
  order = [ViewMode.COMPACT, ViewMode.DEFAULT, ViewMode.TABLE],
  localStorageKey = LocalStorageKey.VIEW_MODE
}: ToggleCandidateViewProps): JSX.Element => {
  const [currViewMode, setViewMode] = useLocalStorage(localStorageKey, ViewMode.DEFAULT)
  const { colors } = useTheme()

  const viewModes = useMemo(() => {
    return order.map((mode): JSX.Element => {
      switch (mode) {
        case ViewMode.COMPACT:
          return (
            <Button
              key={mode}
              ariaLabel="Enable compact mode for candidate cards"
              $variant={currViewMode === ViewMode.COMPACT ? 'raised-light' : 'ghost'}
              // $colorTheme="muted"
              $height={24}
              $fontSize={12}
              leadingIcon="layout-list"
              disabled={disabled}
              onClick={() => {
                setViewMode?.(ViewMode.COMPACT)
              }}
              tooltip={{
                text: disabled ? disabledTooltip : 'Compact View'
              }}
            />
          )
        case ViewMode.DEFAULT:
          return (
            <Button
              key={mode}
              ariaLabel="Enable details mode for candidate cards"
              $variant={currViewMode === ViewMode.DEFAULT ? 'raised-light' : 'ghost'}
              // $colorTheme="muted"
              $height={24}
              $fontSize={12}
              leadingIcon="layout-details"
              disabled={disabled}
              onClick={() => {
                setViewMode?.(ViewMode.DEFAULT)
              }}
              tooltip={{
                text: disabled ? disabledTooltip : 'Detailed View'
              }}
            />
          )
        case ViewMode.TABLE:
          return (
            <Button
              key={mode}
              ariaLabel="Enable table mode for candidate cards"
              $variant={currViewMode === ViewMode.TABLE ? 'raised-light' : 'ghost'}
              // $colorTheme="muted"
              $height={24}
              $fontSize={12}
              leadingIcon="table-2"
              disabled={disabled}
              onClick={() => {
                setViewMode?.(ViewMode.TABLE)
              }}
              tooltip={{
                text: disabled ? disabledTooltip : 'Table View'
              }}
            />
          )
        default:
          return <></>
      }
    })
  }, [order, currViewMode, disabled, disabledTooltip, setViewMode])

  return (
    <ButtonGroup
      $variant="ghost"
      $gap={2}
      $direction="horizontal"
      $padding={2}
      $backgroundColor={colors.bgTertiary}
      $borderRadius={4}
    >
      {viewModes}
    </ButtonGroup>
  )
}
