import { useMemo, useState } from 'react'
import { closeDialogAtom, controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import * as Dialog from 'src/components/primitives/dialog'
import { z } from 'zod'
import { useForm } from 'src/hooks/use-form'
import { Input } from 'src/components/forms/input'
import { FormActionButtons } from 'src/components/blocks/form-action-buttons'
import { Form } from 'src/components/forms'
import { useSubmitFeedback } from 'src/hooks/mutations/use-submit-feedback'
import { FeedbackType } from 'src/libs/api/backend/feedback'
import { useSession } from 'src/hooks/use-session'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './request-applicants-access-dialog.styled'
import { Button, Flex } from 'src/components/primitives'
import { BookADemoButton } from 'src/components/blocks/book-a-demo-button'
import { Divider } from 'src/components/primitives/divider'
import { AnimatePresence, motion } from 'framer-motion'

const requestAccessSchema = z.object({
  currentAts: z.string().optional()
})

type RequestAccessSchema = z.infer<typeof requestAccessSchema>

enum DialogView {
  FORM = 'FORM',
  SUCCESS = 'SUCCESS'
}

const AnimatedContent = ({
  children,
  motionKey
}: {
  children: React.ReactNode
  motionKey: string
}): JSX.Element => {
  return (
    <motion.div
      key={motionKey}
      initial={{ opacity: 0, y: 6 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -6 }}
      transition={{ duration: 0.3, ease: 'easeOut' }}
    >
      {children}
    </motion.div>
  )
}

export const RequestApplicantsAccessDialog = (): JSX.Element => {
  const [view, setView] = useState<DialogView>(DialogView.FORM)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isDialogOpen = useAtomValue(
    useMemo(() => isDialogOpenAtom(DialogId.REQUEST_APPLICANTS_ACCESS), [])
  )
  const controlDialog = useSetAtom(controlDialogAtom)
  const closeDialog = useSetAtom(closeDialogAtom)
  const { submitFeedback } = useSubmitFeedback()
  const { org } = useSession()

  const { submit, register } = useForm<RequestAccessSchema>({
    schema: requestAccessSchema
  })

  const handleSubmit = async (formData: RequestAccessSchema): Promise<void> => {
    setIsSubmitting(true)
    submitFeedback({
      feedback: {
        body: `Enable Applicants Beta Access for ${org?.name}.\nCurrent ATS: ${
          formData.currentAts ?? 'N/A'
        }`,
        type: FeedbackType.OTHER
      },
      onSuccess: () => {
        setIsSubmitting(false)
        setView(DialogView.SUCCESS)
      }
    })
  }

  return (
    <Dialog.Root
      id={DialogId.REQUEST_APPLICANTS_ACCESS}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.REQUEST_APPLICANTS_ACCESS, newState: value })
      }}
      $maxWidth="640px"
      $innerPadding={
        view === DialogView.FORM
          ? { top: 24, left: 24, right: 24, bottom: 24 }
          : { top: 0, left: 0, right: 0, bottom: 0 }
      }
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Request Beta Access to Applicants"
          onClose={() => {
            controlDialog({ id: DialogId.REQUEST_APPLICANTS_ACCESS, newState: false })
          }}
        />
        <Dialog.Content>
          <AnimatePresence mode="wait" initial={false}>
            <AnimatedContent motionKey={view}>
              {view === DialogView.FORM && (
                <Form onSubmit={submit(handleSubmit)}>
                  <Input name="currentAts" label="Which ATS are you using?" register={register} />
                  <FormActionButtons
                    saveText="Request Beta Access"
                    cancelText="Cancel"
                    onCancel={() => {
                      closeDialog(DialogId.REQUEST_APPLICANTS_ACCESS)
                    }}
                    isSaving={isSubmitting}
                  />
                </Form>
              )}
              {view === DialogView.SUCCESS && (
                <S.SuccessView>
                  <S.SuccessIcon>
                    <Icon name={Icons.check} size={32} color="bgPrimary" />
                  </S.SuccessIcon>
                  <Flex $direction="column" $gap={4}>
                    <Caption size="LG" $fontWeight={400}>
                      Thank you!
                    </Caption>
                    <Paragraph size="SM" $color="fgSecondary">
                      We have received your access request and we&apos;ll get in touch with you
                      soon.
                    </Paragraph>
                  </Flex>
                  <Divider $spacing={{ before: 28, after: 28 }} />
                  <Flex $direction="column" $gap={24}>
                    <Flex $direction="column" $gap={4}>
                      <Caption size="MD">Would you like to schedule a demo call with us?</Caption>
                      <Paragraph size="SM" $color="fgSecondary">
                        Our team can help you get the most out of Pin.
                      </Paragraph>
                    </Flex>
                    <Flex $align="center" $justify="space-between">
                      <BookADemoButton />
                      <Button
                        $variant="fill"
                        $colorTheme="tint"
                        $height={40}
                        onClick={() => {
                          closeDialog(DialogId.REQUEST_APPLICANTS_ACCESS)
                        }}
                      >
                        Close
                      </Button>
                    </Flex>
                  </Flex>
                </S.SuccessView>
              )}
            </AnimatedContent>
          </AnimatePresence>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
