import { PriceInterval } from 'src/libs/api/backend/billing'
import type { BillingProduct, CustomerSubscription } from 'src/libs/api/backend/billing'
import { Button, Flex } from 'src/components/primitives'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { NavLink } from 'react-router-dom'
import { Icon, Icons } from 'src/components/primitives/icon'
import { formatCurrency } from 'src/libs/currency'
import { getProductDetails } from './billing-utils'
import * as S from './plan.styled'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { useMemo, useState } from 'react'
import { useSetUserRole } from 'src/hooks/mutations/use-set-user-role'
import { TeamMemberRow } from 'src/components/team-members-list/team-member-row'
import { useSession } from 'src/hooks/use-session'
import { useDeleteUser } from 'src/hooks/mutations/use-delete-user'
import { useActivateUser } from 'src/hooks/mutations/use-activate-user'

interface PlanProps {
  professionalProduct: BillingProduct
  customersSubscriptionsByProductId: Record<string, CustomerSubscription>
  interval: PriceInterval
  checkoutItems: () => Promise<void>
  expired: boolean
}

export const Plan = ({
  professionalProduct,
  customersSubscriptionsByProductId,
  interval,
  checkoutItems,
  expired
}: PlanProps): JSX.Element | null => {
  const { user } = useSession()
  const { data: orgUsers } = useOrgUsersQuery()
  const { deleteUser } = useDeleteUser()
  const { restoreUser } = useActivateUser()
  const [isEditingUsers, setIsEditingUsers] = useState(false)
  const { updateUserRole } = useSetUserRole()

  const activeUsersCount = useMemo(() => {
    return orgUsers?.filter((user) => user.active).length
  }, [orgUsers])

  const {
    monthlyPrice,
    productCost,
    productInterval,
    productSubscribed,
    perUsage
  } = getProductDetails({
    product: professionalProduct,
    customersSubscriptionsByProductId,
    interval
  })

  return (
    <S.PlanBox>
      <S.PlanDescriptionBox>
        <Flex $direction='column' $flex='1 1 auto' $width='fit-content' $gap={4}>
          <Caption size='MD'>{professionalProduct.name}</Caption>
          <NavLink to='https://www.pin.com/#pricing' target='_blank' end={true}>
            <S.Link>
              Plan details
              <Icon name={Icons.externalLink} color='fgTertiary' />
            </S.Link>
          </NavLink>
        </Flex>
        {interval === PriceInterval.year && !productSubscribed && (
          <S.DiscountedTag>
            <Caption size='2XS' $color='positiveFg'>10% OFF</Caption>
          </S.DiscountedTag>
        )}
        <Flex $gap={12} $align='center' $width='fit-content'>
          {interval === PriceInterval.year && !productSubscribed && (
            <S.OriginalPrice>
              <Caption size='XL' $fontWeight={400} $color='fgTertiary'>{formatCurrency(Number(monthlyPrice?.unitAmount ?? 0))}</Caption>
            </S.OriginalPrice>
          )}
          <Caption size='XL'>{formatCurrency(Number(productCost))}</Caption>
          <Flex $direction='column' $align='flex-start' $width='60px'>
            <Paragraph size='XS'>{perUsage}</Paragraph>
            <Paragraph size='XS'>per {productInterval}</Paragraph>
          </Flex>
        </Flex>
        {productSubscribed
          ? <S.CurrentTag>Current</S.CurrentTag>
          : <Button
              $variant='fill'
              $colorTheme='tint'
              $height={40}
              $width={120}
              $fontSize={14}
              $align='center'
              onClick={checkoutItems}
            >
              Subscribe
            </Button>
        }
      </S.PlanDescriptionBox>
      {expired && orgUsers && (
        <S.MembersBox>
          <S.MembersBoxHeader>
            <Caption size='XS'>You have {activeUsersCount} users on your team</Caption>
            <Button
              $variant='ghost'
              $colorTheme={isEditingUsers ? 'normal' : 'tint'}
              $height={24}
              $width={44}
              $align='center'
              $fontSize={12}
              onClick={() => {
                setIsEditingUsers(!isEditingUsers)
              }}
            >
              {isEditingUsers ? 'Done' : 'Edit'}
            </Button>
          </S.MembersBoxHeader>
          {isEditingUsers && (
            <S.OptionsList>
              {orgUsers?.map((orgUser) => {
                const currentUser = orgUser.id === user?.id
                return (
                  <TeamMemberRow
                    key={orgUser.id}
                    orgUser={orgUser}
                    currentUser={currentUser}
                    deleteUser={deleteUser}
                    restoreUser={restoreUser}
                    orgUsers={orgUsers}
                    updateUserRole={updateUserRole}
                  />
                )
              })}
            </S.OptionsList>
          )}
        </S.MembersBox>
      )}
    </S.PlanBox>
  )
}
