import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  flex: 1 1 auto;
  overflow-y: auto;
  scrollbar-width: thin;
  max-height: 100%;
`

export const ActionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
  padding: ${({ theme }) => theme.spacing[16]};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
`

export const CriteriaGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  width: 100%;
`

export const CriteriaGroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[12]} ${({ theme }) => theme.spacing[20]};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  margin-bottom: ${({ theme }) => theme.spacing[12]};

  h5 {
    text-transform: uppercase;
  }
`
