import { isNil } from 'lodash'
import { useState, useEffect, useMemo, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { Button } from 'src/components/primitives'
import type { ButtonStyleProps, ButtonActionProps } from 'src/components/primitives'
import { Divider } from 'src/components/primitives/divider'
import { Icon, type IconName } from 'src/components/primitives/icon'
import type { FeatureFlags } from 'src/libs/api/backend/session'
import { LoadingSkeleton } from '../loading-skeleton'
import { When } from '../when'
import { Badge } from 'src/components/primitives/badge'
import type { Variant as BadgeVariant } from 'src/components/primitives/badge'
import type { Color } from 'src/styles/theme/types'
import * as S from './navigation.styled'
import { Tooltip } from 'src/components/primitives/tooltip'
import { Caption } from 'src/components/primitives/typography'

interface NavigationItemProps {
  navItem: NavItem
  isCollapsed: boolean
  featureFlags?: FeatureFlags[] | null
}

interface GetNavItemReturnType {
  variant: ButtonStyleProps['$variant']
  colorTheme: ButtonStyleProps['$colorTheme']
  width: ButtonStyleProps['$width']
}

export interface NavigationSubItem {
  id: string
  type?: 'link' | 'separator'
  title?: string
  loading?: boolean
  count?: number | string
  badgeVariant?: BadgeVariant
  href?: string
  searchParams?: string | null
  onClick?: ButtonActionProps['onClick']
  icon?: IconName
  iconColor?: Color
  colorTheme?: ButtonStyleProps['$colorTheme']
  featureFlag?: FeatureFlags
  includeBasePath?: boolean
}

export interface NavItem {
  id: string
  title: string
  type: 'link' | 'separator'
  basePath?: string
  href?: string
  onClick?: ButtonActionProps['onClick']
  icon?: IconName
  count?: number | string
  expandedCount?: number | string
  badgeText?: string
  badgeVariant?: BadgeVariant
  subItems?: NavigationSubItem[]
  featureFlag?: FeatureFlags
}

const getTrailingIcon = (navItem: NavItem, isCollapsed: boolean, isActiveItem: boolean): JSX.Element | null => {
  if (!isCollapsed && !isNil(navItem.badgeText)) {
    return (
      <Badge $variant={navItem.badgeVariant ?? 'bgSecondary'}>
        {navItem.badgeText}
      </Badge>
    )
  }
  if (!isCollapsed && !isNil(navItem.count)) {
    const useExpandedCount = isActiveItem && !isNil(navItem.expandedCount)
    const count = useExpandedCount ? navItem.expandedCount : navItem.count
    if (typeof count === 'number' && count <= 0) {
      return <></>
    }

    return (
      <Badge
        $variant={
          useExpandedCount
            ? 'bgSecondary' // hardcoded atm, but we can add a new field if needed
            : navItem.badgeVariant ?? 'bgSecondary'
        }
      >
        {count}
      </Badge>
    )
  }
  return null
}

const isActiveSubItem = (activeSubItem: string, subItem: NavigationSubItem): boolean => {
  return activeSubItem === subItem.href || activeSubItem === subItem.searchParams
}

const renderIndicator = (subItem: NavigationSubItem, isActiveSubItem: boolean, isActiveItem: boolean, isCollapsed: boolean): JSX.Element => {  
  if (!isCollapsed) {
    return <></>
  }

  if (subItem.icon) {
    return (
      <S.SubitemIcon
        $isActiveItem={isActiveItem}
        $isActiveSubItem={isActiveSubItem}
        $isCollapsed={isCollapsed}
      >
        <Icon
          name={subItem.icon}
          size={12}
          color={
            subItem.iconColor ??
            (isActiveSubItem ? 'fgPrimary' : 'fgTertiary')
          }
        />
      </S.SubitemIcon>
    )
  } else {
    return (
      <S.SubitemIndicator
        $isActiveSubItem={isActiveSubItem}
        $isCollapsed={isCollapsed}
      />
    )
  }
}

export const NavigationItem = ({ navItem, isCollapsed, featureFlags }: NavigationItemProps): JSX.Element | null => {
  const location = useLocation()
  const pathname = location.pathname
  const [activeSubItem, setActiveSubItem] = useState<string>('/')
  const hasSubItems = navItem.subItems && navItem.subItems.length > 0

  useEffect(() => {
    if (navItem.subItems) {
      for (const subItem of navItem.subItems) {
        if (location.search) {
          if (location.search === subItem.searchParams) {
            setActiveSubItem(subItem.searchParams)
            break
          }
        } else {
          if (location.pathname === `${navItem.basePath}${subItem.href ?? ''}`.toLowerCase()) {
            setActiveSubItem(subItem.href ?? '/')
            break
          }
        }
      }
    }
  }, [location.pathname, location.search, navItem.basePath, navItem.subItems])

  const isActiveItem = useMemo((): boolean => {
    return navItem.basePath ? pathname.startsWith(navItem.basePath ?? '') : false
  }, [navItem.basePath, pathname])

  const itemStyles = useMemo((): GetNavItemReturnType => {
    return {
      variant: (isActiveItem && !hasSubItems) ? 'navItem' : 'ghost',
      colorTheme: 'normal',
      width: isCollapsed ? 40 : 'full'
    }
  }, [isActiveItem, isCollapsed, hasSubItems])

  const getSubItemHref = (basePath: string | undefined, subItem: NavigationSubItem): string => {
    // Only exclude basePath if `includeBasePath` is explicitly set to false
    if (subItem.includeBasePath === false) {
      return subItem.href ?? subItem.searchParams ?? ''
    }
    return `${basePath ?? ''}${subItem.href ?? subItem.searchParams ?? ''}`
  }

  if (navItem.featureFlag && !featureFlags?.includes(navItem.featureFlag)) {
    return null
  }

  return (
    <>
      <When condition={navItem.type === 'separator'}>
        <Divider $color="bgTertiary" $spacing={{ before: 8, after: 8 }} />
      </When>
      <When condition={navItem.type === 'link'}>
        <S.NavigationItem
          id={navItem.id}
          $isActive={isActiveItem}
          $isCollapsed={isCollapsed}
        >
          <Button
            $borderless={true}
            $variant={itemStyles.variant}
            $colorTheme={itemStyles.colorTheme}
            $height={36}
            $width={itemStyles.width}
            leadingIcon={navItem.icon}
            trailingIcon={getTrailingIcon(navItem, isCollapsed, isActiveItem)}
            $align="space-between"
            href={navItem.basePath ? `${navItem.basePath}${navItem.href}` : undefined}
            onClick={navItem.onClick}
            tooltip={{
              text: isCollapsed ? navItem.title : undefined,
              position: 'right'
            }}
          >
            {!isCollapsed && navItem.title}
          </Button>
          <When condition={isActiveItem && !isNil(navItem.subItems)}>
            <S.Subitems $isCollapsed={isCollapsed}>
              {navItem.subItems?.map((subItem) => {
                if (subItem.featureFlag && !featureFlags?.includes(subItem.featureFlag)) {
                  return null
                }
                if (subItem.type === 'separator') {
                  if (isCollapsed && subItem.title !== 'AI Sourced') {
                    return <></>
                  }
                  return (
                    <S.SubitemSeparator key={subItem.id} $isCollapsed={isCollapsed}>
                      <Caption size="2XS" $color="fgTertiary" $transform="uppercase">{isCollapsed ? subItem.title?.substring(0, 2) : subItem.title}</Caption>
                    </S.SubitemSeparator>
                  )
                }

                const isActive = isActiveSubItem(activeSubItem, subItem)
                return (
                  <Fragment key={subItem.id}>
                    <When condition={!isNil(subItem.href) || !isNil(subItem.searchParams)}>
                      <Tooltip
                        disabled={!isCollapsed}
                        position="right"
                        trigger={
                          <S.Subitem
                            to={getSubItemHref(navItem.basePath, subItem)}
                            $isActiveSubItem={isActive}
                            $isCollapsed={isCollapsed}
                            $hasIcon={Boolean(subItem.icon)}
                          >
                            {renderIndicator(subItem, isActive, isActiveItem, isCollapsed)}
                            <When condition={!isCollapsed && subItem.href === '/search'}>
                              <S.SubitemIconExpanded>
                                <Icon name="plus" size={12} color="fgPrimary" />
                              </S.SubitemIconExpanded>
                            </When>
                            <When condition={!isCollapsed}>
                              <Button
                                nested
                                $variant={isActive ? 'flat' : 'ghost'}
                                $colorTheme="normal"
                                $height={32}
                                $width={isCollapsed ? '32px' : 'full'}
                                $fontSize={12}
                                trailingIcon={
                                  !isCollapsed &&
                                  !isNil(subItem.count) &&
                                  Number(subItem.count) > 0 && (
                                    <Badge $variant={subItem.badgeVariant ?? 'bgSecondary'}>
                                      {subItem.count}
                                    </Badge>
                                  )
                                }
                                $align="space-between"
                                $borderless
                              >
                                {subItem.loading ? <LoadingSkeleton $variant="NavigationTitle" /> : subItem.title}
                              </Button>
                            </When>
                          </S.Subitem>
                        }
                      >
                        {subItem.title}
                      </Tooltip>
                    </When>
                    <When condition={!isNil(subItem.onClick)}>
                      <Tooltip
                        disabled={!isCollapsed}
                        position="right"
                        trigger={
                          <S.SubitemButton
                            $isActiveSubItem={isActive}
                            $isCollapsed={isCollapsed}
                            $hasIcon={Boolean(subItem.icon)}
                            onClick={subItem.onClick}
                          >
                            {renderIndicator(subItem, isActive, isActiveItem, isCollapsed)}
                            <Button
                              nested
                              $variant={isActive ? 'navItem' : 'ghost'}
                              $colorTheme={subItem.colorTheme ?? 'normal'}
                              $height={32}
                              $width={isCollapsed ? '32px' : 'full'}
                              $fontSize={12}
                              trailingIcon={
                                !isCollapsed &&
                                !isNil(subItem.count) &&
                                Number(subItem.count) > 0 && (
                                  <Badge $variant={subItem.badgeVariant ?? 'bgSecondary'}>
                                    {subItem.count}
                                  </Badge>
                                )
                              }
                              $align="space-between"
                            >
                              {subItem.title}
                            </Button>
                          </S.SubitemButton>
                        }
                      >
                        {subItem.title}
                      </Tooltip>
                    </When>
                  </Fragment>
                )
              })}
            </S.Subitems>
          </When>
        </S.NavigationItem>
      </When>
    </>
  )
}
