import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { fetchApiStatus } from 'src/libs/api/backend/configuration'
import type { ApiStatusResponse } from 'src/libs/api/backend/configuration'
import { queryKeys } from 'src/libs/query-keys'

export const useApiStatusQuery = (): UseQueryResult<ApiStatusResponse> => {
  return useQuery({
    queryKey: [queryKeys.apiStatus],
    queryFn: async () => await fetchApiStatus(),
    staleTime: 60 * 1000
  })
}
