import { Avatar } from 'src/components/primitives/avatar'
import * as S from './carousel.styled'
import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import { Icons } from 'src/components/primitives/icon'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import { When } from '../when'

interface CarouselItemBaseProps {
  title: string
  description: string
  timespan?: string
  logoUrl?: string | null
  website?: string | null
  expandableItemsCount?: number
}

interface CarouselItemProps extends CarouselItemBaseProps {
  onWidthCalculation: (width: number) => void
  onExpandItem: () => void
}

const CarouselItem = ({
  title,
  description,
  timespan,
  logoUrl,
  website,
  onWidthCalculation,
  expandableItemsCount,
  onExpandItem
}: CarouselItemProps): JSX.Element => {
  const itemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (itemRef.current) {
      const observer = new ResizeObserver(() => {
        const width = itemRef.current?.getBoundingClientRect().width ?? 0
        onWidthCalculation(width)
      })

      observer.observe(itemRef.current)
      return () => { observer.disconnect() }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.Item ref={itemRef}>
      <S.AvatarWrapper $needsOutline={!logoUrl}>
        <Avatar
          $size={48}
          $shape="soft"
          $type="logo"
          $innerPadding={0}
          $border={false}
          company={{ name: title, url: website, logoUrl }}
          initials={title}
          fallbackAvatar="random"
        />
      </S.AvatarWrapper>
      <S.ItemContent>
        <S.ItemContentRow>
          <Caption size="XS" $color="fgPrimary">{title}</Caption>
        </S.ItemContentRow>
        <S.ItemContentRow>
          <Flex $gap={4} $align="center">
            <Caption size="XS" $color="fgPrimary">{description}</Caption>
            {
              expandableItemsCount && expandableItemsCount >= 1
                ? (
                    <Button
                      $variant="flat"
                      $colorTheme="muted"
                      $height={20}
                      $fontSize={10}
                      onClick={onExpandItem}
                      tooltip={{
                        text: 'Show all experiences from this company'
                      }}
                    >
                      +{expandableItemsCount}
                    </Button>
                  )
                : null
            }
          </Flex>
        </S.ItemContentRow>
        <S.ItemContentRow>
          {timespan && (
            <Paragraph size="XS" $color="fgSecondary">{timespan}</Paragraph>
          )}
        </S.ItemContentRow>
      </S.ItemContent>
    </S.Item>
  )
}

interface CarouselProps {
  carouselHeading: string
  carouselItems: CarouselItemBaseProps[]
  groupItemsByTitle?: boolean
}

export const Carousel = ({ carouselHeading, carouselItems, groupItemsByTitle = true }: CarouselProps): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [itemWidths, setItemWidths] = useState<number[]>([])
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set())

  const carouselViewportRef = useRef<HTMLDivElement>(null)
  const carouselInnerRef = useRef<HTMLDivElement>(null)

  const groupedItems = carouselItems.reduce<CarouselItemBaseProps[]>((acc, item, index) => {
    if (!groupItemsByTitle) {
      return [...acc, { ...item, expandableItemsCount: 0 }]
    }

    if (expandedItems.has(item.title)) {
      return [...acc, { ...item, expandableItemsCount: 0 }]
    }

    const prevItem = carouselItems[index - 1]

    if (prevItem?.title === item.title) {
      return acc
    }

    let upcomingItemsCount = 0
    let nextIndex = index + 1
    while (nextIndex < carouselItems.length && carouselItems[nextIndex].title === item.title) {
      upcomingItemsCount++
      nextIndex++
    }

    return [...acc, { ...item, expandableItemsCount: upcomingItemsCount }]
  }, [])

  const currentOffset = itemWidths
    .slice(0, currentIndex)
    .reduce((sum, width) => sum + width, 0)

  const { showNavigation, lastItemIsVisible } = useMemo(() => {
    const viewport = carouselViewportRef.current?.getBoundingClientRect()
    const inner = carouselInnerRef.current?.getBoundingClientRect()
    const viewportWidth = viewport?.width ?? 0
    const innerWidth = inner?.width ?? 0

    const totalScrolled = currentOffset
    const remainingWidth = innerWidth - totalScrolled - viewportWidth

    return {
      showNavigation: innerWidth > viewportWidth,
      lastItemIsVisible: remainingWidth <= 0
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemWidths, currentOffset])

  const handleItemWidthCalculation = useCallback((index: number, width: number) => {
    setItemWidths(prev => {
      const updated = [...prev]
      updated[index] = width
      return updated
    })
  }, [])

  const handlePrevious = useCallback((): void => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }, [currentIndex])

  const handleNext = useCallback((): void => {
    if (currentIndex < groupedItems.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }, [currentIndex, groupedItems])

  const handleExpandItem = useCallback((itemTitle: string) => {
    setExpandedItems(prev => {
      const next = new Set(prev)
      next.add(itemTitle)
      return next
    })
  }, [])

  if (!groupedItems.length) {
    return <></>
  }

  return (
    <S.Carousel>
      <S.Header>
        <Caption size="2XS" $color="fgTertiary" $transform="uppercase" $whiteSpace="nowrap">
          {carouselHeading}
        </Caption>
        <When condition={showNavigation}>
          <Flex $gap={8} $align="center" $justify="flex-end">
            <Button
              $width={24}
              $height={24}
              $variant="outline"
              $colorTheme="muted"
              leadingIcon={Icons.chevronLeft}
              onClick={handlePrevious}
              disabled={currentIndex === 0}
            />
            <Button
              $width={24}
              $height={24}
              $variant="outline"
              $colorTheme="muted"
              leadingIcon={Icons.chevronRight}
              onClick={handleNext}
              disabled={currentIndex === groupedItems.length - 1 || lastItemIsVisible}
            />
          </Flex>
        </When>
      </S.Header>
      <S.CarouselViewport ref={carouselViewportRef}>
        <S.CarouselItems ref={carouselInnerRef} style={{ transform: `translateX(-${currentOffset}px)` }}>
          {groupedItems.map((item, index) => (
            <CarouselItem
              key={`${item.title}-${item.description}-${item.timespan}`}
              title={item.title}
              description={item.description}
              timespan={item.timespan}
              logoUrl={item.logoUrl}
              website={item.website}
              expandableItemsCount={item.expandableItemsCount}
              onWidthCalculation={(width) => { handleItemWidthCalculation(index, width) }}
              onExpandItem={() => { handleExpandItem(item.title) }}
            />
          ))}
        </S.CarouselItems>
      </S.CarouselViewport>
    </S.Carousel>
  )
}
