import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING } from 'src/styles/constants'
import type { Spacing } from 'src/styles/theme/types'
import styled from 'styled-components'

interface CandidatesTablePagesContentInnerProps {
  $padding?: Spacing
  $maxWidth?: string
  $maxHeight?: string
}

export const CandidatesTablePagesContentInner = styled.div<CandidatesTablePagesContentInnerProps>`
  padding: ${({ $padding }) => $padding ?? `0 ${CONTENT_PADDING}`};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: ${({ $maxHeight }) => $maxHeight ?? 'unset'};
  max-width: ${({ $maxWidth }) => $maxWidth ?? CANDIDATES_PAGES_MAX_WIDTH};
`
