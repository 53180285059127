import { Paragraph } from 'src/components/primitives/typography'
import { SubscriptionSettings } from 'src/components/blocks/subscription-settings'
import { useSession } from 'src/hooks/use-session'
import { differenceInDays } from 'date-fns'
import { Flex } from 'src/components/primitives'
import { useState } from 'react'
import { ClosePinAccountFeedback } from 'src/components/blocks/close-pin-account-feedback'

export const SubscriptionDialog = (): JSX.Element => {
  const { org, subscribed, trialActive } = useSession()
  const [closeAccount, setCloseAccount] = useState(false)
  if (closeAccount) {
    return (
      <ClosePinAccountFeedback
        expired={false}
        onClose={() => {
          setCloseAccount(false)
        }}
      />
    )
  }
  return (
    <SubscriptionSettings
      notificationBanner={
        !subscribed && trialActive && org?.trialActiveUntil
          ? (
              <Flex $direction='column'>
                <Paragraph size='SM' $fontWeight={500}>You’re currently in the the free trial period with {differenceInDays(org.trialActiveUntil, new Date())} days left.</Paragraph>
                <Paragraph size='SM'>Subscribe today to avoid disruption.</Paragraph>
              </Flex>
            )
          : null
      }
      onCloseAccount={setCloseAccount}
    />
  )
}
