import { useMemo, useState } from 'react'
import { Button } from 'src/components/primitives'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'
import { useAdvanceJobInterviewStage } from 'src/hooks/mutations/use-advance-job-interview-stage'
import { useAtsInterviewStagesQuery } from 'src/hooks/queries/use-ats-interview-stages'

interface ApplicantMoveStageProps {
  jobId: string
  candidateJobId: string
}

export const ApplicantMoveStage = ({
  jobId,
  candidateJobId
}: ApplicantMoveStageProps): JSX.Element => {
  const [inProgress, setInProgress] = useState(false)
  const { data: atsInterviewStages, isPending } = useAtsInterviewStagesQuery(jobId)
  const { advanceJobInterviewStage } = useAdvanceJobInterviewStage()
  const items = useMemo(() => {
    const items: MenuItemProps[] = [{
      type: 'label',
      title: 'Move to Stage'
    }]
    if (atsInterviewStages) {
      items.push(...atsInterviewStages.map((stage) => ({
        id: stage.id,
        title: stage.mergeName ?? '',
        onSelect: () => {
          setInProgress(true)
          advanceJobInterviewStage({
            jobId,
            candidateJobIds: [candidateJobId],
            destinationJobInterviewStageId: stage.id,
            onSuccess: () => {
              setInProgress(false)
            }
          })
        }
      })))
    }
    return items
  }, [advanceJobInterviewStage, atsInterviewStages, candidateJobId, jobId])
  return (
    <Dropdown
      disabled={inProgress}
      trigger={
        <Button
          nested
          $loading={isPending || atsInterviewStages?.length === 0}
          $fontSize={12}
          $variant="raised"
          $colorTheme="tint"
          $height={24}
          loading={inProgress}
          leadingIcon={Icons.arrowRightCircle}
        >
          Move to Stage
        </Button>
      }
      items={items}
      size="small"
    />
  )
}
