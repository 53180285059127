export enum LocalStorageKey {
  APPLICANT_VIEW_MODE = 'applicantViewMode',
  VIEW_MODE = 'viewMode',
  LAST_VIEWED_JOB = 'lastViewedJob',
  HAS_LOGGED_IN = 'hasLoggedIn',
  SUBMIT_FEEDBACK_NOTICED = 'submitFeedbackNoticed',
  CHROME_EXTENSION_NOTICED = 'chromeExtensionNoticed',
  JOB_SOURCING_STATUS = 'jobSourcingStatus',
  REPORT_FEEDBACK_BANNER_CLOSED = 'reportFeedbackBannerClosed',
  API_STATUS_NOTICED = 'apiStatusNoticed',
  APP_IS_READY_FOR_PRODUCT_TOUR = 'appIsReadyForProductTour',
  HAS_BOOKED_DEMO = 'hasBookedDemo',
  HAS_BOOKED_DEMO_DURING_ONBOARDING = 'hasBookedDemoDuringOnboarding'
}

export enum ViewMode {
  DEFAULT = 'default',
  COMPACT = 'compact',
  TABLE = 'table'
}

export enum CandidateActivityViewMode {
  COMPACT = 'COMPACT',
  DETAIL = 'DETAIL'
}

export const PinExtensionId = 'infcaknhippabnplinmgidmjojcbkijd'
export const PinExtensionChromeStoreUrl = `https://chromewebstore.google.com/detail/${PinExtensionId}`

export enum CreateSequenceDialogView {
  INTRO = 'INTRO',
  OPTIONS = 'OPTIONS',
  CREATE_MANUALLY = 'CREATE_MANUALLY',
  CREATE_WITH_AI = 'CREATE_WITH_AI'
}
