import { HEADER_HEIGHT, HEADER_PADDING } from 'src/styles/constants'
import styled from 'styled-components'
import type { CandidatesPageHeaderStyleProps } from './candidates-page-header'

export const CandidatesPageHeader = styled.header<CandidatesPageHeaderStyleProps>`
  /* padding: 18px 0 6px 0; */
  padding-top: ${({ $padding }) => $padding?.top ? `${$padding.top}px` : HEADER_PADDING};
  padding-bottom: ${({ $padding }) => $padding?.bottom ? `${$padding.bottom}px` : HEADER_PADDING};

  h1 {
    line-height: ${HEADER_HEIGHT};
  }
`

export const HeadingGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  button {
    opacity: 0.75;
  }
  form {
    width: auto;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    input {
      height: 28px;
      font-size: 1rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.fgPrimary};
      background-color: transparent !important;
      line-height: ${HEADER_HEIGHT};
      padding: 0 !important;
      border: none !important;
      outline: none !important;
      min-width: 10px;
      white-space: nowrap;
      margin: 0 !important;
    }
  }
`

export const HeaderBlock = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]};
  flex: 1 1 auto;
`
