import { BenefitsList } from 'src/components/blocks/benefits-list'
import { OnboardingHeader } from 'src/components/blocks/onboarding-header'
import { Button } from 'src/components/primitives/button'
import { Grid } from 'src/components/primitives/grid'
import { Spacer } from 'src/components/primitives/spacer'
import { PinExtensionChromeStoreUrl } from '../../constants'
import { usePinExtensionVersion } from '../../hooks/use-pin-extension'
import { Paragraph } from '../../components/primitives/typography'
import { useEffect, useState } from 'react'
import { useOnboardingSteps } from 'src/hooks/use-onboarding-steps'

const OnboardingExtension = (): JSX.Element => {
  const pinExtensionVersion = usePinExtensionVersion()
  const [didVisitChromeStore, setDidVisitChromeStore] = useState(false)
  const { nextStep } = useOnboardingSteps()

  useEffect(() => {
    if (!didVisitChromeStore) {
      return
    }

    // This uses focus instead of visibilitychange because if they genuinely interacted with the
    // webstore, we will loose focus. If they just open then close it, we never loose focus.

    const onWindowActive = (): void => {
      window.location.reload()
    }

    window.addEventListener('focus', onWindowActive)

    return () => {
      window.removeEventListener('focus', onWindowActive)
    }
  }, [didVisitChromeStore])

  return (
    <>
      <OnboardingHeader
        heading="Chrome Extension"
        subheading="Supercharge your LinkedIn workflow with Pin."
      />

      <Grid $columns={2} $columnGap={16}>
        <Button
          $variant={pinExtensionVersion ? 'outline' : 'fill'}
          $colorTheme={pinExtensionVersion ? 'muted' : 'tint'}
          $height={40}
          $width="full"
          $align="center"
          leadingIcon="chrome"
          href={PinExtensionChromeStoreUrl}
          onClick={() => {
            setDidVisitChromeStore(true)
          }}
        >
          {pinExtensionVersion ? 'Chrome Extension installed' : 'Install Chrome Extension'}
        </Button>

      </Grid>

      {didVisitChromeStore && (
        <div>
          <Paragraph>Once the extension is installed, reload this page to test it.</Paragraph>
        </div>
      )}

      <Spacer $size={40} />

      <BenefitsList
        $itemSize="small"
        benefits={[
          {
            icon: 'messages-square',
            title: 'Send and automate InMail through Pin',
            description: 'Make LinkedIn InMail part of your outreach sequence.n'
          },
          {
            icon: 'user-circle',
            title: 'Get more information on candidates',
            description: 'View candidate connections and more without leaving Pin.'
          },
          {
            icon: 'inbox',
            title: 'Show InMail messages on your candidate communication history',
            description:
              'View both email and InMail messages in the same place.'
          }
        ]}
      />

      <Spacer $size={40} />

      <Button
          $variant={pinExtensionVersion ? 'fill' : 'raised'}
          $colorTheme='tint'
          $height={40}
          $align="center"
          href={nextStep?.href}
        >
        {pinExtensionVersion ? 'Continue' : 'Continue without Chrome Extension'}
      </Button>
    </>
  )
}

export default OnboardingExtension
