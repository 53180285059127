import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING } from 'src/styles/constants'
import type { Spacing } from 'src/styles/theme/types'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  contain: strict;
  scroll-behavior: smooth;
  padding: ${({ theme }) => theme.spacing[20]} ${CONTENT_PADDING} 0 0;
  width: 100%;
`

export const StatusContainer = styled.div`
  width: 100%;
  max-width: ${CANDIDATES_PAGES_MAX_WIDTH};
  padding: ${({ theme }) => theme.spacing[40]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  flex: 1;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[24]};
`

export const BlankLoadingState = styled.div`
  width: 100%;
  height: 100%;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[12]};
`

export const EmptyWrapper = styled.div`
  max-width: 30rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[40]};
`

export const CandidatesViewContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  padding-left: ${({ theme }) => theme.spacing[2]};
`

export const CandidatesViewFilter = styled.div<{ $clickable: boolean, $selected: boolean }>`
  flex: 1;
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
  align-items: center;
  cursor: ${({ $clickable }) => $clickable ? 'pointer' : 'default'};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: ${({ theme }) => theme.spacing[12]};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
  }
  ${({ $selected, theme }) => $selected && `
    background-color: ${theme.colors.bgTertiary};
  `}
`

export const CandidatesViewActionsBar = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[20]};
  max-width: ${CANDIDATES_PAGES_MAX_WIDTH};
  align-items: center;
  width: 100%;
  height: ${({ theme }) => theme.spacing[40]};
`

interface CandidatesTablePagesContentInnerProps {
  $padding?: Spacing
  $maxWidth?: string
  $maxHeight?: string
}

export const CandidatesTablePagesContentInner = styled.div<CandidatesTablePagesContentInnerProps>`
  padding: ${({ $padding }) => $padding ?? `0 ${CONTENT_PADDING}`};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: ${({ $maxHeight }) => $maxHeight ?? 'unset'};
  max-width: ${({ $maxWidth }) => $maxWidth ?? CANDIDATES_PAGES_MAX_WIDTH};
`
