import { createGlobalStyle } from 'styled-components'

export const ProductTourStyles = createGlobalStyle`
  .driver-overlay {
    background-color: rgba(0, 0, 0, 0.15);
  }
  .driver-popover {
    background-color: #fff;
    border-radius: 8px;
    padding: 0;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05), 0px 6px 24px 0px rgba(0, 0, 0, 0.10);
    width: 220px;
  }
  .driver-popover-title {
    color: #27272A;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    padding: 16px 16px 0 16px;
  }
  .driver-popover-arrow {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #2563EB;
    border: 1px solid #fff;
    z-index: 10;
  }

  @keyframes pulse {
    0% { opacity: 0.45; }
    50% { opacity: 0.2; }
    100% { opacity: 0.45; }
  }

  .driver-popover-arrow:after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 50%;
    background-color: #2563EB;
    animation: pulse 2s ease-in-out infinite;
    z-index: 12;
  }

  .driver-popover-footer {
    flex-direction: column;
    margin-top: 0;
  }
  .driver-popover-navigation-btns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    box-sizing: border-box;
    padding: 12px;
  }
  .driver-popover-navigation-btns button,
  .driver-popover-navigation-btns button:hover,
  .driver-popover-navigation-btns button:focus {
    flex: 0 0 50%;
    background-color: #27272A;
    color: #fff;
    display: flex;
    justify-content: center;
    height: 24px;
    border-radius: 4px;
    text-align: center;
    line-height: 1;
    font-size: 12px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding: 4px 6px;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 !important;
  }
  .driver-popover-btn-disabled {
    background-color: #fff !important;
    color: #27272A !important;
    border: solid 1px #18181B1A !important;
    opacity: 0.5 !important;
  }
  .driver-popover-navigation-btns button:hover {
    background-color: #27272A;
    color: #fff;
    border: none;
    opacity: 0.9;
  }
  .book-a-demo-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 8px;
    border-top: solid 1px rgba(63, 63, 70, 0.10);
    padding: 12px;
  }
  .book-a-demo-footer button {
    all: unset;
    width: 100%;
    background-color: #fff;
    color: #114FD7;
    border-radius: 4px;
    border: solid 1px rgba(37, 99, 235, 0.10);
    height: 24px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    line-height: 1;
    padding: 0 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    text-shadow: none !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }
  .book-a-demo-footer button svg {
    transform: translateY(-1px);
  }
  .book-a-demo-footer button:hover,
  .book-a-demo-footer button:focus {
    background-color: #fff;
    color: #114FD7;
    opacity: 0.9;
  }
  .book-a-demo-footer button:focus {
    opacity: 1;
  }
`
