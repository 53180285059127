import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import {
  ApplicantStageActionsCell,
  CandidateCreatedDateCell,
  CandidateCriteriaMatchCell,
  CandidateCriteriaMatchDetailsCell,
  CandidateErrorActionCell,
  CandidateErrorStatusCell,
  CandidateJobTitleCell,
  CandidateLastContactDateCell,
  CandidateNameCell,
  CandidateNextContactDateCell,
  CandidateRejectedDateCell,
  CandidateRejectionReasonCell,
  CandidateSequenceStepCell,
  CandidateSourceCell,
  CandidateStageActionsCell,
  CandidateStatusCell,
  DefaultTableHeader,
  FavoriteCell,
  FavoriteHeader
} from './candidate-table-cells'
import { isSourcingTableExpandedAtom } from 'src/stores/job-refinement'
import { store } from 'src/stores'
import { isNil, keyBy } from 'lodash'

export enum COLUMN {
  SELECTION_COLUMN_ID = 'selection',
  FAVORITE = 'favorite',
  NAME = 'name',
  STATUS = 'status',
  SOURCE = 'source',
  JOB_TITLE = 'jobTitle',
  CRITERIA = 'criteria',
  CRITERIA_EXPANDED = 'criteriaExpanded',
  CREATED_AT = 'createdAt',
  REJECTION_REASON = 'rejectionReason',
  LAST_CONTACT = 'lastContact',
  NEXT_CONTACT = 'nextContact',
  REJECTED_AT = 'rejectedAt',
  ERROR = 'error',
  ERROR_ACTIONS = 'errorActions',
  CANDIDATE_STAGE_ACTIONS = 'candidateStageActions',
  SEQUENCE_STEP = 'sequenceStep',
  APPLICANT_STAGE_ACTIONS = 'applicantStageActions'
}

export interface TableColumnDef<T> {
  columnId: string
  size?: string | undefined
  collapsedSize?: string | undefined
  canResize?: boolean
  collapsed?: boolean
  dependentExpandableColumnId?: string
  customizedHeader?: boolean
  customizedCell?: boolean
  ignoredOnRowClick?: boolean
  colSpan?: number
  sortingFn?: (rowA: T, rowB: T) => number
  cell: (info: T, index: number) => JSX.Element | null
  collaspedCell?: (info: T, index: number) => JSX.Element | null
  header: JSX.Element | null
  collapsedHeader?: JSX.Element | null
}

export type TableRowDef = CandidateJobExpanded

export const sortByLatestSequenceStep = () => {
  return (rowA: TableRowDef, rowB: TableRowDef) => {
    const stepA = rowA.candidateSequence?.latestStep ?? -1
    const stepB = rowB.candidateSequence?.latestStep ?? -1

    return stepA - stepB
  }
}

type SortableCandidateJobDateFields = 'createdAt' | 'updatedAt'

export const sortByCandidateJobDateField = (field: SortableCandidateJobDateFields) => {
  return (rowA: TableRowDef, rowB: TableRowDef) => {
    const dateA = rowA[field] ?? ''
    const dateB = rowB[field] ?? ''

    if (dateA < dateB) return -1
    if (dateA > dateB) return 1
    return 0
  }
}

export type SortableCandidateDateFields = 'lastCommunicatedAt'

export const sortByCandidateDateField = (field: SortableCandidateDateFields) => {
  return (rowA: TableRowDef, rowB: TableRowDef) => {
    const nameA = rowA.candidate?.[field] ?? ''
    const nameB = rowB.candidate?.[field] ?? ''

    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  }
}

export type SortableCandidateSequenceDateFields = 'nextSequenceStepDate'

export const sortByCandidateSequenceDateField = (field: SortableCandidateSequenceDateFields) => {
  return (rowA: TableRowDef, rowB: TableRowDef) => {
    const dateA = rowA.candidateSequence?.[field] ?? ''
    const dateB = rowB.candidateSequence?.[field] ?? ''

    if (dateA < dateB) return -1
    if (dateA > dateB) return 1
    return 0
  }
}

export const DEFAULT_WIDTH = 'minmax(150px, 1fr)'
export const DEFAULT_COLLAPSED_WIDTH = '38px'

export const buildTableColumns = ({
  visibleColumns,
  customColumnDefinitions
}: {
  visibleColumns: COLUMN[]
  customColumnDefinitions?: Array<Partial<TableColumnDef<TableRowDef>>>
}): Array<TableColumnDef<TableRowDef>> => {
  const tableIsExpanded = store.get(isSourcingTableExpandedAtom)
  const isExpandedCriteriaVisible = isNil(tableIsExpanded[COLUMN.CRITERIA_EXPANDED]) || tableIsExpanded[COLUMN.CRITERIA_EXPANDED]
  const customColumns = keyBy(customColumnDefinitions ?? [], 'columnId')
  const columnsDef: Array<TableColumnDef<TableRowDef>> = [{
    columnId: COLUMN.SELECTION_COLUMN_ID,
    size: '48px',
    colSpan: 1,
    ignoredOnRowClick: true,
    cell: () => null,
    header: null,
    collapsed: false
  }]
  visibleColumns.forEach((column: COLUMN) => {
    const defaultColumnDef = {
      columnId: column,
      size: DEFAULT_WIDTH, // By default all columns have a minimum width of 150 up to max-content
      canResize: true,
      collapsed: false,
      colSpan: 1,
      header: null,
      cell: () => null
    }
    const overrideCustomDef = customColumns[column] ?? {}
    let columnDef = {}
    switch (column) {
      case COLUMN.FAVORITE:
        columnDef = {
          size: '32px',
          canResize: false,
          ignoredOnRowClick: true,
          // sortingFn: (rowA: TableRowDef, rowB: TableRowDef) => {
          //   const a = rowA.favorite ?? false
          //   const b = rowB.favorite ?? false

          //   if (a < b) return -1
          //   if (a > b) return 1
          //   return 0
          // },
          header: <FavoriteHeader sorting={false} />,
          cell: (info: TableRowDef) => <FavoriteCell candidateJob={info} />
        }
        break
      case COLUMN.NAME:
        columnDef = {
          size: 'minmax(150px, 1.5fr)',
          sortingFn: (rowA: TableRowDef, rowB: TableRowDef) => {
            const nameA = rowA.candidate?.name?.toLowerCase() ?? ''
            const nameB = rowB.candidate?.name?.toLowerCase() ?? ''

            if (nameA < nameB) return 1
            if (nameA > nameB) return -1
            return 0
          },
          header: <DefaultTableHeader title="Name" />,
          cell: (info: TableRowDef) => <CandidateNameCell candidateJob={info} />
        }
        break
      case COLUMN.STATUS:
        columnDef = {
          ignoredOnRowClick: true,
          sortingFn: (rowA: TableRowDef, rowB: TableRowDef) => {
            const statusA =
              [rowA.statusDisplay?.title?.toLowerCase() ?? '', rowA.statusDisplay?.subtitle?.toLowerCase() ?? ''].join('')
            const statusB =
              [rowB.statusDisplay?.title?.toLowerCase() ?? '', rowB.statusDisplay?.subtitle?.toLowerCase() ?? ''].join('')

            if (statusA < statusB) return -1
            if (statusA > statusB) return 1
            return 0
          },
          header: <DefaultTableHeader title="Status" />,
          cell: (info: TableRowDef) => <CandidateStatusCell candidateJob={info} />
        }
        break
      case COLUMN.SOURCE:
        columnDef = {
          sortingFn: (rowA: TableRowDef, rowB: TableRowDef) => {
            const sourceA = rowA.source ?? rowA.creatorUserId ?? ''
            const sourceB = rowB.source ?? rowB.creatorUserId ?? ''

            if (sourceA < sourceB) return -1
            if (sourceA > sourceB) return 1
            return 0
          },
          header: <DefaultTableHeader title="Source" />,
          cell: (info: TableRowDef) => <CandidateSourceCell candidateJob={info} />
        }
        break
      case COLUMN.JOB_TITLE:
        columnDef = {
          sortingFn: (rowA: TableRowDef, rowB: TableRowDef) => {
            const jobA = rowA.candidate?.experiences?.[0]?.title?.toLowerCase() ?? ''
            const jobB = rowB.candidate?.experiences?.[0]?.title?.toLowerCase() ?? ''

            if (jobA < jobB) return 1
            if (jobA > jobB) return -1
            return 0
          },
          header: <DefaultTableHeader title="Job Title" />,
          cell: (info: TableRowDef) => <CandidateJobTitleCell candidateJob={info} />
        }
        break
      case COLUMN.CRITERIA:
        columnDef = {
          ignoredOnRowClick: true,
          sortingFn: (rowA: TableRowDef, rowB: TableRowDef) => {
            const countTrueValues = (obj: Record<string, boolean> | undefined): number => {
              return Object.values(obj ?? {}).filter(Boolean).length
            }

            const scoreA = countTrueValues(rowA.sourcingScores?.criteria_matches ?? undefined)
            const scoreB = countTrueValues(rowB.sourcingScores?.criteria_matches ?? undefined)

            return scoreA - scoreB
          },
          collapsed: isExpandedCriteriaVisible,
          collaspedCell: () => <></>,
          collapsedHeader: <></>,
          collapsedSize: DEFAULT_COLLAPSED_WIDTH,
          header: <DefaultTableHeader title="Criteria" />,
          cell: (info: TableRowDef) => <CandidateCriteriaMatchCell candidateJob={info} />
        }
        break
      case COLUMN.CRITERIA_EXPANDED:
        columnDef = {
          ignoredOnRowClick: true,
          header: null,
          collapsed: !isExpandedCriteriaVisible,
          collapsedSize: '0px',
          collapsedHeader: <></>,
          collapsedCell: () => <></>,
          cell: (info: TableRowDef) => <CandidateCriteriaMatchDetailsCell candidateJob={info} />
        }
        break
      case COLUMN.CREATED_AT:
        columnDef = {
          sortingFn: sortByCandidateJobDateField(COLUMN.CREATED_AT),
          header: <DefaultTableHeader title="Date Added" />,
          cell: (info: TableRowDef) => <CandidateCreatedDateCell candidateJob={info} />
        }
        break
      case COLUMN.REJECTION_REASON:
        columnDef = {
          sortingFn: (rowA: TableRowDef, rowB: TableRowDef) => {
            const reasonA = rowA.rejectionReason?.toLowerCase() ?? ''
            const reasonB = rowB.rejectionReason?.toLowerCase() ?? ''

            if (reasonA < reasonB) return -1
            if (reasonA > reasonB) return 1
            return 0
          },
          header: <DefaultTableHeader title="Reason" />,
          cell: (info: TableRowDef) => <CandidateRejectionReasonCell candidateJob={info} />
        }
        break
      case COLUMN.LAST_CONTACT:
        columnDef = {
          sortingFn: sortByCandidateDateField('lastCommunicatedAt'),
          header: <DefaultTableHeader title="Last Contact" />,
          cell: (info: TableRowDef) => <CandidateLastContactDateCell candidateJob={info} />
        }
        break
      case COLUMN.NEXT_CONTACT:
        columnDef = {
          sortingFn: sortByCandidateSequenceDateField('nextSequenceStepDate'),
          header: <DefaultTableHeader title="Next Step" />,
          cell: (info: TableRowDef) => <CandidateNextContactDateCell candidateJob={info} />
        }
        break
      case COLUMN.REJECTED_AT:
        columnDef = {
          sortingFn: sortByCandidateJobDateField('updatedAt'),
          header: <DefaultTableHeader title="Archived at" />,
          cell: (info: TableRowDef) => <CandidateRejectedDateCell candidateJob={info} />
        }
        break
      case COLUMN.ERROR:
        columnDef = {
          header: <DefaultTableHeader title="Error" />,
          cell: (info: TableRowDef) => <CandidateErrorStatusCell candidateJob={info} />
        }
        break
      case COLUMN.SEQUENCE_STEP:
        columnDef = {
          sortingFn: sortByLatestSequenceStep(),
          header: <DefaultTableHeader title="Step" />,
          size: 'minmax(75px, 0.5fr)',
          cell: (info: TableRowDef) => <CandidateSequenceStepCell candidateJob={info} />
        }
        break
      case COLUMN.ERROR_ACTIONS:
        columnDef = {
          ignoredOnRowClick: true,
          header: null,
          cell: (info: TableRowDef) => <CandidateErrorActionCell candidateJob={info} />
        }
        break
      case COLUMN.CANDIDATE_STAGE_ACTIONS:
        columnDef = {
          ignoredOnRowClick: true,
          size: 'minmax(220px, 1fr)',
          header: null,
          cell: (info: TableRowDef) => <CandidateStageActionsCell candidateJob={info} />
        }
        break
      case COLUMN.APPLICANT_STAGE_ACTIONS:
        columnDef = {
          ignoredOnRowClick: true,
          size: 'minmax(220px, 1fr)',
          header: null,
          cell: (info: TableRowDef) => <ApplicantStageActionsCell candidateJob={info} />
        }
        break
      default:
        break
    }
    columnsDef.push({ ...defaultColumnDef, ...columnDef, ...overrideCustomDef })
  })
  return columnsDef
}
