import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { LogoSymbol } from 'src/components/primitives/logo'
import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { sanitizeRefinementCriteria } from 'src/utils/refinement-criteria'
import * as S from './tweak-search-refinement-criteria-banner.styled'
import { CriteriaKey } from 'src/libs/api/backend/candidate_search'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { When } from '../when'
import { JobSearchRefinementCriteriaImprovementStatus } from 'src/libs/api/backend/jobs'
import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { isNil } from 'lodash'
import { Spacer } from 'src/components/primitives'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { Tooltip } from 'src/components/primitives/tooltip'
import { Spinner } from 'src/components/primitives/spinner'

export interface TweakSearchRefinementCriteriaBannerStyledProps {
  $padding?: {
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
  $contentAlign?: 'flex-start' | 'center' | 'flex-end'
}

export interface TweakSearchRefinementProps {
  onUpdateSearchRefinementCriteria?: (criteria: Criteria) => void
}

interface TweakSearchRefinementCriteriaBannerProps extends TweakSearchRefinementProps, TweakSearchRefinementCriteriaBannerStyledProps {
  pin?: 'normal' | 'sad' | 'happy' | 'searching' | null
  heading?: string
  subheading?: string
  formheading?: string
  jobSearchRefinement: JobSearchRefinement
}

export const TweakSearchRefinementCriteriaBanner = ({
  pin,
  heading = 'You reached the end of the list',
  subheading,
  formheading,
  onUpdateSearchRefinementCriteria,
  $padding = { top: 64, bottom: 64, left: 40, right: 120 },
  $contentAlign = 'flex-start',
  jobSearchRefinement
}: TweakSearchRefinementCriteriaBannerProps): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)
  const [selectedRadiusInMiles, setSelectedRadiusInMiles] = useState<number | null>(null)
  const {
    searchCriteria,
    criteriaImprovement,
    criteriaImprovementExplanation,
    criteriaImprovementToReplace,
    criteriaImprovementStatus
  } = jobSearchRefinement

  const currentCriteria = useMemo(() => {
    return searchCriteria ?? {}
  }, [searchCriteria])

  const radiusInMilesOptions = [5, 10, 20, 50]

  const radiusInMilesDropdownItems: MenuItemProps[] = useMemo(() => {
    const currentRadius = currentCriteria.locations?.[0]?.radiusInMiles ?? 0
    return radiusInMilesOptions
      .filter((miles) => miles > currentRadius)
      .map((miles) => ({
        title: `Within ${miles} miles`,
        value: miles.toString(),
        onSelect: () => {
          setSelectedRadiusInMiles(miles)
        }
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCriteria.locations])

  const getNextAvailableRadius = (currentRadius: number): number | null => {
    const nextRadius = radiusInMilesOptions.find((radius) => radius > currentRadius)
    return nextRadius ?? null
  }

  useEffect(() => {
    const currentRadius = currentCriteria.locations?.[0]?.radiusInMiles ?? 0
    const nextRadius = getNextAvailableRadius(currentRadius)
    setSelectedRadiusInMiles(nextRadius)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCriteria.locations])

  const handleLocationUpdate = useCallback((): void => {
    const newLocationsWithAppliedRadius = currentCriteria.locations?.map((location) => {
      if (location.city && (location.radiusInMiles === 0 || isNil(location.radiusInMiles))) {
        return {
          ...location,
          radiusInMiles: selectedRadiusInMiles
        }
      }
      return location
    })
    const updatedCriteria = {
      ...currentCriteria,
      locations: newLocationsWithAppliedRadius
    }
    const sanitizedCriteria = sanitizeRefinementCriteria(updatedCriteria)
    onUpdateSearchRefinementCriteria?.(sanitizedCriteria)
  }, [currentCriteria, onUpdateSearchRefinementCriteria, selectedRadiusInMiles])

  const handleDegreeRequirementsUpdate = useCallback((): void => {
    const updatedCriteria = Object.fromEntries(
      Object.entries(currentCriteria).filter(([key]) => key !== CriteriaKey.DEGREE)
    )
    const sanitizedCriteria = sanitizeRefinementCriteria(updatedCriteria)
    onUpdateSearchRefinementCriteria?.(sanitizedCriteria)
  }, [currentCriteria, onUpdateSearchRefinementCriteria])

  const showLocationCriteriaUpdateForm = useMemo(() => {
    const anyCityWithoutRadius = currentCriteria.locations?.some((location) => location.city && (location.radiusInMiles === 0 || isNil(location.radiusInMiles)))
    return Boolean(currentCriteria.locations) && !!anyCityWithoutRadius
  }, [currentCriteria.locations])

  const showDegreeRequirementsUpdateForm = useMemo(() => {
    return Boolean(currentCriteria.degree)
  }, [currentCriteria.degree])

  const acceptAiInstructionsSuggestedChanges = useCallback(() => {
    if (!isNil(currentCriteria[CriteriaKey.CUSTOM_REQUIREMENTS]) && criteriaImprovementToReplace) {
      const currentAiInstructionIndex = currentCriteria[CriteriaKey.CUSTOM_REQUIREMENTS].findIndex((instruction) => {
        return instruction.requirement.toLowerCase().trim() === criteriaImprovementToReplace.toLowerCase().trim()
      })
      if (currentAiInstructionIndex > -1 && criteriaImprovement) {
        const updatedCriteria = {
          ...currentCriteria,
          [CriteriaKey.CUSTOM_REQUIREMENTS]: [
            ...currentCriteria[CriteriaKey.CUSTOM_REQUIREMENTS].slice(0, currentAiInstructionIndex),
            {
              ...currentCriteria[CriteriaKey.CUSTOM_REQUIREMENTS][currentAiInstructionIndex],
              requirement: criteriaImprovement
            },
            ...currentCriteria[CriteriaKey.CUSTOM_REQUIREMENTS].slice(currentAiInstructionIndex + 1)
          ]
        }
        const sanitizedCriteria = sanitizeRefinementCriteria(updatedCriteria)
        onUpdateSearchRefinementCriteria?.(sanitizedCriteria)
      }
    }
  }, [currentCriteria, criteriaImprovementToReplace, criteriaImprovement, onUpdateSearchRefinementCriteria])

  const suggestionChanges = useMemo(() => {
    if (criteriaImprovementStatus === JobSearchRefinementCriteriaImprovementStatus.SUCCESS && criteriaImprovement && criteriaImprovementToReplace) {
      return (
        <S.SuggestedChangesBox>
          <Caption size="SM" $color="fgPrimary" as="h5">
            Suggested change
          </Caption>
          <Spacer $size={16} />
          <S.SuggestedChange>
            <Paragraph size="SM">
              Before
            </Paragraph>
            <Paragraph size="SM" $color="fgPrimary">
              {criteriaImprovementToReplace}
            </Paragraph>
          </S.SuggestedChange>
          <Spacer $size={8} />
          <S.SuggestedChange>
            <Flex $align="center" $gap={4} $height="fit-content">
              <Paragraph size="SM">
                After
              </Paragraph>
              <Tooltip
                trigger={
                  <span>
                    <Icon name={Icons.info} size={12} color="fgSecondary" />
                  </span>
                }
                $maxWidth={400}
              >
                {criteriaImprovementExplanation}
              </Tooltip>
            </Flex>
            <Paragraph size="SM" $color="fgPrimary">
              {criteriaImprovement}
            </Paragraph>
          </S.SuggestedChange>
          <Spacer $size={24} />
          <Flex $align='center' $justify='space-between'>
            <Button
              $variant='fill'
              $colorTheme='tint'
              $height={32}
              $fontSize={14}
              onClick={acceptAiInstructionsSuggestedChanges}
            >
              Accept Suggestions
            </Button>
            <Button
              $variant='outline'
              $colorTheme='muted'
              $height={32}
              $fontSize={14}
              leadingIcon={Icons.megaphone}
              onClick={() => {
                openDialog({ id: DialogId.REFINEMENT_FEEDBACK })
              }}
            >
              Send Feedback
            </Button>
          </Flex>
        </S.SuggestedChangesBox>
      )
    } else if (criteriaImprovementStatus === JobSearchRefinementCriteriaImprovementStatus.PENDING) {
      return (
        <S.SuggestedChangesBox>
          <S.GettingSuggestedChanges>
            <Spinner size={16} />
            <Paragraph size="SM" $color="fgPrimary">
              Getting suggested changes...
            </Paragraph>
          </S.GettingSuggestedChanges>
        </S.SuggestedChangesBox>
      )
    }
    return (
      <>
        <When condition={showLocationCriteriaUpdateForm}>
          <Flex $direction="column" $gap={12} $width="100%" $align={$contentAlign}>
            {formheading && (
              <Caption size="SM" $color="fgPrimary" as="h5">
                {formheading}
              </Caption>
            )}
            <S.CriteriaBox>
              <Flex $align="flex-start" $width="auto">
                <Paragraph size="SM" $color="fgPrimary">
                  Location
                </Paragraph>
              </Flex>
              <Flex $align="center" $gap={8}>
                <Icon name={Icons.arrowRight} size={12} color="fgSecondary" />
                {radiusInMilesDropdownItems.length > 1
                  ? (
                      <Dropdown
                        $minWidth="12rem"
                        side="bottom"
                        trigger={
                        <Button $variant="ghost" $height={24} trailingIcon={Icons.chevronDownSmall}>
                          {selectedRadiusInMiles ? `+${selectedRadiusInMiles} miles` : '+ miles'}
                        </Button>
                      }
                        items={radiusInMilesDropdownItems}
                      />
                    )
                  : (
                      <Paragraph size="SM" $color="fgPrimary">
                        {selectedRadiusInMiles ? `+${selectedRadiusInMiles} miles` : '+ miles'}
                      </Paragraph>
                    )
                }
              </Flex>
              <Button
                $variant="fill"
                $colorTheme="tint"
                $height={24}
                $fontSize={12}
                $width={72}
                $align="center"
                onClick={handleLocationUpdate}
              >
                Update
              </Button>
            </S.CriteriaBox>
          </Flex>
        </When>
        <When condition={showDegreeRequirementsUpdateForm}>
          <S.CriteriaBox>
            <Flex $align="flex-start" $width="auto">
              <Paragraph size="SM" $color="fgPrimary">
                Remove Degree Requirements
              </Paragraph>
            </Flex>
            <Flex />
            <Button
              $variant="fill"
              $colorTheme="tint"
              $height={24}
              $fontSize={12}
              $width={72}
              $align="center"
              onClick={handleDegreeRequirementsUpdate}
            >
              Remove
            </Button>
          </S.CriteriaBox>
        </When>
      </>
    )
  }, [$contentAlign, acceptAiInstructionsSuggestedChanges, criteriaImprovement, criteriaImprovementExplanation, criteriaImprovementStatus, criteriaImprovementToReplace, formheading, handleDegreeRequirementsUpdate, handleLocationUpdate, openDialog, radiusInMilesDropdownItems, selectedRadiusInMiles, showDegreeRequirementsUpdateForm, showLocationCriteriaUpdateForm])

  return (
    <S.Wrapper $columns={pin ? '40px 1fr' : '1fr'} $padding={$padding}>
      {pin && (
        <S.Icon>
          <LogoSymbol variant="normal" width={40} height={40} />
        </S.Icon>
      )}
      <S.Content>
        <Flex $width="100%" $direction="column" $gap={2} $align={$contentAlign}>
          <Caption as="h4" size="MD" $color="fgPrimary">{heading}</Caption>
          {subheading && <Paragraph size="SM" $color="fgSecondary">{subheading}</Paragraph>}
        </Flex>
        <S.CriteriaUpdateSuggestions>
          {suggestionChanges}
        </S.CriteriaUpdateSuggestions>
      </S.Content>
    </S.Wrapper>
  )
}
