import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import { Caption } from 'src/components/primitives/typography'
import { VisualDivider } from 'src/components/primitives/visual-divider'
import { EmailSequenceSvg } from 'src/components/primitives/svgs'
import { When } from 'src/components/blocks/when'
import * as S from './sequence-options.styled'
import { useParams } from 'react-router-dom'
import { useJobByDepartmentsQuery } from 'src/hooks/queries/use-jobs-by-department'
import { useMemo } from 'react'
import { JobTile } from 'src/components/blocks/job-tile'
import { Spacer } from 'src/components/primitives/spacer'
import type { JobAndDepartment } from 'src/libs/api/backend/jobs'

interface SequenceOptionsProps {
  onSelectGenerationType: (type: 'MANUAL' | 'AI', existingJobId?: string | null) => void
  onSkip: () => void
}

export const SequenceOptions = ({ onSelectGenerationType, onSkip }: SequenceOptionsProps): JSX.Element => {
  const { jobId } = useParams()
  const { isPending, data: jobs } = useJobByDepartmentsQuery()

  const flattenJobs = useMemo(() => {
    if (!jobs) {
      return []
    }
    const flattenedMap = Object.values(jobs).reduce<JobAndDepartment[]>((departmentAcc, department) => {
      const projectJobs = Object.values(department).reduce<JobAndDepartment[]>((projectAcc, project) => {
        return [...projectAcc, ...Object.values(project)]
      }, [])
      return [...departmentAcc, ...projectJobs]
    }, [])

    return flattenedMap.filter((job) => job.id !== jobId) // Remove current job from the list
  }, [jobId, jobs])

  return (
    <S.SequenceOptions>
      <S.Content>
        <S.ContentInner>
          <Flex $direction="column" $gap={40}>
            <Caption size="LG">Create outreach sequence</Caption>
            <Flex $direction="column" $gap={16}>
              <Flex $align="center" $gap={16}>
                <Button
                  $variant="fill"
                  $colorTheme="tint"
                  $width="full"
                  $height={40}
                  $align="center"
                  leadingIcon="sparkles-solid"
                  onClick={() => {
                    onSelectGenerationType('AI')
                  }}
                >
                  Generate with AI
                </Button>
                <Button
                  $variant="raised"
                  $colorTheme="tint"
                  $width="full"
                  $height={40}
                  $align="center"
                  leadingIcon="pencil"
                  onClick={() => {
                    onSelectGenerationType('MANUAL')
                  }}
                >
                  Write manually
                </Button>
              </Flex>
              <Flex>
                <Button
                  $variant="outline"
                  $colorTheme="normal"
                  $width="full"
                  $height={40}
                  $align="center"
                  onClick={() => {
                    onSkip()
                  }}
                >
                  Skip for now
                </Button>
              </Flex>
            </Flex>
            <When condition={!isPending && flattenJobs.length > 0}>
              <VisualDivider $fill>Or</VisualDivider>
              <Flex $direction="column" $gap={16}>
                <Caption size="SM" $color="fgSecondary">
                  Copy from previous job position
                </Caption>
                <Flex $direction="column" $gap={4}>
                  {flattenJobs.map((job) => (
                    <JobTile
                      id={job.id}
                      key={job.id}
                      job={job}
                      isActive={false}
                      title={job.title}
                      onClick={() => {
                        onSelectGenerationType('MANUAL', job.id)
                      }}
                    />
                  ))}
                  <Spacer $size={40} />
                </Flex>
              </Flex>
            </When>
          </Flex>
        </S.ContentInner>
      </S.Content>
      <S.Sidebar>
        <EmailSequenceSvg />
      </S.Sidebar>
    </S.SequenceOptions>
  )
}
