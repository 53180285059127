import { useMutation, useQueryClient } from '@tanstack/react-query'
import { bulkReassignSequenceSender } from 'src/libs/api/backend/sequences'
import { queryKeys } from 'src/libs/query-keys'
import { useParams } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  originalAccountId: string
  updatedAccountId: string
  updatedEmailAddress: string
  onSuccess?: () => void
}

interface Res {
  reassignSequenceSender: (args: Args) => void
}

export const useBulkReassignSequenceSender = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const queryClient = useQueryClient()
  const { jobId } = useParams()

  const mutation = useMutation({
    mutationFn: async ({ originalAccountId, updatedAccountId }: Args) => {
      await bulkReassignSequenceSender({ originalAccountId, updatedAccountId })
    },
    onError: (error) => {
      console.error(error)
      notifyError({
        message: `An error occurred while reassigning senders: ${error.message}`
      })
    },
    onSuccess: async (_, variables) => {
      notifySuccess({
        message: `Sequence sender email address updated to ${variables.updatedEmailAddress}`
      })

      if (variables.onSuccess) {
        variables.onSuccess()
      }

      await queryClient.invalidateQueries({ queryKey: [queryKeys.jobSequence, jobId] })
      await queryClient.invalidateQueries({ queryKey: [queryKeys.session] })
    }
  })

  const reassignSequenceSender = (args: Args): void => {
    mutation.mutate(args)
  }

  return { reassignSequenceSender }
}
