import { useEffect, useState } from 'react'
import RouteBuilder from 'src/libs/route-builder'
import { SessionNotLoggedInError } from 'src/libs/api/backend/session'
import type { OnboardingStatus } from 'src/libs/api/backend/session'
import { useSession } from './queries/use-session'
import { isNil } from 'lodash'
import { useLocalStorage, useWindowSize } from 'usehooks-ts'
import { LocalStorageKey } from 'src/constants'
import { useJobsQuery } from './queries/use-jobs'
import { useSearchParams } from 'react-router-dom'
import { TrackingEventType } from 'src/types/track'
import { trackEvent } from 'src/libs/track'
import { useAtomValue } from 'jotai'
import { lastViewedJobAtom } from 'src/stores/job'

export const useLoginRedirectPath = (): { isLoading: boolean, redirectPath: string | null, jobId: string | null } => {
  const [isLoading, setIsLoading] = useState(true)
  const [redirectPath, setRedirectPath] = useState<string | null>(null)
  const { data: session, error: sessionError, isLoading: isSessionLoading } = useSession()
  const { data: jobs, isLoading: isJobsLoading } = useJobsQuery({
    enabled: !isNil(session) && !isNil(session.org)
  })
  const lastViewedJobId = useAtomValue<string | null>(lastViewedJobAtom)
  const [_hasLoggedIn, setHasLoggedIn] = useLocalStorage<boolean>(LocalStorageKey.HAS_LOGGED_IN, false)
  const [searchParams] = useSearchParams()
  const [jobId, setJobId] = useState<string | null>(null)
  const { width } = useWindowSize()

  const getOnboardingPath = (status: OnboardingStatus): string => {
    switch (status) {
      case 'ORG': {
        return RouteBuilder.build('ONBOARDING_LOCATION_NOTICE')
      }
      case 'ORG_JOIN': {
        return RouteBuilder.build('ONBOARDING_JOIN_TEAM')
      }
      case 'JOB': {
        return RouteBuilder.build('ONBOARDING_JOB_POSITION')
      }
      default: {
        return RouteBuilder.build('ONBOARDING_COMPANY_PROFILE')
      }
    }
  }

  useEffect(() => {
    if (sessionError instanceof SessionNotLoggedInError) {
      setRedirectPath(RouteBuilder.build('LOGIN'))
      setIsLoading(false)
      return
    }

    if (isSessionLoading || isJobsLoading) {
      return
    }

    if (isNil(session)) {
      return
    }

    setHasLoggedIn(true)
    trackEvent(TrackingEventType.LOGGED_IN)

    const { onboarding: onboardingStatus } = session
    if (onboardingStatus === 'COMPLETE') {
      if (isNil(jobs) || jobs.length === 0) {
        setRedirectPath(RouteBuilder.build('ONBOARDING_JOB_POSITION'))
        setIsLoading(false)
        return
      }

      const firstNonArchivedJob = jobs?.find(job => !job.deleted)
      let jobId = firstNonArchivedJob?.id ?? jobs?.[0]?.id
      const jobIds = jobs.map(job => job.id)
      if (lastViewedJobId && jobIds.includes(lastViewedJobId)) {
        jobId = lastViewedJobId
      }

      setJobId(jobId)
      trackEvent(TrackingEventType.COMPLETED_ONBOARDING)
      setRedirectPath(RouteBuilder.build('JOBS_CANDIDATES', { jobId }, { ...searchParams }))
      setIsLoading(false)
      return
    }
    if (width <= 1024) {
      setRedirectPath(RouteBuilder.build('ONBOARDING_WELCOME'))
      trackEvent(TrackingEventType.STARTED_ONBOARDING_ON_MOBILE)
    } else {
      setRedirectPath(getOnboardingPath(onboardingStatus))
    }
    setIsLoading(false)
  }, [session, sessionError, lastViewedJobId, jobs, isSessionLoading, isJobsLoading, setHasLoggedIn, searchParams, width])

  return { isLoading, redirectPath, jobId }
}
