import { updateProductTourStatus as updateProductTourStatusHandler } from 'src/libs/api/backend/me'
import type { ProductTourStatus } from 'src/libs/api/backend/me'
import { useMutation } from '@tanstack/react-query'
import { useSetSessionQueryCache } from '../use-session'

interface MutateArgs {
  status: ProductTourStatus
}

interface Res {
  updateProductTourStatus: ({ status }: MutateArgs) => void
}

export const useUpdateProductTourStatus = (): Res => {
  const setSessionQueryCache = useSetSessionQueryCache()

  const mutation = useMutation({
    mutationFn: async ({ status }: MutateArgs) => {
      await updateProductTourStatusHandler(status)
    },
    onSuccess: (_, { status }) => {
      setSessionQueryCache((old) => ({
        ...old,
        user: {
          ...old.user,
          productTourStatus: status
        }
      }))
    }
  })

  const updateProductTourStatus = (args: MutateArgs): void => {
    mutation.mutate(args)
  }

  return { updateProductTourStatus }
}
