import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { useUndoStageTransition } from 'src/hooks/mutations/use-undo-stage-transition'
import { Flex } from '../flex'
import { Caption } from '../typography'
import * as S from './candidate-step.styled'
import { Avatar } from '../avatar'
import type { Color } from 'src/styles/theme/types'
import { Button } from '../button'
import type { NotificationType } from 'src/stores/notifications'

interface CandidateStepProps {
  candidateJob: CandidateJobExpanded
  type: NotificationType
  closeSelf: () => void
}

const getContent = (type: NotificationType): { textColor: Color, stepDescription: string } => {
  switch (type) {
    case 'candidate-rejected': {
      return {
        textColor: 'negativeBg',
        stepDescription: 'Archived'
      }
    }
    case 'candidate-added': {
      return {
        textColor: 'positiveBg',
        stepDescription: 'Added to outreach'
      }
    }
    case 'candidate-favorited': {
      return {
        textColor: 'positiveBg',
        stepDescription: 'Shortlisted'
      }
    }
    default: {
      return {
        textColor: 'inherit',
        stepDescription: ''
      }
    }
  }
}

export const CandidateStep = ({ candidateJob, type, closeSelf }: CandidateStepProps): JSX.Element => {
  const { undoStageTransition } = useUndoStageTransition({})

  function onUndo (candidateJobs: CandidateJobExpanded[]): void {
    undoStageTransition(candidateJobs.map(candidateJob => candidateJob.id))
    closeSelf()
  }

  return (
    <S.CandidateStep>
      <Flex $gap={12} $align="center">
        <Avatar
          $size={24}
          $shape="soft"
          $type="photo"
          initials={candidateJob?.candidate.name}
          photoUrl={candidateJob?.candidate.profilePhotoUrl}
        />
        <Caption size="XS" $whiteSpace="nowrap" $color={getContent(type).textColor}>
          {getContent(type).stepDescription}
        </Caption>
      </Flex>
      <Button
        $variant="outline"
        $colorTheme="muted"
        leadingIcon="undo"
        $height={24}
        $width="auto"
        $fontSize={12}
        onClick={() => { onUndo([candidateJob]) }}
      >
        Undo
      </Button>
    </S.CandidateStep>
  )
}

interface CandidateStepMultipleProps {
  candidateJobs: CandidateJobExpanded[]
  type: NotificationType
  closeSelf: () => void
}
export const CandidateStepMultiple = ({ candidateJobs, type, closeSelf }: CandidateStepMultipleProps): JSX.Element => {
  const { textColor, stepDescription } = getContent(type)
  const caption = `${stepDescription} ${candidateJobs.length} candidates`

  const { undoStageTransition } = useUndoStageTransition({})

  function onUndo (candidateJobs: CandidateJobExpanded[]): void {
    undoStageTransition(candidateJobs.map(candidateJob => candidateJob.id))
    closeSelf()
  }

  return (
    <S.CandidateStep>
      <Flex $gap={12} $align="center">
        <Caption size="XS" $whiteSpace="nowrap" $color={textColor}>
          {caption}
        </Caption>
      </Flex>
      <Button
        $variant="outline"
        $colorTheme="muted"
        leadingIcon="undo"
        $height={24}
        $width="auto"
        $fontSize={12}
        onClick={() => { onUndo(candidateJobs) }}
      >
        Undo
      </Button>
    </S.CandidateStep>
  )
}
