import { Form, Input } from 'src/components/forms'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import { Flex } from 'src/components/primitives'
import { useEffect, useMemo, useState } from 'react'
import { sleep } from 'src/libs/sleep'
import type { Nullable } from 'src/utils/type-utils'
import { createNewPositionFormSchema } from '../create-job-form/types/create-new-position-form'
import type { CreateNewPositionForm } from '../create-job-form/types/create-new-position-form'
import { Select } from 'src/components/forms/select'
import type { SelectItem } from 'src/components/forms/select'
import { useSession } from 'src/hooks/use-session'
import { isNil } from 'lodash'
import { useGetDepartmentQuery } from 'src/hooks/queries/use-departments'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { DepartmentLogo } from '../department-logo'
import { Avatar } from 'src/components/primitives/avatar'
import { useSetAtom } from 'jotai'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useProjectsQuery } from 'src/hooks/queries/use-projects'
import { FormActionButtons } from '../form-action-buttons'

const jobDetailsSchema = z
  .object({
    title: z.string(),
    departmentId: z.string(),
    projectId: z.string().nullish(),
    // country: z.string().nullable().optional(),
    locations: z.array(z.string()).nullable().optional(),
    workspace: z.enum(['REMOTE', 'OFFICE', 'HYBRID']).nullable(),
    description: z.string().nullable().optional(),
    diversitySearch: z.boolean().nullish(),
    salaryMin: z.number(),
    salaryMax: z.number()
  })
  .refine((data) => data.salaryMax > data.salaryMin, {
    message: 'Max salary must be greater than min salary'
  })

export type JobDetailsProps = z.infer<typeof jobDetailsSchema>

const ORG_SELECT_VALUE = '<<org>>'

interface JobDetailsFormProps {
  initialValues?: Partial<CreateNewPositionForm>
  jobData?: Nullable<Partial<CreateNewPositionForm>> | null
  onSubmit?: (data: CreateNewPositionForm) => void
  formIsSubmitting?: boolean
  submitButtonText?: string
  isCreating?: boolean
}

export const JobDetailsForm = ({
  onSubmit,
  formIsSubmitting,
  initialValues,
  jobData,
  submitButtonText = 'Create job position',
  isCreating = false
}: JobDetailsFormProps): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)
  const [isSubmitting, setIsSubmitting] = useState(formIsSubmitting ?? false)
  const { org, featureFlags, orgLogoUrl } = useSession()
  const { departments } = useGetDepartmentQuery()
  const { data: projects } = useProjectsQuery()

  const { submit, errors, register, setValue, setValues, formData } =
    useForm<CreateNewPositionForm>({
      schema: createNewPositionFormSchema,
      initialValues: {
        ...initialValues,
        departmentId: ORG_SELECT_VALUE
      }
    })

  useEffect(() => {
    const setData = async (): Promise<void> => {
      if (jobData) {
        setValues({
          ...jobData,
          departmentId: jobData.departmentId ?? ORG_SELECT_VALUE,
          projectId: jobData.projectId ?? null
        })
      }
    }
    void setData()
  }, [jobData, org?.id])

  const handleSubmit = async (formData: CreateNewPositionForm): Promise<void> => {
    setIsSubmitting(true)
    if (onSubmit) {
      await sleep(300)
      onSubmit({
        ...formData,
        departmentId: formData.departmentId === ORG_SELECT_VALUE ? null : formData.departmentId
      })
      setIsSubmitting(false)
    }
  }

  const departmentItems = useMemo((): SelectItem[] => {
    if (isNil(org)) {
      return []
    }

    const orgListItem = {
      value: ORG_SELECT_VALUE,
      title: `${org.name} • ${org.domain}`,
      image: <Avatar
        $type="logo"
        $size={16}
        $border={false}
        photoUrl={orgLogoUrl}
        initials={org.name}
      />
    }
    const deptsListItems = departments?.filter((department) => !department.deleted).map((dept) => ({
      value: dept.id,
      title: `${dept.name} • ${dept.domain}`,
      image: <DepartmentLogo $size={16} departmentId={dept.id} />
    })) ?? []

    return [orgListItem, ...deptsListItems]
  }, [org, departments, orgLogoUrl])

  const projectSelectBoxItems = useMemo((): SelectItem[] => {
    if (!projects) {
      return []
    }
    return projects
      .filter(project => {
        if (project.deleted) {
          return false
        }
        if (formData.departmentId === ORG_SELECT_VALUE) {
          // If org is selected
          return isNil(project.departmentId) && project.orgId === org?.id
        } else {
          // If a department is selected
          return !isNil(project.departmentId) && project.departmentId === formData.departmentId
        }
      })
      .map((project) => {
        return {
          value: project.id,
          title: project.name
        }
      })
  }, [projects, formData.departmentId, org?.id])

  return (
    <Form onSubmit={submit(handleSubmit)} errors={errors}>
      <Input
        name="title"
        placeholder="e.g. Software Engineer"
        label="Position title"
        register={register}
      />
      <Flex $align="center" $gap={24}>
        <Select
          name="departmentId"
          label="Hiring for"
          placeholder="Select an organization"
          defaultValue={formData.departmentId as string}
          items={departmentItems}
          $maxHeight={406}
          createItem={{
            value: 'new-company',
            title: 'New company',
            onClick: () => {
              openDialog({
                id: DialogId.CREATE_DEPARTMENT,
                payload: {
                  setDepartmentId: (deptId: string): void => {
                    setValue('departmentId', deptId)
                  }
                }
              })
            }
          }}
          createItemIsSticky={departmentItems?.length >= 8}
          register={register}
        />
        {
          featureFlags?.includes(FeatureFlags.PROJECTS) && (
            <Select
              name="projectId"
              label="Project"
              placeholder="Optional"
              items={projectSelectBoxItems}
              defaultValue={formData.projectId as string}
              createItem={{
                value: 'new-project',
                title: 'New Project',
                onClick: () => {
                  openDialog({
                    id: DialogId.CREATE_PROJECT,
                    payload: formData?.departmentId === ORG_SELECT_VALUE ? undefined : formData?.departmentId
                  })
                }
              }}
              onReset={() => { setValue('projectId', null) }}
              showIcons={false}
              emptyStateText="You don't have any projects for this department yet"
              register={register}
            />
          )
        }
      </Flex>
      <FormActionButtons
        disabled={!formData.title || (isCreating && !formData.description)}
        saveText={submitButtonText}
        isSaving={isSubmitting}
      />
    </Form>
  )
}
