import styled from 'styled-components'
import type { FlexStyleProps } from './flex'

export const Flex = styled.div<FlexStyleProps>`
  width: ${({ $width }) => $width};
  max-width: ${({ $maxWidth }) => $maxWidth};
  height: ${({ $height }) => ($height === 'full' ? '100%' : $height)};
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  flex: ${({ $flex }) => $flex};
  align-items: ${({ $align }) => $align};
  justify-content: ${({ $justify }) => $justify};
  flex: ${({ $flex }) => $flex};
  gap: ${({ $gap, theme }) => theme.spacing[$gap ?? 0]};
  flex-wrap: ${({ $wrap }) => $wrap};
`
