import { useMemo } from 'react'
import { closeDialogAtom, controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import * as Dialog from 'src/components/primitives/dialog'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { Button } from 'src/components/primitives/button'
import { productTour } from 'src/libs/product-tour'
import * as S from './welcome-to-pin-dialog.styled'
import { BookADemoButton } from 'src/components/blocks/book-a-demo-button'
import { trackEvent } from 'src/libs/track'
import { TrackingEventType } from 'src/types/track'
import { useUpdateProductTourStatus } from 'src/hooks/mutations/use-update-product-tour-status'
import { ProductTourStatus } from 'src/libs/api/backend/me'

export const WelcomeToPinDialog = (): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.WELCOME_TO_PIN), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const closeDialog = useSetAtom(closeDialogAtom)
  const { updateProductTourStatus } = useUpdateProductTourStatus()

  return (
    <Dialog.Root
      id={DialogId.WELCOME_TO_PIN}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.WELCOME_TO_PIN, newState: value })
      }}
      $maxWidth="640px"
    >
      <Dialog.Portal>
        <Dialog.Content>
          <S.Content>
            <S.Preview>
              <img src="/onboarding/product-tour.jpg" alt="Welcome to Pin" />
            </S.Preview>
            <Flex $direction="column" $gap={8}>
              <Caption size="LG">Welcome to Pin</Caption>
              <Paragraph size="SM" $color="fgSecondary" $lineHeight={1.5}>
                Hit the ground running with Pin! Let us show you around and help you get the most out
                of our platform. In just a few steps, you'll learn the essential features that will
                make your recruiting workflow more efficient.
              </Paragraph>
            </Flex>
            <S.Footer>
              <Flex $align="center" $gap={8}>
                <Button
                  $variant="fill"
                  $colorTheme="tint"
                  $height={40}
                  onClick={() => {
                    closeDialog(DialogId.WELCOME_TO_PIN)
                    productTour.drive()
                    updateProductTourStatus({ status: ProductTourStatus.STARTED })
                    trackEvent(TrackingEventType.PRODUCT_TOUR_STARTED)
                  }}
                >
                  Show Me Around
                </Button>
                <Button
                  $variant="ghost"
                  $colorTheme="normal"
                  $height={40}
                  onClick={() => {
                    closeDialog(DialogId.WELCOME_TO_PIN)
                    updateProductTourStatus({ status: ProductTourStatus.SKIPPED })
                    trackEvent(TrackingEventType.PRODUCT_TOUR_SKIPPED)
                  }}
                >
                  Skip
                </Button>
              </Flex>
              <BookADemoButton $variant="outline" />
            </S.Footer>
          </S.Content>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
