import { useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import * as Dialog from 'src/components/primitives/dialog'
import { waitForNetworkIdleWithTimeout } from 'src/utils/network-idle'
import CONFIG from 'src/config'
import { Button, Flex } from 'src/components/primitives'
import * as S from './download-pdf.styled'
import { Spinner } from 'src/components/primitives/spinner'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useJobQuery } from 'src/hooks/queries/use-job'

export interface DownloadPdfDialogProps {
  exportToPdfPrintUrl: string
}

export const DownloadPdfDialog = ({ exportToPdfPrintUrl }: DownloadPdfDialogProps): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.DOWNLOAD_PDF), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const { data: job } = useJobQuery()

  const [exporting, setExporting] = useState(true)
  const [iframe, setIframe] = useState<HTMLIFrameElement | null>(null)

  const fileName = useMemo(() => {
    return `${job?.title}.pdf`
  }, [job?.title])

  const onLoadIFrame = useCallback(async (iframe: HTMLIFrameElement): Promise<void> => {
    const closePrint = (): void => {
      document.body.removeChild(iframe)
    }
    if (iframe.contentWindow) {
      iframe.contentWindow.onbeforeunload = closePrint
      iframe.contentWindow.onafterprint = closePrint

      try {
        // First wait for document to be ready
        await new Promise<void>((resolve) => {
          const checkReady = (): void => {
            if (iframe.contentDocument?.readyState === 'complete') {
              resolve()
            } else {
              setTimeout(checkReady, 500)
            }
          }
          checkReady()
        })

        // Then wait for network to be idle
        await waitForNetworkIdleWithTimeout(iframe)
      } catch (error) {
        console.error('Error loading iframe:', error)
      }
    }
    setExporting(false)
    setIframe(iframe)
  }, [])

  useEffect(() => {
    const hideFrame = document.createElement('iframe')
    hideFrame.onload = () => {
      void onLoadIFrame(hideFrame)
    }
    hideFrame.style.display = 'none'
    hideFrame.src = `${CONFIG.APP_DOMAIN}${exportToPdfPrintUrl}`
    document.body.appendChild(hideFrame)
  }, [exportToPdfPrintUrl, onLoadIFrame])

  const triggerIframeDownload = useCallback(() => {
    if (iframe) {
      if (iframe.contentDocument) {
        iframe.contentDocument.title = fileName
      }
      iframe.contentWindow?.print()
      controlDialog({ id: DialogId.DOWNLOAD_PDF, newState: false })
    }
  }, [controlDialog, fileName, iframe])

  return (
    <Dialog.Root
      id={DialogId.DOWNLOAD_PDF}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.DOWNLOAD_PDF, newState: value })
      }}
      $width="640px"
      $maxWidth="640px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Export Candidates List"
          onClose={() => {
            controlDialog({ id: DialogId.DOWNLOAD_PDF, newState: false })
          }}
        />
        <Dialog.Content>
          <Flex $direction="column" $gap={24}>
            <Flex $gap={16} $align="center">
              <S.IconBlock>
                {exporting
                  ? <Spinner />
                  : <Icon name={Icons.file} size={24} />
                }
                {!exporting &&
                  <S.CheckmarkBlock>
                    <Icon name={Icons.filledCircleCheck} size={16} />
                  </S.CheckmarkBlock>
                }
              </S.IconBlock>
              <Flex $direction="column" $gap={4}>
                <Caption size="SM">{exporting ? 'Generating PDF…' : fileName}</Caption>
                {exporting
                  ? <Paragraph size="SM" $color="fgSecondary">This can take a minute.</Paragraph>
                  : <Paragraph size="SM" $color="fgPositive">Ready to Download</Paragraph>
                }
              </Flex>
            </Flex>
            <Flex $align="center" $gap={16}>
              <Button
                $variant="fill"
                $colorTheme="tint"
                $height={40}
                $fontSize={14}
                disabled={!iframe || exporting}
                onClick={triggerIframeDownload}
              >
                Download PDF
              </Button>
              <Button
                $variant="outline"
                $colorTheme="muted"
                $height={40}
                $fontSize={14}
                onClick={() => {
                  controlDialog({ id: DialogId.DOWNLOAD_PDF, newState: false })
                }}
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
