import { useCallback, useEffect, useRef, useState } from 'react'
import * as S from './tabs.styled'

export interface TabOption {
  leadingIcon?: React.ReactNode
  label: string
  value: string
  onClick: () => void
  disabled?: boolean
}

interface TabsProps {
  selectedTab: string
  options: TabOption[]
}

export const Tabs = ({ selectedTab, options }: TabsProps): JSX.Element => {
  const [indicatorStyle, setIndicatorStyle] = useState({ width: 0, left: 0 })
  const tabRefs = useRef<Array<HTMLButtonElement | null>>([])

  const updateIndicator = useCallback((index: number): void => {
    const currentTab = tabRefs.current[index]
    if (currentTab) {
      const { width, left } = currentTab.getBoundingClientRect()
      const containerLeft = tabRefs.current[0]?.getBoundingClientRect().left ?? 0
      setIndicatorStyle({
        width,
        left: left - containerLeft
      })
    }
  }, [])

  useEffect(() => {
    updateIndicator(options.findIndex((option) => option.value === selectedTab))
  }, [options, selectedTab, updateIndicator])

  return (
    <S.TabsGroup>
      {options.map((option, index) => (
        <S.TabButton
          key={option.value}
          ref={(el) => {
            if (el) {
              tabRefs.current[index] = el
            }
          }}
          aria-label={option.label}
          aria-selected={selectedTab === option.value}
          onClick={option.onClick}
          $isActive={selectedTab === option.value}
          disabled={option.disabled}
          $isDisabled={!!option.disabled}
        >
          {option.leadingIcon && <S.TabIcon>{option.leadingIcon}</S.TabIcon>}
          {option.label}
        </S.TabButton>
      ))}

      <S.TabIndicator $width={indicatorStyle.width} $left={indicatorStyle.left} />
    </S.TabsGroup>
  )
}
