import { ReactNodeViewRenderer } from '@tiptap/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { VariableComponent } from './variable-component'
import { COMPONENT_TAG } from './variable-parser'

const VariableComponentExtension = Node.create({
  name: 'variableComponent',
  content: 'inline*',
  group: 'inline',
  inline: true,
  atom: true,
  selectable: false,

  addAttributes () {
    return {
      variable: {
        default: ''
      },
      aiinstructions: {
        default: null
      }
    }
  },

  parseHTML () {
    return [
      {
        tag: COMPONENT_TAG
      }
    ]
  },

  renderHTML ({ HTMLAttributes }) {
    return [COMPONENT_TAG, mergeAttributes(HTMLAttributes), 0]
  },

  addNodeView () {
    return ReactNodeViewRenderer(VariableComponent, { contentDOMElementTag: 'span' })
  }
})

export default VariableComponentExtension
