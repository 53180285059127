import styled from 'styled-components'
import * as RadixDropdown from '@radix-ui/react-dropdown-menu'
import { CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'

export const Trigger = styled(RadixDropdown.Trigger)<{ $fullWidth?: boolean }>`
  position: relative;
  border: none;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  cursor: pointer;
  user-select: none;
  &[data-state='open'] {
    box-shadow: ${({ theme }) => theme.boxShadows['light-active']};
    border-radius: 4px;
  }
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  opacity: 0.5;
  z-index: 100;
`

export const DropdownContent = styled(RadixDropdown.Content)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: 0px 2px 8px 0px rgba(115, 74, 231, 0.10), 0px 6px 24px 0px rgba(115, 74, 231, 0.10);
  border-radius: ${({ theme }) => theme.spacing[8]};
  width: 100vw;
  max-width: ${CANDIDATES_PAGES_MAX_WIDTH};
  overflow: hidden;
  z-index: ${({ theme }) => theme.zindeces.dialog};
`

export const NumberOfFilters = styled.div`
  height: ${({ theme }) => theme.spacing[16]};
  color: ${({ theme }) => theme.colors.tintContrast};
  background-color: ${({ theme }) => theme.colors.aiSolidBg};
  border-radius: ${({ theme }) => theme.spacing[2]};
  padding: ${({ theme }) => `0 ${theme.spacing[4]}`};
  font-size: ${({ theme }) => theme.fontSizes[10]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: ${({ theme }) => theme.spacing[6]};
  padding: ${({ theme }) => theme.spacing[12]};
`
