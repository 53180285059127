import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { advanceJobInterviewStageApi } from 'src/libs/api/backend/applicants'
import type { MergeInterviewStage } from 'src/libs/api/backend/applicants'
import type { CandidateJob } from 'src/libs/api/backend/candidate_jobs'
import type { Job } from 'src/libs/api/backend/jobs'
import { notifyErrorAtom } from 'src/stores/notifications'
import queryClient from '../query-client'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  jobId: Job['id']
  candidateJobIds: Array<CandidateJob['id']>
  destinationJobInterviewStageId: MergeInterviewStage['id']
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  advanceJobInterviewStage: (args: Args) => void
}

export const useAdvanceJobInterviewStage = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ jobId, candidateJobIds, destinationJobInterviewStageId }: Args) => {
      await advanceJobInterviewStageApi(jobId, candidateJobIds, destinationJobInterviewStageId)
    },
    onError: (err, variables) => {
      notifyError({
        message: err.message
      })
      variables.onError?.()
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, variables.jobId]
      })
      variables.onSuccess?.()
    }
  })

  const advanceJobInterviewStage = (args: Args): void => {
    mutation.mutate(args)
  }

  return { advanceJobInterviewStage }
}
