import { CONTENT_PADDING } from 'src/styles/constants'
import styled from 'styled-components'

export const Banner = styled.div<{ $isEnabled: boolean, $maxWidth: string }>`
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  padding: 0.75rem 1.25rem;
  border-radius: 6px;
  background: ${({ theme, $isEnabled }) =>
    $isEnabled ? theme.backgrounds.aiGradientFaded : theme.colors.bgTertiary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  max-width: ${({ $maxWidth }) => $maxWidth};
  max-height: 5rem;
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  margin-left: ${CONTENT_PADDING};
`

export const ToggleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  gap: 6px;
  padding: 4px 4px;
  border-radius: 4px;
  background: ${({ theme }) => theme.backgrounds.aiGradientFaded};
  cursor: pointer;
  svg {
    stroke: #8269c8;
  }
  p {
    background: ${({ theme }) => theme.backgrounds.aiGradientVivid};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const Arrow = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 205px;
  top: -6px;
  clip-path: polygon(0 0, 100% 0, 100% 7px, 0 7px);
  transform: translateY(-1px);
  /* border: solid 1px red; */
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ArrowShape = styled.div`
  width: 14px;
  height: 14px;
  transform: rotate(-45deg);
  background: ${({ theme }) => theme.backgrounds.aiGradientFaded};
`

export const ToggleButton = styled.button<{ $isChecked: boolean }>`
  all: unset;
  position: relative;
  flex: 0 0 26px;
  width: 26px;
  height: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background: ${({ theme }) => theme.backgrounds.aiGradientPastel};
  cursor: pointer;
  span {
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.tintContrast};
  }
  &:focus {
    &:after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border: solid 1px;
      border-color: ${({ theme }) => theme.colors.tintBg};
      border-radius: 3px;
      pointer-events: none;
      z-index: 2;
    }
  }
`
