import { Checkbox } from 'src/components/primitives/checkbox'
import type { IconName } from 'src/components/primitives/icon'
import type { CriteriaKey } from 'src/libs/api/backend/candidate_search'

interface CheckboxFieldProps {
  criteriaKey: CriteriaKey
  criteriaValue: boolean
  label: string
  onUpdate: (value: boolean) => void
  disabled: boolean
  tooltip?: {
    icon: IconName
    text: string
    position?: 'top' | 'bottom' | 'left' | 'right'
  }
}

export const CheckboxField = ({
  criteriaKey,
  criteriaValue,
  label,
  onUpdate,
  disabled,
  tooltip
}: CheckboxFieldProps): JSX.Element => {
  return (
    <Checkbox
      checked={criteriaValue}
      onCheckedChange={onUpdate}
      label={label}
      name={criteriaKey}
      $variant='plain'
      disabled={disabled}
      tooltip={tooltip}
    />
  )
}
