import { useEffect, useMemo, useRef } from 'react'
import { isNil, debounce } from 'lodash'

import { FormElement } from '../form-element'
import { FieldLabel } from '../field-label'
import { FieldError } from '../field-error'
import type { FieldCommonProps } from '../common'
import type { FieldValue } from 'src/hooks/use-form'
import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import * as S from './input.styled'
import { formatPhoneNumber } from 'src/utils/format-phone-number'

export type Variant = 'normal' | 'flat' | 'naked'
export type Size = 'normal' | 'small' | 'xsmall'
export type InputFieldType = 'text' | 'number' | 'email' | 'url' | 'password' | 'hidden' | 'tel'

interface Props extends FieldCommonProps {
  type?: InputFieldType
  variant?: Variant
  size?: Size
  icon?: IconName
  leadingNote?: string
  trailingNote?: string
  trailingAction?: React.ReactNode
  max?: number
  min?: number
  maxLength?: number
  onBlurEvent?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  isLoading?: boolean
  isDisabled?: boolean
  setFocus?: boolean
  debounceCallback?: (value?: FieldValue) => Promise<void>
  autoGrow?: boolean
  $height?: number
  $padding?: string
}

const DEBOUNCE_DELAY = 300

export const Input = ({
  type = 'text',
  name,
  label,
  description,
  ariaLabel,
  placeholder,
  register,
  icon,
  leadingNote,
  trailingNote,
  trailingAction,
  isLoading = false,
  isDisabled = false,
  hiddenLabel = false,
  setFocus = false,
  variant = 'normal',
  size = 'normal',
  max,
  min,
  maxLength,
  autoGrow = false,
  $marginBottom = 16,
  $width,
  $height,
  $padding,
  onBlurEvent,
  debounceCallback
}: Props): JSX.Element => {
  const inputEl = useRef<HTMLInputElement>(null)
  const { value, onChange, onBlur, error } = register(name)

  const debounceFunc = debounce(async () => {
    if (!isNil(debounceCallback) && !isNil(value)) {
      await debounceCallback(value)
    }
  }, DEBOUNCE_DELAY)

  // `debounceFunc` needs to be wrapped with `useRef`, because otherwise,
  // a new version of `debounceFunc` would be created after each re-render
  const debounceFuncRef = useRef(debounceFunc).current

  useEffect(() => {
    if (setFocus) {
      setTimeout(() => {
        if (inputEl.current) {
          inputEl.current.focus()
        }
      }, 100)
    }
  }, [setFocus])

  useEffect(() => {
    if (autoGrow && inputEl.current) {
      const input = inputEl.current
      const handleAutoGrow = (): void => {
        if (input) {
          input.style.width = '10px'
          input.style.width = `${input.scrollWidth}px`
        }
      }
      handleAutoGrow()
      input.addEventListener('input', handleAutoGrow)
      return () => {
        input.removeEventListener('input', handleAutoGrow)
      }
    }
  }, [autoGrow, value])

  const fieldValue = useMemo(() => {
    return type === 'tel' ? formatPhoneNumber(value as string) : value as string
  }, [type, value])

  return (
    <FormElement $marginBottom={variant === 'naked' ? 0 : $marginBottom} $width={$width}>
      <FieldLabel
        label={label}
        htmlFor={name}
        hiddenLabel={hiddenLabel}
        description={description}
      />
      {icon ?? leadingNote ?? trailingNote ?? trailingAction
        ? <S.InputWithIcon
            $variant={isLoading || isDisabled ? 'flat' : variant}
            $size={size}
            $isLoading={isLoading}
            $isDisabled={isDisabled}
          >
            <S.Leading>
              {icon
                ? (<Icon name={icon} color="fgSecondary" />)
                : leadingNote
                  ? (<p>{leadingNote}</p>)
                  : null}
            </S.Leading>
            <input
              ref={inputEl}
              id={name}
              type={type}
              data-1p-ignore={type !== 'password'}
              name={name}
              aria-label={ariaLabel ?? label}
              value={value as string}
              placeholder={placeholder}
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (debounceCallback) {
                  void debounceFuncRef()
                }

                onChange(event)
              }}
              onBlur={onBlur}
              aria-describedby={`${name}-error`}
              aria-invalid={error ? 'true' : 'false'}
              disabled={isDisabled || isLoading}
            />
            {trailingNote
              ? <S.Trailing>
                  <p>{trailingNote}</p>
                </S.Trailing>
              : null
            }
            {trailingAction && !isLoading
              ? <>{trailingAction}</>
              : null
            }
            {isLoading && (
              <S.LoadingSpinner>
                <Icon name="loader" size={16} />
              </S.LoadingSpinner>
            )}
          </S.InputWithIcon>
        : <S.Input
            ref={inputEl}
            id={name}
            type={type}
            data-1p-ignore={type !== 'password'}
            name={name}
            max={max}
            min={min}
            maxLength={maxLength}
            aria-label={ariaLabel ?? label}
            $variant={isLoading || isDisabled ? 'flat' : variant}
            $size={size}
            height={$height}
            padding={$padding}
            value={fieldValue}
            placeholder={placeholder}
            disabled={isDisabled || isLoading}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (debounceCallback) {
                void debounceFuncRef()
              }

              onChange(event)
            }}
            onBlur={(event) => {
              onBlur()
              if (onBlurEvent) {
                onBlurEvent(event)
              }
            }}
            aria-describedby={`${name}-error`}
            aria-invalid={error ? 'true' : 'false'}
          />
        }
      {error ? <FieldError id={`${name}-error`}>{error}</FieldError> : null}
    </FormElement>
  )
}
