import styled from 'styled-components'

export const RadioSelection = styled.input<{ $isActive: boolean }>`
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.tintBg : theme.colors.bgPrimary};
  border: 0.5px solid;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.tintBg : theme.colors.bgPrimary)};
  min-height: ${({ theme }) => theme.spacing[16]};
  min-width: ${({ theme }) => theme.spacing[16]};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing[24]};
  padding: ${({ theme }) => theme.spacing[24]};
  flex: 1 1 auto;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
`

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing[16]};
  padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[24]}`};
  width: 100%;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  button {
    overflow: hidden;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`
