import { ApplicantCandidatesATS } from 'src/components/blocks/applicant-candidates-ats'
import { SEO } from 'src/components/primitives/seo'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useSession } from 'src/hooks/use-session'
import { ApplicantsComingSoon } from 'src/components/blocks/applicants-coming-soon'

const ApplicantsCandidatesPage = (): JSX.Element => {
  const { featureFlags } = useSession()

  return (
    <>
      <SEO title="Applicants Candidates" />
      {featureFlags?.includes(FeatureFlags.APPLICANTS) ? <ApplicantCandidatesATS /> : <ApplicantsComingSoon />}
    </>
  )
}

export default ApplicantsCandidatesPage
