import type { CriteriaKey, CriteriaValue, LocationCriteria } from 'src/libs/api/backend/candidate_search'
import * as S from './index.styled'
import { CriteriaPill } from './criteria-pill'
import { RemoveCriteria } from './remove-criteria'
import { useMemo, useState } from 'react'
import { useLocationSuggestions } from 'src/hooks/queries/use-location-suggestion'
import { debounce, isNil } from 'lodash'
import { SuggestionInput } from './suggestion-input'
import type { LocationSuggestion } from 'src/libs/api/backend/typeaheads'
import { titleCase } from 'src/libs/string'
import { useGenerateLocationCriteria } from 'src/hooks/mutations/use-generate-location-criteria'
import { useParams } from 'react-router-dom'
import { deduplicateLocationCriteria, getSubtitle } from 'src/utils/refinement-criteria'
import { Spinner } from 'src/components/primitives/spinner'
import { Paragraph } from 'src/components/primitives/typography'
import { CriteriaProperties } from '../constants'

interface LocationRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: LocationCriteria[]
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey) => void
  disabled: boolean
}

export const LocationRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  disabled
}: LocationRowProps): JSX.Element => {
  const { jobId } = useParams()
  const [query, setQuery] = useState<string | undefined>(undefined)
  const setQueryDebounced = useMemo(() => {
    return debounce(setQuery, 300)
  }, [])

  const { isPending, data: locationSuggestions } = useLocationSuggestions({ query })
  const { isPending: isGeneratingLocationCriteria, generateLocationCriteria } = useGenerateLocationCriteria()
  const type = useMemo(() => {
    return CriteriaProperties.get(criteriaKey)?.type ?? 'location'
  }, [criteriaKey])

  const items = useMemo(() => {
    if (criteriaValue.length === 0) {
      return []
    }
    return criteriaValue.map((locationCriteria, index) => {
      const label = [
        locationCriteria.city,
        locationCriteria.metro,
        locationCriteria.state,
        locationCriteria.country
      ].filter(Boolean).join(', ')
      return (
        <CriteriaPill
          key={index}
          value={{
            label: titleCase(label),
            optional: locationCriteria.optional ?? true,
            negative: locationCriteria.negative ?? false,
            subtitle: [
              getSubtitle({
                name: label,
                city: locationCriteria.city,
                metro: locationCriteria.metro,
                state: locationCriteria.state,
                country: locationCriteria.country
              }),
              locationCriteria.radiusInMiles ? `+${locationCriteria.radiusInMiles} mi` : ''
            ].join(' '),
            radiusInMiles: locationCriteria.radiusInMiles ?? null
          }}
          type={type}
          onUpdate={(key: string, value: boolean | number | null) => {
            const updatedCriteriaValue = [...criteriaValue]
            updatedCriteriaValue[index] = {
              ...updatedCriteriaValue[index],
              [key]: value
            }
            onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
          }}
          useRange={!!locationCriteria.city}
          onRemove={() => {
            const updatedCriteriaValue = criteriaValue.filter((_, i) => i !== index)
            onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
          }}
        />
      )
    })
  }, [criteriaKey, criteriaValue, onCriteriaUpdate, type])

  return (
    <S.RowContainer>
      <SuggestionInput
        isLoading={isPending}
        disabled={disabled}
        triggerSearch={setQueryDebounced}
        suggestions={locationSuggestions ?? []}
        suggestionLabel='Locations in United States or Canada'
        onSelect={(suggestion: LocationSuggestion) => {
          onCriteriaUpdate(
            criteriaKey,
            [...criteriaValue, {
              city: suggestion.city ?? undefined,
              metro: suggestion.metro ?? undefined,
              state: suggestion.state ?? undefined,
              country: suggestion.country ?? undefined,
              radiusInMiles: !isNil(suggestion.city) ? 10 : undefined,
              optional: false,
              negative: false
            }]
          )
        }}
        placeholder={
          criteriaValue.length === 0 && !isGeneratingLocationCriteria
            ? 'Locations in United States or Canada'
            : undefined
        }
        loadingNode={isGeneratingLocationCriteria && (
          <S.CriteriaPill>
            <Spinner />
            <Paragraph size='XS'>{query}</Paragraph>
          </S.CriteriaPill>
        )}
        items={
          <>
            {items}
          </>
        }
        onKeyUp={(input: string, callback: () => void) => {
          if (input && jobId) {
            setQuery(input)
            generateLocationCriteria({
              instructions: input,
              jobId,
              onSuccess: (newLocations: LocationCriteria[]) => {
                onCriteriaUpdate(
                  criteriaKey,
                  deduplicateLocationCriteria([...criteriaValue, ...newLocations])
                )
                callback()
              }
            })
          }
        }}
      />
      <RemoveCriteria
        onRemove={() => {
          onCriteriaRemove(criteriaKey)
        }}
        disabled={disabled}
      />
    </S.RowContainer>
  )
}
