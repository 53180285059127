import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { isNil } from 'lodash'

import LoginBox from 'src/components/blocks/login-box'
import RouteBuilder from 'src/libs/route-builder'
import LoginError from 'src/components/blocks/login-error'

import { Flex, Spacer } from 'src/components/primitives'
// import { AuthLayout } from 'src/components/layouts/auth'
import { AuthLayoutV2 } from 'src/components/layouts/auth-v2'
import { Caption } from 'src/components/primitives/typography'
import { useSession } from 'src/hooks/use-session'
import { useLoginRedirectPath } from 'src/hooks/use-login-redirect-path'
import { SEO } from '../components/primitives/seo'
import { trackEvent } from 'src/libs/track'
import { TrackingEventType } from 'src/types/track'
import { useWindowSize } from 'usehooks-ts'
import { When } from 'src/components/blocks/when'

const Login = (): JSX.Element => {
  const [authStateIsLoading, setAuthStateIsLoading] = useState(true)
  const navigate = useNavigate()
  const { isLoading, redirectPath: loginRedirectPath } = useLoginRedirectPath()
  const { user } = useSession()
  const { width } = useWindowSize()

  const { search } = useLocation()

  const params = new URLSearchParams(search)
  const error = params.get('error')

  // Redirect logged in users to dashboard
  useEffect(() => {
    if (!isLoading && !isNil(loginRedirectPath) && user?.id) {
      navigate(loginRedirectPath)
    }
    if (!isLoading && !user?.id) {
      setAuthStateIsLoading(false)
    }
  }, [user, isLoading, loginRedirectPath, navigate])

  if (authStateIsLoading) {
    return <></>
  }

  trackEvent(TrackingEventType.VIEWED_LOGIN_PAGE)

  // return (
  //   <>
  //     <SEO title="Login" />
  //     <AuthLayout>
  //       <LoginBox redirectPath={RouteBuilder.build('LOGIN/REDIRECT')}>
  //         <When condition={width > 1024}>
  //           <Caption size="LG">Continue with your work email</Caption>
  //         </When>
  //         <When condition={width <= 1024}>
  //           <Caption size="LG">Continue with</Caption>
  //           <Caption size="LG" $color="fgTertiary">your work email</Caption>
  //         </When>
  //         {error && (
  //           <>
  //             <Spacer $size={24} />
  //             <LoginError error={error} />
  //           </>
  //         )}
  //       </LoginBox>
  //     </AuthLayout>
  //   </>
  // )

  return (
    <>
      <SEO title="Login" />
      <AuthLayoutV2>
        <LoginBox redirectPath={RouteBuilder.build('LOGIN/REDIRECT')}>
          <When condition={width > 1024}>
            <Flex $direction="column" $gap={0}>
              <Caption size="LG">Sign in with</Caption>
              <Caption size="LG" $color="fgTertiary">your work email </Caption>
            </Flex>
          </When>
          <When condition={width <= 1024}>
            <Caption size="LG">Continue with</Caption>
            <Caption size="LG" $color="fgTertiary">your work email</Caption>
          </When>
          {error && (
            <>
              <Spacer $size={24} />
              <LoginError error={error} />
            </>
          )}
        </LoginBox>
      </AuthLayoutV2>
    </>
  )
}

export default Login
