import React, { forwardRef } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import type { Color, FontSize, FontWeight, Spacing } from 'src/styles/theme/types'
import { Icon } from '../icon'
import type { IconName } from '../icon'
import { BrandIcon } from '../brand-icon'
import type { BrandIconName } from '../brand-icon'
import { getIconColor } from './utils'
import { isExternalLink } from 'src/libs/is-external-link'
import { detectIconType } from 'src/utils/detect-icon-type'
import RouteBuilder from 'src/libs/route-builder'
import * as S from './button.styled'
import { Tooltip } from '../tooltip/tooltip'
import type { TooltipProps } from '../tooltip/tooltip'

export type Variant = 'raised' | 'raised-light' | 'fill' | 'ghost' | 'flat' | 'outline' | 'link' | 'subtle' | 'navItem'
export type ColorTheme = 'normal' | 'tint' | 'positive' | 'negative' | 'warning' | 'muted'
export type Width = 'auto' | 'full' | number | Spacing | string
export type Height = 'normal' | Spacing

export interface ButtonStyleProps {
  // We differ between a single "default" $variant and
  // multiple $variants that give more granular control
  // over various states. This is especially useful
  // in combination with React Router's `NavLink` that
  // gives us active states.
  // Please note: $variants is an escape hatch and will
  // always overwrite $variant!
  $variant?: Variant
  $variants?: {
    default: Variant
    active: Variant
  }
  $colorTheme?: ColorTheme
  $height?: Height
  $width?: Width
  $minWidth?: string
  $innerMinWidth?: string
  $fontSize?: FontSize
  $fontWeight?: FontWeight
  $align?: 'flex-start' | 'center' | 'flex-end' | 'space-between'
  $direction?: 'row' | 'column'
  $loading?: boolean
  $disabled?: boolean
  $gap?: Spacing
  $borderRadius?: Spacing
  $borderless?: boolean
}

interface IconProps {
  leadingIcon?: IconName | BrandIconName | React.ReactNode
  trailingIcon?: IconName | React.ReactNode
  iconSize?: Spacing
}

export interface ButtonActionProps {
  type?: 'submit' | 'button'
  children?: React.ReactNode
  ariaLabel?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>
  onMouseEnter?: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>
  onMouseLeave?: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>
  href?: string
  hrefTarget?: '_blank' | '_self'
  disabled?: boolean
  loading?: boolean
  nested?: boolean
  icon?: IconName
  tooltip?: {
    text?: string
    position?: TooltipProps['position']
    keys?: TooltipProps['keys']
    maxWidth?: TooltipProps['$maxWidth']
    textAlign?: TooltipProps['$textAlign']
  }
}

export interface ButtonProps extends ButtonActionProps, ButtonStyleProps, IconProps {
  id?: string
}

interface InnerProps extends IconProps {
  $variant: Variant
  loading?: boolean
  $fontSize?: FontSize
  $fontWeight?: FontWeight
  $colorTheme?: ColorTheme
  $align?: 'flex-start' | 'center' | 'flex-end' | 'space-between'
  $direction?: 'row' | 'column'
  $gap?: Spacing
  children: React.ReactNode
  $innerMinWidth?: string
  $disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>
}

const renderIcon = (
  icon: IconName | Color | React.ReactNode,
  variant: Variant,
  colorTheme: ColorTheme,
  size: Spacing
): JSX.Element | React.ReactNode => {
  if (typeof icon !== 'string') {
    return icon
  }

  const iconType = detectIconType(icon)

  switch (iconType) {
    case 'Icon': {
      return <Icon name={icon as IconName} color={getIconColor(variant, colorTheme)} size={size} />
    }
    case 'BrandIcon': {
      const originalColoredIcons = ['google', 'microsoft', 'chrome', 'linkedinOriginal']
      const iconColor = originalColoredIcons.includes(icon) && colorTheme !== 'tint' ? 'original' : getIconColor(variant, colorTheme)
      return <BrandIcon name={icon as BrandIconName} size={size} color={iconColor} />
    }
    default: {
      return <Icon name={icon as IconName} color={getIconColor(variant, colorTheme)} size={size} />
    }
  }
}

const Inner = ({
  $variant,
  leadingIcon,
  iconSize,
  $fontSize = 16,
  $fontWeight = 500,
  $colorTheme = 'normal',
  trailingIcon,
  children,
  $align,
  $direction,
  $gap,
  $innerMinWidth,
  loading,
  onClick,
  $disabled
}: InnerProps): JSX.Element => {
  const ICON_SIZE = iconSize ?? ($fontSize <= 12 ? 12 : 16)

  return (
    <>
      {loading && (
        <S.Loading>
          <Icon name="loader" size={16} color={getIconColor($variant, $colorTheme)} />
        </S.Loading>
      )}
      <>
        <S.Inner onClick={onClick} $align={$align} $loading={loading} $innerMinWidth={$innerMinWidth} $disabled={$disabled}>
          <S.Content $fontSize={$fontSize} $fontWeight={$fontWeight} $gap={$gap} $direction={$direction}>
            {leadingIcon && (
              <S.Icon $size={ICON_SIZE}>
                {renderIcon(leadingIcon, $variant, $colorTheme, ICON_SIZE)}
              </S.Icon>
            )}
            {children}
          </S.Content>
          {trailingIcon && (
            <S.Icon $size={ICON_SIZE}>
              {renderIcon(trailingIcon, $variant, $colorTheme, ICON_SIZE)}
            </S.Icon>
          )}
        </S.Inner>
      </>
    </>
  )
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref): JSX.Element => {
  const { jobId } = useParams()
  const location = useLocation()

  const {
    children,
    ariaLabel,
    type = props.nested ? undefined : 'button',
    onClick,
    onMouseEnter,
    onMouseLeave,
    href,
    hrefTarget = '_blank',
    disabled = false,
    loading = false,
    nested = false,
    leadingIcon,
    trailingIcon,
    iconSize,
    tooltip,
    $colorTheme = 'normal',
    $variant = 'raised',
    $variants,
    $width = 'auto',
    $minWidth,
    $innerMinWidth,
    $height = 'normal',
    $fontSize = 14,
    $fontWeight = 500,
    $align = 'flex-start',
    $direction = 'row',
    $gap = 6,
    $borderRadius = 4,
    $borderless = false,
    id
  } = props
  const buttonProps = {
    type,
    onClick,
    onMouseEnter,
    onMouseLeave,
    disabled,
    id
  }

  const styleProps = {
    $colorTheme,
    $variant,
    $width,
    $minWidth,
    $height,
    $fontSize,
    $fontWeight,
    $borderRadius,
    $borderless,
    $disabled: disabled,
    $loading: loading
  }

  const innerProps = {
    $variant,
    $loading: loading,
    loading,
    leadingIcon,
    trailingIcon,
    iconSize,
    $fontSize,
    $fontWeight,
    $colorTheme,
    $gap,
    $innerMinWidth,
    $disabled: disabled,
    $direction
  }

  const ICON_ONLY_BUTTON = Boolean(!children && leadingIcon)
  const INNER_ALIGN = ICON_ONLY_BUTTON ? 'center' : $align
  const PADDING = (() => {
    if ($fontSize <= 12 && $height === 'normal') {
      return '0.625rem 0.375rem'
    } else if ($fontSize <= 12 && $height !== 'normal') {
      return '0 0.375rem'
    } else {
      return '0.625rem'
    }
  })()

  if (href) {
    if (isExternalLink(href)) {
      return (
        <S.Button
          as="a"
          ref={ref}
          href={href}
          target={hrefTarget}
          rel="noopener noreferrer"
          aria-label={ariaLabel}
          {...styleProps}
          $align={INNER_ALIGN}
          $iconOnlyButton={ICON_ONLY_BUTTON}
          $padding={PADDING}
          id={id}
        >
          <Inner {...innerProps} onClick={onClick} $align={INNER_ALIGN}>
            {children}
          </Inner>
        </S.Button>
      )
    } else {
      if (tooltip?.text) {
        return (
          <Tooltip
            position="right"
            keys={tooltip.keys}
            triggerDisabled={disabled}
            trigger={
              <NavLink to={href} end={false}>
                {({ isActive }) => {
                  // This is some weird abstraction to highlight all nested
                  // candidate routes (e.g. `/candidates/sourcing` or `/candidates/in-sequence`.
                  // If we would not do this, the "Candidates" link in the navigation
                  // would only be highlighted when it matches "/candidates/sourcing" as
                  // this is the href we have to pass in.
                  let hrefToCompare = href
                  if (href.includes('candidates')) {
                    hrefToCompare = RouteBuilder.build('JOBS_CANDIDATES', { jobId })
                  }
                  const variantType =
                    isActive || location.pathname.includes(hrefToCompare) ? 'active' : 'default'
                  const CURRENT_VARIANT = $variants ? $variants[variantType] : $variant
                  return (
                    <S.Button
                      as="span"
                      ref={ref}
                      aria-label={ariaLabel}
                      {...styleProps}
                      $variant={CURRENT_VARIANT}
                      $align={INNER_ALIGN}
                      $iconOnlyButton={ICON_ONLY_BUTTON}
                      $padding={PADDING}
                      onClick={onClick}
                      id={id}
                    >
                      <Inner {...innerProps} $align={INNER_ALIGN}>
                        {children}
                      </Inner>
                    </S.Button>
                  )
                }}
              </NavLink>
            }
          >
            {tooltip.text}
          </Tooltip>
        )
      } else {
        return (
          <NavLink to={href} end={false}>
            {({ isActive }) => {
              // This is some weird abstraction to highlight all nested
              // candidate routes (e.g. `/candidates/sourcing` or `/candidates/in-sequence`.
              // If we would not do this, the "Candidates" link in the navigation
              // would only be highlighted when it matches "/candidates/sourcing" as
              // this is the href we have to pass in.
              let hrefToCompare = href
              if (href.includes('candidates')) {
                hrefToCompare = RouteBuilder.build('JOBS_CANDIDATES', { jobId })
              }
              const variantType =
                isActive || location.pathname.includes(hrefToCompare) ? 'active' : 'default'
              const CURRENT_VARIANT = $variants ? $variants[variantType] : $variant
              return (
                <S.Button
                  as="span"
                  ref={ref}
                  aria-label={ariaLabel}
                  {...styleProps}
                  $variant={CURRENT_VARIANT}
                  $align={INNER_ALIGN}
                  $iconOnlyButton={ICON_ONLY_BUTTON}
                  $padding={PADDING}
                  onClick={onClick}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  id={id}
                >
                  <Inner {...innerProps} $align={INNER_ALIGN}>
                    {children}
                  </Inner>
                </S.Button>
              )
            }}
          </NavLink>
        )
      }
    }
  }

  if (tooltip?.text) {
    return (
      <Tooltip
        position={tooltip?.position ?? 'top'}
        keys={tooltip.keys}
        triggerDisabled={disabled}
        $maxWidth={tooltip.maxWidth}
        $textAlign={tooltip.textAlign}
        trigger={
          <S.Button
            ref={ref}
            // as={nested ? 'span' : 'button'}
            as="button"
            aria-label={ariaLabel}
            {...buttonProps}
            {...styleProps}
            $align={INNER_ALIGN}
            $iconOnlyButton={ICON_ONLY_BUTTON}
            $padding={PADDING}
            $disabled={false}
            $disabledWithTooltip={disabled}
            id={id}
          >
            <Inner {...innerProps} $align={INNER_ALIGN}>
              {children}
            </Inner>
          </S.Button>
        }
      >
        {tooltip.text}
      </Tooltip>
    )
  }
  return (
    <S.Button
      ref={ref}
      as={nested ? 'span' : 'button'}
      aria-label={ariaLabel}
      {...buttonProps}
      {...styleProps}
      $align={INNER_ALIGN}
      $iconOnlyButton={ICON_ONLY_BUTTON}
      $padding={PADDING}
      id={id}
    >
      <Inner {...innerProps} $align={INNER_ALIGN}>
        {children}
      </Inner>
    </S.Button>
  )
})
