import { Logo } from 'src/components/primitives/logo'
import * as S from './trial-expired.styled'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Avatar } from 'src/components/primitives/avatar'
import { useMemo, useState } from 'react'
import { useSession } from 'src/hooks/queries/use-session'
import { SubscriptionSettings } from '../subscription-settings'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { ClosePinAccountFeedback } from '../close-pin-account-feedback'
import { SUBSCRIPTION_ISSUE } from 'src/libs/api/backend/session'

export const TrialExpired = (): JSX.Element => {
  const { data: sessionContext } = useSession()
  const [closeAccount, setCloseAccount] = useState(false)
  const { emailAccountAccessTokens, subscriptionIssue } = sessionContext ?? {}
  const primaryEmail = useMemo(() => {
    return emailAccountAccessTokens?.find(account => account.isPrimary) ?? emailAccountAccessTokens?.[0]
  }, [emailAccountAccessTokens])

  return (
    <S.Page>
      <S.Banner>
        <Logo variant="dark" size={64} />
        <Dropdown
          trigger={
            <S.UserProfileButton>
              <Avatar
                $shape="circle"
                $type="photo"
                $size={24}
                initials={sessionContext?.user?.name}
                photoUrl={sessionContext?.user?.profilePhoto}
                ariaLabel="Toggle account menu"
              />
            </S.UserProfileButton>
          }
          menuPosition="start"
          items={[
            {
              id: 'account',
              title: sessionContext?.user?.name ?? '',
              subTitle: primaryEmail?.email ?? '',
              isSelectable: false
            },
            {
              id: 'account-separator',
              title: 'AccountSeparator',
              type: 'separator'
            },
            {
              id: 'logout',
              title: 'Log out',
              variant: 'negative',
              icon: 'log-out',
              href: '/logout'
            }
          ]}
        />
      </S.Banner>
      <S.Body>
        <S.TrialEndedNotificationContainer>
          { closeAccount
            ? (
                <S.TrialCloseAccountBox>
                  <ClosePinAccountFeedback
                    expired={true}
                    onClose={() => {
                      setCloseAccount(false)
                    }}
                  />
                </S.TrialCloseAccountBox>
              )
            : (
                <S.TrialEndedBox>
                  <SubscriptionSettings
                    expired={true}
                    notificationBanner={
                      <>
                        {subscriptionIssue === SUBSCRIPTION_ISSUE.SUBSCRIPTION_EXPIRED || subscriptionIssue === SUBSCRIPTION_ISSUE.EXTENDED_TRIAL_EXPIRED
                          ? <Caption size='SM' $color='warningFg'>Your subscription has expired.</Caption>
                          : <Caption size='SM' $color='warningFg'>Your free trial period has ended.</Caption>
                        }
                        <Paragraph size='SM' $color='warningFg'>Subscribe below to continue using Pin.</Paragraph>
                      </>
                    }
                    onCloseAccount={setCloseAccount}
                />
              </S.TrialEndedBox>
              )
          }
        </S.TrialEndedNotificationContainer>
      </S.Body>
    </S.Page>
  )
}
