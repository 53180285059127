import { OnboardingNavigation } from 'src/components/blocks/onboarding-navigation'
import { Outlet, useLocation } from 'react-router-dom'
import * as S from './onboarding.styled'
import RouteBuilder from 'src/libs/route-builder'
import { useOnboardingSteps } from 'src/hooks/use-onboarding-steps'

export const OnboardingLayout = (): JSX.Element => {
  const { pathname } = useLocation()
  const navIsHidden = pathname.includes(RouteBuilder.build('ONBOARDING_WELCOME'))
  const showCurrentStep = !pathname.includes(RouteBuilder.build('ONBOARDING_JOIN_TEAM'))
  const { currentStep } = useOnboardingSteps()
  const stepImageSrc = pathname.includes('onboarding/welcome') ? 'onboarding/welcome.jpg' : pathname.includes('onboarding/join-team') ? 'onboarding/invite-team.jpg' : currentStep?.image

  return (
    <S.OnboardingLayout>
      {!navIsHidden && <OnboardingNavigation showCurrentStep={showCurrentStep} />}
      <S.Inner>
        <S.Content>
          <S.MediaColumn>
            <img
              src={`/${stepImageSrc}`}
              alt={`Illustration showing off ${currentStep?.title}`}
            />
          </S.MediaColumn>
          <S.FormColumn>
            <S.FormColumnInner>
              <Outlet />
            </S.FormColumnInner>
          </S.FormColumn>
        </S.Content>
      </S.Inner>
    </S.OnboardingLayout>
  )
}
