import { z } from 'zod'
import Api, { ApiError } from '..'
import { UserRole } from './users'

const orgInviteParser = z.object({
  id: z.string().uuid(),
  org: z.object({
    id: z.string().uuid(),
    name: z.string(),
    domain: z.string(),
    logoUrl: z.string().nullable()
  })
})

export type OrgInvite = z.infer<typeof orgInviteParser>

export async function fetchOrgInvite (inviteId: string, token: string): Promise<OrgInvite> {
  const response = await Api.get(`/org/invites/${inviteId}/${token}`)
  return orgInviteParser.parse(response.data)
}

const orgInvitesParser = z.array(
  z.object({
    id: z.string().uuid(),
    email: z.string(),
    name: z.string().nullable(),
    accepted: z.boolean(),
    orgId: z.string().uuid(),
    createdAt: z.coerce.date(),
    role: z.nativeEnum(UserRole)
  })
)

export type OrgInvites = z.infer<typeof orgInvitesParser>

export async function fetchOrgInvites (): Promise<OrgInvites> {
  const response = await Api.get('/org/invites')
  return orgInvitesParser.parse(response.data)
}

const orgInviteDirectoryTeammateParser = z.object({
  email: z.string(),
  name: z.string().nullable(),
  photoUrl: z.string().nullable()
})

type OrgInviteDirectoryTeammate = z.infer<typeof orgInviteDirectoryTeammateParser>

export async function fetchOrgInviteDirectory (): Promise<OrgInviteDirectoryTeammate[]> {
  try {
    const response = await Api.get('/org/invites/directory', null)
    return z.array(orgInviteDirectoryTeammateParser).parse(response.data)
  } catch (e) {
    if (e instanceof ApiError) {
      return []
    }

    throw e
  }
}

const newInviteParser = z.object({
  name: z.string().optional(),
  email: z.string().email(),
  role: z.nativeEnum(UserRole).default(UserRole.ADMIN)
})

export type NewInvite = z.infer<typeof newInviteParser>

export async function createOrgInvite (invitees: NewInvite[]): Promise<void> {
  const parsed = z.array(newInviteParser).parse(invitees)
  await Api.post('/org/invites', null, {
    invitees: parsed
  })
}

export async function acceptInvite (inviteId: string, token: string): Promise<true> {
  await Api.post(`/org/invites/${inviteId}/${token}`, null)
  return true
}

export async function deleteInvite (inviteId: string): Promise<void> {
  await Api.delete(`/org/invites/${inviteId}`)
}
