import { useEffect, useMemo, useState } from 'react'
import { IfElse } from 'src/components/blocks/if-else'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { SearchBox } from 'src/components/blocks/searchbox'
import { Button, Flex } from 'src/components/primitives'
import * as Dialog from 'src/components/primitives/dialog'
import { Caption, Span } from 'src/components/primitives/typography'
import * as S from './copy-company-ratings.styled'
import { useJobByDepartmentsQuery } from 'src/hooks/queries/use-jobs-by-department'
import { useParams } from 'react-router-dom'
import { Icons } from 'src/components/primitives/icon'
import { When } from 'src/components/blocks/when'
import { JobTile } from '../../blocks/job-tile/job-tile'
import { closeDialogAtom, controlDialogAtom, DialogId, isDialogOpenAtom, openDialogAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import type { JobAndDepartment } from 'src/libs/api/backend/jobs'
import { useCopyCompanyPreferences } from 'src/hooks/mutations/use-copy-company-preferences'

export const CopyCompanyRatingsDialog = (): JSX.Element => {
  const { jobId } = useParams()
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.COPY_COMPANY_RATINGS), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const openDialog = useSetAtom(openDialogAtom)
  const closeDialog = useSetAtom(closeDialogAtom)
  const { isLoading, data: jobs } = useJobByDepartmentsQuery()
  const [isCopying, setIsCopying] = useState(false)

  const [selectedJob, setSelectedJob] = useState<JobAndDepartment | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const { copyCompanyPreferences } = useCopyCompanyPreferences()

  const flattenJobs = useMemo(() => {
    if (!jobs) {
      return []
    }
    const flattenedMap = Object.values(jobs).reduce<JobAndDepartment[]>((deptAcc, dept) => {
      const projectJobs = Object.values(dept).reduce((projAcc, project) => {
        return [...projAcc, ...Object.values(project)]
      }, [])
      return [...deptAcc, ...projectJobs]
    }, [])

    return flattenedMap.filter((job) => job.id !== jobId && !job.deleted)
  }, [jobId, jobs])

  const filteredJobs = useMemo(() => {
    return flattenJobs.filter((job) => {
      return (
        job.title.toLowerCase().includes(searchValue) ||
        job.department?.name.toLowerCase().includes(searchValue)
      )
    })
  }, [flattenJobs, searchValue])

  useEffect(() => {
    if (
      filteredJobs.length &&
      (!selectedJob || !filteredJobs.find((job) => job.id === selectedJob.id))
    ) {
      setSelectedJob(filteredJobs[0])
    }
  }, [filteredJobs, selectedJob])

  const handleCopyRatings = (): void => {
    setIsCopying(true)

    if (!jobId || !selectedJob?.id) {
      return
    }

    copyCompanyPreferences({
      fromJobId: selectedJob?.id,
      toJobId: jobId,
      onSuccess: () => {
        closeDialog(DialogId.COPY_COMPANY_RATINGS)
        setIsCopying(false)
      },
      onError: () => {
        setIsCopying(false)
      }
    })
  }

  return (
    <Dialog.Root
      id={DialogId.COPY_COMPANY_RATINGS}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.COPY_COMPANY_RATINGS, newState: value })
      }}
      $maxWidth="640px"
      $height="640px"
      $innerPadding={{
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Copy Company Ratings from Another Job"
          onClose={() => {
            closeDialog(DialogId.COPY_COMPANY_RATINGS)
          }}
        />
        <Dialog.Content>
          <Flex $direction="column" $height="full" $width="100%">
            <S.Content>
              <When condition={flattenJobs.length === 0}>
                <Flex $gap={8} $direction="column" $align='center' $width='100%'>
                  <Caption size="SM" $color="fgSecondary">
                    No jobs available to move candidates to
                  </Caption>
                  <Button
                    $variant="outline"
                    $colorTheme="tint"
                    onClick={async () => {
                      openDialog({ id: DialogId.CREATE_JOB_POSITION })
                      closeDialog(DialogId.COPY_COMPANY_RATINGS)
                    }}
                    leadingIcon={Icons.plusCircle}
                  >
                    Create new job
                  </Button>
                </Flex>
              </When>
              <When condition={flattenJobs.length > 0}>
                <Flex $gap={16} $direction="column">
                  {flattenJobs.length >= 10 &&
                    <SearchBox
                      placeholder="Search for job by title"
                      onClose={() => {
                        setSearchValue('')
                      }}
                      onValueChange={(value) => {
                        setSearchValue(value.toLowerCase())
                      }}
                      $gap={8}
                      searchStyles={{
                        $width: '100%',
                        $border: 'solid 1px',
                        $borderColor: 'borderTranslucent',
                        $borderRadius: 4,
                        $padding: '0.625rem',
                        $height: 40,
                        $inputHeight: 20,
                        $fontSize: 14
                      }}
                    />
                  }
                  <IfElse
                    condition={isLoading}
                    ifNode={<LoadingSkeleton $variant="JobsList" />}
                    elseNode={
                      <Flex $gap={6} $direction="column">
                        {filteredJobs.map((job) => {
                          const isActive = job.id === selectedJob?.id
                          return (
                            <JobTile
                              key={job.id}
                              id={job.id}
                              isActive={isActive}
                              title={job.title}
                              job={job}
                              onClick={() => { setSelectedJob(job) }}
                            />
                          )
                        })}
                      </Flex>
                    }
                  />
                </Flex>
              </When>
            </S.Content>
            <S.Actions>
              <Button
                $variant="outline"
                $colorTheme="muted"
                $align="center"
                onClick={() => {
                  closeDialog(DialogId.COPY_COMPANY_RATINGS)
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                $variant="fill"
                $colorTheme="tint"
                $height={40}
                $align="center"
                onClick={handleCopyRatings}
                loading={isCopying}
              >
                <Span $color="bgPrimary" $fontWeight={500} $ellipsis>Copy from {selectedJob?.title}</Span>
              </Button>
            </S.Actions>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
