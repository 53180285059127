import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Table } from 'src/components/primitives/table'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { Flex } from 'src/components/primitives/flex'
import { useGlobalError } from 'src/hooks/use-global-error'
import { buildTableColumns, COLUMN } from '../candidate-table-cells/table-builder'
import type { TableColumnDef, TableRowDef } from '../candidate-table-cells/table-builder'
import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING, ERROR_BLOCK_HEIGHT, HEADER_PADDING } from 'src/styles/constants'
import * as S from './candidates-table.styled'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { isSourcingTableExpandedAtom } from 'src/stores/job-refinement'
import { isEmpty, isNil } from 'lodash'
interface CandidatesTableProps {
  isLoading?: boolean
  pageHeaderHeight?: number
  candidateJobs?: CandidateJobExpanded[]
  emptyState: React.ReactNode
  selectedRowsActions?: React.ReactNode
  visibleColumns: COLUMN[]
  customColumnDefinitions?: Array<Partial<TableColumnDef<TableRowDef>>>
}

const EXTENDED_MAX_TABLE_WIDTH = '1516px'

export const CandidatesTable = ({
  isLoading,
  candidateJobs,
  emptyState,
  pageHeaderHeight = 64,
  selectedRowsActions,
  visibleColumns,
  customColumnDefinitions
}: CandidatesTableProps): JSX.Element => {
  const tableIsExpanded = useAtomValue(isSourcingTableExpandedAtom)
  const openDialog = useSetAtom(openDialogAtom)
  const { isGlobalErrorOpen } = useGlobalError()
  const [columns, setColumns] = useState<Array<TableColumnDef<TableRowDef>>>([])

  useEffect(() => {
    if (isLoading) {
      return
    }
    setColumns(buildTableColumns({ visibleColumns, customColumnDefinitions }))
  }, [isLoading, visibleColumns, customColumnDefinitions, columns.length])

  useEffect(() => {
    // Reset columns on unmount
    return () => {
      setColumns([])
    }
  }, [])

  const maxHeight = useMemo(() => {
    const errorBlockHeight = isGlobalErrorOpen ? ERROR_BLOCK_HEIGHT : '0px'
    if (pageHeaderHeight > 0) {
      return `calc(100vh - ${pageHeaderHeight}px - ${CONTENT_PADDING} - ${errorBlockHeight})`
    }
    return `calc(100vh - 62px - ${HEADER_PADDING}*3 - ${CONTENT_PADDING} - ${errorBlockHeight})`
    // return `calc(100vh - ${HEADER_PADDING}*3 - ${CONTENT_PADDING} - ${errorBlockHeight})`
  }, [isGlobalErrorOpen, pageHeaderHeight])

  const handleRowClick = useCallback((row: TableRowDef): void => {
    openDialog({ id: DialogId.CANDIDATE_DETAILS, payload: row.id })
  }, [openDialog])

  const anyColumnExpanded = useMemo(() => {
    if (!visibleColumns.includes(COLUMN.CRITERIA_EXPANDED)) {
      return false
    }
    if (isNil(tableIsExpanded) || isEmpty(tableIsExpanded)) {
      return true
    }
    return Object.values(tableIsExpanded).some(Boolean)
  }, [tableIsExpanded, visibleColumns])

  const maxWidth = useMemo(() => {
    return anyColumnExpanded ? `min(100%, ${EXTENDED_MAX_TABLE_WIDTH})` : CANDIDATES_PAGES_MAX_WIDTH
  }, [anyColumnExpanded])

  if (!!isLoading || columns.length === 0) {
    return (
      <div
        style={{
          height: '100%',
          maxWidth
        }}
      >
        <LoadingSkeleton $variant="CandidatesTable" delay={100} />
      </div>
    )
  }

  return (
    <S.TableWrapper
      $maxHeight={maxHeight ?? 'auto'}
      style={{
        width: anyColumnExpanded ? 'fit-content' : '100%',
        overflowX: 'auto',
        maxWidth
      }}
    >
      <Table<TableRowDef>
        columns={columns}
        setColumns={setColumns}
        tableData={candidateJobs ?? []}
        onRowClick={(row) => {
          handleRowClick(row)
        }}
        emptyState={<div style={{ height: maxHeight ?? 'auto' }}>{emptyState}</div>}
        selectionActions={<Flex $gap={6}>{selectedRowsActions}</Flex>}
        stickyHeader
      />
    </S.TableWrapper>
  )
}
