import { useEffect } from 'react'
import { resetTracking } from 'src/libs/track'
import CONFIG from 'src/config'

const Logout = (): JSX.Element => {
  useEffect(() => {
    resetTracking()
    window.location.href = `${CONFIG.API_DOMAIN}/auth/logout?redirect=${CONFIG.APP_DOMAIN}`
  }, [])

  return <></>
}

export default Logout
