import { useEffect, useState } from 'react'
import { z } from 'zod'
import { Flex } from 'src/components/primitives/flex'
import { FieldLabel } from 'src/components/forms/field-label'
import { Tabbar } from 'src/components/primitives/tabbar'
import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Form, Input } from 'src/components/forms'
import type { InputFieldType } from 'src/components/forms'
import { useForm } from 'src/hooks/use-form'
import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { Avatar } from 'src/components/primitives/avatar'
import * as S from './options-list.styled'
import { When } from 'src/components/blocks/when'
import { UserRole } from 'src/libs/api/backend/users'

export interface OptionItem {
  title: string
  description?: string | null
  value: string
  photoUrl?: string | null
}

export type Variant = 'outline' | 'raised'

export interface StyleProps {
  $variant?: Variant
}

type AllowedAction = 'add' | 'remove'

interface Option {
  title: string
  value: string
}

interface OptionsListProps extends StyleProps {
  label: string
  name: string
  items: OptionItem[]
  type: 'toggle' | 'dropdown'
  options?: Option[]
  allowedActions?: AllowedAction[]
  addItemIcon?: IconName
  addItemTitle?: string
  addItemFieldType?: InputFieldType
  addItemDisabledWhenEmpty?: boolean
  removeItemIcon?: IconName
  onItemsChanged?: (items: OptionItem[]) => void
  onUpdateItem?: (items: OptionItem[]) => void
  onRemoveItem?: (items: OptionItem[]) => void
  onAddItem?: (items: OptionItem[]) => void
}

export const OptionsList = ({
  $variant = 'outline',
  label,
  name,
  items,
  type,
  options,
  onItemsChanged,
  onUpdateItem,
  onRemoveItem,
  onAddItem,
  allowedActions = ['add', 'remove'],
  addItemIcon,
  addItemTitle = 'Add new',
  addItemFieldType = 'text',
  addItemDisabledWhenEmpty = false,
  removeItemIcon = 'x'
}: OptionsListProps): JSX.Element => {
  const [listItems, setListItems] = useState<OptionItem[] | null>(items)

  const schemaParser = z.object({
    optionTitle: addItemFieldType === 'email' ? z.string().email() : z.string()
  })

  const { formData, submit, register, reset } = useForm<z.infer<typeof schemaParser>>({
    schema: schemaParser
  })

  useEffect(() => {
    if (onItemsChanged && listItems?.length) {
      onItemsChanged(listItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listItems])

  const handleUpdateItem = (selectedItem: OptionItem, newValue: string) => {
    if (!listItems) {
      return
    }
    const updatedItems = listItems?.map((item) => {
      if (item.title === selectedItem.title) {
        return {
          ...item,
          value: newValue
        }
      } else {
        return item
      }
    })

    setListItems(updatedItems)
    if (onUpdateItem) {
      onUpdateItem(updatedItems)
    }
  }

  const handleRemoveItem = (selectedItem: OptionItem): void => {
    const updatedItems = listItems?.filter((item) => item.title !== selectedItem.title)
    if (updatedItems) {
      setListItems(updatedItems)
      if (onRemoveItem) {
        onRemoveItem(updatedItems)
      }
    }
  }

  const handleAddItem = async (data: z.infer<typeof schemaParser>) => {
    const updatedItems = [
      ...(listItems ?? []),
      {
        title: data.optionTitle,
        value: UserRole.ADMIN
      }
    ]
    setListItems(updatedItems)
    reset()
    if (onAddItem) {
      onAddItem(updatedItems)
    }
  }

  return (
    <>
      <FieldLabel label={label} htmlFor={name} />

      <When condition={allowedActions.includes('add')}>
        <S.AddOptionWrapper>
          <S.AddOption>
            <Form onSubmit={submit(handleAddItem)}>
              <S.AddOptionInput className="AddOptionInput">
                {
                  addItemIcon && (
                    <S.AddIcon>
                      <Icon name={addItemIcon} color="fgTertiary" size={12} />
                    </S.AddIcon>
                  )
                }
                <Input
                  variant="naked"
                  name="optionTitle"
                  type={addItemFieldType}
                  placeholder={addItemTitle}
                  label="Add option"
                  hiddenLabel
                  register={register}
                />
              </S.AddOptionInput>
              <Button
                type="submit"
                $variant="outline"
                $colorTheme="muted"
                $height={24}
                $fontSize={12}
                disabled={addItemDisabledWhenEmpty && !formData?.optionTitle}
              >
                Add
              </Button>
            </Form>
          </S.AddOption>
        </S.AddOptionWrapper>
      </When>

      <S.OptionsList id={name} $variant={$variant}>
        {listItems?.map((item) => (
          <S.Option>
            <S.OptionAvatar>
              <Avatar $type="photo" initials={item.title} photoUrl={item.photoUrl} />
            </S.OptionAvatar>
            <S.OptionLabel>
              <p>{item.title}</p>
              {item.description?.length ? <p>{item.description}</p> : null}
            </S.OptionLabel>
            <Flex $align="center" $justify="flex-end" $gap={12}>
              {options?.length
                ? (
                  <>
                    {type === 'toggle' && (
                      <Tabbar
                        ariaLabel="Choose preference"
                        initiallySelected={item.value ?? options[0].value}
                        tabs={options.map((option) => ({
                          title: option.title,
                          value: option.value,
                          onSelect: () => { handleUpdateItem(item, option.value) }
                        }))}
                      />
                    )}
                    {type === 'dropdown' && (
                      <Dropdown
                        trigger={
                          <Button
                            $variant="raised"
                            $colorTheme="muted"
                            $height={24}
                            $fontSize={12}
                            nested
                            trailingIcon="chevrons-up-down-small"
                          >
                            {options.find((o) => o.value === item.value)?.title ?? options[0].title}
                          </Button>
                        }
                        items={options.map((option) => ({
                          id: option.value,
                          title: option.title,
                          value: option.value,
                          onSelect: () => { handleUpdateItem(item, option.value) }
                        }))}
                        size="small"
                      />
                    )}
                  </>
                  )
                : null}
              <Button
                ariaLabel="Remove item from list"
                leadingIcon={removeItemIcon}
                $variant="ghost"
                $colorTheme="muted"
                $height={24}
                $width={24}
                $fontSize={12}
                onClick={() => { handleRemoveItem(item) }}
              />
            </Flex>
          </S.Option>
        ))}

      </S.OptionsList>
    </>
  )
}
