import { map } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'
import { useRejectCandidate } from 'src/hooks/mutations/use-reject-candidate'
import { sourcingRejectionReasons } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobRejectionReason } from 'src/libs/api/backend/candidate_jobs'

interface ApplicantArchiveProps {
  candidateJobId: string
}

export const ApplicantArchive = ({
  candidateJobId
}: ApplicantArchiveProps): JSX.Element => {
  const [isArchiving, setIsArchiving] = useState(false)
  const { rejectCandidate } = useRejectCandidate()

  const handleReject = useCallback((rejectionReason?: CandidateJobRejectionReason): void => {
    rejectCandidate({
      candidateJobIds: [candidateJobId],
      rejectionReason,
      onSettled: () => {
        setIsArchiving(false)
      }
    })
  }, [rejectCandidate, candidateJobId])

  const items = useMemo(() => {
    const items: MenuItemProps[] = [{
      type: 'label',
      title: 'Archive'
    }]
    items.push(...map(sourcingRejectionReasons, (reason, key) => ({
      id: key,
      title: reason,
      onSelect: () => {
        setIsArchiving(true)
        handleReject(key as CandidateJobRejectionReason)
      }
    })))
    return items
  }, [handleReject])
  return (
    <Dropdown
      trigger={
        <Button
          nested
          $fontSize={12}
          $variant="raised"
          $colorTheme="negative"
          $height={24}
          leadingIcon={Icons.archive}
          loading={isArchiving}
        >
          Archive
        </Button>
      }
      items={items}
      size="small"
    />
  )
}
