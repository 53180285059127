import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OnboardingHeader } from 'src/components/blocks/onboarding-header'
import { StandaloneCheckbox } from 'src/components/forms/checkbox/standalone-checkbox'
import { Box } from 'src/components/primitives/box'
import { Button } from 'src/components/primitives/button'
import { Grid } from 'src/components/primitives/grid'
import { Spacer } from 'src/components/primitives/spacer'
import { SourcingSuccessSvg } from 'src/components/primitives/svgs/sourcing-success'
import { Caption } from 'src/components/primitives/typography'
import { useOnboardingSteps } from 'src/hooks/use-onboarding-steps'
import { useSession } from 'src/hooks/use-session'
import { updateTrackedUser } from 'src/libs/track'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { When } from 'src/components/blocks/when'

const HEARD_ABOUT_OPTIONS = [
  'Software Reviews',
  'News & Media',
  'Google Search',
  'Advertisement',
  'Events',
  'Facebook',
  'LinkedIn',
  'Word of mouth and peers',
  'ChatGPT or other AI',
  'Other'
]

const OnboardingSuccess = (): JSX.Element => {
  const navigate = useNavigate()
  const [heardAboutValues, setHeardAboutValues] = useState<string[]>([])
  const { nextStep } = useOnboardingSteps()
  const { user } = useSession()
  const { data: orgUsers } = useOrgUsersQuery()
  const isFirstUserBeingOnboardingForOrg = orgUsers?.length === 1 && orgUsers[0].id === user?.id

  const handleSubmit = async (): Promise<void> => {
    if (heardAboutValues.length > 0) {
      updateTrackedUser({
        heardAbout: heardAboutValues.join(', ')
      })
    }
    if (nextStep?.href) {
      navigate(nextStep.href)
    }
  }

  return (
    <>
      <Box $offset={{ x: -50 }}>
        <SourcingSuccessSvg />
      </Box>
      <Spacer $size={36} />
      <OnboardingHeader
        heading="You’re all set!"
        subheading="We’ve picked some candidates for you."
      />
      <When condition={isFirstUserBeingOnboardingForOrg}>
        <Caption size="SM" $color="fgPrimary">How did you hear about Pin?</Caption>
        <Spacer $size={16} />
        <Grid $columns={2} $columnGap={8} $rowGap={8}>
          {HEARD_ABOUT_OPTIONS.map((option) => (
            <StandaloneCheckbox
              key={option}
              $variant="outline"
              name={option}
              label={option}
              onChange={(checked) => {
                if (checked) {
                  setHeardAboutValues([...heardAboutValues, option])
                } else {
                  setHeardAboutValues(heardAboutValues.filter((o) => o !== option))
                }
              }}
              $marginBottom={0}
            />
          ))}
        </Grid>
        <Spacer $size={40} />
      </When>
      <Button
        type="submit"
        $variant="fill"
        $colorTheme="tint"
        $height={40}
        $align="center"
        onClick={handleSubmit}
      >
        View Candidates
      </Button>
    </>
  )
}

export default OnboardingSuccess
