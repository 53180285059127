import styled from 'styled-components'

export const HiringForContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[24]};
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing[24]};
`

export const DepartmentsListGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[12]};
  margin-bottom: 1rem;
`

export const DepartmentsListHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  justify-content: space-between;
  padding: 0 0.25rem;
`

export const DepartmentDetails = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing[12]};
  justify-content: flex-start;
  padding: 0.75rem 1rem;
`

export const DepartmentsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  overflow: hidden;
  ${DepartmentDetails} {
    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.bgSecondary};
    }
  }
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 0.5rem;
`

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[12]};
  padding: ${({ theme }) => theme.spacing[36]};
`
