import { redirect } from 'react-router-dom'
import { sessionQuery } from 'src/hooks/queries/use-session'
import queryClient from 'src/hooks/query-client'
import RouteBuilder from '../route-builder'

export const trialExpiredLoader = async (): Promise<Response | null> => {
  const { trialActive, subscribed } = await queryClient.ensureQueryData(sessionQuery())
  if (!!subscribed || !!trialActive) {
    return redirect(RouteBuilder.build('ROOT'))
  }
  return null
}
