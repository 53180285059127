import type { Spacing } from 'src/styles/theme/types'
import type { ButtonStyleProps } from '../button'
import { Icon, Icons } from '../icon'
import type { IconName } from '../icon'
import * as S from './double-button.styled'
import { Dropdown } from '../dropdown'
import type { MenuItemProps } from '../dropdown'
import { Button as DefaultButton } from '../button'
import { getIconColor } from '../button/utils'

export interface DoubleButtonStyleProps extends Omit<ButtonStyleProps, '$height'> {
  $height?: Spacing
}

interface DoubleButtonProps extends DoubleButtonStyleProps {
  icon: IconName
  children: string
  onPrimaryClick: () => void
  secondaryItems?: MenuItemProps[]
}

export const DoubleButton = ({
  $variant = 'raised',
  $colorTheme = 'muted',
  $height = 48,
  icon,
  children,
  onPrimaryClick,
  secondaryItems
}: DoubleButtonProps): JSX.Element => {
  return (
    <S.DoubleButton
      $height={$height}
      $variant={$variant}
      $colorTheme={$colorTheme}
      $hasNoSecondaryAction={!secondaryItems}
    >
      <S.Primary onClick={onPrimaryClick} $variant={$variant} $colorTheme={$colorTheme} $hasNoSecondaryAction={!secondaryItems}>
        <Icon name={icon} size={14} color={getIconColor($variant, $colorTheme)} />
        {children}
      </S.Primary>
      {
        secondaryItems && (
          <S.SecondaryWrapper>
            <Dropdown
              trigger={
                <DefaultButton
                  nested
                  $fontSize={12}
                  $width={16}
                  $height={16}
                  $variant="flat"
                  $colorTheme={$colorTheme}
                  leadingIcon={Icons.chevronDown}
                />
              }
              items={secondaryItems}
              size="small"
              menuPosition="end"
              />
          </S.SecondaryWrapper>
        )
      }
    </S.DoubleButton>
  )
}
