import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { AuthContext } from 'src/contexts/auth'
import type { User } from 'src/libs/api/backend/users'
import type { Org } from 'src/libs/api/backend/orgs'
import { SessionNotLoggedInError } from 'src/libs/api/backend/session'
import type { OnboardingStatus, EmailAccount } from 'src/libs/api/backend/session'
import { useSession } from 'src/hooks/queries/use-session'
import { isNil } from 'lodash'

export const AuthProvider = ({ children }: React.PropsWithChildren): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState<User>({} as User)
  const [org, setOrg] = useState<Org>({} as Org)
  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus | null>(null)
  const [emailAccountAccessTokens, setEmailAccountAccessTokens] = useState<EmailAccount[]>([])
  const [orgLogoUrl, setOrgLogoUrl] = useState<string | null>(null)
  const location = useLocation()
  const navigate = useNavigate()
  const { data: sessionContext, error: sessionError } = useSession()

  useEffect(() => {
    setIsLoading(true)

    if (sessionError instanceof SessionNotLoggedInError) {
      setUser({} as User)
      setOrg({} as Org)
      setOnboardingStatus(null)
      setEmailAccountAccessTokens([])

      if (location.pathname !== '/login' && !location.pathname.startsWith('/invite/') && !location.pathname.startsWith('/meeting-booked')) {
        navigate('/login')
      }

      setIsLoading(false)
    } else if (!isNil(sessionContext)) {
      const orgOnboardingStatus: OnboardingStatus = sessionContext.onboarding

      setUser(sessionContext.user)
      setOrg(sessionContext.org ?? {} as Org) // TODO: Remove this fallback, have to understand why we're using {} as Org
      setOnboardingStatus(orgOnboardingStatus)
      setEmailAccountAccessTokens(sessionContext.emailAccountAccessTokens)
      setOrgLogoUrl(sessionContext.logoUrl ?? null)

      if (sessionContext.impersonating) {
        // @ts-expect-error Intercom is loaded in the window
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'f1g33stu',
          user_id: user.id,
          user_hash: sessionContext.intercomUserHash,
          name: user.name,
          email: emailAccountAccessTokens[0]?.email,
          company: {
            company_id: org.id,
            name: org.name,
            website: org.domain
          }
        })
      } else {
        // @ts-expect-error Intercom is loaded in the window
        window.Intercom('shutdown')
      }

      if (location.pathname === '/') {
        navigate('/login/redirect')
      }

      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, sessionContext, sessionError])

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        setIsLoading,
        user,
        setUser,
        org,
        setOrg,
        onboardingStatus,
        setOnboardingStatus,
        emailAccountAccessTokens,
        setEmailAccountAccessTokens,
        orgLogoUrl
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
