import { useEffect, useState } from 'react'
import * as RadixSelect from '@radix-ui/react-select'
import { FieldLabel } from '../field-label'
import { FieldError } from '../field-error'
import type { FieldCommonProps } from '../common'
import { FormElement } from '../form-element'
import { Icon } from 'src/components/primitives/icon'
import { Avatar } from 'src/components/primitives/avatar'
import * as S from './select.styled'
import { When } from 'src/components/blocks/when'
import { isNil } from 'lodash'
import { Spinner } from 'src/components/primitives/spinner'
import { Flex } from 'src/components/primitives/flex'
import { Paragraph } from 'src/components/primitives/typography'
import { Spacer } from 'src/components/primitives/spacer'
import type { FontSize } from 'src/styles/theme/types'

export interface SelectItem {
  value: string
  title: string
  image?: JSX.Element
  trailingIcon?: JSX.Element
  disabled?: boolean
}

interface CreateItem {
  value: string
  title: string
  onClick: () => void
}

interface SelectProps extends FieldCommonProps {
  placeholder: string
  defaultValue?: string
  items: SelectItem[]
  createItem?: CreateItem
  createItemIsSticky?: boolean
  emptyStateText?: string
  showIcons?: boolean
  $maxHeight?: number
  $height?: number
  $fontSize?: FontSize
  onValueChange?: (value: string) => void
  onReset?: () => void
  disabled?: boolean
  loading?: boolean
}

export const Select = ({
  placeholder,
  name,
  label,
  hiddenLabel = false,
  description,
  defaultValue,
  items,
  showIcons = true,
  register,
  $marginBottom = 16,
  $maxHeight,
  $height,
  $fontSize = 14,
  createItem,
  createItemIsSticky = false,
  emptyStateText,
  onValueChange = undefined,
  onReset = undefined,
  disabled = false,
  loading = false
}: SelectProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const [defaultSelected, setDefaultSelected] = useState(defaultValue)
  const { onSelect, error, value } = register(name)

  useEffect(() => {
    if (defaultValue) {
      setDefaultSelected(defaultValue)
      onSelect(defaultValue)
    }
  }, [defaultValue])

  return (
    <FormElement $marginBottom={$marginBottom}>
      <RadixSelect.Root
        open={isOpen}
        disabled={disabled}
        onOpenChange={setIsOpen}
        onValueChange={(value: string) => {
          onSelect(value)

          if (!isNil(onValueChange)) {
            onValueChange(value)
          }
        }}
        defaultValue={defaultSelected}
        value={value as string}
      >
        {
          !hiddenLabel && (
            <S.Label>
              <FieldLabel
                label={label}
                htmlFor={name}
                hiddenLabel={hiddenLabel}
                description={description}
                marginBottom={0}
              />
              {onReset && typeof value === 'string' && value && (
                <S.ResetButton type="button" onClick={onReset}>
                  Clear
                </S.ResetButton>
              )}
            </S.Label>
          )
        }
        <S.Trigger aria-label={placeholder} $isLoading={loading} $fontSize={$fontSize} $height={$height}>
          <RadixSelect.Value placeholder={placeholder} />
          {loading
            ? <Spinner />
            : <S.TriggerIcon>
                <Icon name="chevrons-up-down" size={12} />
              </S.TriggerIcon>
          }
        </S.Trigger>
        <RadixSelect.Portal>
          <S.Content position="popper" sideOffset={6}>
            <S.Viewport $maxHeight={$maxHeight} $hasStickyItem={createItemIsSticky}>
              <RadixSelect.Group>
                {items?.map((item) => (
                  <S.Item key={item.value} value={item.value} disabled={item.disabled} $hasStickyItem={createItemIsSticky} $fontSize={$fontSize}>
                    <S.ItemMain>
                      <When condition={showIcons}>
                        {item.image ?? <Avatar initials={item.title} $size={20} $shape="soft" />}
                      </When>
                      <RadixSelect.ItemText asChild>
                        <S.ItemText $fontSize={$fontSize}>{item.title}</S.ItemText>
                      </RadixSelect.ItemText>
                    </S.ItemMain>
                    {item.trailingIcon}
                  </S.Item>
                ))}
                {!items.length && emptyStateText && (
                  <S.EmptyState>
                  <Flex $gap={8} $direction="column" $align="center">
                    <Icon name="binoculars" size={24} color="fgTranslucent10" />
                    <Paragraph size="SM" $color="fgSecondary" $align="center">
                      {emptyStateText}
                    </Paragraph>
                    <Spacer $size={16} />
              </Flex>
                  </S.EmptyState>
                )}
              </RadixSelect.Group>
              {!createItemIsSticky && createItem?.title
                ? (
                    <S.CreateItemSection>
                      <S.CreateItemButton
                        onClick={() => {
                          createItem.onClick()
                          setIsOpen(false)
                        }}
                        $fontSize={$fontSize}
                      >
                        <S.CreateItemButtonIcon>
                          <Icon name="plus" color="tintBg" />
                        </S.CreateItemButtonIcon>
                        {createItem.title}
                      </S.CreateItemButton>
                    </S.CreateItemSection>
                  )
                : null}
              <When condition={createItemIsSticky}>
                <S.StickyItem>
                    <S.CreateItemButton
                      onClick={() => {
                        createItem?.onClick()
                        setIsOpen(false)
                      }}
                    >
                      <S.CreateItemButtonIcon>
                        <Icon name="plus" color="tintBg" />
                      </S.CreateItemButtonIcon>
                      {createItem?.title}
                    </S.CreateItemButton>
                </S.StickyItem>
              </When>
            </S.Viewport>
          </S.Content>
        </RadixSelect.Portal>
      </RadixSelect.Root>
      {error ? <FieldError id={`${name}-error`}>{error}</FieldError> : null}
    </FormElement>
  )
}
