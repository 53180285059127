import styled from 'styled-components'

export const AuthLayout = styled.div`
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  padding: 1rem;
  @media screen and (max-width: 1024px) {
    align-items: flex-start;
    padding: 0.5rem;
  }
`

export const AuthInner = styled.div`
  width: 100%;
  aspect-ratio: 10 / 6.67;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1512px), screen and (max-height: 880px) {
    height: 100%;
    max-width: unset;
    aspect-ratio: unset;
    box-shadow: unset;
    overflow: unset;
    padding: 0;
  }
  @media screen and (max-width: 1024px) {
    aspect-ratio: unset;
    flex-direction: column;
    justify-content: flex-start;
    max-width: unset;
    height: unset;
  }
`

export const Spotlight = styled.div`
  position: relative;
  padding: 1rem;
  border-radius: 1rem;
  flex: 0 0 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    padding: 0;
    width: 100%;
    flex: unset;
    justify-content: flex-start;
    height: auto;
  }
`

export const SpotlightInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 2;
  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
    gap: 2.5rem;
  }
`

export const SpotlightMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4rem;
  @media screen and (max-width: 1024px) {
    gap: 2.5rem;
  }
`

export const SpotlightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 5rem 0;
  @media screen and (max-width: 1024px) {
    padding: 2.5rem 0;
  }
  @media screen and (max-width: 800px) {
    padding: 0;
    gap: 0.75rem;
  }
`

export const AuthForm = styled.div`
  flex: 0 0 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  padding: 2.5rem;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  @media screen and (max-width: 1024px) {
    padding: 2rem 1.5rem 1.5rem 1.5rem;
    width: 100%;
    flex: unset;  
    justify-content: flex-start;
    height: auto;
  }
`

export const AuthFormInner = styled.div`
  max-width: 400px;
  width: 100%;
`

export const BackLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  p {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.fgSecondary};
    font-size: ${({ theme }) => theme.fontSizes[14]};
    span {
      all: inherit;
      text-decoration: underline;
      margin-left: 0.25rem;
    }
  }
`
