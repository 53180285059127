import { useCallback, useMemo, useState } from 'react'
import * as S from './applicants-ats.styled'
import { Button, Flex } from 'src/components/primitives'
import { Caption } from 'src/components/primitives/typography'
import { useSession } from 'src/hooks/use-session'
import type { MergeIntegration } from 'src/libs/api/backend/merge'
import { isNil } from 'lodash'
import { titleCase } from 'src/libs/string'
import { Avatar } from 'src/components/primitives/avatar'
import { Select } from 'src/components/forms/select'
import type { SelectItem } from 'src/components/forms/select'
import { useSuspenseQuery } from '@tanstack/react-query'
import { getMergeJobs } from 'src/hooks/queries/use-get-merge-jobs'
import { useLinkJobWithMergeJob } from 'src/hooks/mutations/use-link-job-with-merge-job'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useForm } from 'src/hooks/use-form'
import { jobQuery } from 'src/hooks/queries/use-job'
import { Spinner } from 'src/components/primitives/spinner'
import RouteBuilder from 'src/libs/route-builder'

interface ATSJobSelectionProps {
  isSyncing: boolean
  mergeIntegrations: MergeIntegration[]
}

const mergeAtsJobSchema = z.object({
  mergeJobId: z.string().nullish()
})
type MergeAtsJobSchema = z.infer<typeof mergeAtsJobSchema>

export const ATSJobSelection = ({ isSyncing, mergeIntegrations }: ATSJobSelectionProps): JSX.Element => {
  const [submitting, setSubmitting] = useState(false)
  const { register, formData } = useForm<MergeAtsJobSchema>({
    schema: mergeAtsJobSchema
  })

  const { org } = useSession()
  const navigate = useNavigate()
  const { data: mergeJobs = [] } = useSuspenseQuery(getMergeJobs())
  const { linkJobWithMergeAtsJob } = useLinkJobWithMergeJob()
  const { jobId } = useParams()
  const { data: job } = useSuspenseQuery(jobQuery(jobId ?? ''))
  const [titleNotMatched, setTitleNotMatched] = useState(false)
  const { integrationName, integrationImage } = useMemo(() => {
    let integrationName: string | null = null
    let integrationImage = null
    if (!isNil(org) && !isNil(org?.mergeAtsIntegration)) {
      integrationName = titleCase(org.mergeAtsIntegration)
      integrationImage = mergeIntegrations.find(integration => integration.name === integrationName)?.square_image
    }
    return { integrationName, integrationImage }
  }, [mergeIntegrations, org])

  const mergeJobsMap = useMemo(() => {
    const mergeJobMap = new Map<string, SelectItem>()
    for (const mergeJob of mergeJobs) {
      mergeJobMap.set(mergeJob.id, {
        value: mergeJob.id,
        title: mergeJob.name
      })
    }

    return mergeJobMap
  }, [mergeJobs])

  const mergeJobsList = useMemo(() => {
    return [...mergeJobsMap.values()]
  }, [mergeJobsMap])

  const checkTitleNotMatched = useCallback((mergeJobId: string): void => {
    const mergeJobName = mergeJobsMap.get(mergeJobId)?.title ?? ''
    // Trim whitespace and remove any special characters to compare
    const trimmedMergeJobName = mergeJobName.toLowerCase().trim().replace(/[^a-zA-Z0-9]/g, '')
    const trimmedJobTitle = (job?.title ?? '').toLowerCase().trim().replace(/[^a-zA-Z0-9]/g, '')
    setTitleNotMatched(trimmedMergeJobName !== trimmedJobTitle)
  }, [job?.title, mergeJobsMap])

  return (
    <S.ATSJobSelectionContainer>
      <Flex $direction="column" $gap={4}>
        <Caption size="MD">Connect ATS Job Listing</Caption>
        <Caption size="SM" $fontWeight={400}>
          Choose which job listing to import applicants from
        </Caption>
      </Flex>
      <Flex $direction="column" $gap={12}>
        <Flex $align='center' $gap={10}>
          <Avatar
            $type='logo'
            $size={24}
            company={{
              name: integrationName ?? '',
              externalLogoUrl: integrationImage
            }}
          />
          <Caption>{integrationName}</Caption>
        </Flex>
        {isSyncing
          ? <S.JobSelectionLoading>
              <Spinner />
              <Caption size="SM" $fontWeight={400} $color="fgSecondary">Getting jobs from your ATS</Caption>
            </S.JobSelectionLoading>
          : <S.ATSSelect>
              <Select
                name="mergeJobId"
                hiddenLabel
                register={register}
                placeholder="Choose ATS job listing"
                items={mergeJobsList}
                $marginBottom={0}
                onValueChange={(value: string) => {
                  checkTitleNotMatched(value)
                }}
              />
            </S.ATSSelect>
        }
        {titleNotMatched && (
          <S.TitleNotMatched>
            <Caption size="SM" $color="negativeFg">Job titles don’t match</Caption>
            <Caption size="XS" $color="negativeFg" $fontWeight={400}>Are you sure you’re connecting to the correct job listing?</Caption>
          </S.TitleNotMatched>
        )}
      </Flex>
      <Button
        $width="fit-content"
        disabled={!formData.mergeJobId || !jobId}
        onClick={() => {
          if (jobId && formData.mergeJobId) {
            setSubmitting(true)
            linkJobWithMergeAtsJob({
              jobId,
              mergeAtsJobId: formData.mergeJobId as string,
              onSuccess: () => {
                navigate(RouteBuilder.build('JOBS_APPLICANTS_CANDIDATES', { jobId }))
              },
              onError: () => {
                setSubmitting(false)
              }
            })
          }
        }}
        $variant='fill'
        $colorTheme='tint'
        $height={40}
        loading={submitting}
      >
        Connect ATS Job Position
      </Button>
    </S.ATSJobSelectionContainer>
  )
}
