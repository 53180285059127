import { Logo } from 'src/components/primitives/logo'
import * as S from './auth-layout-v2.styled'
import { Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { useWindowSize } from 'usehooks-ts'
import { LOGIN_PAGE_TESTIMONIAL_QUOTES } from 'src/libs/data'

export const AuthLayoutV2 = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { width } = useWindowSize()

  const randomQuote = LOGIN_PAGE_TESTIMONIAL_QUOTES[Math.floor(Math.random() * LOGIN_PAGE_TESTIMONIAL_QUOTES.length)]

  return (
    <S.AuthLayout>
      <S.AuthInner>
        <S.AuthForm>
          <S.AuthFormInner>
            <Logo variant="dark" size={width > 768 ? 84 : 64} />
            <div>
              {children}
            </div>
          </S.AuthFormInner>
        </S.AuthForm>
        <S.Spotlight>
          <S.SpotlightInner>
            <S.QuoteBox>
              <Paragraph size="MD" $color="fgPrimary">&quot;{randomQuote.quote}&quot;</Paragraph>
              <S.QuoteAuthor>
                <S.QuoteAuthorAvatar>
                  <S.QuoteAuthorProfile>
                    {/* <img src="/onboarding/onboarding-3.jpg" alt="Quotee profile photo" /> */}
                    <img src={`/onboarding/testimonials/${randomQuote.headshot}`} alt={`${randomQuote.name} headshot`} />
                  </S.QuoteAuthorProfile>
                  {/* <S.QuoteAuthorCompany>
                    <img src="/onboarding/dummy-logo-1.jpg" alt="Apple" />
                  </S.QuoteAuthorCompany> */}
                </S.QuoteAuthorAvatar>
                <Flex $direction="column" $gap={0} $justify="center">
                  <Paragraph size="SM" $color="fgPrimary">{randomQuote.name}</Paragraph>
                  <Paragraph size="SM" $color="fgPrimary">{randomQuote.context}</Paragraph>
                </Flex>
              </S.QuoteAuthor>
            </S.QuoteBox>
            <S.PreviewBox>
              <S.Preview>
                <img src="/onboarding/preview-1.jpg" alt="Preview of the Pin.com web application" />
              </S.Preview>
            </S.PreviewBox>
          </S.SpotlightInner>
        </S.Spotlight>
      </S.AuthInner>
    </S.AuthLayout>
  )
}
