import { Spacer } from 'src/components/primitives/spacer'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { When } from '../when'
import { isNil } from 'lodash'
import { useWindowSize } from 'usehooks-ts'

interface OnboardingHeaderProps {
  heading: string
  subheading?: string
}

export const OnboardingHeader = ({ heading, subheading }: OnboardingHeaderProps): JSX.Element => {
  const { width } = useWindowSize()

  return (
    <>
      <Caption size="LG">{heading}</Caption>
      <When condition={!isNil(subheading)}>
        <>
          <Spacer $size={8} />
          <Paragraph size={width <= 1024 ? 'MD' : 'SM'} $color={width <= 1024 ? 'fgTertiary' : 'fgSecondary'}>{subheading}</Paragraph>
        </>
      </When>
      <Spacer $size={width <= 1024 ? 20 : 40} />
    </>
  )
}
