import { CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'
import { EmptyStateArchivedJob } from '../empty-state-archived-job'
import { useMemo } from 'react'
import { StatusView } from '../status-view'
import { SourcingView } from './sourcing-view'
import { EmptyState } from '../empty-state'
import { JobSourcingState } from 'src/libs/api/backend/jobs'
import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { useParams } from 'react-router-dom'
import { LoadingSkeleton } from '../loading-skeleton'
import { PinExtensionChromeStoreUrl } from '../../../constants'
import type { ViewMode } from '../../../constants'
import { usePinExtensionVersion } from '../../../hooks/use-pin-extension'
import { useIsChrome } from '../../../hooks/use-is-browser'
import { Paragraph } from 'src/components/primitives/typography'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { TweakSearchRefinementCriteriaBanner } from '../tweak-search-refinement-criteria-banner'
import type { TweakSearchRefinementProps } from '../tweak-search-refinement-criteria-banner'
import { isNil } from 'lodash'

interface SourcingStatusProps extends TweakSearchRefinementProps {
  jobSearchRefinement?: JobSearchRefinement
  isSourcingView: boolean
  sourcingState?: JobSourcingState | null
  isManualSourceView: boolean
  isRecommending: boolean
  isEmpty: boolean
  lastRequestedAt: Date
  isArchived: boolean
  isLoading?: boolean
  viewMode: ViewMode
}

export const SourcingStatus = ({
  jobSearchRefinement,
  isSourcingView,
  sourcingState,
  isManualSourceView,
  isRecommending,
  isEmpty,
  lastRequestedAt,
  isArchived,
  isLoading = false,
  viewMode,
  onUpdateSearchRefinementCriteria
}: SourcingStatusProps): JSX.Element | null => {
  const openDialog = useSetAtom(openDialogAtom)
  const { jobId } = useParams()
  const pinExtensionVersion = usePinExtensionVersion()
  const isChrome = useIsChrome()

  const emptyView = useMemo(() => {
    if (isNil(jobSearchRefinement)) {
      return null
    }

    return (
      <StatusView
        pin="sad"
        animatePin
        children={
          <TweakSearchRefinementCriteriaBanner
            heading="We couldn't find any candidates that match all criteria."
            subheading="Consider adjusting your criteria"
            jobSearchRefinement={jobSearchRefinement}
            onUpdateSearchRefinementCriteria={onUpdateSearchRefinementCriteria}
            $padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
            $contentAlign="center"
          />
        }
        $contentMaxWidth={640}
      />
    )
  }, [jobSearchRefinement, onUpdateSearchRefinementCriteria])

  const errorContent = useMemo(() => {
    switch (sourcingState) {
      case JobSourcingState.NO_CANDIDATES_ERROR:
        if (!isEmpty) {
          // This happens when there's no more additional candidates to source
          return null
        }
        return emptyView
      case JobSourcingState.SEARCH_EMPTY_ERROR:
        return (
          <StatusView
            pin="sad"
            animatePin
            heading="Something went wrong"
            description="Your search could not be interpreted correctly. Please review and try again."
          />
        )
      case JobSourcingState.SEARCH_NOT_SUPPORTED_ERROR:
        return (
          <StatusView
            pin="sad"
            variant="negative"
            animatePin
          >
            <Paragraph size="XS" $color="fgSecondary">Your search contains one or more criteria that aren&rsquo;t allowed by Pin.</Paragraph>
            <Paragraph size="XS" $color="fgSecondary">Please review and try again.</Paragraph>
          </StatusView>
        )
      case JobSourcingState.INTERNAL_ERROR:
        return (
          <StatusView
            pin="sad"
            animatePin
            heading="Something went wrong"
            description="Can’t find candidates at this moment."
          />
        )
      default:
        return null
    }
  }, [sourcingState, emptyView])

  const content = useMemo(() => {
    if (isArchived) {
      return <EmptyStateArchivedJob />
    }

    if (errorContent != null) {
      return errorContent
    }

    if (isLoading) {
      return <LoadingSkeleton $variant="CandidateDetailsCard" delay={300} />
    }

    if (isSourcingView) {
      return (
        <SourcingView
          isRecommending={isRecommending}
          isEmpty={isEmpty}
          emptyView={emptyView ?? null}
          lastRequestedAt={lastRequestedAt}
          viewMode={viewMode}
          onUpdateSearchRefinementCriteria={onUpdateSearchRefinementCriteria}
        />
      )
    }

    if (isManualSourceView && isEmpty) {
      return (
        <EmptyState
          delay={300}
          heading="Manually Added"
          description="Add candidates by their LinkedIn URL to manually add them for consideration."
          svg="userCards"
          actions={[
            {
              children: 'Add Candidate',
              onClick: () => {
                openDialog({ id: DialogId.ADD_CANDIDATE, payload: { jobId } })
              }
            },
            ...(pinExtensionVersion != null || !isChrome
              ? []
              : [
                  {
                    children: 'Install Chrome Extension',
                    href: PinExtensionChromeStoreUrl,
                    leadingIcon: 'chrome'
                  }])
          ]}
        />
      )
    }
    return null
  }, [
    isArchived,
    errorContent,
    isLoading,
    isSourcingView,
    isManualSourceView,
    isEmpty,
    isRecommending,
    lastRequestedAt,
    viewMode,
    onUpdateSearchRefinementCriteria,
    pinExtensionVersion,
    isChrome,
    openDialog,
    jobId
  ])

  return (
    content
      ? <div
          style={{
            // maxWidth: renderedCandidates?.length ? CANDIDATES_PAGES_MAX_WIDTH : '100%',
            maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
            flex: 1
            // paddingBottom: spacing[48]
          }}
        >
          {content}
        </div>
      : null
  )
}
