import { queryOptions, useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { getApplicantsOverviewStatus } from 'src/libs/api/backend/applicants'
import type { ApplicantsOverviewStatus } from 'src/libs/api/backend/applicants'
import type { Job } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'

export const applicantsOverviewStatusQuery = (jobId: Job['id']) => queryOptions({
  queryKey: [queryKeys.applicantsOverviewStatus, jobId],
  queryFn: async () => await getApplicantsOverviewStatus(jobId),
  staleTime: 60 * 1000
})

export const useApplicantsOverviewStatusQuery = (jobId?: Job['id']): UseQueryResult<ApplicantsOverviewStatus> => {
  return useQuery({
    ...applicantsOverviewStatusQuery(jobId ?? ''),
    enabled: !!jobId
  })
}
