import { Icon } from 'src/components/primitives/icon'
import * as S from './subscribe-cta-card.styled'
import { Span } from 'src/components/primitives/typography'
import { Button, Flex } from 'src/components/primitives'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { SettingParams } from 'src/components/dialogs/default-settings-dialog'
import { useQueryParams } from 'src/hooks/use-query-params'

interface UpgradeCtaBannerProps {
  heading: string
  benefits: string[]
}

export const UpgradeCtaBanner = ({ heading, benefits }: UpgradeCtaBannerProps): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)
  const { setParam } = useQueryParams()

  return (
    <S.Banner>
      <S.Inner>
        <S.Heading>
          <Icon name="lock" />
          <h3>{heading}</h3>
        </S.Heading>
        <S.UpgradeBenefits>
          {benefits.map((benefit) => (
            <li key={benefit}>
              <S.UpgradeBenefitIcon>
                <Icon name="check" color="fgPrimary" size={12} />
              </S.UpgradeBenefitIcon>
              <Span size="SM" $color="fgPrimary">
                {benefit}
              </Span>
            </li>
          ))}
        </S.UpgradeBenefits>
        <Flex $align="center">
          <Button
            $variant="fill"
            $colorTheme="tint"
            $height={40}
            onClick={() => {
              setParam('settings', SettingParams.SUBSCRIPTION)
              openDialog({ id: DialogId.DEFAULT_SETTINGS })
            }}
          >
            Subscribe to Pin
          </Button>
        </Flex>
      </S.Inner>
    </S.Banner>
  )
}
