import type { Spacing } from 'src/styles/theme/types'
import * as S from './card.styled'

export interface CardStyleProps {
  $variant?: 'raised' | 'outline'
  $padding?: {
    top?: Spacing
    right?: Spacing
    bottom?: Spacing
    left?: Spacing
  }
  $minHeight?: number
}

export interface CardProps extends CardStyleProps {
  children: React.ReactNode
}

export const Card = ({
  $variant = 'raised',
  $padding = { top: 24, right: 24, bottom: 24, left: 24 },
  $minHeight,
  children
}: CardProps): JSX.Element => {
  return <S.Card $variant={$variant} $padding={$padding} $minHeight={$minHeight}>{children}</S.Card>
}
