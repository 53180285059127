import { OnboardingHeader } from 'src/components/blocks/onboarding-header'
import { Box } from 'src/components/primitives/box'
import { Button } from 'src/components/primitives/button'
import { Spacer } from 'src/components/primitives/spacer'
import { LocationNoticeSvg } from 'src/components/primitives/svgs/location-notice'
import { useOnboardingSteps } from 'src/hooks/use-onboarding-steps'

const OnboardingLocationNotice = (): JSX.Element => {
  const { nextStep } = useOnboardingSteps()

  return (
    <>
      <Box>
        <LocationNoticeSvg />
      </Box>
      <Spacer $size={36} />
      <OnboardingHeader
        heading="United States & Canada"
        subheading="Pin only supports searching candidates in the U.S. and Canada."
      />
      <Button
        type="submit"
        $variant="fill"
        $colorTheme="tint"
        $height={40}
        $align="center"
        href={nextStep?.href}
      >
        Continue
      </Button>
    </>
  )
}

export default OnboardingLocationNotice
