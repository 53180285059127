export const NAV_HEIGHT_LG = '0px'
export const ONBOARDING_NAV_HEIGHT_LG = '56px'

export const DIALOG_HEADER_HEIGHT_LG = '56px'

export const SIDEBAR_WIDTH = {
  LG: {
    CLOSED: '106px',
    OPEN: '304px'
  }
}

export const NAVIGATION_WIDTH = {
  LG: {
    CLOSED: '68px',
    OPEN: '264px'
  },
  MD: {
    CLOSED: '68px',
    OPEN: '264px'
  }
}

export const CONTENT_PADDING = '0.5rem'

export const CANDIDATES_HEADER_PADDING = CONTENT_PADDING

export const HEADER_HEIGHT = '28px'

export const HEADER_PADDING = '18px'

export const SIDEBAR_PADDING = '1.25rem'

export const SIDEBAR_PADDING_COLLAPSED = '0.75rem'

export const CANDIDATES_TABLE_HEADER_HEIGHT = '64px'

export const CANDIDATES_PAGES_MAX_WIDTH = '1084px'

export const INBOX_MAX_WIDTH = '800px'

export const ERROR_BLOCK_HEIGHT = '64px'
