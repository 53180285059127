import { map } from 'lodash'
import { useMemo, useState } from 'react'
import { Button } from 'src/components/primitives'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'
import { useAdvanceJobInterviewStage } from 'src/hooks/mutations/use-advance-job-interview-stage'
import { useRejectCandidate } from 'src/hooks/mutations/use-reject-candidate'
import { useAtsInterviewStagesQuery } from 'src/hooks/queries/use-ats-interview-stages'
import { sourcingRejectionReasons } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobRejectionReason, CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import type { Job } from 'src/libs/api/backend/jobs'

interface ApplicantsActionsBulkProps {
  candidateJobIds: Array<CandidateJobExpanded['id']>
  jobId: Job['id']
}

export const ApplicantsActionsBulk = ({
  candidateJobIds,
  jobId
}: ApplicantsActionsBulkProps): JSX.Element => {
  const { data: atsInterviewStages, isPending } = useAtsInterviewStagesQuery(jobId)
  const [expandUnmatchedActions, setExpandUnmatchedActions] = useState(false)
  const [isMovingCandidates, setIsMovingCandidates] = useState(false)
  const [isArchiving, setIsArchiving] = useState(false)
  const { advanceJobInterviewStage } = useAdvanceJobInterviewStage()
  const { rejectCandidate } = useRejectCandidate()

  const stageSubItems = useMemo(() => {
    const items: MenuItemProps[] = [{
      type: 'label',
      title: 'Move to Stage'
    }]
    if (atsInterviewStages) {
      items.push(...atsInterviewStages.map((stage) => ({
        type: 'item',
        id: stage.id,
        title: stage.mergeName ?? '',
        onSelect: () => {
          setIsMovingCandidates(true)
          advanceJobInterviewStage({
            jobId,
            candidateJobIds,
            destinationJobInterviewStageId: stage.id,
            onSuccess: () => {
              setIsMovingCandidates(false)
            }
          })
        }
      })))
    }
    return items
  }, [advanceJobInterviewStage, atsInterviewStages, candidateJobIds, jobId])

  const archiveSubItems = useMemo(() => {
    const items: MenuItemProps[] = [{
      type: 'label',
      title: 'Archive'
    }]
    items.push(...map(sourcingRejectionReasons, (reason, key) => ({
      type: 'item',
      id: key,
      title: reason,
      onSelect: () => {
        setIsArchiving(true)
        rejectCandidate({
          candidateJobIds,
          rejectionReason: key as CandidateJobRejectionReason,
          onSettled: () => {
            setIsArchiving(false)
          }
        })
      }
    })))
    return items
  }, [candidateJobIds, rejectCandidate])

  const items = useMemo((): MenuItemProps[] => {
    const items: MenuItemProps[] = [
      {
        type: 'description',
        title: `${candidateJobIds.length} candidates match your criteria`
      },
      {
        type: 'item',
        title: 'Move all to stage',
        icon: Icons.arrowRightCircle,
        variant: 'tint',
        isDisabled: isMovingCandidates || isPending,
        subitems: stageSubItems
      },
      {
        type: 'item',
        title: 'Archive all',
        icon: Icons.archive,
        variant: 'negative',
        isDisabled: isArchiving,
        subitems: archiveSubItems
      },
      {
        type: 'separator',
        title: 'actions-separator'
      },
      {
        type: 'description',
        title: '4 candidates don\'t match criteria',
        trailingIcon: expandUnmatchedActions ? Icons.chevronDownSmall : Icons.chevronRight,
        onSelect: () => {
          setExpandUnmatchedActions(!expandUnmatchedActions)
        }
      }
    ]
    if (expandUnmatchedActions) {
      items.push({
        type: 'item',
        title: 'Archive all not matching',
        icon: Icons.archive,
        variant: 'negative',
        onSelect: () => {
          console.log('archive all not matching')
        }
      })
    }
    return items
  }, [candidateJobIds.length, expandUnmatchedActions, isMovingCandidates, isPending, stageSubItems])

  return (
    <Dropdown
      trigger={
        <Button
          $variant='outline'
          $colorTheme='muted'
          $height={24}
          $fontSize={12}
          trailingIcon={Icons.chevronDown}
        >
          Actions
        </Button>
      }
      $minWidth='fit-content'
      menuPosition="end"
      items={items}
    />
  )
}
