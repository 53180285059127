import styled from 'styled-components'

export const IconBlock = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
  border-radius: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  position: relative;
`

export const CheckmarkBlock = styled.div`
  position: absolute;
  bottom: -4px;
  right: 4px;
`
