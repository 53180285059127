import { useEffect, useMemo, useState } from 'react'
import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import { When } from '../when'
import { Action } from './action'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { InboxMessageEditor } from '../inbox-message-editor'
import { InboxCommentEditor } from '../inbox-comment-editor'
import { isNil } from 'lodash'
import type {
  CandidateJobExpanded,
  SuggestedResponse
} from 'src/libs/api/backend/candidate_jobs'
import * as S from './inbox-actions.styled'
import { useCreateCandidateNote } from 'src/hooks/mutations/use-create-candidate-note'
import { useSendManualMessage } from 'src/hooks/mutations/use-send-manual-email'
import type { EmailMessage } from 'src/libs/api/backend/candidate_activities'
import { CandidateJobStatusDropdown } from '../candidate-job-status'
import { ActionType } from './action-type'
import { useSession } from 'src/hooks/use-session'
import { useSession as useSessionQuery } from 'src/hooks/queries/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { CalendarIcon } from '../calendar-icon'
import { format } from 'date-fns'
import { useCreateCalendarEvent } from 'src/hooks/mutations/use-create-calendar-event'
import type { NewCalendarEvent } from 'src/libs/api/backend/calendar_events'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import type { EmailData } from '../message-composer'
import { useSetAtom } from 'jotai'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { Dropdown } from 'src/components/primitives/dropdown'
import { EditorMessageType, SequenceStepType } from 'src/libs/api/backend/sequences'
import { convertSubjectToText } from '../editor/extensions/variable-parser'

interface InboxActionsProps {
  candidateJob: CandidateJobExpanded
  referenceEmailMessage: EmailMessage | null
  suggestedResponse: SuggestedResponse | null
  onSelectedActionChange?: (action: ActionType | null) => void
  onEditorHeightChange?: (height: number) => void
  onClose?: () => void
  selectedAction: ActionType | null
  setSelectedAction: (action: ActionType | null) => void
}

export const InboxActions = ({
  candidateJob,
  referenceEmailMessage,
  suggestedResponse,
  onSelectedActionChange,
  onEditorHeightChange,
  onClose,
  selectedAction,
  setSelectedAction
}: InboxActionsProps): JSX.Element => {
  const suggestedManualEmailInitialState = {
    body: suggestedResponse?.bodyHtml ?? '',
    subject: suggestedResponse?.subject ?? '',
    sendingEmailAccountId: suggestedResponse?.sendingEmailAccountId,
    sendingUserId: suggestedResponse?.sendingUserId ?? '',
    sendingEmailAlias: suggestedResponse?.sendingEmailAlias ?? null,
    sendingLinkedInAccountId: null,
    candidateIds: [candidateJob.candidateId]
  }
  const [suggestedManualEmail, setSuggestedManualEmail] = useState<EmailData & { sendingUserId: string, candidateIds: string[] }>(suggestedManualEmailInitialState)

  const [selectedEditorMessageType, setSelectedEditorMessageType] = useState<EditorMessageType>(EditorMessageType.EMAIL)

  const referenceManualMessageInitialState = useMemo(() => ({
    body: '',
    subject: selectedAction === 'MESSAGE_REPLY' ? referenceEmailMessage?.subject : '',
    sendingUserId: referenceEmailMessage?.userId ?? '',
    sendingEmailAccountId: referenceEmailMessage?.emailAccountId,
    sendingEmailAlias: referenceEmailMessage?.sendingEmailAlias ?? null,
    sendingLinkedInAccountId: null,
    candidateIds: [candidateJob.candidateId],
    type: selectedEditorMessageType === EditorMessageType.LINKEDIN_MAIL ? SequenceStepType.MANUAL_LINKEDIN_MESSAGE : SequenceStepType.MANUAL_EMAIL
  }), [
    selectedAction,
    referenceEmailMessage?.subject,
    referenceEmailMessage?.userId,
    referenceEmailMessage?.emailAccountId,
    referenceEmailMessage?.sendingEmailAlias,
    candidateJob.candidateId,
    selectedEditorMessageType
  ])

  const [referenceManualMessage, setReferenceManualMessage] = useState<EmailData & { sendingUserId: string, candidateIds: string[], type: SequenceStepType }>(referenceManualMessageInitialState)

  const [suggestedResponseFetched, setSuggestedResponseFetched] = useState(false)

  const [commentContent, setCommentContent] = useState<string>()
  const { createCandidateNote } = useCreateCandidateNote()
  const { sendManualMessage } = useSendManualMessage()
  const openDialog = useSetAtom(openDialogAtom)
  const { featureFlags } = useSession()
  const { createEvent } = useCreateCalendarEvent()
  const { data: sessionData } = useSessionQuery()
  const { data: orgUsers } = useOrgUsersQuery()
  const sessionEmailAccount = useMemo(() => {
    if (isNil(sessionData) || isNil(orgUsers)) {
      return null
    }

    const emailAccount = sessionData.emailAccountAccessTokens.find(token => token.isPrimary) ?? sessionData.emailAccountAccessTokens[0]
    const orgUser = orgUsers.find(user => user.emailAccounts.some(account => account.email === emailAccount.email))
    return orgUser?.emailAccounts?.find(account => account.email === emailAccount.email)
  }, [orgUsers, sessionData])

  const handleSendManualMessage = (manualEmail: EmailData): void => {
    if (selectedEditorMessageType === EditorMessageType.EMAIL && isNil(manualEmail.subject)) {
      return
    }
    if (isNil(manualEmail.body)) {
      return
    }
    if (isNil(manualEmail.sendingEmailAccountId)) {
      return
    }

    let referenceEmailMessageId: string | undefined
    if (selectedAction === ActionType.MESSAGE_REPLY) {
      referenceEmailMessageId = referenceEmailMessage?.id
    } else if (selectedAction === ActionType.SUGGESTED_RESPONSE) {
      referenceEmailMessageId = suggestedResponse?.latestEmailMessageId
    }

    sendManualMessage({
      manualMessage: {
        subject: convertSubjectToText(manualEmail.subject) ?? '',
        body: manualEmail.body,
        cc: manualEmail.cc ?? undefined,
        bcc: manualEmail.bcc ?? undefined,
        candidateIds: [candidateJob.candidateId],
        sendingEmailAccountId: manualEmail.sendingEmailAccountId,
        sendingEmailAlias: manualEmail.sendingEmailAlias ?? null,
        sendingLinkedInAccountId: manualEmail.sendingLinkedInAccountId ?? null,
        sendingUserId: manualEmail.sendingUserId ?? '',
        referenceEmailMessageId,
        attachmentUploads: manualEmail.attachmentUploads ?? undefined
      },
      messageType: selectedEditorMessageType
    })

    if (selectedAction === ActionType.SUGGESTED_RESPONSE && suggestedResponse?.calEventStart && suggestedResponse?.calEventEnd) {
      const newEvent: NewCalendarEvent = {
        title: suggestedResponse?.calEventTitle ?? 'Intro call',
        isPinEvent: true,
        startPosition: 0,
        endPosition: 0,
        start: suggestedResponse?.calEventStart,
        end: suggestedResponse?.calEventEnd,
        candidateJobId: candidateJob?.id,
        attendees: [
          {
            address: sessionEmailAccount?.email ?? '',
            name: sessionData?.user?.name ?? sessionEmailAccount?.email ?? ''
          },
          {
            address: candidateJob.candidate.emails?.[0] ?? '',
            name: candidateJob?.candidate?.name
          }
        ]
      }
      createEvent({ newEvent })
    }

    setSelectedAction(null)
  }

  useEffect(() => {
    if (!isNil(referenceEmailMessage)) {
      setReferenceManualMessage(prev => ({
        ...prev,
        candidateIds: [candidateJob.candidateId],
        subject: selectedAction === 'MESSAGE_REPLY' ? referenceEmailMessage?.subject : '',
        sendingUserId: referenceEmailMessage?.userId ?? '',
        sendingEmailAccountId: referenceEmailMessage?.emailAccountId,
        sendingEmailAlias: referenceEmailMessage?.sendingEmailAlias ?? null,
        type: selectedEditorMessageType === EditorMessageType.LINKEDIN_MAIL ? SequenceStepType.MANUAL_LINKEDIN_MESSAGE : SequenceStepType.MANUAL_EMAIL
      }))
    }
  }, [referenceEmailMessage, setSelectedAction, selectedAction, candidateJob.candidateId, selectedEditorMessageType])

  useEffect(() => {
    if (!isNil(referenceEmailMessage)) {
      setSelectedAction(ActionType.MESSAGE_REPLY)
    }
  }, [referenceEmailMessage, setSelectedAction])

  useEffect(() => {
    if (!suggestedResponseFetched && !isNil(suggestedResponse)) {
      setSuggestedResponseFetched(true)
      setSelectedAction(ActionType.SUGGESTED_RESPONSE)
    }
    if (suggestedResponseFetched && !isNil(suggestedResponse)) {
      setSuggestedManualEmail(prev => ({
        ...prev,
        subject: suggestedResponse.subject,
        body: suggestedResponse.bodyHtml,
        candidateIds: [candidateJob.candidateId],
        sendingUserId: suggestedResponse.sendingUserId ?? '',
        sendingEmailAccountId: suggestedResponse.sendingEmailAccountId,
        sendingEmailAlias: suggestedResponse?.sendingEmailAlias ?? null
      }))
    }
  }, [candidateJob.candidateId, setSelectedAction, suggestedResponse, suggestedResponseFetched])

  const handleAddComment = (): void => {
    if (isNil(commentContent)) {
      return
    }
    createCandidateNote({
      candidateId: candidateJob.candidateId,
      body: commentContent,
      onSuccess: () => {
        setSelectedAction(null)
      }
    })
  }

  useEffect(() => {
    if (!isNil(onSelectedActionChange)) {
      onSelectedActionChange(selectedAction)
    }
  }, [onSelectedActionChange, selectedAction])

  const dataToUpdate = (data: EmailData): EmailData => {
    return {
      subject: data.subject ?? '',
      body: data.body ?? '',
      sendingEmailAccountId: data.sendingEmailAccountId,
      sendingUserId: data.sendingUserId,
      sendingEmailAlias: data.sendingEmailAlias,
      sendingLinkedInAccountId: data.sendingLinkedInAccountId,
      cc: data.cc ?? undefined,
      bcc: data.bcc ?? undefined,
      attachmentUploads: data.attachmentUploads ?? undefined
    }
  }

  return (
    <>
      <S.InboxTimelineAction>
        {selectedAction === 'SUGGESTED_RESPONSE' && suggestedResponse && (
          <Action>
            <InboxMessageEditor
              currentData={suggestedManualEmail}
              onDataChanged={data => {
                const update = dataToUpdate(data)
                setSuggestedManualEmail(prev => ({
                  ...prev,
                  ...update
                }))
              }}
              isAiGeneratedEmail
              onSendMessage={() => {
                handleSendManualMessage(suggestedManualEmail)
              }}
              onClose={() => {
                setSuggestedManualEmail(suggestedManualEmailInitialState)
                setSelectedAction(null)
                if (!isNil(onClose)) {
                  onClose()
                }
              }}
              onEditorHeightChange={onEditorHeightChange}
              minHeight='150px'
              editorFooterContent={
                suggestedResponse?.calEventStart && suggestedResponse?.calEventEnd
                  ? (
                      <Flex $gap={12}>
                        <CalendarIcon timestamp={suggestedResponse?.calEventStart} />
                        <Flex $direction="column">
                          <Paragraph size="XS" $color="fgSecondary">
                            {`${format(suggestedResponse?.calEventStart, 'h:mma').toLowerCase()} - ${format(suggestedResponse?.calEventEnd, 'h:mma').toLowerCase()}`}{' '}
                            {' '}{suggestedResponse?.calEventStart.toLocaleString('en-US', { timeZoneName: 'short' }).split(' ').pop()}
                          </Paragraph>
                          <Caption size="XS" $color="fgPrimary">{suggestedResponse?.calEventTitle}</Caption>
                        </Flex>
                      </Flex>
                    )
                  : undefined
              }
            />
          </Action>
        )}

        <When condition={selectedAction === ActionType.MESSAGE || selectedAction === ActionType.MESSAGE_REPLY}>
          <Action>
            <InboxMessageEditor
              preselectedMessageType={selectedEditorMessageType}
              onDataChanged={data => {
                const update = dataToUpdate(data)
                setReferenceManualMessage(prev => ({
                  ...prev,
                  ...update,
                  type: selectedEditorMessageType === EditorMessageType.LINKEDIN_MAIL ? SequenceStepType.MANUAL_LINKEDIN_MESSAGE : SequenceStepType.MANUAL_EMAIL
                }))
              }}
              onSendMessage={() => {
                handleSendManualMessage(referenceManualMessage)
              }}
              currentData={referenceManualMessage}
              onClose={() => {
                setReferenceManualMessage(referenceManualMessageInitialState)
                setSelectedAction(null)
                if (!isNil(onClose)) {
                  onClose()
                }
              }}
              onEditorHeightChange={onEditorHeightChange}
              minHeight='150px'
            />
          </Action>
        </When>
        <When condition={selectedAction === ActionType.COMMENT}>
          <Action>
            <InboxCommentEditor
              onCancel={() => {
                setSelectedAction(null)
              }}
              onDataChanged={(data) => {
                setCommentContent(data)
              }}
              onEditorHeightChange={onEditorHeightChange}
              onAddComment={handleAddComment}
            />
          </Action>
        </When>
      </S.InboxTimelineAction>
      <S.InboxActions>
        <S.InboxActionsInner>
          <Flex $gap={8}>
            <When condition={!!featureFlags && featureFlags?.includes(FeatureFlags.LINKEDIN_CONNECTION)}>
              <Dropdown
                trigger={
                  <Button
                    nested
                    $variant="raised"
                    $colorTheme="normal"
                    leadingIcon="pen-square"
                    $fontSize={12}
                    $height={24}
                    trailingIcon="chevron-down"
                  >
                    Write message
                  </Button>
                }
                items={[
                  {
                    title: 'Email',
                    value: ActionType.MESSAGE,
                    icon: 'mail',
                    onSelect: () => {
                      setSelectedAction(ActionType.MESSAGE)
                      setSelectedEditorMessageType(EditorMessageType.EMAIL)
                    }
                  },
                  {
                    title: 'LinkedIn',
                    value: ActionType.MESSAGE,
                    icon: 'linkedin-outline',
                    onSelect: () => {
                      setSelectedAction(ActionType.MESSAGE)
                      setSelectedEditorMessageType(EditorMessageType.LINKEDIN_MAIL)
                    }
                  }
                ]}
                size="small"
              />

            </When>
            <When condition={!featureFlags || !featureFlags?.includes(FeatureFlags.LINKEDIN_CONNECTION)}>
              <Button
                $variant="raised"
                $colorTheme="normal"
                leadingIcon="mail"
                $fontSize={12}
                $height={24}
                onClick={() => {
                  setSelectedAction(ActionType.MESSAGE)
                }}
              >
                Write message
              </Button>
            </When>
            {/* <Button
              $variant="raised"
              $colorTheme="tint"
              leadingIcon="mail"
              $fontSize={12}
              $height={24}
              onClick={() => {
                setSelectedAction(ActionType.MESSAGE)
              }}
            >
              Write message
            </Button> */}
            <Button
              $variant="raised"
              $colorTheme="normal"
              leadingIcon="message-circle"
              $fontSize={12}
              $height={24}
              onClick={() => {
                setSelectedAction(ActionType.COMMENT)
              }}
            >
              Comment
            </Button>
            <When condition={!!featureFlags && featureFlags?.includes(FeatureFlags.CALENDAR)}>
              <Button
                $variant="raised"
                $colorTheme="normal"
                leadingIcon="calendar"
                $fontSize={12}
                $height={24}
                onClick={() => {
                  openDialog({ id: DialogId.CALENDAR, payload: { candidateJob } })
                }}
              >
                Schedule meeting
              </Button>
            </When>
          </Flex>
          <Flex $justify="flex-end">
            <CandidateJobStatusDropdown candidateJob={candidateJob} $variant="flat" />
          </Flex>
        </S.InboxActionsInner>
      </S.InboxActions>
    </>
  )
}
