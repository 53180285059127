import { queryOptions, useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { getInterviewStagesByJobId } from 'src/libs/api/backend/applicants'
import type { MergeInterviewStage } from 'src/libs/api/backend/applicants'
import type { Job } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'

export const atsInterviewStagesQuery = (jobId: Job['id']) => queryOptions({
  queryKey: [queryKeys.atsInterviewStages, jobId],
  queryFn: async () => await getInterviewStagesByJobId(jobId),
  staleTime: 60 * 1000
})

export const useAtsInterviewStagesQuery = (jobId: Job['id']): UseQueryResult<MergeInterviewStage[]> => {
  return useQuery(atsInterviewStagesQuery(jobId))
}
