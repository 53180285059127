import posthog from 'posthog-js'

// These tokens are publishable tokens so they do not need to be kept safe
const POSTHOG_TOKEN = 'phc_AyPdTkiyxF7078fbsdX8r4l8TbXaPapf7iCxnvSSARa'

const initPostHog = (): void => {
  posthog.init(POSTHOG_TOKEN, {
    api_host: 'https://d3tdn7b19iskzy.cloudfront.net',
    ui_host: 'https://us.posthog.com',
    person_profiles: 'identified_only',
    capture_pageview: false
  })
}

export const initializeTracking = (): void => {
  // initialized via index.html script tag:
  // - google analytics
  // - google tag manager
  if (process.env.NODE_ENV !== 'development') {
    initPostHog()
  }
}
