import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  padding: 1.25rem;
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 6px;
  padding: 2.5rem;
  gap: 4rem;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  max-width: 480px;
`

export const CtaWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 2.5rem;
  width: 100%;
  margin-top: 1.5rem;
`

export const Preview = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  max-width: 960px;
`

export const PreviewCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const PreviewImage = styled.div`
  width: 100%;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  overflow: hidden;
  aspect-ratio: 1.76/1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
`
