import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { CandidateJobStage, setCandidateFavoriteStatus } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobCounts, CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { isNil, keyBy } from 'lodash'
import { useParams } from 'react-router-dom'
import type { CandidateSequenceStepMessageReviewQueryResult } from '../queries/use-candidate-sequence-step-message-review'
import { notifyAtom } from 'src/stores/notifications'
import { useSetAtom } from 'jotai'

interface Args {
  candidateJobId: string
  newFavoriteStatus: boolean
  showNotification?: boolean
}

interface ReturnType {
  toggleFavoriteStatus: (args: Args) => void
}

export const useToggleCandidateFavoriteStatus = (): ReturnType => {
  const queryClient = useQueryClient()
  const notify = useSetAtom(notifyAtom)
  const { jobId } = useParams()

  const mutation = useMutation({
    mutationFn: async ({ candidateJobId, newFavoriteStatus }: Args) => {
      return await setCandidateFavoriteStatus(candidateJobId, newFavoriteStatus)
    },
    onSuccess: async (data, { newFavoriteStatus, showNotification = false }: Args) => {
      const candidateJobs = keyBy(data, 'id')
      const newFavoriteCount = newFavoriteStatus ? 1 : -1

      queryClient.setQueriesData<CandidateJobExpanded[]>({ queryKey: [queryKeys.candidateJobs, jobId] }, (oldCandidateJobs) => {
        if (isNil(oldCandidateJobs)) {
          return oldCandidateJobs
        }

        return oldCandidateJobs.map((o) => {
          if (candidateJobs[o.id]) {
            return {
              ...o,
              favorite: candidateJobs[o.id].favorite
            }
          }

          return o
        })
      })

      for (const candidateJob of data) {
        queryClient.setQueriesData<CandidateJobCounts>({ queryKey: [queryKeys.candidateJobCounts, candidateJob.jobId] }, (oldCounts) => {
          if (isNil(oldCounts)) {
            return oldCounts
          }
          return {
            ...oldCounts,
            shortlisted: {
              total: oldCounts.shortlisted.total + newFavoriteCount,
              sourced: candidateJob.stage === CandidateJobStage.SOURCED ? oldCounts.shortlisted.sourced + newFavoriteCount : oldCounts.shortlisted.sourced
            }
          }
        })
        queryClient.setQueriesData<CandidateJobExpanded>({ queryKey: [queryKeys.candidateJob, candidateJob.id] }, (oldCandidateJob) => {
          if (isNil(oldCandidateJob)) {
            return oldCandidateJob
          }

          return {
            ...oldCandidateJob,
            favorite: candidateJob.favorite
          }
        })
      }

      const jobIds = [...new Set(data.map((candidateJob) => candidateJob.jobId))]
      for (const jobId of jobIds) {
        const queriesData = queryClient.getQueriesData<CandidateJobExpanded[]>({ queryKey: [queryKeys.candidateJobs, jobId] })
        for (const [queryKey] of queriesData) {
          const filters = queryKey[2] as Record<string, unknown> ?? {}
          if (filters.favorite === true) {
            await queryClient.invalidateQueries({
              queryKey
            })
          }
        }

        queryClient.setQueriesData<CandidateSequenceStepMessageReviewQueryResult>({
          queryKey: [queryKeys.candidateSequenceStepMessageReviews, jobId]
        }, (oldCandidateSequenceStepMessageReviews) => {
          if (isNil(oldCandidateSequenceStepMessageReviews)) {
            return oldCandidateSequenceStepMessageReviews
          }

          return {
            ...oldCandidateSequenceStepMessageReviews,
            pages: oldCandidateSequenceStepMessageReviews.pages.map((page) => {
              return page.map((o) => {
                const candidateJobId = o.candidateJob.id
                if (candidateJobs[candidateJobId]) {
                  return {
                    ...o,
                    candidateJob: {
                      ...o.candidateJob,
                      favorite: candidateJobs[candidateJobId].favorite
                    }
                  }
                }
                return o
              })
            })
          }
        })
      }
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobCounts, jobId]
      })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, jobId]
      })
      if (showNotification && newFavoriteStatus) {
        notify({
          type: 'candidate-favorited',
          variant: 'neutral',
          payload: data
        })
      }
      // await queryClient.invalidateQueries({
      //   queryKey: [queryKeys.candidateJobs],
      // })
    },
    onError: (err) => {
      console.error(err)
    }
  })

  const toggleFavoriteStatus = (args: Args): void => {
    mutation.mutate(args)
  }

  return { toggleFavoriteStatus }
}
