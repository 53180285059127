import { driver } from 'driver.js'

export const productTour = driver()

export enum ProductTourStep {
  CANDIDATE_ACTIONS = 'tour-candidate-actions',
  SEARCH_CRITERIA = 'tour-search-criteria',
  MENU_SOURCING = 'tour-menu-sourcing',
  MENU_OUTREACH = 'tour-menu-outreach',
  MENU_INBOX = 'tour-menu-inbox',
  MENU_REPORTS = 'tour-menu-reports',
  MENU_JOB_SETTINGS = 'tour-menu-job-settings',
  MENU_ACCOUNT_SETTINGS = 'tour-menu-account-settings'
}
