import { z } from 'zod'
import { SequenceStepType } from './sequences'

export const candidateSequenceParser = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  bounced: z.boolean(),
  bouncedAt: z.coerce.date().nullable(),
  candidateId: z.string().uuid(),
  orgId: z.string().uuid(),
  sequenceId: z.string().uuid(),
  currentSequence: z.boolean(),
  firstStepAt: z.coerce.date().nullable(),
  latestStep: z.number(),
  latestStepAt: z.coerce.date().nullable(),
  responded: z.boolean(),
  respondedAt: z.coerce.date().nullable(),
  opened: z.boolean(),
  openedAt: z.coerce.date().nullable(),
  openedCount: z.number().nullable(),
  clicked: z.boolean(),
  clickedAt: z.coerce.date().nullable(),
  paused: z.boolean(),
  nextSequenceStepDate: z.coerce.date().nullable().optional(),
  nextSequenceStepType: z.nativeEnum(SequenceStepType).nullable().optional(),
  isNextSequenceStepManual: z.boolean().nullable().optional()
})

export type CandidateSequence = z.infer<typeof candidateSequenceParser>
