import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateDepartment } from 'src/libs/api/backend/departments'
import type { Department } from 'src/libs/api/backend/departments'
import { queryKeys } from 'src/libs/query-keys'
import { isNil } from 'lodash'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  id: string
  name?: string
  domain?: string | null
  stealth?: boolean
  onSuccess?: (updatedDept: Department) => void
  onError?: () => void
}

interface Res {
  updateDepartmentByOrg: (args: Args) => void
}

export const useUpdateDepartment = (): Res => {
  const queryClient = useQueryClient()

  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async ({ id, name, domain, stealth }: Args) => await updateDepartment(id, name, domain, stealth),
    onError: (err, variables) => {
      console.error(err)
      notifyError({
        message: `An error occurred while updating your department: ${err.message}`
      })

      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (updatedDept: Department, variables) => {
      queryClient.setQueryData<Department[]>([queryKeys.departments], (oldDepartments) => {
        if (isNil(oldDepartments)) {
          return
        }

        return oldDepartments.map((dept) => {
          if (dept.id === updatedDept.id) {
            return updatedDept
          }
          return dept
        })
      })

      notifySuccess({
        message: 'Department successfully updated'
      })
      if (variables.onSuccess) {
        variables.onSuccess(updatedDept)
      }
    }
  })
  const updateDepartmentByOrg = (args: Args): void => {
    mutation.mutate(args)
  }
  return { updateDepartmentByOrg }
}
