import { useMemo } from 'react'
import { useTimezonesAndHolidaysQuery } from './queries/use-timezones-and-holidays'
import type { TimezonesAndHolidays } from 'src/libs/api/backend/configuration'

interface HolidaysReturnType {
  holidays: TimezonesAndHolidays['holidays'] | null
  nextHoliday: TimezonesAndHolidays['holidays'][number] | null
  passedHolidays: TimezonesAndHolidays['holidays'] | null
}

export const useHolidays = (): HolidaysReturnType => {
  const { data: timezonesAndHolidays } = useTimezonesAndHolidaysQuery()

  const { holidays, nextHoliday, passedHolidays } = useMemo(() => {
    const today = new Date()

    const filteredHolidays = timezonesAndHolidays?.holidays?.filter(
      (holiday) => new Date(holiday.date) >= today
    )

    const passedHolidays = timezonesAndHolidays?.holidays?.filter(
      (holiday) => new Date(holiday.date) < today
    )

    return {
      holidays: filteredHolidays,
      nextHoliday: filteredHolidays?.[0] ?? null,
      passedHolidays
    }
  }, [timezonesAndHolidays])

  return {
    holidays: holidays ?? null,
    nextHoliday: nextHoliday ?? null,
    passedHolidays: passedHolidays ?? null
  }
}
