import { isUuid } from './../../libs/uuid'
import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { getJobSearchRefinement, JobSearchRefinementType } from 'src/libs/api/backend/jobs'
import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'
import queryClient from '../query-client'

interface UpdateListJobSearchRefinementsProps {
  jobId: string
  jobSearchRefinementId: string
  jobSearchRefinement: Partial<JobSearchRefinement>
}

export const updateListJobSearchRefinements = ({
  jobId,
  jobSearchRefinementId,
  jobSearchRefinement
}: UpdateListJobSearchRefinementsProps): void => {
  // Update the job search refinement in the list of job search refinements
  queryClient.setQueriesData<JobSearchRefinement[]>({
    queryKey: [queryKeys.jobSearchRefinements, jobId]
  }, (oldJobSearchRefinements) => {
    if (!oldJobSearchRefinements) {
      return oldJobSearchRefinements
    }
    // Update the title and subtitle for the job search refinement
    return oldJobSearchRefinements.map((refinement) => {
      if (refinement.id === jobSearchRefinementId) {
        return {
          ...refinement,
          ...jobSearchRefinement
        }
      }
      return refinement
    })
  })
}

interface UpdateListApplicationSearchRefinementsProps {
  jobId: string
  applicantsRefinementId: string
  jobSearchRefinement: Partial<JobSearchRefinement>
}
export const updateApplicantsRefinements = ({
  jobId,
  applicantsRefinementId,
  jobSearchRefinement
}: UpdateListApplicationSearchRefinementsProps): void => {
  // Update the job search refinement in the list of job search refinements
  queryClient.setQueriesData<JobSearchRefinement[]>({
    queryKey: [queryKeys.jobSearchRefinements, jobId, { type: JobSearchRefinementType.APPLICANTS }]
  }, (oldJobSearchRefinements) => {
    if (!oldJobSearchRefinements) {
      return oldJobSearchRefinements
    }
    return oldJobSearchRefinements.map((refinement) => {
      if (refinement.id === applicantsRefinementId) {
        return {
          ...refinement,
          ...jobSearchRefinement
        }
      }
      return refinement
    })
  })
}

export const useJobSearchRefinementQuery = (jobId?: string, jobSearchRefinementId?: string): UseQueryResult<JobSearchRefinement> => {
  return useQuery({
    queryKey: [queryKeys.jobSearchRefinement, jobId, jobSearchRefinementId],
    queryFn: async () => {
      if (!jobId || !jobSearchRefinementId) {
        return null
      }

      const jobSearchRefinement = await getJobSearchRefinement(jobId, jobSearchRefinementId)

      // Update the job search refinement in the list of job search refinements
      updateListJobSearchRefinements({ jobId, jobSearchRefinementId, jobSearchRefinement })

      return jobSearchRefinement
    },
    staleTime: 60 * 1000 * 15, // 15 minutes
    enabled: isUuid(jobId) && isUuid(jobSearchRefinementId)
  })
}
