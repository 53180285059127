import { isUuid } from './../../libs/uuid'
import { queryOptions, useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { fetchCandidateJobCounts, IN_OUTREACH_STEPS } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobCounts } from 'src/libs/api/backend/candidate_jobs'
import { useParams } from 'react-router-dom'
import { isNil } from 'lodash'

const DEFAULT_JOB_COUNTS: CandidateJobCounts = {
  stages: {},
  sequenceSteps: {},
  shortlisted: {
    total: 0,
    sourced: 0
  },
  manuallyAdded: 0,
  prospectingErrors: 0,
  total: {
    total: 0,
    totalWithoutDataWarehouseSourcedCount: 0
  },
  inbox: {
    totalCommunicating: 0,
    totalAwaitingResponse: 0,
    myTotalCommunicating: 0,
    myTotalAwaitingResponse: 0
  },
  emailStats: {
    openedSum: 0,
    clickedSum: 0,
    respondedSum: 0,
    totalSum: 0
  },
  sequenceEmailsSentByOrg: {
    sumPastWeek: null,
    sumToday: null,
    totalSum: 0
  },
  candidateOutreach: {
    inSequence: 0,
    replied: 0,
    opened: 0,
    scheduled: 0,
    contacted: 0
  },
  readyToSend: 0,
  candidateInOutreach: {
    [IN_OUTREACH_STEPS.ALL_CONTACTS]: 0,
    [IN_OUTREACH_STEPS.NOT_YET_CONTACTED]: 0,
    [IN_OUTREACH_STEPS.MESSAGED]: 0,
    [IN_OUTREACH_STEPS.IN_PROGRESS]: 0,
    [IN_OUTREACH_STEPS.OPENED]: 0,
    [IN_OUTREACH_STEPS.REPLIED]: 0,
    [IN_OUTREACH_STEPS.SCHEDULED]: 0,
    [IN_OUTREACH_STEPS.NO_RESPONSE]: 0
  }
}

export const candidateJobCountsQuery = (jobId: string | undefined) => queryOptions({
  queryKey: [queryKeys.candidateJobCounts, jobId],
  queryFn: async () => {
    if (isNil(jobId)) {
      return DEFAULT_JOB_COUNTS
    }
    return await fetchCandidateJobCounts(jobId)
  },
  staleTime: 60 * 1000,
  enabled: isUuid(jobId)
})

export const useCandidateJobCountsQuery = (): UseQueryResult<CandidateJobCounts> => {
  const { jobId } = useParams()

  return useQuery(candidateJobCountsQuery(jobId))
}
