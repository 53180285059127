import Cal from '@calcom/embed-react'
import { useOnboardingSteps } from 'src/hooks/use-onboarding-steps'
import { OnboardingHeader } from 'src/components/blocks/onboarding-header'
import { Button } from 'src/components/primitives/button'
import { Spacer } from 'src/components/primitives/spacer'
import CONFIG from 'src/config'
import { useWindowSize, useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey } from 'src/constants'
import * as S from './schedule-demo.styled'

const OnboardingScheduleDemo = (): JSX.Element => {
  const { width } = useWindowSize()
  const { nextStep } = useOnboardingSteps()
  const [hasScheduledDemo] = useLocalStorage(LocalStorageKey.HAS_BOOKED_DEMO, false)

  return (
    <>
      <OnboardingHeader
        heading={hasScheduledDemo ? 'Your meeting is scheduled!' : 'Would you like to schedule a demo with us?'}
        subheading={hasScheduledDemo ? 'Looking forward to discussing how we can help you in your recruiting efforts. In the meantime continue the onboarding process!' : 'Our team can help you get the most out of Pin.'}
      />
      <Button
        $variant={hasScheduledDemo ? 'fill' : 'raised'}
        $colorTheme="tint"
        $height={40}
        $align="center"
        href={nextStep?.href}
      >
        {hasScheduledDemo ? 'Continue' : 'Continue without scheduling'}
      </Button>
      <Spacer $size={width <= 1024 ? 24 : 48} />
      <S.ScheduleDemo>
        <S.ScheduleDemoInner>
          <Cal
            namespace={CONFIG.CAL_NAMESPACE}
            calLink={CONFIG.CAL_LINK}
            // style={{ width: '100%', height: '100%' }}
            config={{ layout: 'month_view', theme: 'light' }}
          />
        </S.ScheduleDemoInner>
      </S.ScheduleDemo>
    </>
  )
}

export default OnboardingScheduleDemo
