import styled from 'styled-components'
import type { TweakSearchRefinementCriteriaBannerStyledProps } from './tweak-search-refinement-criteria-banner'

interface WrapperProps extends TweakSearchRefinementCriteriaBannerStyledProps {
  $columns: string
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $columns }) => $columns};
  gap: 64px;
  padding-top: ${({ $padding }) => $padding?.top ?? 64}px;
  padding-bottom: ${({ $padding }) => $padding?.bottom ?? 64}px;
  padding-left: ${({ $padding }) => $padding?.left ?? 40}px;
  padding-right: ${({ $padding }) => $padding?.right ?? 120}px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const CriteriaUpdateSuggestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CriteriaBox = styled.div`
  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 1.25rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 6px;
  display: grid;
  grid-template-columns: 1fr 1fr 72px;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  gap: 0.5rem;
  p {
    align-self: center;
  }
`

export const SuggestedChange = styled.div`
  display: grid;
  grid-template-columns: 44px 1fr;
  gap: ${({ theme }) => theme.spacing[20]};
  padding: ${({ theme }) => theme.spacing[12]};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 6px;
`

export const SuggestedChangesBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => theme.spacing[24]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
`

export const GettingSuggestedChanges = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[12]};
  align-items: center;
  justify-content: center;
  min-height: ${({ theme }) => theme.spacing[24]};
`
