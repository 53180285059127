import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { Button } from 'src/components/primitives/button'
import { Spacer } from 'src/components/primitives/spacer'
import CONFIG from 'src/config'
import { useApiStatusQuery } from 'src/hooks/queries/use-api-status'
import { notifyAtom } from 'src/stores/notifications'
import { useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey } from 'src/constants'
import { subMinutes } from 'date-fns'

export const ApiStatusProvider = (): JSX.Element | null => {
  const { data: apiStatus } = useApiStatusQuery()
  const notify = useSetAtom(notifyAtom)
  const [lastNoticed] = useLocalStorage<number | null>(
    LocalStorageKey.API_STATUS_NOTICED,
    null
  )

  useEffect(() => {
    if (apiStatus?.ongoing_incidents && apiStatus.ongoing_incidents.length > 0) {
      const timePassedToNotifyAgain = subMinutes(new Date(), 60).getTime()
      if (lastNoticed && lastNoticed > timePassedToNotifyAgain) {
        return
      }

      notify({
        type: 'api-status-toast',
        variant: 'warning',
        position: 'bottom-right',
        icon: 'alert-triangle',
        autoClose: false,
        message: `We're currently experiencing issues${apiStatus.ongoing_incidents?.[0]?.name ? ': ' : '.'} ${apiStatus.ongoing_incidents?.[0]?.name ?? ''}`,
        customContent: (
          <>
            <Spacer $size={8} />
            <Button
              href={CONFIG.API_STATUS_PAGE_URL}
              $height={24}
              $fontSize={12}
              $variant="flat"
              $colorTheme="warning"
            >
              Read more
            </Button>
          </>
        )
      })
    }
  }, [apiStatus, notify, lastNoticed])

  return null
}
