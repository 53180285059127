import { Button } from 'src/components/primitives/button'
import type { EmailSequenceStep } from 'src/models/sequence'
import { Flex } from 'src/components/primitives/flex'
import { When } from '../when'
import { WaitDays } from './waitdays'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './sequence-editor.styled'
import { SequenceReply, SequenceStepGenerationState, SequenceStepType } from 'src/libs/api/backend/sequences'
import type { SequenceReplyType } from 'src/libs/api/backend/sequences'
import { Dropdown } from 'src/components/primitives/dropdown'
import { isNil, keyBy } from 'lodash'
import { MessageComposer } from '../message-composer'
import { SequenceStepTypeSelector } from './sequence-step-type-selector'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import { useSetAtom } from 'jotai'
import { closeDialogAtom, DialogId, openAlertAtom, openDialogAtom } from 'src/stores/dialogs'
import { Select } from 'src/components/forms/select'
import { Box } from 'src/components/primitives/box'
import { Form, Input } from 'src/components/forms'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { Avatar } from 'src/components/primitives/avatar'
import type { CandidateJob } from 'src/libs/api/backend/candidate_jobs'

interface SequenceStepProps {
  initialStepBody?: string
  step: EmailSequenceStep
  steps?: EmailSequenceStep[]
  totalSteps: number
  isGenerating?: boolean
  onReplyTypeUpdate?: (replyType: SequenceReplyType) => void
  onRemoveStep: () => void
  onReorder: (newPosition: number) => void
  onDataChanged: (updated: EmailSequenceStep) => void
  onSendTestEmail?: (step: EmailSequenceStep, recipients: string[], candidateId?: string | undefined) => void
  isSendingTestEmail?: boolean
  onCancelGeneratingEmail?: () => void
  getStepSubjectAndPlaceholder: (stepPosition: number) => {
    subject: string | null
    placeholder: string | null
  }
  forceEditorFocus: boolean
  emailSuggestionFooter?: JSX.Element | null
}

const previewEmailSchema = z.object({
  sendPreviewTo: z.string(),
  candidateId: z.string()
})

type PreviewEmailForm = z.infer<typeof previewEmailSchema>

export const SequenceStep = ({
  initialStepBody,
  step,
  steps,
  // totalSteps,
  // isGenerating = false,
  onDataChanged,
  onSendTestEmail,
  isSendingTestEmail = false,
  onReplyTypeUpdate,
  onRemoveStep,
  // onReorder,
  onCancelGeneratingEmail,
  getStepSubjectAndPlaceholder,
  forceEditorFocus,
  emailSuggestionFooter,
  ...emailComposerProps
}: SequenceStepProps): JSX.Element => {
  const { data: candidateJobs } = useCandidateJobsQuery()
  const candidateJobsMap = keyBy(candidateJobs, (candidateJob: CandidateJob) => candidateJob.candidateId)

  const openDialog = useSetAtom(openDialogAtom)

  const [candidateJobId, setCandidateJobId] = useState<string | null>(null)

  const candidateList = candidateJobs
    ?.map(candidateJob => ({
      value: candidateJob.candidateId,
      title: candidateJob.candidate.name,
      image: (
        <Avatar
          $type="logo"
          $size={16}
          $border={false}
          photoUrl={candidateJob.candidate.profilePhotoUrl}
          initials={candidateJob.candidate.name}
        />
      )
    }))
    .sort((c1, c2) => c1.title.localeCompare(c2.title)) ?? []

  const closeDialog = useSetAtom(closeDialogAtom)
  const openAlert = useSetAtom(openAlertAtom)

  const { subject, placeholder: subjectPlaceholder } = getStepSubjectAndPlaceholder(step.position)
  const [showPreviewInput, setShowPreviewInput] = useState(false)
  const initialStepsAreGenerating = useMemo(() => {
    return !isNil(steps) && steps.every((step) => step.generationState === SequenceStepGenerationState.IN_PROGRESS)
  }, [steps])

  const { submit, register, formData } = useForm<PreviewEmailForm>({
    schema: previewEmailSchema,
    initialValues: {
      sendPreviewTo: '',
      candidateId: 'default'
    }
  })

  const handleSendTestEmail = useCallback((): void => {
    if (isNil(formData.sendPreviewTo)) {
      return
    }

    const candidateId = formData.candidateId === 'default'
      ? undefined
      : String(formData.candidateId)

    const recipients = [String(formData.sendPreviewTo)]

    onSendTestEmail?.(step, recipients, candidateId)
    setShowPreviewInput(false)
  }, [formData.candidateId, formData.sendPreviewTo, step, onSendTestEmail])

  const currentData = useMemo(() => {
    return {
      ...step,
      subject,
      subjectPlaceholder: subjectPlaceholder === '' ? '(No Subject)' : subjectPlaceholder,
      recipients: undefined,
      body: step.body?.replace(/\n/g, '<br />'),
      sendingEmailAccountId: step.sendingEmailAccountId,
      sendingEmailAlias: step.sendingEmailAlias,
      sendingUserId: step.sendingUserId,
      sendingLinkedInAccountId: step.sendingLinkedInAccountId
    }
  }, [step, subject, subjectPlaceholder])

  const currentStepIsFirstOfTypeEmail = useMemo(() => {
    return steps?.filter(s =>
      s.type === SequenceStepType.AUTOMATED_EMAIL ||
      s.type === SequenceStepType.MANUAL_EMAIL
    )[0]?.position === step.position
  }, [steps, step.position])

  const alreadyHasLinkedInMessageStep = useMemo(() => {
    return steps?.some(step => step.type === SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE || step.type === SequenceStepType.MANUAL_LINKEDIN_MESSAGE)
  }, [steps])

  if (step.position === 0) {
    // Do nothing
  }

  return (
    <>
      <S.SequenceStep id={`step-${step.position}`}>
        <S.StepHeader>
          <When condition={step.position === 0}>
            <Paragraph size="XS" $whiteSpace="nowrap">
              When added to outreach
            </Paragraph>
          </When>
          <Flex $width="auto" $align="center" $gap={10} $justify="flex-start">
            <When condition={step.position !== 0}>
              <WaitDays
                waitDays={step.waitDays ?? 0}
                onWaitDaysUpdate={(waitDays: number) => {
                  const updatedStep = {
                    ...step,
                    waitDays
                  }
                  onDataChanged(updatedStep)
                }}
                isGenerating={initialStepsAreGenerating}
              />
            </When>
            <SequenceStepTypeSelector
              type={step.type ?? SequenceStepType.AUTOMATED_EMAIL}
              isGenerating={initialStepsAreGenerating}
              onSequenceStepTypeUpdate={(type) => {
                const updatedStep = {
                  ...step,
                  type
                }
                onDataChanged(updatedStep)
              }}
              linkedInStepTypeLimitReached={alreadyHasLinkedInMessageStep &&
                step.type !== SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE &&
                step.type !== SequenceStepType.MANUAL_LINKEDIN_MESSAGE}
            />
            <When condition={step.position !== 0 && step.type !== SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE && step.type !== SequenceStepType.MANUAL_TASK}>
              <Dropdown
                disabled={initialStepsAreGenerating || currentStepIsFirstOfTypeEmail}
                trigger={
                  <Button
                    nested
                    trailingIcon={Icons.chevronsUpDownSmall}
                    $height={24}
                    $fontSize={12}
                    $variant="raised"
                    $colorTheme="normal"
                    disabled={initialStepsAreGenerating || currentStepIsFirstOfTypeEmail}
                  >
                    {isNil(step.subject) ? 'Reply on previous thread' : 'Start new thread'}
                  </Button>
                }
                selectedValue={step.subject === null ? SequenceReply.REPLY_TO_PREVIOUS_THREAD : SequenceReply.NEW_THREAD}
                items={[
                  {
                    id: SequenceReply.NEW_THREAD,
                    title: 'Start new thread',
                    onSelect: () => {
                      onReplyTypeUpdate?.(SequenceReply.NEW_THREAD)
                    }
                  },
                  {
                    id: SequenceReply.REPLY_TO_PREVIOUS_THREAD,
                    title: 'Reply on previous thread',
                    onSelect: () => {
                      onReplyTypeUpdate?.(SequenceReply.REPLY_TO_PREVIOUS_THREAD)
                    }
                  }
                ]}
                menuPosition="end"
                size="small"
              />
            </When>
          </Flex>
          <Flex $gap={4} $justify="flex-end">
            <When condition={step.waitDays !== 0}>
              <>
                {/*
                <Button
                  $variant="ghost"
                  $colorTheme="muted"
                  leadingIcon="arrow-up"
                  $width={24}
                  $height={24}
                  $fontSize={12}
                  disabled={step.position === 1}
                  onClick={() => {
                    onReorder(step.position - 1)
                  }}
                />
                <Button
                  $variant="ghost"
                  $colorTheme="muted"
                  leadingIcon="arrow-down"
                  $width={24}
                  $height={24}
                  $fontSize={12}
                  disabled={step.position >= totalSteps}
                  onClick={() => {
                    onReorder(step.position + 1)
                  }}
                />
                */}
                <Button
                  $variant="ghost"
                  $colorTheme="negative"
                  leadingIcon={Icons.trash}
                  $width={24}
                  $height={24}
                  $fontSize={12}
                  onClick={() => {
                    openAlert({
                      message: 'Delete outreach step',
                      description: 'Any scheduled candidates will be moved to the next available step.',
                      confirmText: 'Delete step',
                      onConfirm: () => {
                        onRemoveStep()
                        closeDialog(DialogId.ALERT)
                      }
                    })
                  }}
                />
              </>
            </When>
          </Flex>
        </S.StepHeader>
        <S.StepContent>
          <When condition={(step.type === SequenceStepType.MANUAL_EMAIL || step.type === SequenceStepType.AUTOMATED_EMAIL) && showPreviewInput}>
            <S.SendPreviewEmailInputPopup>
              <Form onSubmit={submit(handleSendTestEmail)}>
                <Box $padding={{ top: 16, bottom: 16, left: 16, right: 16 }} $width='100%'>
                  <Flex $direction='column' $width='100%'>
                    <Flex $justify='space-between' $gap={16} $width='100%'>
                      <Flex $direction='column' $gap={6} $width='100%'>
                        <Caption size='SM' $fontWeight={400}> Send preview to</Caption>
                        <Input
                          $width='100%'
                          $height={24}
                          $marginBottom={0}
                          $padding='0 0.625rem'
                          size='normal'
                          type='email'
                          name='sendPreviewTo'
                          placeholder='me@example.com'
                          hiddenLabel
                          register={register}
                        />
                      </Flex>

                      <Flex $direction='column' $gap={6} $width='100%'>
                        <Caption size='SM' $fontWeight={400}> Candidate to preview</Caption>
                          <Flex $gap={12}>
                            <Select
                              $width='100%'
                              $height={24}
                              name='candidateId'
                              register={register}
                              hiddenLabel
                              placeholder='Choose ATS job listing'
                              defaultValue='default'
                              items={[
                                {
                                  value: 'default',
                                  title: 'Random candidate'
                                },
                                ...candidateList
                              ]}
                              onValueChange={(value: string) => {
                                if (value === 'default') {
                                  setCandidateJobId(null)
                                }

                                const candidateJob = candidateJobsMap[value]
                                setCandidateJobId(candidateJob.id)
                              }}
                            />
                            <Button
                              disabled={candidateJobId === null}
                              leadingIcon={(
                                <Icon name={Icons.contact} size={12} />
                              )}
                              ariaLabel='candidate-preview'
                              $variant='raised'
                              $height={24}
                              $width={24}
                              onClick={() => {
                                openDialog({ id: DialogId.CANDIDATE_DETAILS, payload: candidateJobId })
                              }}
                            />
                          </Flex>
                      </Flex>
                    </Flex>
                    <Flex $gap={8}>
                      <Button
                        leadingIcon={Icons.send}
                        ariaLabel='send-preview'
                        $variant='raised'
                        $colorTheme="tint"
                        $fontSize={12}
                        $height={24}
                        onClick={() => { handleSendTestEmail() }}
                      >
                        Send preview
                      </Button>
                      <Button
                        ariaLabel='send-preview'
                        $variant='outline'
                        $colorTheme='muted'
                        $fontSize={12}
                        $height={24}
                        onClick={() => { setShowPreviewInput(false) }}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              </Form>
            </S.SendPreviewEmailInputPopup>
          </When>
          <MessageComposer
            key={`message-composer-${step.position}`}
            forceEditorFocus={forceEditorFocus}
            initialEmailBody={initialStepBody}
            emailSuggestionFooter={emailSuggestionFooter}
            currentData={currentData}
            onDataChanged={(updated) => {
              onDataChanged({
                ...step,
                subject: updated.subject,
                body: updated.body,
                sendingEmailAccountId: updated.sendingEmailAccountId,
                sendingEmailAlias: updated.sendingEmailAlias ?? null,
                sendingUserId: updated.sendingUserId,
                sendingLinkedInAccountId: updated.sendingLinkedInAccountId ?? null,
                type: updated.type,
                personalizationInstructions: updated.personalizationInstructions
              })
              if (updated.type === SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE) {
                setShowPreviewInput(false)
              }
            }}
            onCancelGeneratingEmail={onCancelGeneratingEmail}
            useVariables={true}
            usePersonalizationInstructions={true}
            trailingToolbarActions={
              <When condition={currentData.type === SequenceStepType.MANUAL_EMAIL || currentData.type === SequenceStepType.AUTOMATED_EMAIL}>
                <Button
                  $height={24}
                  $variant='ghost'
                  $colorTheme='muted'
                  leadingIcon={Icons.send}
                  onClick={() => {
                    setShowPreviewInput(!showPreviewInput)
                  }}
                  disabled={isSendingTestEmail}
                  loading={isSendingTestEmail}
                >
                  Preview
                </Button>
              </When>
            }
            {...emailComposerProps}
            isGenerating={initialStepsAreGenerating}
          />
        </S.StepContent>
      </S.SequenceStep>
    </>
  )
}
