import { Icons } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import CONFIG from 'src/config'
import { useEffect } from 'react'
import { Button } from 'src/components/primitives/button'
import type { ButtonStyleProps } from 'src/components/primitives/button'
import Cal, { getCalApi } from '@calcom/embed-react'
import { trackEvent } from 'src/libs/track'
import { TrackingEventType } from 'src/types/track'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey } from 'src/constants'

export const CalComProvider = (): JSX.Element => {
  const { pathname } = useLocation()
  const [, setHasBookedDemoDuringOnboarding] = useLocalStorage(LocalStorageKey.HAS_BOOKED_DEMO_DURING_ONBOARDING, false)
  const [, setHasBookedDemo] = useLocalStorage(LocalStorageKey.HAS_BOOKED_DEMO, false)

  useEffect(() => {
    void (async function () {
      const cal = await getCalApi({ namespace: CONFIG.CAL_NAMESPACE })
      cal('on', {
        action: 'bookingSuccessful',
        callback: (e) => {
          const booking = e.detail.data.booking
          trackEvent(TrackingEventType.BOOKING_SUCCESSFUL, { booking })

          // Store locally when booked during onboarding
          // Needed to skip the onboarding schedule demo step
          if (pathname.includes('onboarding/job-position')) {
            setHasBookedDemoDuringOnboarding(true)
          }
          if (pathname.includes('onboarding/schedule-demo')) {
            setHasBookedDemo(true)
          }
        }
      })
      cal('ui', { hideEventTypeDetails: true, layout: 'month_view', theme: 'light' })
    })()
  }, [pathname, setHasBookedDemoDuringOnboarding, setHasBookedDemo])

  return <Cal calLink={CONFIG.CAL_LINK} style={{ display: 'none' }} />
}

interface BookADemoButtonProps extends Pick<ButtonStyleProps, '$variant' | '$height'> {
  children?: string
  leadingIcon?: IconName | null
}

export const BookADemoButton = ({
  children = 'Book a Demo',
  $variant = 'raised',
  $height = 40,
  leadingIcon = Icons.calendar
}: BookADemoButtonProps): JSX.Element => {
  return (
    <div
      data-cal-namespace={CONFIG.CAL_NAMESPACE}
      data-cal-link={CONFIG.CAL_LINK}
      data-cal-config='{"layout":"month_view"}'
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <Button $variant={$variant} $colorTheme="tint" leadingIcon={leadingIcon} $height={$height}>
        {children}
      </Button>
    </div>
  )
}
