import { Suspense } from 'react'
import type { ApplicantsCandidatesLoaderData } from 'src/libs/loaders'
import { Await, useLoaderData } from 'react-router-dom'
import * as S from './applicant-candidates-ats.styled'
import { ApplicantsCandidates } from './applicants-candidates'
import { LoadingSkeleton } from '../loading-skeleton'
import { ATSJobMerge } from '../applicants-ats/ats-job-merge'

export const ApplicantCandidatesATS = (): JSX.Element => {
  const {
    atsCandidateIntegrationDetails,
    atsAttachmentIntegrationDetails,
    applicantsOverviewStatus,
    jobId,
    applicantsRefinementId,
    stage,
    mergeIntegrations,
    mergeJobs,
    atsJobIntegrationDetails
  } = useLoaderData() as ApplicantsCandidatesLoaderData
  if (!stage) {
    return (
      <Suspense fallback={<LoadingSkeleton $variant='ATSApplicants' />}>
        <Await resolve={
          Promise.all([mergeIntegrations, mergeJobs, atsJobIntegrationDetails])
        }>
          {([mergeIntegrations]) => {
            return (
              <ATSJobMerge
                mergeIntegrations={mergeIntegrations}
              />
            )
          }}
        </Await>
      </Suspense>
    )
  }
  return (
    <Suspense fallback={<S.BlankLoadingState />}>
      <Await resolve={
        Promise.all([
          atsCandidateIntegrationDetails,
          atsAttachmentIntegrationDetails,
          applicantsOverviewStatus
        ])
      }>
        {() => (
          <ApplicantsCandidates
            key={applicantsRefinementId}
            jobId={jobId}
            applicantsRefinementId={applicantsRefinementId}
            stage={stage}
          />
        )}
      </Await>
    </Suspense>
  )
}
