import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { ApplicantsActions } from '../applicant-candidates-ats/applicants-actions'
import { CandidateJobStatusDropdown } from '../candidate-job-status'
import { CandidateActions } from '../candidate-actions'
import { useAddCandidateToSequence } from 'src/hooks/mutations/use-add-candidate-to-sequence'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { CreateSequenceDialogView } from 'src/constants'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { isSequenceStepsEmpty } from 'src/libs/sequence'

export type CandidateDetailsActionType = 'applicants' | 'sourcing' | 'inbox'

interface CandidateDetailsActionsProps {
  actionType?: CandidateDetailsActionType
  candidateJob: CandidateJobExpanded
}

export const CandidateDetailsActions = ({ actionType, candidateJob }: CandidateDetailsActionsProps): JSX.Element => {
  const { id, jobId, favorite } = candidateJob
  const { addCandidateToSequence } = useAddCandidateToSequence()
  const openDialog = useSetAtom(openDialogAtom)
  const { data: sequence } = useJobSequenceQuery(jobId)
  if (actionType === 'applicants') {
    return <ApplicantsActions candidateJobId={id} jobId={jobId} favorite={favorite} />
  }
  if (actionType === 'inbox') {
    return (
      <CandidateJobStatusDropdown $variant="flat" candidateJob={candidateJob} />
    )
  }
  if (actionType === 'sourcing') {
    return (
      <CandidateActions
        candidateJob={candidateJob}
        onAddToSequenceClick={() => {
          if (isSequenceStepsEmpty(sequence)) {
            openDialog({ id: DialogId.CREATE_SEQUENCE, payload: { defaultView: CreateSequenceDialogView.OPTIONS } })
          } else {
            addCandidateToSequence([candidateJob.id])
          }
        }}
      />
    )
  }
  return <></>
}
