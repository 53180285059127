import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'
import { CandidateExportFileType } from 'src/libs/api/backend/candidate_jobs'
import { useExportCandidatesToCsv } from 'src/hooks/use-export-candidates-to-csv'
import { useSetAtom } from 'jotai'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'

interface ExportCandidatesDropdownProps {
  exportOptions?: CandidateExportFileType[]
  exportToPdfPrintUrl?: string
  disabled?: boolean
  disabledTooltip?: string
}

export const ExportCandidatesDropdown = ({
  exportToPdfPrintUrl,
  exportOptions = [CandidateExportFileType.PDF, CandidateExportFileType.CSV],
  disabled = false,
  disabledTooltip
}: ExportCandidatesDropdownProps): JSX.Element => {
  const { exportCandidates } = useExportCandidatesToCsv()
  const openDialog = useSetAtom(openDialogAtom)

  const handleCsvExport = async (): Promise<void> => {
    await exportCandidates()
  }

  return (
    <Dropdown
      trigger={
        <Button
          nested
          $variant="outline"
          $colorTheme="muted"
          $height={24}
          $fontSize={12}
          trailingIcon={Icons.chevronDown}
          disabled={disabled}
          tooltip={{
            text: disabled && disabledTooltip ? disabledTooltip : undefined,
            position: 'top'
          }}
        >
          Export
        </Button>
      }
      disabled={disabled}
      items={[
        ...(exportOptions.includes(CandidateExportFileType.PDF)
          ? [{
              title: 'Export to PDF',
              icon: Icons.printer,
              onSelect: () => {
                openDialog({ id: DialogId.DOWNLOAD_PDF, payload: { exportToPdfPrintUrl } })
              }
            }]
          : []),
        ...(exportOptions.includes(CandidateExportFileType.CSV)
          ? [{
              title: 'Export to CSV',
              icon: Icons.fileSpreadsheet,
              onSelect: handleCsvExport
            }]
          : [])
      ]}
      size="small"
      menuPosition="end"
      $menuWidth={180}
      $minWidth="180px"
      $maxWidth="180px"
    />
  )
}
