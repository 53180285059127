import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import * as S from './benefits-list.styled'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import type { BrandIconName } from 'src/components/primitives/brand-icon'
import { detectIconType } from 'src/utils/detect-icon-type'

export interface Benefit {
  title: string
  description?: string
  icon: IconName | BrandIconName
}

export interface BenefitsListStyleProps {
  $variant?: 'default' | 'tint'
  $colorTheme?: 'dark' | 'light'
  $itemSize?: 'normal' | 'small'
}

interface BenefitsListProps extends BenefitsListStyleProps {
  benefits: Benefit[]
}

const renderIcon = (
  icon: IconName | BrandIconName,
  $itemSize: BenefitsListStyleProps['$itemSize'],
  $variant: BenefitsListStyleProps['$variant']
): JSX.Element | React.ReactNode => {
  if (typeof icon !== 'string') {
    return icon
  }

  const iconType = detectIconType(icon)
  const color = $variant === 'tint' ? 'tintFg' : 'fgPrimary'
  const size = $itemSize === 'small' ? 16 : 20

  switch (iconType) {
    case 'Icon': {
      return <Icon name={icon as IconName} size={size} color={color} />
    }
    case 'BrandIcon': {
      return <BrandIcon name={icon as BrandIconName} size={size} color={color} />
    }
    default: {
      return <Icon name={icon as IconName} size={size} color={color} />
    }
  }
}

interface BenefitItemProps extends BenefitsListStyleProps {
  benefit: Benefit
}

const BenefitItem = ({
  benefit,
  $colorTheme = 'dark',
  $itemSize = 'normal',
  $variant
}: BenefitItemProps): JSX.Element => {
  return (
    <S.Benefit key={benefit.title} $itemSize={$itemSize}>
      <S.Icon $colorTheme={$colorTheme} $itemSize={$itemSize} $variant={$variant}>
        {renderIcon(benefit.icon, $itemSize, $variant)}
      </S.Icon>
      <Flex $gap={4} $direction="column">
        <Caption
          $color={$colorTheme === 'light' ? 'bgPrimary' : 'fgPrimary'}
          size={$itemSize === 'small' ? 'XS' : 'MD'}
        >
          {benefit.title}
        </Caption>
        <Paragraph
          $color={$colorTheme === 'light' ? 'fgTertiary' : 'fgSecondary'}
          size={$itemSize === 'small' ? 'XS' : 'SM'}
        >
          {benefit.description}
        </Paragraph>
      </Flex>
    </S.Benefit>
  )
}

export const BenefitsList = ({
  benefits,
  $colorTheme = 'dark',
  $itemSize = 'normal',
  $variant = 'default'
}: BenefitsListProps): JSX.Element => {
  return (
    <S.BenefitsList $itemSize={$itemSize}>
      {benefits.map((benefit) => (
        <BenefitItem
          key={benefit.title}
          benefit={benefit}
          $colorTheme={$colorTheme}
          $itemSize={$itemSize}
          $variant={$variant}
        />
      ))}
    </S.BenefitsList>
  )
}
