import { useLocation } from 'react-router-dom'
import { ACCOUNT_ONBOARDING_STEPS } from 'src/libs/data'
import RouteBuilder from 'src/libs/route-builder'
import { useIsChrome } from './use-is-browser'
import { useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey } from 'src/constants'

interface UseOnboardingStepReturnType {
  currentStep?: {
    position: number
    title: string
    image: string
    nextStepPathname?: string
  }
  nextStep?: {
    href: string
  }
  totalStepsCount: number
}

export const useOnboardingSteps = (): UseOnboardingStepReturnType => {
  const { pathname } = useLocation()
  const isChrome = useIsChrome()
  const [hasScheduledDemo] = useLocalStorage(LocalStorageKey.HAS_BOOKED_DEMO_DURING_ONBOARDING, false)

  const renderedSteps = ACCOUNT_ONBOARDING_STEPS.filter(
    (step) =>
      (!step.isChromeOnly || (step.isChromeOnly && isChrome)) &&
      !step.isSeparateStep &&
      !(hasScheduledDemo && step.skipWhenAlreadyBooked)
  )

  const currentStepIndex = renderedSteps.findIndex((step) => {
    return RouteBuilder.build(step.pathname).includes(pathname)
  })

  if (currentStepIndex === -1) {
    return { currentStep: undefined, nextStep: undefined, totalStepsCount: 0 }
  }

  const currentStep = renderedSteps[currentStepIndex]
  const nextStep = currentStep.isFinalStep
    ? { pathname: 'ROOT' }
    : renderedSteps[currentStepIndex + 1]

  return {
    currentStep: {
      position: currentStepIndex + 1,
      title: currentStep.title,
      image: currentStep.image,
      nextStepPathname: nextStep ? RouteBuilder.build(nextStep.pathname) : undefined
    },
    nextStep: {
      href: nextStep ? RouteBuilder.build(nextStep.pathname) : RouteBuilder.build('ROOT')
    },
    totalStepsCount: renderedSteps.length
  }
}
