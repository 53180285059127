import { useLocation } from 'react-router-dom'
import { ConnectedEmailAccounts } from 'src/components/blocks/connected-email-accounts'
import { OnboardingHeader } from 'src/components/blocks/onboarding-header'
import { Flex, Spacer } from 'src/components/primitives'
import { Button } from 'src/components/primitives/button'
import { useOnboardingSteps } from 'src/hooks/use-onboarding-steps'
import { useSession } from 'src/hooks/use-session'

const OnboardingConnectedAccounts = (): JSX.Element => {
  const { emailAccountAccessTokens } = useSession()
  const hasConnectedAccounts = emailAccountAccessTokens?.some((token) => token.hasAccessToken)
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const skipInvite = !!params.get('skip_invite')
  const { nextStep } = useOnboardingSteps()

  return (
    <>
      <OnboardingHeader
        heading="Connect Your Accounts"
        subheading="Connect your accounts to sync inboxes and calendars, automate emails, and streamline meeting scheduling with candidates."
      />

      <ConnectedEmailAccounts />
      <Spacer $size={40} />

      <Flex $justify="flex-start">
        <Button
          $variant={hasConnectedAccounts ? 'fill' : 'raised'}
          $colorTheme={hasConnectedAccounts ? 'tint' : 'tint'}
          $width="auto"
          $height={40}
          href={skipInvite ? nextStep?.href : nextStep?.href}
        >
          {hasConnectedAccounts ? 'Continue' : 'Continue without connecting accounts'}
        </Button>
      </Flex>
    </>
  )
}

export default OnboardingConnectedAccounts
