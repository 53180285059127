import styled from 'styled-components'

export const CandidateResumeContainer = styled.div`
  width: 100%;
  height: 1325px;
  padding: ${({ theme }) => `0 ${theme.spacing[16]}`};
`

export const ResumePreview = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.fgSecondary};
  color: ${({ theme }) => theme.colors.bgPrimary};
`
