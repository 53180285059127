import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const AddDateSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  padding: 0.5rem;
  form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 48px;
    grid-column-gap: 0.5rem;
    input {
      height: 34px;
    }
  }
`

export const CustomDatesList = styled.ul`
  list-style: none;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 6px;
  overflow: hidden;
`

export const CustomDatesListItem = styled.li`
  padding: 0.5rem;
  border-bottom: solid 1px;
  display: grid;
  grid-template-columns: 1fr 1fr 48px;
  grid-column-gap: 0.5rem;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
  }
  button {
    justify-self: flex-end;
  }
`

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 2.5rem;
`
