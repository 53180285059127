const CONFIG = {
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN ?? '',
  API_DOMAIN: import.meta.env.VITE_API_DOMAIN ?? '',
  APP_DOMAIN: import.meta.env.VITE_APP_DOMAIN ?? '',
  BASE_URL: import.meta.env.BASE_URL ?? '',
  API_STATUS_PAGE_URL: 'https://status.pin.com/',
  API_STATUS_FETCH_URL: 'https://status.pin.com/api/v1/summary',
  CALENDLY_URL: 'https://calendly.com/pinrecruiting/product-demo',
  CAL_NAMESPACE: 'onboarding',
  CAL_LINK: 'team/pin/onboarding'
}

export default CONFIG
