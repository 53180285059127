import { FeatureFlags } from 'src/libs/api/backend/session'
import { RefinementFilter } from '../refinement-filter'
import { AutomateInfoBanner, AutomateInfoBannerInfo, RateThisSearch } from '../automate-info-banner'
import { Flex } from 'src/components/primitives'
import { ToggleCandidateView } from '../toggle-candidate-view'
import { useSession } from 'src/hooks/use-session'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { isRefinementErrored } from 'src/libs/api/backend/jobs'
import type { Job, JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { usePrintView } from 'src/hooks/use-print-view'
import { RefinementFilterSummary } from '../refinement-filter/refinement-filter-summary'
import { Paragraph } from 'src/components/primitives/typography'
import { ExportCandidatesDropdown } from '../export-candidates-dropdown'
import { CandidateExportFileType } from 'src/libs/api/backend/candidate_jobs'
import { When } from '../when'
import { Tooltip } from 'src/components/primitives/tooltip'
import * as S from './refinement-actions-bar.styled'
import { useEffect, useRef } from 'react'

const getCountText = (count: number): string => {
  if (count < 50) {
    return '< 50 candidates'
  }
  if (count < 500) {
    return '< 500 candidates'
  }
  if (count < 1000) {
    return '< 1k candidates'
  }
  return count >= 1000 ? `~${Math.round(count / 1000)}k candidates` : `${count} candidates`
}

interface RefinementActionsBarProps {
  jobSearchRefinement?: JobSearchRefinement
  autoApproveIsEnabled: boolean
  isRecommending: boolean
  maxWidth: string
  newSearchRefinement?: boolean
  onSubmit: (criteria: Criteria) => void
  exportToPdfPrintUrl?: string
  showExportOptions?: boolean
  showActions?: boolean
  job: Job
}

export const RefinementActionsBar = ({
  job,
  jobSearchRefinement,
  autoApproveIsEnabled,
  isRecommending,
  maxWidth,
  newSearchRefinement,
  onSubmit,
  exportToPdfPrintUrl,
  showExportOptions = true,
  showActions = true
}: RefinementActionsBarProps): JSX.Element => {
  const { featureFlags } = useSession()
  const isPrintView = usePrintView()
  const containerRef = useRef<HTMLDivElement>(null)
  const sentinelRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = containerRef.current
    const sentinel = sentinelRef.current

    if (!container || !sentinel) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (container) {
          const isSticky = !entry.isIntersecting
          container.style.setProperty('--border-opacity', isSticky ? '1' : '0')
        }
      },
      { threshold: 1.0 }
    )

    observer.observe(sentinel)

    return () => {
      observer.disconnect()
    }
  }, [])

  const actionsAreDisabled = !!newSearchRefinement || isRecommending

  if (isPrintView && jobSearchRefinement) {
    return (
      <S.RefinementActionsBarPrint $maxWidth={maxWidth}>
        <RefinementFilterSummary jobSearchRefinement={jobSearchRefinement} />
      </S.RefinementActionsBarPrint>
    )
  }
  return (
    <S.RefinementActionsBar $maxWidth={maxWidth} ref={containerRef} >
      <S.Sentinel ref={sentinelRef} />
      <S.RefinementActionsBarHeader $maxWidth={maxWidth}>
        <Flex $align='center' $gap={10} $justify='flex-start' $flex='1' $width='auto'>
          <RefinementFilter
            job={job}
            searchRefinement={jobSearchRefinement}
            newSearchRefinement={newSearchRefinement}
            isRecommending={isRecommending}
            onSubmit={onSubmit}
            criteria={jobSearchRefinement?.searchCriteria}
            isErrored={isRefinementErrored(jobSearchRefinement?.sourcingState)}
            filterType='searchRefinement'
          />
          {!isRecommending && !!jobSearchRefinement?.searchStats?.candidatesPassedFiltersCount && (
            <Tooltip
              position='bottom'
              align='start'
              trigger={
                <div>
                  <Paragraph size='XS'>
                    {getCountText(jobSearchRefinement.searchStats.candidatesPassedFiltersCount)}
                  </Paragraph>
                </div>
              }
            >
              Estimated match count. More candidates will be evaluated after you accept or decline current ones.
            </Tooltip>
          )}
        </Flex>
        <When condition={showActions}>
          <Flex $align='center' $gap={8} $justify='flex-end' $width='auto'>
            {!actionsAreDisabled && (
              <>
                <RateThisSearch disabled={isRecommending} />
              </>
            )}
            {featureFlags?.includes(FeatureFlags.AI_AUTOMATION) &&
              <AutomateInfoBanner
                autoApproveIsEnabled={autoApproveIsEnabled}
                disabled={actionsAreDisabled}
                disabledTooltip='Search in Progress'
              />
            }
            <When condition={showExportOptions}>
              <ExportCandidatesDropdown
                exportToPdfPrintUrl={exportToPdfPrintUrl}
                exportOptions={[CandidateExportFileType.PDF]}
                disabled={actionsAreDisabled}
                disabledTooltip='Search in Progress'
              />
            </When>
            <ToggleCandidateView disabled={actionsAreDisabled} disabledTooltip='Search in Progress' />
          </Flex>
        </When>
      </S.RefinementActionsBarHeader>
      {autoApproveIsEnabled && (
        <AutomateInfoBannerInfo autoApproveIsEnabled={autoApproveIsEnabled} $maxWidth={maxWidth} />
      )}
    </S.RefinementActionsBar>
  )
}
