import { Flex } from 'src/components/primitives'
import type { CustomRequirementCriteria } from 'src/libs/api/backend/candidate_search'
import { CriteriaSelection } from './criteria-selection'
import * as S from './index.styled'
import { RemoveCriteria } from './remove-criteria'
import { Paragraph } from 'src/components/primitives/typography'

interface CustomRequirementRowProps {
  criteriaValue: CustomRequirementCriteria
  onUpdate: (key: string, value: string | boolean) => void
  onRemove: () => void
  disabled: boolean
  errored?: boolean
}

export const CustomRequirementRow = ({
  criteriaValue,
  onUpdate,
  onRemove,
  disabled,
  errored
}: CustomRequirementRowProps): JSX.Element => {
  return (
    <Flex $gap={8} $align='flex-start'>
      <CriteriaSelection
        value={criteriaValue.optional ?? true}
        onUpdate={onUpdate}
        disabled={disabled}
      />
      <Flex $direction='column' $gap={4} $flex='1 1 0'>
        <S.CustomRequirementField $disabled={disabled} $errored={errored}>
          <input
            placeholder='Custom requirement'
            value={criteriaValue.requirement}
            onChange={(e) => {
              onUpdate('requirement', e.target.value)
            }}
            autoFocus={true}
          />
        </S.CustomRequirementField>
        {errored && (
          <Paragraph size='XS' $color='negativeFg'>
            Criteria violates Pin guidelines
          </Paragraph>
        )}
      </Flex>
      <S.RemoveCriteriaBox>
        <RemoveCriteria
          onRemove={onRemove}
          disabled={disabled}
        />
      </S.RemoveCriteriaBox>
    </Flex>
  )
}
