import { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { debounce } from 'lodash'
import { ComboboxProvider } from '@ariakit/react'
import * as RadixPopover from '@radix-ui/react-popover'
import { FieldLabel } from '../field-label'
import type { FieldLabelProps } from '../field-label'
import type { FieldCommonProps } from '../common'
import { FormElement } from '../form-element'
import type { IconName } from 'src/components/primitives/icon'
import { Icon } from 'src/components/primitives/icon'
import * as S from './combobox.styled'
import * as fuzzAldrin from 'fuzzaldrin-plus'
import { Flex } from 'src/components/primitives/flex'
import { Spinner } from 'src/components/primitives/spinner'

export interface ComboboxOption {
  type?: 'option' | 'label'
  value: string
  name?: string
  nameContext?: string
  symbol?: React.ReactNode
  trailingAction?: React.ReactNode
}

export interface ComboboxStyleProps {
  $height?: number
  $itemHeight?: number
}

interface ComboboxProps extends ComboboxStyleProps, FieldCommonProps, FieldLabelProps {
  selectType?: 'single' | 'multi'
  icon?: IconName
  placeholder?: string
  defaultValue?: string | string[]
  // defaultValue?: string
  maxDisplayedOptions?: number
  options: ComboboxOption[]
  acceptInputAsValue?: boolean
  onOptionSelect?: (value: string) => void
  isLoading?: boolean
}

export const Combobox = ({
  selectType = 'single',
  icon,
  options,
  placeholder = 'Select an option',
  defaultValue,
  maxDisplayedOptions,
  $marginBottom = 16,
  // acceptInputAsValue updates the formData based on the input field so
  // it does not require clicking a value from the list, meaning the component
  // can be used as both input field and select box.
  acceptInputAsValue = false,
  onOptionSelect,
  isLoading = false,
  $height,
  $itemHeight,
  ...props
}: ComboboxProps): JSX.Element => {
  const { label, name, description, register, hiddenLabel } = props
  const comboboxRef = useRef<HTMLInputElement>(null)
  const listboxRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [currentInputValue, setCurrentInputValue] = useState<string>()
  const [selectedValues, setSelectedValues] = useState<string[]>()
  // const [defaultSelected, setDefaultSelected] = useState(defaultValue)
  const [defaultSelected, setDefaultSelected] = useState(() => {
    if (selectType === 'multi' && Array.isArray(defaultValue)) {
      return defaultValue.join(',')
    }
    return defaultValue as string
  })
  const [filteredOptions, setFilteredOptions] = useState(options)
  const { onSelect, onChange } = register(name)

  useEffect(() => {
    if (options) {
      setFilteredOptions(options)
    }
  }, [options])

  useEffect(() => {
    if (defaultValue) {
      if (selectType === 'multi') {
        setSelectedValues(defaultValue as string[])
        setCurrentInputValue('')
      }
      if (selectType === 'multi' && Array.isArray(defaultValue)) {
        setDefaultSelected(defaultValue.join(','))
      }
      setDefaultSelected(defaultValue as string)
    }
  }, [defaultValue, selectType])

  const debouncedInputChange = useMemo(
    () =>
      debounce((searchValue: string) => {
        if (searchValue.trim().length >= 2) {
          const filteredList: ComboboxOption[] = fuzzAldrin.filter(options, searchValue, {
            key: 'value'
          })
          setFilteredOptions(filteredList.length ? filteredList : options)
        } else {
          setFilteredOptions(options)
        }
      }, 150),
    [options]
  )

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentInputValue(event.target.value)
      const searchValue = event.target.value.toLowerCase()
      debouncedInputChange(searchValue)
    },
    [debouncedInputChange]
  )

  const handleSelectValue = (value: string | string[]): void => {
    if (selectType === 'multi' && typeof value === 'string') {
      const existsInSelection = selectedValues?.some((sv) => sv === value)
      if (!existsInSelection) {
        const updated = [...(selectedValues ?? []), value]
        setSelectedValues(updated)
        onSelect(updated)
        setCurrentInputValue('')
        setFilteredOptions(options)
        setTimeout(() => {
          comboboxRef.current?.focus()
        }, 10)
      }
    } else {
      onSelect(value)
      onOptionSelect?.(value as string)
    }
  }

  const handleRemoveValue = (value: string): void => {
    const updated = selectedValues?.filter((selected) => selected !== value)
    setSelectedValues(updated)
    onSelect(updated)
  }

  return (
    <FormElement $marginBottom={$marginBottom}>
      <RadixPopover.Root open={open} onOpenChange={setOpen}>
        <ComboboxProvider
          key={defaultSelected}
          defaultValue={defaultSelected}
          // selectedValue={selectType === 'multi' ? 'X' : selectedValues}
          setSelectedValue={(value) => {
            handleSelectValue(value)
          }}
          open={open}
          setOpen={setOpen}
        >
          <FieldLabel label={label} htmlFor={name} description={description} hiddenLabel={hiddenLabel} />
          <RadixPopover.Anchor asChild>
            <S.ComboboxWrapper $isLoading={isLoading}>
              {icon && (
                <S.Icon>
                  <Icon name={icon} color="fgSecondary" />
                </S.Icon>
              )}
              <Flex $width="auto" $gap={6} $align="center" $height="full">
                {selectedValues?.map((value) => (
                  <S.SelectedValueButton
                    key={value}
                    onClick={() => {
                      handleRemoveValue(value)
                    }}
                    type="button"
                    aria-label={`Remove ${value} from selection`}
                  >
                    <S.ComboboxItemText>{value}</S.ComboboxItemText>
                    <Icon name="x" size={12} color="fgPrimary" />
                  </S.SelectedValueButton>
                ))}
              </Flex>
              <S.Combobox
                ref={comboboxRef}
                onChange={handleInputChange}
                onBlur={(event) => {
                  if (acceptInputAsValue) {
                    onChange(event)
                  }
                }}
                value={selectType === 'multi' ? currentInputValue : selectedValues}
                placeholder={placeholder}
                $isLoading={isLoading}
              />
              {isLoading && <Spinner />}
            </S.ComboboxWrapper>
          </RadixPopover.Anchor>
          <RadixPopover.Content
            asChild
            sideOffset={8}
            onOpenAutoFocus={(event) => {
              event.preventDefault()
            }}
            onInteractOutside={(event) => {
              const target = event.target as Element | null
              const isCombobox = target === comboboxRef.current
              const inListbox = target && listboxRef.current?.contains(target)
              if (isCombobox || inListbox) {
                event.preventDefault()
              }
            }}
          >
            <S.ComboboxList ref={listboxRef} role="listbox">
              <S.ComboboxListInner $height={$height}>
                {filteredOptions
                  ?.slice(0, maxDisplayedOptions ?? filteredOptions.length)
                  .map((option) => (
                    <>
                      {
                        option.type === 'label'
                          ? (
                            <S.ComboboxItemLabel>
                              {option.name}
                            </S.ComboboxItemLabel>
                            )
                          : (
                            <S.ComboboxItem
                              key={option.value}
                              focusOnHover
                              value={option.value}
                              onClick={() => {
                                handleSelectValue(option.value)
                              }}
                              $itemHeight={$itemHeight}
                            >
                              <Flex $align="center" $gap={8}>
                                {option.symbol && (
                                  <>{option.symbol}</>
                                )}
                                <S.ComboboxItemText>{option.name ?? option.value}</S.ComboboxItemText>
                                {option.nameContext && (
                                  <S.ComboboxItemTextContext>{option.nameContext}</S.ComboboxItemTextContext>
                                )}
                              </Flex>
                              {option.trailingAction && (
                                <S.TrailingAction>
                                  {option.trailingAction}
                                </S.TrailingAction>
                              )}
                            </S.ComboboxItem>
                            )
                      }
                    </>
                  ))}
              </S.ComboboxListInner>
            </S.ComboboxList>
          </RadixPopover.Content>
        </ComboboxProvider>
      </RadixPopover.Root>
    </FormElement>
  )
}
