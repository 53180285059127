import styled from 'styled-components'

export const AuthLayout = styled.div`
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  @media screen and (max-width: 1024px) {
    align-items: flex-start;
  }
`

export const AuthInner = styled.div`
  width: 100%;
  aspect-ratio: 10 / 6.67;
  max-width: 1200px;
  max-height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1512px), screen and (max-height: 880px) {
    height: 100%;
    max-height: unset;
    max-width: unset;
    aspect-ratio: unset;
    box-shadow: unset;
    overflow: unset;
    padding: 1.5rem;
  }
  @media screen and (max-width: 1024px) {
    aspect-ratio: unset;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    gap: 2.5rem;
    max-width: unset;
    height: 100%;
  }
  @media screen and (max-width: 768px) {
    gap: 1rem;
  }
`

const Column = styled.div`
  flex: 0 0 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
    flex: unset;
    justify-content: flex-start;
    height: 100%;
  }
`

export const AuthForm = styled(Column)`
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  padding: 6rem 6rem 10rem 6rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  @media screen and (max-width: 1024px) {
    margin: 2.5rem 2.5rem 0 2.5rem;
    width: calc(100% - 5rem);
  }
  @media screen and (max-width: 768px) {
    padding: 2rem 1.5rem 1.5rem 1.5rem;
    margin: 1rem 1rem 0 1rem;
    width: calc(100% - 2rem);
  }
`

export const AuthFormInner = styled.div`
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  @media screen and (max-width: 1024px) {
    gap: 2.5rem;
  }
  @media screen and (max-width: 768px) {
    gap: 1.75rem;
  }
`

export const Spotlight = styled.div`
  position: relative;
  flex: 0 0 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
    flex: unset;
    justify-content: flex-start;
    height: auto;
    padding: 0;
  }
`

export const SpotlightInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 2;
  @media screen and (max-width: 1440px) {
    padding-left: 1.5rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
  h1 {
    font-size: ${({ theme }) => theme.fontSizes[24]};
    font-weight: ${({ theme }) => theme.fontWeights[500]};
    color: ${({ theme }) => theme.colors.bgPrimary};
    span {
      font-size: ${({ theme }) => theme.fontSizes[24]};
      font-weight: ${({ theme }) => theme.fontWeights[400]};
      color: ${({ theme }) => theme.colors.bgPrimary};
    }
  }
`

export const QuoteBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: linear-gradient(267deg, rgba(206, 150, 239, 0.05) -0.11%, rgba(130, 146, 240, 0.05) 99.81%);
  padding: 2.5rem;
  border-radius: 0.75rem;
  margin-bottom: 2.5rem;
  @media screen and (max-width: 1440px) {
    margin-bottom: 1.5rem;
  }
  @media screen and (max-width: 1024px) {
    margin: 0 2.5rem 2.5rem 2.5rem;
    width: calc(100% - 5rem);
  }
  @media screen and (max-width: 768px) {
    padding: 1.25rem;
    margin: 0 1rem 1rem 1rem;
    width: calc(100% - 2rem);
  }
`

export const QuoteAuthorProfile = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 2;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
`

export const QuoteAuthorCompany = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
  transform: translateX(-4px);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
`

export const QuoteAuthor = styled.div`
  display: grid;
  /* grid-template-columns: 70px 1fr; */
  grid-template-columns: 40px 1fr;
  gap: 1rem;
`

export const QuoteAuthorAvatar = styled.div`
  grid-column: 1;
  height: 100%;
  display: flex;
  align-items: center;
`

export const PreviewBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const Preview = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 1 / 0.624;
  opacity: 0.75;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
  @media screen and (max-width: 1024px) {
    position: relative;
    bottom: unset;
    left: unset;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 2.5rem;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    img {
      width: 145%;
      height: auto;
      min-height: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    margin-left: 1rem;
  }
`
