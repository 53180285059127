import { useMemo } from 'react'
import { useSession } from 'src/hooks/use-session'

const ignoredDomains = ['pin.com', 'pinoutlook.com', 'input.com', 'expa.com']

interface TrialLimitsReturnType {
  limitSourcing: boolean
}

export const useTrialLimits = (): TrialLimitsReturnType => {
  const { org: currentOrg, subscribed, trialActive } = useSession()

  const limitSourcing = useMemo(() => {
    return Boolean(trialActive && !subscribed && !ignoredDomains.includes(currentOrg?.domain ?? ''))
  }, [trialActive, subscribed, currentOrg?.domain])

  return { limitSourcing }
}
