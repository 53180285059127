import { useEffect } from 'react'
import { EmptyState } from 'src/components/blocks/empty-state'
import { CandidatesTable } from '../candidates-table'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { RejectButton } from '../candidate-table-actions'
import { useQueryParams } from 'src/hooks/use-query-params'
import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import { COLUMN } from '../candidate-table-cells/table-builder'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import type { WriteMessageDialogProps } from 'src/components/dialogs/write-message-dialog'

export const CandidatesShortlistedTable = (): JSX.Element => {
  const { getParam } = useQueryParams()
  const { jobId } = useParams()
  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    favorite: true
  })

  // For extension to open dialog with write email
  const writeManualEmailParams = getParam('writeManualEmail')
  const openDialog = useSetAtom(openDialogAtom)

  useEffect(() => {
    if (writeManualEmailParams && !isNil(candidateJobs)) {
      const payload: WriteMessageDialogProps = {
        candidateJobs: candidateJobs.filter((candidateJob) => candidateJob.candidateId === writeManualEmailParams)
      }
      openDialog({
        id: DialogId.WRITE_MESSAGE,
        payload
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeManualEmailParams, candidateJobs])

  if (!candidateJobs) {
    return <></>
  }

  return (
    <CandidatesTable
      visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.STATUS, COLUMN.SOURCE, COLUMN.CREATED_AT]}
      isLoading={isPending}
      candidateJobs={candidateJobs}
      pageHeaderHeight={64}
      emptyState={
        <EmptyState
          heading="Shortlisted"
          description="Shortlist candidates from Sourcing to create a list of favorite candidates to share with teammates."
          svg="shortlistedCandidates"
          $height="100%"
          actions={[
            {
              href: `/jobs/${jobId}/candidates/sourcing`,
              children: 'Go to sourcing'
            }
          ]}
        />
      }
      selectedRowsActions={
        <>
          <RejectButton />
        </>
      }
    />
  )
}
