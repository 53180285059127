import { CommonFieldStyles } from 'src/components/forms/common/common.styled'
import styled from 'styled-components'

export const CompaniesSearch = styled.div``

export const SearchInput = styled(CommonFieldStyles)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 0.75rem;
  gap: 0.5rem;
  svg {
    flex-grow: 0;
    flex-shrink: 0;
  }
  input {
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
    padding: 0 !important;
    &:focus {
      outline: none;
    }
  }
`

export const LoadingSpinner = styled.div`
  position: absolute;
  top: 0;
  right: 0.75rem;
  width: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Result = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  top: 36px;
  left: -1px;
  width: calc(100% + 2px);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  overflow-y: auto;
  z-index: 2;
`

export const ResultInner = styled.div`
  padding: 0.375rem;
`

export const ItemCta = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;
  opacity: 0;
  transition: opacity 0.15s ease;
`

export const ResultItem = styled.div<{ $isFocused: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  border-radius: 3px;
  ${({ $isFocused, theme }) => $isFocused && `
    background-color: ${theme.colors.bgTertiary};
    [data-name="result-item-cta"] {
      opacity: 1;
    }
  `}
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgTertiary};
      ${ItemCta} {
        opacity: 1;
      }
    }
  }
`
