import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import * as S from './checkbox.styled'
import { Paragraph } from 'src/components/primitives/typography'
import { Tooltip } from '../tooltip'

interface CheckboxProps {
  label: string
  name: string
  checked: boolean
  onCheckedChange: (checked: boolean) => void
  description?: string
  $variant?: S.CheckboxVariant
  disabled?: boolean
  tooltip?: {
    icon: IconName
    text: string
    position?: 'top' | 'bottom' | 'left' | 'right'
  }
}

// This component does not use form register function because it is not a form element
export const Checkbox = ({
  checked,
  label,
  name,
  description,
  onCheckedChange,
  $variant = 'plain',
  disabled = false,
  tooltip
}: CheckboxProps): JSX.Element => {
  return (
    <S.Checkbox $variant={$variant} $isChecked={checked}>
      <S.CheckboxRoot
        id={name}
        checked={checked}
        disabled={disabled}
        onCheckedChange={(checked: boolean) => {
          onCheckedChange(checked)
        }}
        $hasDescription={Boolean(description)}
      >
        <S.CheckboxIndicator>
          <Icon name="check" color="tintBg" size={12} />
        </S.CheckboxIndicator>
      </S.CheckboxRoot>
      <S.LabelWrapper>
        <S.Label htmlFor={name}>{label}</S.Label>
        {description && <Paragraph size="XS" $color="fgSecondary">{description}</Paragraph>}
      </S.LabelWrapper>
      {tooltip && (
        <Tooltip
          trigger={
            <span>
              <Icon name={tooltip.icon} color="fgTertiary" size={12} />
            </span>
          }
          position={tooltip.position}
        >
          {tooltip.text}
        </Tooltip>
      )}
    </S.Checkbox>
  )
}
