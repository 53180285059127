import { z } from 'zod'
import Api from '../'
import CONFIG from 'src/config'

const timezonesParser = z.object({
  timezones: z.array(z.object({
    name: z.string(),
    offset: z.string()
  }))
})

type Timezones = z.infer<typeof timezonesParser>

export type Timezone = Timezones['timezones'][number]

export async function fetchTimezones (): Promise<Timezone[]> {
  const res = await Api.get<string[]>('/configuration/timezones')
  const timezones = timezonesParser.parse(res.data)
  return timezones.timezones
}

const timezonesAndHolidaysParser = z.object({
  timezones: timezonesParser.shape.timezones,
  holidays: z.array(z.object({
    name: z.string(),
    date: z.string()
  }))
})

export type TimezonesAndHolidays = z.infer<typeof timezonesAndHolidaysParser>

export async function fetchTimezonesAndHolidays (): Promise<TimezonesAndHolidays> {
  const res = await Api.get<TimezonesAndHolidays>('/configuration')
  const { timezones, holidays } = timezonesAndHolidaysParser.parse(res.data)
  return {
    timezones,
    holidays
  }
}

export const apiStatusResponseSchema = z.object({
  page_title: z.string(),
  page_url: z.string().url(),
  ongoing_incidents: z.array(z.object({
    name: z.string().optional(),
    status: z.string().optional(),
    url: z.string().url().optional(),
    id: z.string().optional(),
    current_worst_impact: z.string().optional(),
    affected_components: z.array(z.object({
      id: z.string().optional(),
      name: z.string().optional()
    })).optional(),
    last_update_at: z.string().datetime().optional(),
    last_update_message: z.string().optional()
  })).optional(),
  in_progress_maintenances: z.array(z.unknown()).optional(),
  scheduled_maintenances: z.array(z.unknown()).optional()
})

export type ApiStatusResponse = z.infer<typeof apiStatusResponseSchema>

export async function fetchApiStatus (): Promise<ApiStatusResponse> {
  const response = await fetch(CONFIG.API_STATUS_FETCH_URL)
  return apiStatusResponseSchema.parse(await response.json())
}
