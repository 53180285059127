import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCreateJobSearchRefinement } from 'src/hooks/mutations/use-create-job-search-refinement'
import RouteBuilder from 'src/libs/route-builder'
import { RefinementActionsBar } from 'src/components/blocks/refinement-actions-bar'
import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING } from 'src/styles/constants'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { JobSearchRefinementType } from 'src/libs/api/backend/jobs'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { isNil } from 'lodash'

const NewJobSearchRefinementPage = (): JSX.Element => {
  const [isRecommending, setIsRecommending] = useState(false)
  const { createJobSearchRefinement } = useCreateJobSearchRefinement()
  const navigate = useNavigate()
  const { data: job, isPending: isJobPending } = useJobQuery()

  const onSubmit = useCallback((searchCriteria: Criteria) => {
    setIsRecommending(true)
    if (job?.id) {
      createJobSearchRefinement({
        jobId: job.id,
        searchCriteria,
        type: JobSearchRefinementType.SEARCH_REFINEMENT,
        onSuccess: (jobSearchRefinement) => {
          navigate(RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId: job.id, jobSearchRefinementId: jobSearchRefinement.id }))
        }
      })
    }
  }, [createJobSearchRefinement, job, navigate])

  if (isJobPending || isNil(job)) {
    return (
      <div
        style={{
          flex: 1,
          marginLeft: CONTENT_PADDING,
          maxWidth: CANDIDATES_PAGES_MAX_WIDTH
        }}
      >
        <LoadingSkeleton $variant="CandidateDetailsCard" delay={200} />
      </div>
    )
  }

  return (
    <div
      style={{
        paddingBottom: CONTENT_PADDING,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative'
      }}
    >
      <div style={{ marginLeft: CONTENT_PADDING }}>
        <CandidatesPageHeader heading="New Pin Search" $padding={{ bottom: 2 }} />
      </div>
      <RefinementActionsBar
        autoApproveIsEnabled={false}
        isRecommending={isRecommending}
        maxWidth={CANDIDATES_PAGES_MAX_WIDTH}
        newSearchRefinement={true}
        onSubmit={onSubmit}
        showExportOptions={false}
        showActions={false}
        job={job}
      />
    </div>
  )
}

export default NewJobSearchRefinementPage
