import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { isNil } from 'lodash'
import { createDepartment } from 'src/libs/api/backend/departments'
import type { Department } from 'src/libs/api/backend/departments'
import { queryKeys } from 'src/libs/query-keys'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  name: string
  domain?: string | null
  stealth?: boolean
  onSuccess?: (newDept: Department) => void
  onError?: () => void
}

interface Res {
  createDepartmentByOrg: ({ name, domain, stealth, onSuccess, onError }: Args) => void
}

export const useCreateDepartment = (): Res => {
  const queryClient = useQueryClient()
  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async ({ name, domain, stealth }: Args) => await createDepartment(name, domain, stealth),
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred while creating your department: ${err.message}`
      })

      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (newDept: Department, variables) => {
      let newDepartment = true
      queryClient.setQueryData<Department[]>([queryKeys.departments], (oldDepartments) => {
        if (isNil(oldDepartments)) {
          return
        }

        const updatedDepartments = oldDepartments.map((dept) => {
          if (dept.id === newDept.id) {
            newDepartment = false
            return newDept
          }
          return dept
        })
        const results = updatedDepartments
        if (newDepartment) {
          results.push(newDept)
        }

        return results.sort((a, b) => a.deleted === b.deleted ? 0 : a.deleted ? 1 : -1)
      })

      // await queryClient.invalidateQueries({
      //   queryKey: [queryKeys.departments]
      // })

      notifySuccess({
        message: newDepartment ? 'Company successfully created' : 'Company successfully updated'
      })
      if (variables.onSuccess) {
        variables.onSuccess(newDept)
      }
    }
  })

  const createDepartmentByOrg = ({ name, domain, stealth, onSuccess, onError }: Args): void => {
    mutation.mutate({ name, domain, stealth, onSuccess, onError })
  }

  return { createDepartmentByOrg }
}
